import {DetailsView} from '../../../../bonding_shared/components/details-view/details-view';
import {Component, OnInit, Output, ViewChild} from '@angular/core';
import {
  BusinessObjectDto,
  ClientInfoDto,
  CompanyBaseDto,
  CompanyContactNoteDto,
  CompanyDto,
  CompanyRatingVersionDto,
  ContactNoteAndTaskDto,
  ContactNoteDto,
  ContactNoteReasonDto,
  ContactPersonCriteriaDto,
  ContactPersonDto,
  DictionaryBaseDto,
  SalesLeadDto,
  SatisfactionSurveyDto,
  UserSimpleDto,
} from '../../../../bonding_shared/model/dtos';
import {NgForm} from '@angular/forms';
import {ComboItem} from '../../../../bonding_shared/components/combos/string-combo.component';
import {ActivatedRoute, Params} from '@angular/router';
import {ContactNoteService} from '../../../../bonding_shared/services/contact-note.service';
import {UserService} from '../../../../bonding_shared/services/user.service';
import {ContactPersonDisplayNamePipe} from '../../../../bonding_shared/pipes/contact-person-display-name.pipe';
import {GrowlService} from '../../../../bonding_shared/services/growl/growl.service';
import {
  ApplicationModule,
  BusinessObjectType,
  ClientStatus,
  CompanyContactNoteRole,
  ContactNoteElementaryRight,
  ContactPersonGroupType,
  ElementaryRight,
  Feature,
} from '../../../../bonding_shared/model/dictionary-ids';
import {ConfirmDialogComponent} from '../../../../bonding_shared/components/confirm-dialog';
import {
  AppConfigService,
  BusinessObjectService,
  CompanyService,
  FeatureService,
  LoggedUserService,
  RouterService,
  SettingsService,
} from '../../../../bonding_shared/services';
import {CompanyRatingService} from '../../../../bonding_shared/services/company-rating.service';
import {ContactNoteTaskComponent} from './embedded/contact-note-task.component';

@Component({
  selector: 'contact-note-details',
  templateUrl: './contact-note-details.component.pug',
})
export class ContactNoteDetailsComponent extends DetailsView implements OnInit {
  contactNote: ContactNoteDto = <ContactNoteDto>{
    company: <CompanyBaseDto>{},
    businessObject: <BusinessObjectDto>{},
    contactNoteReason: <ContactNoteReasonDto>{},
    satisfactionSurvey: <SatisfactionSurveyDto>{},
    directContact: true,
  };
  companyContactPersonCriteria = <ContactPersonCriteriaDto>{};
  _hiddenIds: Set<number> = new Set<number>();
  companyId: number;
  createTask = false;
  createSalesLead = false;
  businessObjectLocked = false;
  users: UserSimpleDto[];
  allowEditSelectedFields = false;
  readonly CompanyContactNoteRole = CompanyContactNoteRole;
  readonly BusinessObjectType = BusinessObjectType;
  readonly hiddenModules = new Set([
    ApplicationModule.ADMINISTRATION,
    ApplicationModule.BONDING,
    ApplicationModule.SALES,
  ]);
  readonly ContactNoteElementaryRight = ContactNoteElementaryRight;
  readonly writeRightMap = new Map<ApplicationModule, ContactNoteElementaryRight>([
    [null, ContactNoteElementaryRight.CONTACT_NOTE_WRITE_COMPANIES],
    [ApplicationModule.POLICY, ContactNoteElementaryRight.CONTACT_NOTE_WRITE_POLICIES],
    [ApplicationModule.BONDING, ContactNoteElementaryRight.CONTACT_NOTE_WRITE_BONDING],
    [ApplicationModule.FINANCE, ContactNoteElementaryRight.CONTACT_NOTE_WRITE_FINANCE],
    [ApplicationModule.CLAIM, ContactNoteElementaryRight.CONTACT_NOTE_WRITE_CLAIMS],
    [ApplicationModule.COLLECTION, ContactNoteElementaryRight.CONTACT_NOTE_WRITE_COLLECTIONS],
    [ApplicationModule.LIMIT, ContactNoteElementaryRight.CONTACT_NOTE_WRITE_LIMITS],
  ]);

  filterContactReasonByBusinessObjectType = false;
  parentDictionaryForContactReason: string;

  businessObject = <BusinessObjectDto>{};
  companyPerson: ContactPersonDto;
  boPerson: ContactPersonDto;

  public errorMessage: string;

  parentDictionaryForCompanyRole: string;

  rating: CompanyRatingVersionDto = undefined;
  ratingOpened = false;
  clientInfo: ClientInfoDto;
  rate: number;
  @ViewChild('deleteConfirm', {static: true}) confirmDialog: ConfirmDialogComponent;
  _contactNoteTask: ContactNoteTaskComponent;
  @ViewChild('contactNoteTask')
  set contactNoteTask(contactNoteTask: ContactNoteTaskComponent) {
    this._contactNoteTask = contactNoteTask;
  }

  get contactNoteTask(): ContactNoteTaskComponent {
    return this._contactNoteTask;
  }

  @ViewChild('ngForm', {static: true}) ngForm: NgForm;
  protected companies: CompanyContactNoteDto[];

  @Output() set hiddenIds(hiddenIds: Set<number>) {
    this._hiddenIds = hiddenIds;
  }

  get hiddenIds() {
    return this._hiddenIds;
  }

  satisfactionRateItems: ComboItem[] = [
    new ComboItem('1', '1'),
    new ComboItem('2', '2'),
    new ComboItem('3', '3'),
    new ComboItem('4', '4'),
    new ComboItem('5', '5'),
  ];

  get companyRoleDisabled(): boolean {
    return (
      (!this.kuke && this.contactNote.id > 0 && !this.allowEditSelectedFields) ||
      (this.kuke && this.contactNote.companyContactNoteRole && this.contactNote.companyContactNoteRole.id > 0)
    );
  }

  private getBoTypeEquals(boTypeId: number): boolean {
    return (
      this.contactNote.contactNoteReason &&
      this.contactNote.contactNoteReason.businessObject &&
      this.contactNote.contactNoteReason.businessObject.relatedTo &&
      this.contactNote.contactNoteReason.businessObject.relatedTo.id === boTypeId
    );
  }

  get applicationModuleChangeEnabled(): boolean {
    return (
      (this.kuke && this.loggedUserService.hasRight(ElementaryRight.CRM_MODULE_CHANGE)) ||
      (!this.kuke && (!this.contactNote.id || this.contactNote.id === 0 || this.allowEditSelectedFields))
    );
  }

  get getBOParentId(): number {
    if (this.businessObjectLocked || !this.contactNote.applicationModule) {
      return null;
    }
    return this.contactNote.applicationModule.id;
  }

  constructor(
    public appService: AppConfigService,
    private userService: UserService,
    private _route: ActivatedRoute,
    protected growlService: GrowlService,
    private contactNoteService: ContactNoteService,
    private companyService: CompanyService,
    private ratingService: CompanyRatingService,
    private loggedUserService: LoggedUserService,
    public _router: RouterService,
    public _settings: SettingsService,
    private businessObjectService: BusinessObjectService,
    private featureService: FeatureService
  ) {
    super(growlService);
    this.hideButtons(false);
    this.newVersionButton.hidden = true;
    this.filterContactReasonByBusinessObjectType = featureService.get(
      Feature.FILTER_CONTACT_REASON_BY_BUSINESS_OBJECT_TYPE
    );
  }

  ngOnInit(): void {
    this.form = this.ngForm.form;
    this._route.params.subscribe((params) => this.initializeView(params));
    if (this.filterContactReasonByBusinessObjectType) {
      this.parentDictionaryForContactReason = 'BusinessObjectType';
    }
  }

  initializeView(params: Params) {
    this.deleteButton.hidden = true;
    this.saveButton.disabled = true;
    const noteId: number = +params['noteId'];
    if (noteId && noteId > 0) {
      this.contactNoteService.getContactNote(noteId).subscribe({
        next: (contact) => {
          this.contactNote = contact;
          this.companyId = contact.company.id;
          this.companyPerson = contact.contactPerson;
          this.refreshCompanyContactPersonCriteria();
          this.loadRating();
          this.loadClientInfo();
          this.deleteButton.hidden =
            this.contactNote.deleted ||
            !this.loggedUserService.hasRight(ContactNoteElementaryRight.CONTACT_NOTE_DELETE);
        },
        error: (error) => this.handleServerError(error),
      });
    } else {
      this.initContactNote();
      this.loadRating();
      this.loadClientInfo();
    }

    this.selectorNameList = ['BOContactPerson', 'CompanyContactPerson', 'Broker'];
    this.initializeSelectorEmitters(true);
    this.loadUsers();
    this.filterCompanyRoleByModule();
  }

  filterCompanyRoleByModule() {
    if (this.kuke) {
      this.parentDictionaryForCompanyRole = 'ApplicationModule';
    } else {
      this.parentDictionaryForCompanyRole = 'BusinessObjectType';
    }
  }

  getParentDictionaryEntryIdForCompanyRole(): number {
    if (this.kuke) {
      return this.contactNote?.applicationModule?.id;
    } else {
      return this.contactNote?.contactNoteReason?.businessObject?.relatedTo?.id;
    }
  }

  initContactNote(): void {
    this.contactNote = <ContactNoteDto>{
      company: <CompanyBaseDto>{},
      businessObject: <BusinessObjectDto>{},
      contactNoteReason: <ContactNoteReasonDto>{},
      satisfactionSurvey: <SatisfactionSurveyDto>{},
      directContact: true,
    };
    this.showErrors = false;
    this._route.queryParams.subscribe((params) => {
      if (params['boType'] && params['boId'] && params['boType'] > 0 && params['boId'] > 0) {
        this.businessObjectService.getDto(params['boType'], params['boId']).subscribe((bo) => {
          this.contactNote.contactNoteReason.businessObject = bo;
          this.businessObjectLocked = true;
          this.saveButton.disabled = false;
        });
      }
      if (params['companyRoleId'] && params['companyRoleId'] > 0) {
        this.contactNote.companyContactNoteRole = <DictionaryBaseDto>{id: +params['companyRoleId']};
      }
      if (params['contactNoteReasonId'] && params['contactNoteReasonId'] > 0) {
        this.contactNote.contactNoteReason.reasonType = <DictionaryBaseDto>{id: +params['contactNoteReasonId']};
      }
      this.contactNote.applicationModule = <DictionaryBaseDto>{id: this._settings.getActiveModuleId()};
      if (params['companyId'] && params['companyId'] > 0) {
        this.companyId = params['companyId'];
        this.refreshCompanyContactPersonCriteria();
        this.loadCompany();
      }
    });
  }

  private loadCompany() {
    this.companyService.getCompanySimple(this.companyId).subscribe({
      next: (company) => {
        this.contactNote.company = company;
      },
      error: (error) => console.log(error),
    });
  }

  onDirectContactChange(checked: boolean) {
    this.clearContactPersons();
    this.contactNote.brokerCompany = null;
    if (checked) {
      this.refreshCompanyContactPersonCriteria();
    }
  }

  isClient(): boolean {
    if (this.clientInfo.status === undefined) {
      return false;
    }
    if (this.clientInfo && this.clientInfo.status) {
      return this.clientInfo.status.id === ClientStatus.CLIENT;
    }
    return false;
  }

  getContactPersonFullName(cp: ContactPersonDto) {
    return new ContactPersonDisplayNamePipe().transform(cp);
  }

  brokerSelect(c: CompanyDto) {
    this.contactNote.brokerCompany = c;
    this.companyContactPersonCriteria.companyId = c.id;
    this.clearContactPersons();
  }

  refreshCompanyContactPersonCriteria() {
    this.companyContactPersonCriteria = <ContactPersonCriteriaDto>{};
    this.companyContactPersonCriteria.companyId = this.companyId;
    this.businessObject.relatedToId = this.companyId;
    this.businessObject.relatedTo = <DictionaryBaseDto>{id: BusinessObjectType.COMPANY};
  }

  loadUsers() {
    this.userService.getIntranetUsers().subscribe(
      (searchResult) => {
        this.users = searchResult;
      },
      (error) => (this.errorMessage = <any>error)
    );
  }

  loadRating() {
    if (this.companyId > 0) {
      this.ratingService.getLastCompanyRatingVersion(this.companyId).subscribe(
        (rating) => {
          this.rating = rating;
          this.ratingOpened = true;
        },
        (error) => (this.rating = undefined)
      );
    }
  }

  loadClientInfo() {
    if (this.companyId > 0) {
      this.companyService.getClientInfo(this.companyId).subscribe(
        (clientInfo) => {
          this.clientInfo = clientInfo;
        },
        (error) => (this.clientInfo = undefined)
      );
    }
  }

  canSeeRating() {
    return this.loggedUserService.hasRight(ElementaryRight.ACCESS_MODULE_RATING);
  }

  showSavedMsg() {
    this.growlService.notice('Contact note is saved!');
  }

  showErrorMsg() {
    this.growlService.error('Error when saving!');
  }

  showFormError() {
    this.showErrors = true;
    this.growlService.error('The form has errors!');
  }

  onSave() {
    if (
      !(
        this.contactNote.contactNoteReason.businessObject &&
        this.contactNote.contactNoteReason.businessObject.relatedToId > 0
      )
    ) {
      this.createTask = false;
    }
    if (this.form.invalid || (this.createTask && this.contactNoteTask.ngForm.form.invalid)) {
      this.showFormError();
      return;
    }
    if (this.createTask) {
      const cnt = <ContactNoteAndTaskDto>{};
      cnt.task = this.contactNoteTask.task;
      cnt.note = this.contactNote;
      this.contactNoteService.createContactNoteAndTask(cnt).subscribe(
        (contactNote) => {
          this.showSavedMsg();
          if (!this.clientInfo || !this.clientInfo.status) {
            this.loadClientInfo();
          }
          this._router.toCompanyCrm(
            this.companyId,
            this.contactNote.contactNoteReason.businessObject.relatedTo.id,
            this.contactNote.contactNoteReason.businessObject.relatedToId
          );
        },
        (error) => {
          this.showErrorMsg();
          this.handleServerError(error);
        }
      );
    } else {
      this.contactNoteService.saveContactNote(this.contactNote).subscribe(
        (contactNote) => {
          this.showSavedMsg();
          if (!this.clientInfo || !this.clientInfo.status) {
            this.loadClientInfo();
          }
          this._router.toCompanyCrm(
            this.companyId,
            this.contactNote.contactNoteReason.businessObject.relatedTo.id,
            this.contactNote.contactNoteReason.businessObject.relatedToId
          );
        },
        (error) => {
          this.showErrorMsg();
          this.handleServerError(error);
        }
      );
    }
  }

  onCancel() {
    this._router.toCompanyCrm(this.companyId);
  }

  onDelete() {
    if (this.contactNote.id) {
      const confirmationPromise: Promise<boolean> = this.confirmDialog.open(
        'company.crm.deleteConfirm.title',
        'company.crm.deleteConfirm.message'
      );
      confirmationPromise.then((result) => {
        this.deleteContact(result);
      });
    }
  }

  deleteContact(shouldDelete: boolean) {
    if (shouldDelete) {
      this.contactNoteService.deleteContactNote(this.contactNote.id).subscribe(() => {
        this._router.toCompanyCrm(this.companyId);
      });
    }
  }

  createSalesLeadChange(check: boolean) {
    if (check) {
      this.contactNote.createSalesLead = <SalesLeadDto>{};
    } else {
      this.contactNote.createSalesLead = undefined;
    }
  }

  get publicNoteVisible(): boolean {
    return (
      this.isCollectionUser() &&
      (!this.kuke || this.contactNote?.applicationModule?.id === ApplicationModule.COLLECTION)
    );
  }

  isCollectionUser(): boolean {
    return this.loggedUserService.isCollectionUser();
  }

  get getBoContactPersonCriteria(): ContactPersonCriteriaDto {
    const boContactPersonCriteria = <ContactPersonCriteriaDto>{};
    const bo = this.contactNote.contactNoteReason.businessObject;
    if (bo && bo.relatedTo) {
      boContactPersonCriteria.boId = bo.relatedToId;
      boContactPersonCriteria.boTypeId = bo.relatedTo.id;
    }

    if (this.contactNote.companyContactNoteRole?.id === CompanyContactNoteRole.CREDITOR) {
      boContactPersonCriteria.groupType = <DictionaryBaseDto>{id: ContactPersonGroupType.CREDITOR};
    } else if (this.contactNote.companyContactNoteRole?.id === CompanyContactNoteRole.DEBTOR) {
      boContactPersonCriteria.groupType = <DictionaryBaseDto>{id: ContactPersonGroupType.DEBTOR};
    }

    return boContactPersonCriteria;
  }

  setCompanyContactPerson(cp: ContactPersonDto) {
    this.contactNote.contactPerson = cp;
    this.companyPerson = cp;
    this.boPerson = null;
  }

  onSelectBOContactPerson(cp: ContactPersonDto) {
    this.contactNote.contactPerson = cp;
    this.boPerson = cp;
    this.companyPerson = null;
  }

  onBOSelected(event: Boolean) {
    console.log('BO emited change');
    this.saveButton.disabled = true;
    if (this.contactNote.contactNoteReason.businessObject) {
      const bo = this.contactNote.contactNoteReason.businessObject;
      this.contactNoteService.getCrmCompanies(bo.relatedTo.id, bo.relatedToId).subscribe(
        (result) => {
          this.companies = result;
        },
        (error) => console.log('getCrmCompanies server error or warning !', error)
      );
    } else {
      this.companyPerson = null;
      this.boPerson = null;
      this.companies = null;
      this.openSelectorEmitters['BOContactPerson'].next(false);
      this.openSelectorEmitters['CompanyContactPerson'].next(false);
      this.initContactNote();
    }
  }

  onSelectCompany(companyContact: CompanyContactNoteDto) {
    this.companyService.getCompanyBaseDto(companyContact.company.id).subscribe(
      (company) => {
        this.contactNote.company = company;
        this.companyId = company.id;
        this.refreshCompanyContactPersonCriteria();
      },
      (error) => console.log('getCompanyBaseDto server error or warning !', error)
    );
    this.contactNote.companyContactNoteRole = companyContact.companyContactNoteRole;
    this.saveButton.disabled = false;
  }

  get kuke() {
    return this.appService.kuke;
  }

  get mehib() {
    return this.appService.mehib;
  }

  onAllowEdit() {
    this.allowEditSelectedFields = !this.allowEditSelectedFields;
    this.saveButton.disabled = !this.allowEditSelectedFields;
  }

  getDescriptionLength() {
    if (this.kuke) {
      return 3800;
    } else {
      return 15000;
    }
  }

  clearContactPersons() {
    this.contactNote.contactPerson = null;
    this.companyPerson = null;
    this.boPerson = null;
  }

  canEdit() {
    if (this.contactNote.deleted) {
      return false;
    }
    return this.loggedUserService.hasAnyRight([
      ContactNoteElementaryRight.CONTACT_NOTE_WRITE_ALL,
      this.writeRightMap.get(this.contactNote.applicationModule?.id),
    ]);
  }
}
