import {Component, ViewChild} from '@angular/core';
import {RouterService} from '../../bonding_shared/services/router-service';
import {FINoteCriteriaDto, FINoteSimpleDto} from '../../bonding_shared/model/dtos';
import {SearchDataProvider} from '../../bonding_shared/services/search-data-provider';
import {ATableComponent} from '../../bonding_shared/components/aku-table/a-table.component';
import {FINoteCategory} from '../../bonding_shared/model/dictionary-ids';
import {ActivatedRoute, Params} from '@angular/router';
import {SearchViewComponent} from '../../bonding_shared/components/search/search-view/search-view.component';
import {DictionaryBaseService, InvoiceCoreService, LoggedUserService} from '../../bonding_shared/services';
import {AbstractInvoiceComponent} from '../../bonding_shared/components/shared/abstract-invoice.component';
import {TranslateService} from '@ngx-translate/core';
import {FINoteGuiService} from './services/fi-note-gui.service';

@Component({
  selector: 'fi-note-search',
  templateUrl: 'fi-note-search.component.html',
})
export class FINoteSearchComponent extends AbstractInvoiceComponent {
  @ViewChild(SearchViewComponent, {static: true}) searchView: SearchViewComponent<FINoteCriteriaDto, FINoteSimpleDto>;
  @ViewChild(ATableComponent, {static: true}) invoiceTable: ATableComponent<FINoteSimpleDto>;

  public dataProvider: SearchDataProvider<FINoteCriteriaDto, FINoteSimpleDto>;
  captionKey: string;
  newObjectLabelKey: string;

  constructor(
    public router: RouterService,
    protected route: ActivatedRoute,
    public guiService: FINoteGuiService,
    protected dictionaryBaseService: DictionaryBaseService,
    protected translateService: TranslateService,
    protected invoiceCoreService: InvoiceCoreService,
    protected loggedUserService: LoggedUserService
  ) {
    super(dictionaryBaseService, route, translateService, invoiceCoreService, loggedUserService);
  }

  getSearchView(): SearchViewComponent<any, any> {
    return this.searchView;
  }

  protected initializeView(params: Params) {
    const categoryStr: string = params['category'];
    const categoryId = FINoteCategory[categoryStr];
    this.dataProvider = this.guiService.getDataProvider(categoryId);
    if (this.categoryId !== categoryId) {
      if (this.categoryId) {
        // We search again when category is changed. We must avoid double search (SearchViewComponent executes search after view init).
        this.searchView.newProviderSearch(this.dataProvider);
      }
      this.categoryId = categoryId;
    }
    this.captionKey = this.getCategoryTranslationKey('fiNote.search.caption');
    this.newObjectLabelKey = this.getCategoryTranslationKey('fiNote.search.newObject');
  }

  newExecuted(event) {
    this.router.toFINoteDetails(this.categoryId, 0);
  }
}
