import {Component, Input} from '@angular/core';
import {
  PolicyInquiryOfferDto,
  PolicyInquiryOfferSimpleDto,
  PolicyInquiryVersionDto,
  RouterService,
  StringUtils,
} from '../../../bonding_shared';
import {PolicyInquiryOfferVotingStatus, PolicyInquiryStatus} from '../../../bonding_shared/model/dictionary-ids';
import {PolicyContractViewSection, PolicyContractViewService} from '../../policy/services/policy-contract-view.service';
import * as _ from 'lodash';
import {CustomButton} from '../../../bonding_shared/components/aku-table/table.component';

@Component({
  selector: 'mehib-inquiry-offers',
  templateUrl: 'mehib-inquiry-offers.component.pug',
})
export class MehibInquiryOffersComponent {
  @Input() addOfferButton = true;
  @Input() set inquiry(value: PolicyInquiryVersionDto) {
    this._inquiry = value;
    if (this._inquiry) {
      this.offers = this._inquiry.offers;
    }
    this.initializeNewOfferButton();
  }
  private _inquiry: PolicyInquiryVersionDto;
  offers: PolicyInquiryOfferDto[] = [];
  customButtons: CustomButton[];

  constructor(public router: RouterService, public policyContractViewService: PolicyContractViewService) {}

  initializeNewOfferButton() {
    if (this.isNewOfferAllowed()) {
      const newOfferButton = <CustomButton>{
        title: 'New offer from this one',
        class: 'btn-sm btn-rounded btn-info fa fa-clone',
        alignment: 'right',
      };
      this.customButtons = [newOfferButton];
    }
  }

  goToOffer(offerId: number) {
    this.router.toInquiryOfferDetails(this._inquiry.id, offerId);
  }

  newOffer(offer?: PolicyInquiryOfferSimpleDto) {
    const sourceOfferId = offer ? offer.id : null;
    this.router.toNewInquiryOfferDetails(this._inquiry.id, this._inquiry.productType.id, sourceOfferId);
  }

  isNewOfferAllowed(): boolean {
    return (
      this._inquiry &&
      this._inquiry.status &&
      this._inquiry.status.id === PolicyInquiryStatus.POLICY_INQUIRY_ACCEPTED &&
      this.addOfferButton
    );
  }

  getVoters(offer: PolicyInquiryOfferDto, approval: boolean): string[] {
    if (!offer.votes || offer.votes.length < 1) {
      return [];
    }
    return offer.votes.filter((v) => v.approve === approval).map((v) => StringUtils.userName(v.user));
  }
}
