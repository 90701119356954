import {Component, OnInit, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import {ActivatedRoute, Params} from '@angular/router';
import {DetailsView} from '../../bonding_shared/components/details-view/details-view';
import {
  AppConfigService,
  CountryRatingDto,
  CountryRatingService,
  CountryRatingVersionDto,
  DictionaryDto,
  GrowlService,
  StringUtils,
} from '../../bonding_shared';
import {
  CountryRatingElementaryRight,
  CountryRatingSettingType,
  OECDRating,
} from '../../bonding_shared/model/dictionary-ids';

@Component({
  selector: 'country-rating-details',
  templateUrl: './country-rating-details.component.pug',
})
export class CountryRatingDetailsComponent extends DetailsView implements OnInit {
  readonly CountryRatingElementaryRight = CountryRatingElementaryRight;

  ratingVersion: CountryRatingVersionDto;
  ratingVersions: CountryRatingVersionDto[];
  ratingType: string;
  showOECD: boolean;
  showInsurer: boolean;
  insurerRequired: boolean;
  OECDRequired: boolean;
  availableCurrencies: RegExp = this.ecg ? /EUR/ : undefined;
  @ViewChild('ngForm', {static: true}) ngForm: NgForm;

  constructor(
    private route: ActivatedRoute,
    private ratingService: CountryRatingService,
    public appService: AppConfigService,
    protected growlService: GrowlService
  ) {
    super(growlService);
    this.ratingVersion = <CountryRatingVersionDto>{};
    this.deleteButton = undefined;
    this.newVersionButton.name = 'common.change';
  }

  ngOnInit() {
    this.route.params.subscribe((params) => this.initializeView(params));
  }

  initializeView(params: Params) {
    this.objectNotFound = false;
    this.serverErrors = undefined;
    const ratingId = +params['id'];
    this.ratingType = params['type'];
    this.showOECD = this.isOECD(this.ratingType);
    this.showInsurer = this.isInsurer(this.ratingType);
    this.insurerRequired = this.setInsurerRequired(this.ratingType);
    this.OECDRequired = this.setOECDRequired(this.ratingType);
    this.getRating(ratingId);
  }

  setInsurerRequired(type: string): boolean {
    if (this.mehib) {
      return this.isInsurer(type);
    } else {
      return false;
    }
  }

  setOECDRequired(type: string): boolean {
    if (this.mehib) {
      return this.isOECD(type);
    } else {
      return true;
    }
  }

  isOECD(type: string): boolean {
    if (this.ratingType === 'ALL' || this.ratingType === 'OECD') {
      return true;
    }
    return false;
  }

  isInsurer(type: string): boolean {
    if (this.ratingType === 'ALL' || this.ratingType === 'INSURER') {
      return true;
    }
    return false;
  }

  getRating(id: number) {
    this.ratingService.getCountryRating(id).subscribe(
      (rating) => this.setRating(rating),
      (error) => this.handleServerError(error)
    );
  }

  setRating(rating: CountryRatingDto) {
    this.ratingVersion = rating.versions[rating.versions.length - 1];
    this.ratingVersions = rating.versions;
    this.handleButtons();
  }

  setRatingVersion(rating: CountryRatingVersionDto) {
    this.ratingVersion = rating;
    this.handleButtons();
  }

  onCreateNewVersion() {
    if (this.ratingType === 'ALL') {
      this.ratingService
        .newRatingVersion(this.ratingVersion.countryRating.country.id, CountryRatingSettingType.ALL)
        .subscribe((rating) => {
          this.setRatingVersion(rating);
        });
    } else if (this.ratingType === 'OECD') {
      this.ratingService
        .newRatingVersion(this.ratingVersion.countryRating.country.id, CountryRatingSettingType.OECD)
        .subscribe((rating) => {
          this.setRatingVersion(rating);
        });
    } else if (this.ratingType === 'INSURER') {
      this.ratingService
        .newRatingVersion(this.ratingVersion.countryRating.country.id, CountryRatingSettingType.INSURER)
        .subscribe((rating) => {
          this.setRatingVersion(rating);
        });
    }
  }

  onSave() {
    this.serverErrors = undefined;
    if (!this.ngForm.form.valid) {
      this.showFormError();
      StringUtils.logFormInvalidFields(this.ngForm.form);
      return;
    }
    this.inProgress = true;
    this.ratingService.createRatingVersion(this.ratingVersion).subscribe({
      next: (version) => {
        this.ratingVersion = version;
        this.inProgress = false;
        this.showSavedMsg();
        this.getRating(version.countryRating.id);
      },
      error: (error) => this.handleServerError(error),
    });
  }

  onCancel() {
    super.onCancel();
    if (!this.ratingVersion.id) {
      this.getRating(this.ratingVersion.countryRating.id);
    }
  }

  handleButtons() {
    this.hideButtons(false);
    this.saveButton.hidden = this.ratingVersion.id > 0;
    this.cancelButton.hidden = this.ratingVersion.id > 0;
    this.newVersionButton.hidden = this.mehib ? true : !this.ratingVersion.id;
  }

  ratingChanged(rating: DictionaryDto) {
    this.ratingVersion.validTo = undefined;
    this.ratingVersion.nextReviewDate = undefined;
  }

  onSelectVersion(r: CountryRatingVersionDto) {
    this.ratingVersion = r;
    this.handleButtons();
  }

  get kuke(): boolean {
    return this.appService.kuke;
  }

  get mehib(): boolean {
    return this.appService.mehib;
  }

  get ecg(): boolean {
    return this.appService.ecg;
  }

  hideRatings() {
    if (this.mehib) {
      return new Set<number>([OECDRating.R0]);
    } else {
      return new Set<number>();
    }
  }
}
