import {Component, Input, ViewChild} from '@angular/core';
import {
  AddressDto,
  CompanyRawDto,
  CompanySimpleDto,
  ThirdPartyBaseDto,
  ThirdPartyCriteriaDto,
  ThirdPartyDto,
} from '../../../bonding_shared/model';
import {AppConfigService, RouterService, SearchDataProvider} from '../../../bonding_shared/services';
import {ATableComponent} from '../../../bonding_shared/components/aku-table';
import {BusinessUtils} from '../../../bonding_shared/utils/business-utils';
import {StringUtils} from '../../../bonding_shared';

@Component({
  selector: 'thirdparty-search-list',
  templateUrl: './thirdparty-search-list.component.pug',
})
/**
 * Third party list
 */
export class ThirdpartySearchListComponent {
  @Input() pageSize = 20;
  @Input() dataProvider: SearchDataProvider<ThirdPartyCriteriaDto, ThirdPartyBaseDto>;
  @Input() portalMode = false;
  @Input() pagination = true;
  @ViewChild(ATableComponent, {static: true}) tpTable: ATableComponent<ThirdPartyDto>;

  constructor(public router: RouterService, public appService: AppConfigService) {}

  getCompanyName(tp: ThirdPartyDto) {
    return BusinessUtils.companyName(this.getCompany(tp));
  }

  getCompany(tp: ThirdPartyDto): CompanySimpleDto | CompanyRawDto {
    return tp.company ? tp.company : this.portalMode ? tp.firstCompanyRaw : tp.lastCompanyRaw;
  }

  getCompanyAddress(tp: ThirdPartyDto): AddressDto {
    return this.getCompany(tp).address;
  }

  public prettyAddress(a: AddressDto): string {
    return StringUtils.prettyAddress(a, false);
  }

  onCompanyClicked(event: ThirdPartyDto) {
    if (event.company) {
      this.router.toCompanyPreview(event.company.id);
    }
  }

  public get kuke(): boolean {
    return this.appService.kuke;
  }
}
