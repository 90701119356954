import {BusinessObjectRoutingComponent} from './business-object-selector/business-object-routing-component';
import {RepoDownloaderComponent} from './downloader/repo-downloader-component';

export const SharedRoutes = [
  {
    path: 'business-object',
    component: BusinessObjectRoutingComponent,
  },
  {
    path: 'repo-downloader',
    component: RepoDownloaderComponent,
  },
];
