<details-view [title]="'inquiry.technicalDetails'" objectName="Inquiry" [parentComponent]="self"
              [versionListHidden]="true" [fixedBlockHidden]="false">
  <div class="details-content" *ngIf="inquiryId">
    <div class="bon-label-190">
      <form role="form" style="padding-top: 10px;">
        <bon-section *ngIf="appService.kuke" labelKey="contract.technicalDetails.brokerContracts">
          <div class="bon-card-group">
            <div class="bon-card-inner">
              <broker-contract-relation-list [relatedToId]="inquiryId" [relatedToTypeId]="BusinessObjectType.POLICY_INQUIRY_VERSION" [showBrokerColumns]="true">
              </broker-contract-relation-list>
            </div>
          </div>
        </bon-section>

        <bon-section labelKey="contract.technicalDetails.salesRep">
          <div class="bon-card-group">
            <div class="bon-card-inner">
              <sales-rep-relation-inquiry-list [relatedToId]="inquiryId" [mainSalesRepUserId]="mainSalesRepUserId">
              </sales-rep-relation-inquiry-list>
            </div>
          </div>
        </bon-section>

      </form>
    </div>
  </div>
  <div class="additional-buttons" *ngIf="inquiryId">
    <button class="bon-btn-warning" type="button" *ngIf="isAddBrokerAvailable()"
    (click)="routerService.toBrokerContractRelationDetailsForInquiry(0, inquiryId, 0)">
      {{'contract.technicalDetails.addBroker' | translate}}
    </button>
    <ng-container *hasRight="SalesRepElementaryRight.SALES_REP_RELATION_CREATE_UPDATE_POLICIES">
      <button class="bon-btn-warning" type="button" *ngIf="isAddSalesRepAvailable()"
              (click)="routerService.toSalesRepRelationDetailsForPolicyInquiry(undefined, inquiryId)">
        {{'contract.technicalDetails.addSalesRep' | translate}}
      </button>
    </ng-container>
  </div>
  <div class="information-header">
    <business-object-info [objectId]="inquiryId" [typeId]="BusinessObjectType.POLICY_INQUIRY_VERSION">
    </business-object-info>
  </div>
</details-view>
