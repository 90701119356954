import {Component, Input} from '@angular/core';
import {CollectionGroupAssignmentRuleDto} from '../../bonding_shared/model';
import {ControlContainer, UntypedFormGroup, NgForm} from '@angular/forms';

@Component({
  selector: 'collection-assignment-criteria',
  templateUrl: 'collection-assignment-criteria.component.pug',
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}],
})
export class CollectionAssignmentCriteriaComponent {
  @Input() rule: CollectionGroupAssignmentRuleDto;
  @Input() dataForm: UntypedFormGroup;
  @Input() showErrors: boolean;
}
