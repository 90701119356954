import {TrainingSearchComponent} from './training-search.component';
import {TrainingDetailsComponent} from './training-details.componet';
import {UserHolidaySearchComponent} from './user-holiday-search.component';
import {UserHolidayDetailsComponent} from './user-holiday-detalis.component';
import {UserHolidaySettingsSearchComponent} from './user-holiday-settings-search.component';
import {UserHolidaySettingsDetailsComponent} from './user-holiday-settings-details.component';

export const HRRoutes = [
  {
    path: 'training-search',
    component: TrainingSearchComponent,
  },
  {
    path: 'training-details/:id',
    component: TrainingDetailsComponent,
  },
  {
    path: 'user-holiday-search',
    component: UserHolidaySearchComponent,
  },
  {
    path: 'user-holiday-details/:id',
    component: UserHolidayDetailsComponent,
  },
  {
    path: 'user-holiday-settings-search',
    component: UserHolidaySettingsSearchComponent,
  },
  {
    path: 'user-holiday-settings-details/:id',
    component: UserHolidaySettingsDetailsComponent,
  },
];
