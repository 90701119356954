import {Directive, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ValidatorFn, Validators} from '@angular/forms';
import {
  CollectionAccountedCostDto,
  CollectionCostsViewDto,
  CollectionEstimatedCostDto,
  CollectionPaymentDto,
  ExternalProviderRelationDto,
  TemplateBaseDto,
  UserDto,
} from '../../bonding_shared/model';
import {CollectionAuditedViewAbstract} from './collection-audited-view-abstract';
import {
  AppConfigService,
  CellChangeEvent,
  CollectionAuditedService,
  CollectionExternalProviderRelationsService,
  CollectionVersionService,
  GrowlService,
  LoggedUserService,
  RouterService,
  TemplateService,
} from '../../bonding_shared';
import {
  BusinessObjectType,
  CollectionCostCalcType,
  CollectionCostType,
  CollectionElementaryRight,
  DocumentType,
  ProfileQuotaType,
} from '../../bonding_shared/model/dictionary-ids';
import {CustomButton, CustomButtonEvent} from '../../bonding_shared/components/aku-table/table.component';
import {ConfirmDialogComponent} from '../../bonding_shared/components/confirm-dialog';
import {TranslateService} from '@ngx-translate/core';

@Directive()
export abstract class CollectionCostsAbstractComponent extends CollectionAuditedViewAbstract<CollectionCostsViewDto> {
  readonly ProfileQuotaType = ProfileQuotaType;
  readonly COLLECTION_COSTS_CREATE_UPDATE = CollectionElementaryRight.COLLECTION_COSTS_CREATE_UPDATE;

  titleLabelKey = 'collection.costs';
  availableSuppliers: ExternalProviderRelationDto[];
  customButtons: CustomButton[];
  acceptanceUser: UserDto;
  commissionPayments: CollectionPaymentDto[];
  @ViewChild('invoicingConfirmDialog', {static: true}) invoicingConfirmDialog: ConfirmDialogComponent;
  @ViewChild('rejectCostsConfirmDialog') rejectCostsConfirmDialog: ConfirmDialogComponent;

  protected constructor(
    route: ActivatedRoute,
    versionService: CollectionVersionService,
    parentService: CollectionAuditedService<CollectionCostsViewDto>,
    router: RouterService,
    translateService: TranslateService,
    growlService: GrowlService,
    public loggedUserService: LoggedUserService,
    private collectionExternalProviderRelationsService: CollectionExternalProviderRelationsService,
    private templateService: TemplateService,
    public appConfig: AppConfigService
  ) {
    super(route, versionService, parentService, router, translateService, growlService, loggedUserService);
  }

  // following methods implemented in CollectionEstimatedCostsComponent
  accept() {}
  showRejectModal() {}
  reject() {}
  sendToAcceptance() {}
  onSupplierRelationClick(supplierRelation: ExternalProviderRelationDto) {}
  onAddEstimatedCost() {}
  onTypeChange(event: CellChangeEvent<CollectionEstimatedCostDto>) {}
  onProposedAmountChange(event: CellChangeEvent<CollectionEstimatedCostDto>) {}
  recalculateCommissionIfApplicable(event: CellChangeEvent<CollectionEstimatedCostDto>) {}
  proposedAmountRequired(item: CollectionEstimatedCostDto): boolean {
    return false;
  }
  get pickUser(): boolean {
    return false;
  }

  // following methods implemented in CollectionAccountedCostsComponent
  newAccountedCost(item: CollectionEstimatedCostDto) {}
  deleteAccountedCost(item: CollectionAccountedCostDto) {}

  extraInitialization() {
    super.extraInitialization();
    this.collectionExternalProviderRelationsService.getFromRevision(this.id).subscribe((relations) => {
      this.availableSuppliers = relations;
    });
    this.loadTemplates();
  }

  initializeDocumentsButton(templates: TemplateBaseDto[]) {
    const documentsButton = <CustomButton>{
      name: 'Nowy dokument',
      title: 'Nowy dokument',
      class: 'bon-btn-info',
      icon: 'fa-file-text-o',
      alignment: 'right',
      dropdownItems: templates,
    };
    this.customButtons = [documentsButton];
  }

  loadTemplates() {
    this.templateService
      .findByType(DocumentType.COLLECTION_ORDER_FOR_AGENCY, BusinessObjectType.COLLECTION_ESTIMATED_COSTS)
      .subscribe((templates) => {
        if (templates.length > 0) {
          this.initializeDocumentsButton(templates);
        }
      });
  }

  toDocumentView(event: CustomButtonEvent<CollectionEstimatedCostDto>) {
    this.router.toDocumentDetailsNew(event.dropdownItem.id, event.item.id);
  }

  toInvoicingView(costs: CollectionAccountedCostDto) {
    if (this.blockInvoicing(costs)) {
      this.invoicingConfirmDialog.openAndExecuteOnConfirm(
        'collectionCosts.invoicingConfirm.title',
        'collectionCosts.invoicingConfirm.msg',
        this.cancel.bind(this)
      );
    } else {
      this.confirmInvoicingView(costs);
    }
  }

  blockInvoicing(costs: CollectionAccountedCostDto): boolean {
    const estimatedCosts = costs.estimatedCost;
    const calcType = estimatedCosts.calcType;
    return (
      ((calcType.id === CollectionCostCalcType.INSURER_COSTS ||
        calcType.id === CollectionCostCalcType.COSTS_REIMBURSED_BY_INSURER) &&
        costs.insurerShare === 100) ||
      ((calcType.id === CollectionCostCalcType.CREDITOR_COSTS ||
        calcType.id === CollectionCostCalcType.COSTS_REIMBURSED_BY_CREDITOR) &&
        (!costs.insurerShare || costs.insurerShare === 0))
    );
  }

  confirmInvoicingView(costs: CollectionAccountedCostDto) {
    this.router.toCollectionCostsInvoicing(costs.id);
  }

  cancel() {
    //  do nothing
  }

  protected getEmpty(): CollectionCostsViewDto {
    return <CollectionCostsViewDto>{accountedCosts: [], estimatedCosts: []};
  }

  accountedCostsEditable(): boolean {
    return this.newVersion && this.loggedUserService.hasRight(CollectionElementaryRight.COLLECTION_COSTS_CREATE_UPDATE);
  }

  estimatedCostsEditable(): boolean {
    return this.newVersion && this.loggedUserService.hasRight(CollectionElementaryRight.COLLECTION_COSTS_CREATE_UPDATE);
  }

  estimatedCostApproved(e: CollectionEstimatedCostDto): boolean {
    return !!e.acceptanceDate;
  }

  estimatedCostNotApproved(e: CollectionEstimatedCostDto): boolean {
    return !e.acceptanceDate;
  }

  accountedCostEditable(a: CollectionAccountedCostDto): boolean {
    return !a.id;
  }

  calcFreeSpace(e: CollectionEstimatedCostDto): number {
    return e.acceptedAmount - this.calcSumOfAccounted(e);
  }

  calcSumOfAccounted(e: CollectionEstimatedCostDto): number {
    return this.findAccounted(e).reduce((a, b) => a + (b.approvedAmount || 0), 0);
  }

  calcMaxApprovedAmountFunc(a: CollectionAccountedCostDto): number {
    return Math.min(a.requestedAmount, this.calcFreeSpace(a.estimatedCost) + (a.approvedAmount || 0));
  }

  calcInsurerParticipation(a: CollectionAccountedCostDto): number {
    return (a.insurerShare * a.approvedAmount) / 100 || 0;
  }

  calcCreditorParticipation(a: CollectionAccountedCostDto): number {
    return a.approvedAmount - this.calcInsurerParticipation(a) || 0;
  }

  calcTypeChanged(e: CellChangeEvent<CollectionEstimatedCostDto>) {
    if (e.value.id === CollectionCostCalcType.COSTS_REIMBURSED_BY_CREDITOR) {
      e.item.supplierRelation = this.availableSuppliers
        .filter((sup) => sup.company)
        .find((sup) => sup.company.id === this.parent.creditor.id);
    }
  }

  isCommission(e: CollectionEstimatedCostDto): boolean {
    return e.type && e.type.id === CollectionCostType.COMMISSION;
  }

  insurerShareEditable(e: CollectionEstimatedCostDto): boolean {
    return !this.isCommission(e) && this.estimatedCostNotApproved(e);
  }

  insurerShareValidator(e: CollectionEstimatedCostDto): ValidatorFn {
    return (control) => this.insurerShareEditable(e) && Validators.required(control);
  }

  isInsurerShareEditable(c: CollectionAccountedCostDto): boolean {
    return false;
  }

  findAccounted(e: CollectionEstimatedCostDto): CollectionAccountedCostDto[] {
    return this.current.accountedCosts.filter((a) => a.estimatedCost === e || a.estimatedCost.id === e.id);
  }

  decideAcceptanceTask(): boolean {
    return false;
  }

  supplierRelationToString(rel: any): string {
    return (rel.provider && rel.provider.company.registrationName) || (rel.company && rel.company.registrationName);
  }

  getRelationFromList(rel: ExternalProviderRelationDto): ExternalProviderRelationDto {
    return (this.availableSuppliers && this.availableSuppliers.find((r) => r.id === rel.id)) || rel;
  }
}
