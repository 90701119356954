import {SalesLeadSearchComponent} from './sales-lead-search.component';
import {SalesLeadDetailsComponent} from './sales-lead-details.component';

export const SalesLeadRoutes = [
  {
    path: 'sales-lead-search',
    component: SalesLeadSearchComponent,
  },
  {
    path: 'sales-lead-details/:id',
    component: SalesLeadDetailsComponent,
  },
  {
    path: 'sales-lead-details',
    component: SalesLeadDetailsComponent,
  },
];
