import {
  CompanyCriteriaDto,
  CompanySimpleDto,
  LocalDateTimeRange,
  PolicyInquiryBaseDto,
  PolicyInquiryVersionCriteriaDto,
  UserSimpleDto,
} from '../../../bonding_shared';
import {PolicyInquiryStatus} from '../../../bonding_shared/model/dictionary-ids';

export function getInitializedCriteria(isLimitedToPolicyInquiry: boolean, portal: boolean) {
  const criteria = <PolicyInquiryVersionCriteriaDto>{};
  criteria.policyInquiry = <PolicyInquiryBaseDto>{};
  criteria.client = <CompanyCriteriaDto>{};
  criteria.salesPerson = <UserSimpleDto>{};
  criteria.subinsured = <CompanyCriteriaDto>{};
  criteria.creationDateRange = <LocalDateTimeRange>{};
  criteria.registrationDateRange = <LocalDateTimeRange>{};
  criteria.broker = <CompanySimpleDto>{};
  if (isLimitedToPolicyInquiry) {
    criteria.policyInquiryTypes = ['POLICY_INQUIRY'];
    if (portal) {
      criteria.policyInquiryTypes.push('POLICY_ANNEX');
    }
  }
  return criteria;
}

export function isExceptedEdition(inquiryStatusId: number, kuke: boolean): boolean {
  return inquiryStatusId === PolicyInquiryStatus.POLICY_INQUIRY_ACCEPTED && kuke;
}
