import {Component, OnInit, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import {ActivatedRoute, Params} from '@angular/router';
import {
  CollectionHistoryEntryCriteriaDto,
  CollectionHistoryEntryDto,
  CollectionHistoryEntryLinkDto,
  CollectionIdDto,
  CommentDto,
  DictionaryBaseDto,
  LocalDateTimeRange,
  Page,
  SearchCriteria,
  SearchResult,
  UserDto,
  UserIdDto,
} from '../../bonding_shared/model';
import {
  GrowlService,
  LoggedUserService,
  RepositoryService,
  RouterService,
  SearchDataProvider,
} from '../../bonding_shared/services';
import {DetailsView} from '../../bonding_shared/components/details-view/details-view';
import {ComboItem} from '../../bonding_shared/components/combos';
import {PageChangedEvent} from 'ngx-bootstrap/pagination';
import {CollectionHistoryService} from '../../bonding_shared/services/collection-history.service';
import {UserNamePipe} from '../../bonding_shared/pipes/user-name.pipe';
import {FormDialogComponent} from '../../bonding_shared/components/form-dialog';

@Component({
  selector: 'collection-history',
  templateUrl: 'collection-history.component.pug',
})
export class CollectionHistoryComponent extends DetailsView implements OnInit {
  historyComment: CommentDto = <CommentDto>{};
  history: CollectionHistoryEntryDto[];
  manualEntry: CollectionHistoryEntryDto = this.initManualEntry;
  dataProvider: SearchDataProvider<CollectionHistoryEntryCriteriaDto, CollectionHistoryEntryDto>;
  versionId: number;
  collectionId: number;
  collectionNumber: string;
  totalCount = 0;
  pageSize = 25;
  pageSizes: ComboItem[] = [
    new ComboItem('5', '5'),
    new ComboItem('10', '10'),
    new ComboItem('25', '25'),
    new ComboItem('100', '100'),
  ];
  page = 1;
  historySynchronised = false;
  historyCommentEdition = false;
  _manualEntryDialog: FormDialogComponent;

  @ViewChild('manualEntryDialog')
  set manualEntryDialog(manualEntryDialog: FormDialogComponent) {
    this._manualEntryDialog = manualEntryDialog;
  }

  get manualEntryDialog() {
    return this._manualEntryDialog;
  }

  @ViewChild('ngForm', {static: true}) ngForm: NgForm;

  constructor(
    growlService: GrowlService,
    private repositoryService: RepositoryService,
    private route: ActivatedRoute,
    private service: CollectionHistoryService,
    public router: RouterService,
    private loggedUserService: LoggedUserService
  ) {
    super(growlService);
    this.saveButton.hidden = false;
    this.cancelButton.hidden = false;
  }

  get initManualEntry(): CollectionHistoryEntryDto {
    return <CollectionHistoryEntryDto>{confidential: true};
  }

  ngOnInit(): void {
    this.dataProvider = new SearchDataProvider<CollectionHistoryEntryCriteriaDto, CollectionHistoryEntryDto>(
      this.service
    );
    this.dataProvider.searchCriteria = <SearchCriteria<CollectionHistoryEntryCriteriaDto>>{
      criteria: <CollectionHistoryEntryCriteriaDto>{
        collection: <CollectionIdDto>{},
        eventDate: <LocalDateTimeRange>{},
        user: <UserIdDto>{},
        collectionStatus: <DictionaryBaseDto>{},
      },
    };
    this.route.queryParams.subscribe((params) => this.initializeView(params));
  }

  initializeView(params: Params) {
    this.collectionId = params['collectionId'];
    this.versionId = params['versionId'];
    this.collectionNumber = params['collectionNumber'];
    this.dataProvider.searchCriteria.criteria.collection.id = this.collectionId;
    this.getHistoryComment();
    this.fireSearch();
  }

  getHistoryComment() {
    this.service.getHistoryComment(this.collectionId).subscribe((res) => (this.historyComment = res));
  }

  storeHistoryComment() {
    this.service.storeHistoryComment(this.collectionId, this.historyComment).subscribe((res) => {
      this.historyComment = res;
      this.historyCommentEdition = false;
    });
  }

  turnHistoryComment() {
    this.historyCommentEdition = !this.historyCommentEdition;
  }

  search() {
    this.fireSearch();
  }

  fireSearch() {
    this.dataProvider
      .search({start: 0, count: this.pageSize, countOnly: false})
      .subscribe((res) => this.resultsReceived(res));
  }

  resultsReceived(res: SearchResult<CollectionHistoryEntryDto>) {
    this.history = res.result;
    this.totalCount = res.size;
  }

  onChangePageSize(event: any) {
    this.dataProvider
      .search({start: 0, count: this.pageSize, countOnly: false})
      .subscribe((res) => this.resultsReceived(res));
  }

  onUserChange(item: UserDto) {
    this.dataProvider.searchCriteria.criteria.user = item;
  }

  userFullName = (u: UserDto): string => {
    return new UserNamePipe().transform(u);
  };

  paginationPageChanged(event: PageChangedEvent) {
    this.page = event.page;
    this.dataProvider.search(this.getPage()).subscribe((res) => this.resultsReceived(res));
  }

  getPage(): Page {
    const page = <Page>{};
    page.count = this.pageSize;
    page.start = (this.page - 1) * this.pageSize;
    return page;
  }

  get portal(): boolean {
    return this.loggedUserService.portal;
  }

  get entryToPublish(): boolean {
    return !this.manualEntry.confidential;
  }

  set entryToPublish(v: boolean) {
    this.manualEntry.confidential = !v;
  }

  openManualEntryDialog() {
    this.manualEntryDialog.open('collection.history.new').then((value) => {
      if (value) {
        this.serverErrors = undefined;
        this.showErrors = false;
        this.inProgress = true;
        this.service.saveEntry(this.collectionId, this.manualEntry).subscribe(
          (entry) => {
            this.inProgress = false;
            this.manualEntry = this.initManualEntry;
            this.fireSearch();
          },
          (error) => {
            this.serverErrors = error;
            this.inProgress = false;
          }
        );
      }
    });
  }

  generatePdf() {
    this.service.getPdf(this.collectionId);
  }

  changeEntryPrintOnForm(e: CollectionHistoryEntryDto) {
    this.service.setPrintability(e.id, e.printOnForm).subscribe((ue) => (e.printOnForm = ue.printOnForm));
  }

  getDocument(lnk: CollectionHistoryEntryLinkDto) {
    this.repositoryService.getRepositoryDocument(lnk.repositoryId, lnk.documentName);
  }
}
