<search-view labelKey="salesRep.common.salesRep" newObjectLabelKey="salesRep.search.new" newObjectRedirectFunctionName="toSalesRepDetailsNew"
  [dataProvider]="dataProvider" [searchModeSwitchable]="false" [enableSearchViewError]="true"
  [createRight]="SalesRepElementaryRight.SALES_REP_CREATE_UPDATE">
  <div class="search-criteria bon-card-group">
    <div class="bon-card-inner" style="width:35%">
        <input-row labelKey="common.name" [(ngModel)]="dataProvider.searchCriteria.criteria.salesRep.name" name="name"></input-row>
        <form-row labelKey="common.user">
          <user-combo class="bon-input-size" nullLabelKey="All" [range]="salesUserRange" [(ngModel)]="dataProvider.searchCriteria.criteria.salesRep.user"></user-combo>
        </form-row>
    </div>
    <div class="bon-card-inner bon-label-180" style="width:25%">
      <checkbox-row label="salesRep.search.hasPolicy" [(ngModel)]="dataProvider.searchCriteria.criteria.hasPolicy"  name="hasPolicy"></checkbox-row>
      <checkbox-row label="salesRep.search.hasContract" [(ngModel)]="dataProvider.searchCriteria.criteria.hasContract" name="hasContract"></checkbox-row>
    </div>
    <div class="bon-card-inner" style="width:35%">
      <form-row labelKey="common.businessUnit">
        <business-unit-id-selector class="bon-input-size" [userDefaultBU]="false" nullLabel=" "
                                   [(ngModel)]="dataProvider.searchCriteria.criteria.businessUnit" name="businessUnit">
        </business-unit-id-selector>
      </form-row>
      <form-row labelKey="common.version">
        <string-combo class="bon-input-size" [(ngModel)]="dataProvider.searchCriteria.criteria.versionPhase" nullLabelKey="All" [items]='versionPhases'></string-combo>
      </form-row>
    </div>
    <div class="bon-card-inner" style="width:10%">
    </div>
  </div>

  <div class="search-results">
    <a-table [pagination]="true" [pageSize]="20" [selection]="false" [dataProvider]="dataProvider"
             [sortable]='!dataProvider.textSearch' [showBackendErrors]="false">
      <column property="salesRep.name" labelKey="common.name" sort="salesRep.name" [link]="hasViewRight" (linkClick)="router.toSalesRepPreview($event.salesRep.id)"></column>
      <column labelKey="common.user" sort="salesRep.user" type="custom">
        <ng-template let-item="item">{{item.salesRep.user | username}}</ng-template>
      </column>
      <column labelKey="common.validFrom" property="validFrom" type="date" sort="validFrom"></column>
      <column labelKey="common.validTo" property="validTo" type="date" sort="validTo"></column>
    </a-table>
  </div>

</search-view>

