<div class="container">
  <div class="offer-fields">
    <div>&nbsp;</div>
    <div *ngFor="let voter of voters; let i = index">
      <div>{{voter}}</div>
    </div>
  </div>
  <div *ngFor="let item of offers; let i = index" class="offer-data">
    <div></div>
    <div *ngFor="let offerVotes of voting[item.id]; let i = index">
      <i class="fa"  [ngClass]="{'fa-thumbs-o-up vote-up' : offerVotes, 'fa-thumbs-o-down vote-down' : offerVotes === false}" aria-hidden="true"></i>
    </div>
  </div>
</div>
