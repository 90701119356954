<div class="login-page animate">
	<div class="row">
		<div class="col-lg-4 col-lg-offset-4 main-logo">
     	<strong>{{appConfigService.displayName}}</strong>
			<form *ngIf="!isSafariBrowser()" role="form">
				<div class="form-content" (keyup.enter)="doLogin()">
					<div class="form-group">
						<input id="loginInput" name="loginInput" type="text" [(ngModel)]="login" class="form-control input-underline input-lg" [placeholder]="'login.loginPlaceholder' | translate">
					</div>
					<div class="form-group">
						<input id="passwordInput" name="passwordInput" type="password" [(ngModel)]="password" class="form-control input-underline input-lg" [placeholder]="'login.passwordPlaceholder' | translate" >
					</div>
				</div>
				<div class="card-block">
					<span class="text-danger"><h4 [innerHTML]="errorMessage | translate"></h4></span>
				</div>
				<button [disabled]="pendingLogin" id="loginBtn" class="btn rounded-btn" (click)="doLogin()" translate>login.loginButton</button>
        <div style="margin: 20px;">
          <a style="color: white" (click)="router.navigate(['remindPassword'])" translate>login.forgotPassword</a>
        </div>
			</form>
      <div *ngIf="isSafariBrowser()" class="card-block">
        <span class="text-danger"><h4>Safari Web Browser is not supported</h4></span>
        <span></span>
        <span class="text-danger"><h4>Safari Web Browser wird nicht unterstützt</h4></span>
      </div>
		</div>
	</div>
</div>
