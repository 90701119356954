import {Directive, Input, Output, EventEmitter, HostListener} from '@angular/core';

@Directive({selector: '[copyFromClipboard]'})
export class CopyFromClipboardDirective {
  @Input('copyFromClipboard')
  public copyFromClipboard: string;

  @Output('copied')
  public copied: EventEmitter<string> = new EventEmitter<string>();

  @HostListener('click', ['$event'])
  public onClick(event: MouseEvent): void {
    event.preventDefault();
    if (!this.copyFromClipboard) {
      return;
    }

    const listener = (e: ClipboardEvent) => {
      const clipboard = e.clipboardData || window['clipboardData'];
      clipboard.setData('text', this.copyFromClipboard.toString());
      e.preventDefault();
      this.copied.emit(this.copyFromClipboard);
    };

    document.addEventListener('copy', listener, false);
    document.execCommand('copy');
    document.removeEventListener('copy', listener, false);
  }
}
