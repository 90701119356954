import {Directive} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, ValidationErrors, Validator} from '@angular/forms';
import {CustomValidators} from '../../../../bonding_shared/validators';

@Directive({
  selector: '[loginFormat]',
  providers: [{provide: NG_VALIDATORS, useExisting: LoginFormatDirective, multi: true}],
})
export class LoginFormatDirective implements Validator {
  validate(control: AbstractControl): ValidationErrors {
    return CustomValidators.userLoginFormat(control);
  }
}
