import {Subject} from 'rxjs';
import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {ATableComponent} from '../../../../bonding_shared/components/aku-table/a-table.component';
import {SearchDataProvider} from '../../../../bonding_shared/services/search-data-provider';
import {SalesRepBaseDto, SalesRepVersionCriteriaDto, SalesRepVersionDto} from '../../../../bonding_shared/model';
import {TextSearchCriteria} from '../../../../bonding_shared/components/search/model/text-search-criteria';
import {RouterService} from '../../../../bonding_shared/services/router-service';
import {SalesRepVersionService} from '../../../../bonding_shared/services/sales-rep-version.service';
import {BusinessUtils} from '../../../../bonding_shared/utils/business-utils';

@Component({
  selector: 'sales-rep-version-selector',
  templateUrl: './sales-rep-version-selector.component.pug',
})
export class SalesRepVersionSelectorComponent {
  @ViewChild(ATableComponent, {static: true}) SalesRepTable: ATableComponent<SalesRepVersionDto>;

  dataProvider: SearchDataProvider<SalesRepVersionCriteriaDto, SalesRepVersionDto>;

  @Input() title: string;
  @Input() open: Subject<boolean>;
  @Input() textSearch = false;
  @Output() itemSelected = new EventEmitter<SalesRepBaseDto>();

  @Input() set textCriteria(c: TextSearchCriteria) {
    this.dataProvider.textSearch = true;
    this.dataProvider.textSearchCriteria = c;
  }

  fixedSearch = false;
  visibleOnInit = false;

  constructor(public router: RouterService, private SalesRepService: SalesRepVersionService) {
    this.dataProvider = BusinessUtils.createDataProvider(SalesRepService);
    this.dataProvider.textSearch = false;
  }
}
