<details-view [parentComponent]="self" [fixedBlockHidden]="false" [versionListHidden]="true" [objectName]="'User'"
              [title]="'user.details.title'">
  <div class="details-content">
      <ad-user-data *ngIf="(userCompleted && groups)" [header]="'User Details'" [user]="user" [roles]="roles" [groups]="groups"></ad-user-data>
  </div>
  <div class="additional-buttons">
    <button class="bon-btn-success" type="button" [hidden]="!user.id" (click)="updateUserFromAD()" btnIcon="fa-file-text-o" translate>user.details.adUpdate</button>
    <button class="bon-btn-success" type="button" [hidden]="!user.id" (click)="generateHistoryReport()" btnIcon="fa-file-text-o" translate>user.details.historyReport</button>
  </div>
  <div class="information-header" *ngIf="user">
    <b style="display: inline-block; width: 250px;" class="font-larger"> {{user | username}}</b>
    <b class="id" *ngIf="user.id"> Id: {{user.id}}</b>
  </div>
</details-view>
<confirm-dialog></confirm-dialog>
