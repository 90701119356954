import {AppConfigService} from '../services';

export class UserUtils {
  private static getKukeLangRegexp(): RegExp {
    return /^(?:pl|en)$/;
  }

  private static getEcgLangRegexp(): RegExp {
    return /^(?:en|el)$/;
  }

  private static getMehibLangRegexp(): RegExp {
    return /^(?:hu|en)$/;
  }

  private static getCredendoLangRegexp(): RegExp {
    return /^(?:en|de|es|fr|it|nl|pl)$/;
  }

  static resolveLangRegexp(appService: AppConfigService): RegExp {
    if (appService.kuke) {
      return UserUtils.getKukeLangRegexp();
    }
    if (appService.mehib) {
      return UserUtils.getMehibLangRegexp();
    }
    if (appService.credendo) {
      return UserUtils.getCredendoLangRegexp();
    }
    if (appService.ecg) {
      return UserUtils.getEcgLangRegexp();
    }
  }
}
