<!--<link href='https://fonts.googleapis.com/css?family=Abel' rel='stylesheet'>-->
<!--<link href='https://fonts.googleapis.com/css?family=Archivo Black' rel='stylesheet'>-->

<div class="login-page animate">
  <div class="row">
    <div class="col-lg-4 col-lg-offset-4 main-logo">
      <strong>{{appConfigService.displayName}}</strong>
      <form [formGroup]="form">
        <label class="caption-txt" translate="changePassword.changeRequired"></label>
        <div class="form-content" (keyup.enter)="resetPassword()">
          <div class="form-group">
            <input id="newPassword" name="newPassword" type="password" [formControl]="form.controls.newPassword"
                   class="form-control input-underline input-lg" placeholder="{{'changePassword.newPassword' | translate}}">
            <error-message [control]="form.controls.newPassword" [show]="showErrors"></error-message>
          </div>
          <div class="form-group">
            <input id="retypePassword" name="retypePassword" type="password" [formControl]="form.controls.retypePassword"
                   class="form-control input-underline input-lg" placeholder="{{'changePassword.retypePassword' | translate}}" style="background-color: rgba(255, 255, 255, 0)">
            <error-message [control]="form.controls.retypePassword" [show]="showErrors"></error-message>
          </div>
        </div>
        <div>
          <span class="invalid font-medium" *ngIf="form.hasError('passwordsMismatch') && showErrors">
            <h5 translate="changePassword.passwordsMismatch"></h5>
          </span>
          <span class="invalid font-medium" *ngFor="let err of serverErrors">
            <h5>{{err.message | translate}}</h5>
          </span>
        </div>
        <div><a id="loginBtn" class="btn rounded-btn" (click)="resetPassword()" tabindex="0" translate="changePassword.submitButton"></a></div>
      </form>
    </div>
  </div>
</div>
