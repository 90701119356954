import {DetailsView} from '../../bonding_shared/components/details-view/details-view';
import {DictionaryBaseService, GrowlService, UserService} from '../../bonding_shared/services';
import {DictionaryBaseDto} from '../../bonding_shared/model';

export abstract class AbstractAssignmentRulesComponent extends DetailsView {
  profileCategories: DictionaryBaseDto[];
  abstract enabledCategories: string[];
  profileCategory: DictionaryBaseDto;

  protected constructor(
    protected growlService: GrowlService,
    protected dictionaryService: DictionaryBaseService,
    protected userService: UserService
  ) {
    super(growlService);
    this.dictionaryService.getDictionaryBase('ProfileCategory').subscribe((d) => {
      this.userService.getAvailableProfileCategories('ASSIGNMENT_RULES').subscribe((res) => {
        this.profileCategories = d
          .filter((pc) => this.enabledCategories.includes(pc.code, 0))
          .filter((pc) => res.includes(pc.id, 0));
      });
    });
  }
}
