import {Component, OnInit} from '@angular/core';
import {BatchService} from '../../bonding_shared/services/batch.service';
import {StringUtils} from '../../bonding_shared/utils';
import {ActivatedRoute, Params} from '@angular/router';
import {DetailsView} from '../../bonding_shared/components/details-view/details-view';
import {GrowlService, LoggedUserService, RouterService} from '../../bonding_shared/services';
import {Button} from '../../bonding_shared/components/details-view/button';
import {ElementaryRight} from '../../bonding_shared/model/dictionary-ids';
import {BatchParamDto} from '../../bonding_shared/model';

@Component({
  selector: 'batch-run',
  templateUrl: './batch-run.component.pug',
  providers: [],
})
export class BatchRunComponent extends DetailsView implements OnInit {
  params: BatchParamDto[] = [];

  StringUtils = StringUtils;

  constructor(
    protected growlService: GrowlService,
    public router: RouterService,
    protected loggedUserService: LoggedUserService,
    private batchService: BatchService,
    private _route: ActivatedRoute
  ) {
    super(growlService);
    this.newVersion2Button = new Button('Run', this.run.bind(this), false, false, 'paperclip');
    this.newVersion2Button.hidden = !this.loggedUserService.hasRight(ElementaryRight.BATCH_RUN);
    this.newVersion2Button.icon = 'fa-play-circle-o';
  }

  jobName: string;

  run(): void {
    this.batchService.run(this.jobName, this.params).subscribe((res) => {
      this.growlService.notice('Batch ' + StringUtils.camelCaseToText(this.jobName) + ' has been started.');
    });
  }

  ngOnInit(): void {
    this._route.params.subscribe((params) => this.initializeView(params));
  }

  initializeView(params: Params) {
    this.jobName = params['name'];
    this.newVersion2Button.name = 'Run ' + StringUtils.camelCaseToText(this.jobName);
  }
}
