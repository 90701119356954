import {Component} from '@angular/core';
import {AppConfigService} from '../bonding_shared/services';

@Component({
  selector: 'bonding-app',
  templateUrl: 'bonding.component.html',
})
export class BondingComponent {
  isActive: any = undefined;

  constructor(public appService: AppConfigService) {}
}
