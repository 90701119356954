import {Component, OnInit, ViewChild} from '@angular/core';
import {
  AddressDto,
  AppConfigService,
  ATableComponent,
  ClientCompanyBaseDto,
  ClientCompanyDto,
  LoggedUserService,
  RouterService,
  SearchDataProvider,
} from '../../../bonding_shared';
import {SearchView} from '../../../bonding_shared/components/search/search-view/search-view';
import {ClientCompanyGuiService} from '../services/client-company-gui.service';
import {SearchViewComponent} from '../../../bonding_shared/components/search';

@Component({
  selector: 'client-company-search',
  templateUrl: './client-company-search.component.pug',
})
export class ClientCompanySearchComponent extends SearchView implements OnInit {
  @ViewChild(SearchViewComponent, {static: true}) public searchView: SearchViewComponent<
    ClientCompanyBaseDto,
    ClientCompanyDto
  >;
  @ViewChild(ATableComponent, {static: true}) public ccTable: ATableComponent<ClientCompanyDto>;
  public dataProvider: SearchDataProvider<ClientCompanyBaseDto, ClientCompanyDto>;

  constructor(
    public guiService: ClientCompanyGuiService,
    public router: RouterService,
    private loggedUserService: LoggedUserService,
    private appConfigService: AppConfigService
  ) {
    super();
    this.selectorNameList = ['Client', 'Company'];
    this.initializeSelectorEmitters(true);
  }

  public get kuke(): boolean {
    return this.appConfigService.kuke;
  }

  public get portal(): boolean {
    return this.loggedUserService.portal;
  }

  public ngOnInit(): void {
    this.dataProvider = this.guiService.dataProvider;
    this.dataProvider.searchCriteria.criteria.company.address = <AddressDto>{};
    this.dataProvider.searchCriteria.criteria.client.address = <AddressDto>{};
  }

  public onCompanyClicked(event: ClientCompanyDto): void {
    if (event.company) {
      this.router.toCompanyPreview(event.company.id);
    }
  }
}
