import {Component, OnInit} from '@angular/core';
import {RouterService} from '../../bonding_shared/services/router-service';
import {DictionaryBaseDto, UserCriteriaDto, UserSimpleDto} from '../../bonding_shared/model/dtos';
import {SearchDataProvider} from '../../bonding_shared/services/search-data-provider';
import {UserGuiService} from './services/user-gui.service';
import {AppConfigService, DictionaryService, LoggedUserService, UserService} from '../../bonding_shared/services';
import {UserGroupBaseDto} from '../../bonding_shared/model';
import {ElementaryRight, ProfileCategory, UserRole, UserType} from '../../bonding_shared/model/dictionary-ids';
import {ComboItem} from '../../bonding_shared/components/combos';
import {TranslateService} from '@ngx-translate/core';
import {ActivatedRoute, Params} from '@angular/router';
import {BusinessUnit} from '../../bonding_shared/model/business-unit';

@Component({
  selector: 'user-search',
  templateUrl: 'user-search.component.html',
})
export class UserSearchComponent implements OnInit {
  readonly KUKE_SUPERADMIN = UserRole.KUKE_SUPERADMIN;
  readonly EXTERNAL_USER_ACCESS = ElementaryRight.EXTERNAL_USER_ACCESS_ONLY;
  readonly UserType = UserType;

  dataProvider: SearchDataProvider<UserCriteriaDto, UserSimpleDto>;
  userGroups: UserGroupBaseDto[];
  groupsForBu: UserGroupBaseDto[];

  employeeSearchView = false;
  labelKeyCustomTitle = 'searchView.userSearch';

  businessUnitClientsConfiguration: Array<{businessUnit: string; businessUnitCode: number}> = [
    {businessUnit: 'AT', businessUnitCode: BusinessUnit.AT},
    {businessUnit: 'BE', businessUnitCode: BusinessUnit.BE},
    {businessUnit: 'CH', businessUnitCode: BusinessUnit.CH},
    {businessUnit: 'DE', businessUnitCode: BusinessUnit.DE},
    {businessUnit: 'ES', businessUnitCode: BusinessUnit.ES},
    {businessUnit: 'FR', businessUnitCode: BusinessUnit.FR},
    {businessUnit: 'IE', businessUnitCode: BusinessUnit.IE},
    {businessUnit: 'IT', businessUnitCode: BusinessUnit.IT},
    {businessUnit: 'NL', businessUnitCode: BusinessUnit.NL},
    {businessUnit: 'PL', businessUnitCode: BusinessUnit.PL},
  ];

  businessUnitBrokersConfiguration: Array<{businessUnit: string; businessUnitCode: number}> = [
    {businessUnit: 'BE', businessUnitCode: BusinessUnit.BE},
    {businessUnit: 'DE', businessUnitCode: BusinessUnit.DE},
    {businessUnit: 'IT', businessUnitCode: BusinessUnit.IT},
  ];

  statusFilters: ComboItem[] = [];
  hiddenUserRoles: Set<number> = new Set();

  constructor(
    public router: RouterService,
    public guiService: UserGuiService,
    private userService: UserService,
    public loggedUserService: LoggedUserService,
    public appService: AppConfigService,
    public dictService: DictionaryService,
    private translateService: TranslateService,
    private route: ActivatedRoute
  ) {
    this.statusFilters.push(
      new ComboItem('NEW', this.translateService.instant('user.search.criteria.statusFilter.new'))
    );
    this.statusFilters.push(
      new ComboItem('ACTIVE', this.translateService.instant('user.search.criteria.statusFilter.active'))
    );
    this.statusFilters.push(
      new ComboItem('INACTIVE', this.translateService.instant('user.search.criteria.statusFilter.inactive'))
    );
    this.statusFilters.push(
      new ComboItem('ALL', this.translateService.instant('user.search.criteria.statusFilter.all'))
    );
  }

  readonly extranetProfileCategoriesIds: Array<number> = [
    ProfileCategory.KUKE_EXT_POLICIES,
    ProfileCategory.KUKE_EXT_BONDING,
    ProfileCategory.KUKE_EXT_ADMIN,
  ];

  ngOnInit() {
    this.route.params.subscribe((params) => this.initializeView(params));
  }

  initializeView(params: Params) {
    this.dataProvider = this.guiService.initializeDataProvider();
    this.employeeSearchView = params['employee'] === 'true';
    // checkIsEmployee = true  -> search only employee
    // checkIsEmployee = null  -> search not employee
    // checkIsEmployee = undefined  -> search all
    this.dataProvider.searchCriteria.criteria.checkIsEmployee = this.employeeSearchView === true ? true : undefined;
    if (this.employeeSearchView) {
      this.labelKeyCustomTitle = 'searchView.employeeSearch';
    }
    this.userService.getUserGroups().subscribe((groups) => (this.userGroups = groups));
    if (this.appService.kuke && this.userTypeLimitation) {
      this.dataProvider.searchCriteria.criteria.userType = <DictionaryBaseDto>{
        id: this.loggedUserService.hasRight(ElementaryRight.EXTERNAL_USER_ACCESS_ONLY)
          ? UserType.EXTRANET
          : UserType.INTRANET,
      };
      this.hiddenUserRoles.clear();
      this.dictService.getDictionary('UserRole').subscribe((res) => {
        const numbers: Array<number> = res
          .filter((ur) =>
            this.dataProvider.searchCriteria.criteria.userType.id === UserType.INTRANET
              ? this.extranetProfileCategoriesIds.indexOf(ur.parentId) >= 0
              : this.extranetProfileCategoriesIds.indexOf(ur.parentId) === -1
          )
          .map((ur) => ur.id);
        console.log(numbers);
        numbers.forEach((n) => this.hiddenUserRoles.add(n));
      });
    }
  }

  get userTypeLimitation(): boolean {
    return !(
      this.appService.credendo ||
      this.appService.ecg ||
      this.appService.mehib ||
      this.loggedUserService.hasLoggedUserRole(UserRole.KUKE_SUPERADMIN) ||
      (this.loggedUserService.hasRight(ElementaryRight.EXTERNAL_USER_ACCESS_ONLY) &&
        this.loggedUserService.hasRight(ElementaryRight.MY_PROFILE_CATEGORY_USERS_SEARCH))
    );
  }

  newBrokerUser(businessUnit: number) {
    this.router.toUserCreation('BROKER', businessUnit);
  }

  newClientUser(businessUnit: number) {
    this.router.toUserCreation('CLIENT', businessUnit);
  }

  private setGroupsAndUsersForBu() {
    // if (this.dataProvider.searchCriteria.criteria.businessUnit) {
    //   this.groupsForBu = this.userGroups.filter(g => g.businessUnit.id === this.dataProvider.searchCriteria.criteria.businessUnit.id);
    // } else {
    //   this.groupsForBu = this.userGroups;
    // }
  }

  buChanged() {
    this.setGroupsAndUsersForBu();
  }

  updateAllFromAd() {
    this.userService.updateAllFromAd().subscribe();
  }

  userTypeChanged(userType: DictionaryBaseDto) {
    if (this.appService.kuke) {
      if (userType && userType.id === UserType.INTRANET) {
        this.dataProvider.searchCriteria.criteria.extranetClient = false;
        this.dataProvider.searchCriteria.criteria.role = null;
        this.hiddenUserRoles = new Set<number>();
        this.dictService.getDictionary('UserRole').subscribe((res) => {
          const numbers: Array<number> = res
            .filter((ur) => this.extranetProfileCategoriesIds.indexOf(ur.parentId) >= 0)
            .map((ur) => ur.id);
          numbers.forEach((n) => this.hiddenUserRoles.add(n));
        });
      } else if (userType && userType.id === UserType.EXTRANET) {
        this.hiddenUserRoles = new Set<number>();
        this.dataProvider.searchCriteria.criteria.role = null;
        this.dictService.getDictionary('UserRole').subscribe((res) => {
          const numbers: Array<number> = res
            .filter((ur) => this.extranetProfileCategoriesIds.indexOf(ur.parentId) === -1)
            .map((ur) => ur.id);
          numbers.forEach((n) => this.hiddenUserRoles.add(n));
        });
        this.dataProvider.searchCriteria.criteria.businessUnit = undefined;
      } else {
        this.hiddenUserRoles = new Set<number>();
        this.dataProvider.searchCriteria.criteria.extranetClient = false;
        this.dataProvider.searchCriteria.criteria.businessUnit = undefined;
      }
    }
  }
}
