import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import * as moment from 'moment';
import {HolidayService} from '../../services/holiday.service';
import {Country} from '../../model/dictionary-ids';
import {NumberUtils} from '../../utils/number-utils';

export interface GenerationResult {
  amount: number;
  date: Date;
}

@Component({
  selector: 'instalment-generator',
  templateUrl: './instalment-generator.component.pug',
})
export class InstalmentGeneratorComponent {
  @ViewChild('ngForm', {static: true}) ngForm: NgForm;
  showErrors = false;

  @Output()
  instalmentsGenerated = new EventEmitter<GenerationResult[]>();

  @Input()
  public totalAmountMax: number;
  private _totalAmount: number;
  private _numberOfInstalment: number;
  private _instalmentAmount: number;
  private _firstPaymentDate: Date;
  private _lastPaymentDate: Date;
  private _periodicity: number;

  constructor(private holidayService: HolidayService) {}

  get totalAmount(): number {
    return this._totalAmount;
  }

  @Input() set totalAmount(value: number) {
    this._totalAmount = value;
    this.calculateNumberOfInstalment();
  }

  get numberOfInstalment(): number {
    return this._numberOfInstalment;
  }

  @Input() set numberOfInstalment(value: number) {
    this._numberOfInstalment = value;
    this.calculateInstalmentAmount();
    this.calculatePeriodicity();
    this.calculateLastPaymentDate();
    this.calculateFirstPaymentDate();
  }

  get instalmentAmount(): number {
    return this._instalmentAmount;
  }

  @Input() set instalmentAmount(value: number) {
    this._instalmentAmount = value;
    this.calculateNumberOfInstalment();
  }

  get firstPaymentDate(): Date {
    return this._firstPaymentDate;
  }

  @Input() set firstPaymentDate(value: Date) {
    this._firstPaymentDate = value;
    this.calculateNumberOfInstalment();
    this.calculatePeriodicity();
    this.calculateLastPaymentDate();
  }

  get lastPaymentDate(): Date {
    return this._lastPaymentDate;
  }

  @Input() set lastPaymentDate(value: Date) {
    this._lastPaymentDate = value;
    this.calculateNumberOfInstalment();
    this.calculatePeriodicity();
    this.calculateFirstPaymentDate();
  }

  get periodicity(): number {
    return this._periodicity;
  }

  @Input() set periodicity(value: number) {
    this._periodicity = value;
    this.calculateLastPaymentDate();
    this.calculateFirstPaymentDate();
    this.calculateNumberOfInstalment();
  }

  private calculateFirstPaymentDate() {
    if (this._lastPaymentDate && this._numberOfInstalment) {
      this._firstPaymentDate = moment(this._lastPaymentDate)
        .subtract(this._periodicity * (this._numberOfInstalment - 1), 'days')
        .toDate();
    }
  }

  private calculateLastPaymentDate() {
    if (this._firstPaymentDate && this._numberOfInstalment) {
      this._lastPaymentDate = moment(this._firstPaymentDate)
        .add(this._periodicity * (this._numberOfInstalment - 1), 'days')
        .toDate();
    }
  }

  private calculateNumberOfInstalment() {
    if (this._totalAmount / this._instalmentAmount) {
      if ((this._totalAmount / this._instalmentAmount) % 1 <= 0.25) {
        this._numberOfInstalment = Math.floor(this._totalAmount / this._instalmentAmount);
      } else {
        this._numberOfInstalment = Math.ceil(this._totalAmount / this._instalmentAmount);
      }
    } else if (this.daysBetweenFirstAndLast() / this._periodicity) {
      this._numberOfInstalment = this.daysBetweenFirstAndLast() / this._periodicity;
      this.calculateInstalmentAmount();
    }
  }

  private calculatePeriodicity() {
    if (this.daysBetweenFirstAndLast() / this._numberOfInstalment) {
      this._periodicity = this.daysBetweenFirstAndLast() / this._numberOfInstalment;
    }
  }

  private calculateInstalmentAmount() {
    this._instalmentAmount = Math.floor(this._totalAmount / this._numberOfInstalment);
  }

  private daysBetweenFirstAndLast(): number {
    return (
      this._lastPaymentDate &&
      this._firstPaymentDate &&
      moment(this._lastPaymentDate).diff(moment(this._firstPaymentDate), 'days')
    );
  }

  clear() {
    this._numberOfInstalment = undefined;
    this._instalmentAmount = undefined;
    this._firstPaymentDate = undefined;
    this._lastPaymentDate = undefined;
    this._periodicity = undefined;
  }

  async generate() {
    if (!this.ngForm.valid) {
      this.showErrors = true;
      return;
    }
    const result = [];
    let date = this._firstPaymentDate;
    for (let i = 0; i < this._numberOfInstalment; i++) {
      if (i === this._numberOfInstalment - 1) {
        // last payment, adding the rest from division
        result.push({
          date: date,
          amount:
            this._totalAmount - NumberUtils.roundMoney(result.map((x) => x.amount).reduce((a1, a2) => a1 + a2, 0)),
        });
      } else {
        result.push({date: date, amount: this._instalmentAmount});
        date = await this.nextDate(date);
      }
    }
    this.instalmentsGenerated.emit(result);
  }

  async nextDate(date: Date): Promise<Date> {
    let m = moment(date).add(this._periodicity, 'd');
    while (!(await this.holidayService.isWorkingDay(m, Country.PL))) {
      m = m.add(1, 'd');
    }
    return m.toDate();
  }
}
