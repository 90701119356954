import {RepositoryDocumentsComponent} from './repository-documents.component';
import {RepositoryDocumentsGuard} from './_guard';
import {RepositoryHealthPageComponent} from './repository-health-page.component';

export const RepositoryDocumentsRoutes = [
  {
    path: 'repository-documents',
    component: RepositoryDocumentsComponent,
    canActivate: [RepositoryDocumentsGuard],
  },
  {
    path: 'repo-health-page',
    component: RepositoryHealthPageComponent,
  },
];
