import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {LoginComponent} from './login.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ResetPasswordComponent} from './reset-password.component';
import {RemindPasswordComponent} from './remind-password.component';
import {SharedComponentsModule} from '../bonding_shared/components/shared-components.module';
import {TranslateModule} from '@ngx-translate/core';
import {PasswordResetService} from '../portal/services/password-reset.service';

@NgModule({
  imports: [CommonModule, RouterModule, FormsModule, ReactiveFormsModule, TranslateModule, SharedComponentsModule],
  declarations: [LoginComponent, ResetPasswordComponent, RemindPasswordComponent],
  exports: [LoginComponent, ResetPasswordComponent, RemindPasswordComponent],
  providers: [PasswordResetService],
})
export class LoginModule {}
