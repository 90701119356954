import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {DictionaryBaseService, LoggedUserService, PropertyService} from '../bonding_shared/services/index';
import {AppConfigService} from '../bonding_shared/services/app-config.service';
import {RouterService} from '../bonding_shared/services/router-service';
import {LOGIN_ERROR, UserDto} from '../bonding_shared/model';
import {TranslateService} from '@ngx-translate/core';
import {ApplicationModule, ElementaryRight} from '../bonding_shared/model/dictionary-ids';

@Component({
  selector: 'login-cmp',
  templateUrl: 'login.component.html',
})
export class LoginComponent implements OnInit {
  public login: string;
  public password: string;
  public errorMessage: string;

  pendingLogin = false;

  constructor(
    public router: Router,
    public loggedUserService: LoggedUserService,
    public appConfigService: AppConfigService,
    public translateService: TranslateService,
    public dictService: DictionaryBaseService,
    protected routerService: RouterService,
    protected propertyService: PropertyService,
    protected route: ActivatedRoute
  ) {}

  goToSelectedView() {
    this.pendingLogin = false;
    if (this.loggedUserService.redirectUrl) {
      this.router.navigateByUrl(this.loggedUserService.redirectUrl);
    } else if (this.loggedUserService.getLoggedUserDefaultModuleId() === ApplicationModule.ADMINISTRATION) {
      if (this.loggedUserService.hasRight(ElementaryRight.MY_PROFILE_CATEGORY_USERS_SEARCH)) {
        this.routerService.toUserSearch();
      } else {
        this.routerService.toTaskSearch();
      }
    } else {
      this.routerService.toStartPage();
    }
  }

  ngOnInit() {
    this.loggedUserService.clearToken();
    this.dictService.cleanCache();
    this.isSafariBrowser();
    this.route.params.subscribe((params) => {
      const errorCode: string = params['errorCode'];
      if (errorCode && errorCode.length > 0) {
        this.errorMessage = 'errorCode.' + errorCode;
      }
    });
  }

  doLogin() {
    if (this.login && this.password) {
      if (!this.pendingLogin) {
        this.pendingLogin = true;
        this.loggedUserService.login(this.login, this.password).subscribe({
          next: (u) => this.initPropertiesAndLoadView(u),
          error: (error) => this.handleError(error),
        });
      }
    } else {
      this.errorMessage = this.translateService.instant('FILL_BOTH_LOGIN_AND_PASSWORD');
    }
  }

  initPropertiesAndLoadView(user: UserDto) {
    this.translateService.use(user.language.code);
    this.propertyService.initProperties().subscribe({
      next: (value) => {
        this.goToSelectedView();
      },
      error: (error) => {
        this.handleError(error);
      },
    });
  }

  // TODO:: different error msg for status 401 and others
  private handleError(error: any) {
    this.pendingLogin = false;
    console.error('Error on login: status = ' + error.message);
    if (error instanceof Error) {
      this.errorMessage = this.getLoginErrorMsg(<LOGIN_ERROR>(<Error>error).message);
    } else {
      this.errorMessage = 'Incorrect login or password';
    }
  }

  private getLoginErrorMsg(error: LOGIN_ERROR): string {
    return this.translateService.instant(error);
  }

  isSafariBrowser(): boolean {
    const objappVersion = navigator.appVersion;
    const objAgent = navigator.userAgent;
    let objbrowserName = navigator.appName;
    let objfullVersion = '' + parseFloat(navigator.appVersion);
    let objBrMajorVersion = parseInt(navigator.appVersion, 10);
    let objOffsetName = 0;
    let objOffsetVersion = 0;
    let ix = 0;
    if ((objOffsetVersion = objAgent.indexOf('Chrome')) !== -1) {
      // In Chrome
      objbrowserName = 'Chrome';
      objfullVersion = objAgent.substring(objOffsetVersion + 7);
    } else if ((objOffsetVersion = objAgent.indexOf('MSIE')) !== -1) {
      // In Microsoft internet explorer
      objbrowserName = 'Microsoft Internet Explorer';
      objfullVersion = objAgent.substring(objOffsetVersion + 5);
    } else if ((objOffsetVersion = objAgent.indexOf('Firefox')) !== -1) {
      // In Firefox
      objbrowserName = 'Firefox';
    } else if ((objOffsetVersion = objAgent.indexOf('Safari')) !== -1) {
      // In Safari
      objbrowserName = 'Safari';
      objfullVersion = objAgent.substring(objOffsetVersion + 7);
      if ((objOffsetVersion = objAgent.indexOf('Version')) !== -1) {
        objfullVersion = objAgent.substring(objOffsetVersion + 8);
      }
    } else if ((objOffsetName = objAgent.lastIndexOf(' ') + 1) < (objOffsetVersion = objAgent.lastIndexOf('/'))) {
      // For other browser 'name/version' is at the end of userAgent
      objbrowserName = objAgent.substring(objOffsetName, objOffsetVersion);
      objfullVersion = objAgent.substring(objOffsetVersion + 1);
      if (objbrowserName.toLowerCase() === objbrowserName.toUpperCase()) {
        objbrowserName = navigator.appName;
      }
    }
    // trimming the fullVersion string at semicolon/space if present
    if ((ix = objfullVersion.indexOf(';')) !== -1) {
      objfullVersion = objfullVersion.substring(0, ix);
    }
    if ((ix = objfullVersion.indexOf(' ')) !== -1) {
      objfullVersion = objfullVersion.substring(0, ix);
    }
    objBrMajorVersion = parseInt('' + objfullVersion, 10);
    if (isNaN(objBrMajorVersion)) {
      objfullVersion = '' + parseFloat(navigator.appVersion);
      objBrMajorVersion = parseInt(navigator.appVersion, 10);
    }
    // console.info('Browser name = ' + objbrowserName);
    // console.info('Full version = ' + objfullVersion);
    // console.info('Major version = ' + objBrMajorVersion);
    // console.info('navigator.appName = ' + navigator.appName);
    // console.info('navigator.userAgent = ' + navigator.userAgent);
    if (objbrowserName.indexOf('Safari') !== -1) {
      // console.info('Is Safari!');
      return true;
    } else {
      // console.info('Is NOT Safari!');
      return false;
    }
  }
}
