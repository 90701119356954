import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Params} from '@angular/router';
import {SalesRepStatsDto} from '../../bonding_shared/model/dtos';
import {SalesRepVersionDto} from '../../bonding_shared/model';
import {SalesRepVersionService} from '../../bonding_shared/services/sales-rep-version.service';
import {AppConfigService, RouterService} from '../../bonding_shared/services';
import {SalesRepElementaryRight} from '../../bonding_shared/model/dictionary-ids';

@Component({
  selector: 'sales-rep-preview',
  templateUrl: './sales-rep-preview.component.pug',
})
export class SalesRepPreviewComponent implements OnInit {
  readonly SalesRepElementaryRight = SalesRepElementaryRight;

  salesRepVersion: SalesRepVersionDto;
  salesRepStats: SalesRepStatsDto;

  constructor(
    private service: SalesRepVersionService,
    private route: ActivatedRoute,
    public router: RouterService,
    public appConfigService: AppConfigService
  ) {}

  ngOnInit() {
    this.route.params.subscribe((params) => this.initializeView(params));
  }

  initializeView(params: Params) {
    const id = +params['salesRepId'];
    this.service.getLastByParentId(id).subscribe((version) => {
      this.salesRepVersion = version;
    });
    this.service.getStatsByParentId(id).subscribe((stats) => (this.salesRepStats = stats));
  }

  getSalesRepId(): number {
    return this.salesRepVersion ? this.salesRepVersion.salesRep.id : undefined;
  }

  public get kuke(): boolean {
    return this.appConfigService.kuke;
  }
}
