import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {SearchViewComponent} from '../../../bonding_shared/components/search';
import {
  DictionaryBaseDto,
  LocalDateTimeRange,
  RejectManagementCriteriaDto,
  RejectManagementDto,
} from '../../../bonding_shared/model';
import {
  AppConfigService,
  DocumentService,
  GrowlService,
  RouterService,
  SearchDataProvider,
} from '../../../bonding_shared/services';
import {RejectManagementGuiService} from '../services/reject-management-gui.service';
import {RejectManagementSource} from '../../../bonding_shared/model/dictionary-ids';
import {ATableComponent} from '../../../bonding_shared/components/aku-table';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'reject-management',
  templateUrl: './reject-management.component.pug',
})
export class RejectManagementComponent implements OnInit {
  @ViewChild(SearchViewComponent, {static: true}) searchView: SearchViewComponent<
    RejectManagementCriteriaDto,
    RejectManagementDto
  >;
  @ViewChild(ATableComponent, {static: true}) aTable: ATableComponent<RejectManagementDto>;
  dataProvider: SearchDataProvider<RejectManagementCriteriaDto, RejectManagementDto>;
  statusRegExp: RegExp;

  constructor(
    public router: RouterService,
    private service: RejectManagementGuiService,
    public appService: AppConfigService,
    private documentService: DocumentService,
    protected translateService: TranslateService,
    private growlService: GrowlService
  ) {
    this.dataProvider = service.searchDataProvider;
    this.statusRegExp = appService.kuke ? /^(?:UPLOAD|UPLOAD_DOCUMENT|PROPOSALS|WORDINGS|SIGNING)$/ : undefined;
  }

  ngOnInit(): void {
    if (!this.dataProvider.searchCriteria.criteria) {
      this.initCriteria();
    }
  }

  initCriteria() {
    this.dataProvider.searchCriteria.criteria = <RejectManagementCriteriaDto>{};
    this.dataProvider.searchCriteria.criteria.source = <DictionaryBaseDto>{id: RejectManagementSource.REPOSITORY};
    this.dataProvider.searchCriteria.criteria.creationDateRange = <LocalDateTimeRange>{};
    this.dataProvider.textSearch = false;
  }

  gotoDocumentDetail(rm: RejectManagementDto) {
    this.documentService.downloadRMContent(rm.id, 'text/html', rm.fileName);
  }

  complete() {
    const rejects: RejectManagementDto[] = [];
    this.dataProvider.lastSearchResult.result.forEach((r) => rejects.push(r));
    if (rejects) {
      this.service.complete(rejects).subscribe((r) => {
        console.log('reject: ' + r);
        this.showSavedMsg();
        this.searchView.search();
      });
    }
  }

  showSavedMsg() {
    this.translateService.get('rejectManagement.reprocess').subscribe((m) => this.growlService.notice(m));
  }

  kuke() {
    return this.appService.kuke;
  }
  ecg() {
    return this.appService.ecg;
  }
}
