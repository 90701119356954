import {
  PolicyContractViewSection,
  PolicyContractViewService,
} from '../../../policy/services/policy-contract-view.service';
import {PolicyInquiryVersionDto, RelatedObject} from '../../../../bonding_shared';
import {Directive, Input} from '@angular/core';
import {ListEmitters} from '../../../../bonding_shared/components/details-view/list-emitters';
import {UntypedFormGroup} from '@angular/forms';

@Directive()
export class InquirySectionDirective extends ListEmitters {
  public _inquiry: PolicyInquiryVersionDto;

  @Input() inquiryForm: UntypedFormGroup;
  @Input() showErrors: boolean;
  @Input() sectionDisabled = false;

  @Input() set inquiry(inquiry: PolicyInquiryVersionDto) {
    this._inquiry = inquiry;
  }

  get inquiry(): PolicyInquiryVersionDto {
    return this._inquiry;
  }

  get readonly(): boolean {
    return this.sectionDisabled;
  }

  constructor(protected policyContractViewService: PolicyContractViewService) {
    super();
  }

  public viewSection = PolicyContractViewSection;

  isSectionVisible(viewSection: PolicyContractViewSection, defaultVisibility = false) {
    return this.policyContractViewService.isSectionVisible(this.inquiry.productType.id, viewSection, defaultVisibility);
  }

  isRequired(viewSection: PolicyContractViewSection, requiredByDefault = false) {
    return this.isSectionVisible(viewSection, requiredByDefault);
  }

  isEditable(viewSection: PolicyContractViewSection, editableByDefault = true) {
    return this.isSectionVisible(viewSection, editableByDefault);
  }
}
