import {Component, OnInit, ViewChild} from '@angular/core';
import {
  BusinessObjectDto,
  DictionaryBaseDto,
  Page,
  SearchResult,
  ServiceNoteCriteriaDto,
  ServiceNoteDto,
} from '../../bonding_shared/model';
import {GrowlService} from '../../bonding_shared/services/growl/growl.service';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {RouterService} from '../../bonding_shared/services/router-service';
import {ActivatedRoute, NavigationStart, Router} from '@angular/router';
import {SearchDataProvider} from '../../bonding_shared/services/search-data-provider';
import {DetailsView} from '../../bonding_shared/components/details-view/details-view';
import {PageChangedEvent} from 'ngx-bootstrap/pagination';
import {ComboItem} from '../../bonding_shared/components/combos';
import {ServiceNoteService} from './service-note.service';
import {FormDialogComponent} from '../../bonding_shared/components/form-dialog';
import {BusinessObjectType} from '../../bonding_shared/model/dictionary-ids';
import {filter} from 'rxjs/operators';

@Component({
  selector: 'service-notes',
  templateUrl: './service-notes.component.pug',
})
export class ServiceNotesComponent extends DetailsView implements OnInit {
  @ViewChild(FormDialogComponent, {static: true}) addNewServiceNoteDialog: FormDialogComponent;

  _businessObjectType: number;
  _businessObjectId: number;
  returnBusinessObjectTypeId: number;
  returnId: number;

  form: UntypedFormGroup;

  dataProvider: SearchDataProvider<ServiceNoteCriteriaDto, ServiceNoteDto>;

  serviceNotes: any[];

  totalCount = 0;
  pageSize = 10;

  pageSizes: ComboItem[] = [
    new ComboItem('5', '5'),
    new ComboItem('10', '10'),
    new ComboItem('25', '25'),
    new ComboItem('100', '100'),
  ];

  page = 1;

  ngOnInit(): void {
    this._route.params.subscribe((params) => this.initializeView(params));
  }

  constructor(
    public router: RouterService,
    _formBuilder: UntypedFormBuilder,
    protected growlService: GrowlService,
    private _route: ActivatedRoute,
    private serviceNoteService: ServiceNoteService,
    angularRouter: Router
  ) {
    super(growlService);
    this.dataProvider = new SearchDataProvider<ServiceNoteCriteriaDto, ServiceNoteDto>(serviceNoteService);
    this.form = _formBuilder.group({
      content: ['', Validators.compose([Validators.required, Validators.maxLength(3999)])],
    });
    angularRouter.events
      .pipe(
        filter((event) => {
          return event instanceof NavigationStart;
        })
      )
      .subscribe((event: NavigationStart) => {
        if (this.addNewServiceNoteDialog.modalRef) {
          this.addNewServiceNoteDialog.modalRef.hide();
        }
      });
  }

  initializeView(params: any) {
    const businessObjectType = +params['businessObjectType'];
    const businessObjectId = +params['businessObjectId'];
    if (params['returnId']) {
      this.returnId = +params['returnId'];
    }
    this._businessObjectId = businessObjectId;
    this._businessObjectType = businessObjectType;
    this.calculateReturnType();
    this.setupCriteria();
    this.dataProvider.search(this.getPage()).subscribe((res) => this.resultsReceived(res));
  }

  setupCriteria() {
    this.criteria = <ServiceNoteCriteriaDto>{};
    this.criteria.businessObject = <BusinessObjectDto>{};
    this.criteria.businessObject.relatedToId = this._businessObjectId;
    this.criteria.businessObject.relatedTo = <DictionaryBaseDto>{};
    this.criteria.businessObject.relatedTo.id = this._businessObjectType;
  }

  get criteria(): ServiceNoteCriteriaDto {
    return this.dataProvider.searchCriteria.criteria;
  }

  set criteria(c: ServiceNoteCriteriaDto) {
    this.dataProvider.searchCriteria.criteria = c;
  }

  getReturnId(): number {
    if (this.returnId) {
      return this.returnId;
    }
    return this._businessObjectId;
  }

  resultsReceived(res: SearchResult<ServiceNoteDto>) {
    this.serviceNotes = res.result;
    this.totalCount = res.size;
  }

  onChangePageSize(event: any) {
    this.dataProvider
      .search({start: 0, count: this.pageSize, countOnly: false})
      .subscribe((res) => this.resultsReceived(res));
  }

  paginationPageChanged(event: PageChangedEvent) {
    this.page = event.page;
    this.dataProvider.search(this.getPage()).subscribe((res) => this.resultsReceived(res));
  }

  getPage(): Page {
    const page = <Page>{};
    page.count = this.pageSize;
    page.start = (this.page - 1) * this.pageSize;
    return page;
  }

  addServiceNote() {
    const confirmationPromise: Promise<boolean> = this.addNewServiceNoteDialog.open('serviceNotes.addNewServiceNote');
    confirmationPromise.then((result) => this.saveNewServiceNote(result));
  }

  saveNewServiceNote(result: boolean) {
    if (result) {
      const note = <ServiceNoteDto>{
        content: this.form.get('content').value,
        businessObject: {relatedToId: this._businessObjectId, relatedTo: {id: this._businessObjectType}},
      };
      this.serviceNoteService.createServiceNote(note).subscribe((_) => {
        this.form.get('content').setValue('');
        this.growlService.notice('Service note saved');
        this.dataProvider.search(this.getPage()).subscribe((res) => this.resultsReceived(res));
      });
    } else {
      this.form.get('content').setValue('');
    }
  }

  calculateReturnType() {
    switch (this._businessObjectType) {
      case BusinessObjectType.MASTER_POLICY:
        this.returnBusinessObjectTypeId = BusinessObjectType.POLICY_VERSION;
        break;
      case BusinessObjectType.POLICY_INQUIRY:
        this.returnBusinessObjectTypeId = BusinessObjectType.POLICY_INQUIRY_VERSION;
        break;
      case BusinessObjectType.CONTRACT:
        this.returnBusinessObjectTypeId = BusinessObjectType.CONTRACT_VERSION;
        break;
      case BusinessObjectType.CLAIM:
        this.returnBusinessObjectTypeId = BusinessObjectType.CLAIM_VERSION;
        break;
      default:
        this.returnBusinessObjectTypeId = this._businessObjectType;
    }
  }
}
