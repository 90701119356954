import {AfterViewInit, Component} from '@angular/core';
import {SalesLeadService} from '../../bonding_shared/services/sales-lead.service';
import {TranslateService} from '@ngx-translate/core';
import {
  ClientInfoDto,
  CompanyIdDto,
  CompanyRatingVersionDto,
  CompanySimpleDto,
  SalesLeadDto,
} from '../../bonding_shared/model/dtos';
import {ActivatedRoute} from '@angular/router';
import {CompanyRatingService} from '../../bonding_shared/services/company-rating.service';
import {CompanyService} from '../../bonding_shared/services/company.service';
import {RouterService} from '../../bonding_shared/services/router-service';
import {GrowlService} from '../../bonding_shared/services/growl/growl.service';
import {
  BusinessObjectType,
  ContactNoteElementaryRight,
  ElementaryRight,
  RatingType,
  SalesLeadProduct,
} from '../../bonding_shared/model/dictionary-ids';
import {AuditedViewAbstract} from '../../bonding_shared/components/details-view/audited-view-abstract';
import {LoggedUserService, UserRange} from '../../bonding_shared/services';

@Component({
  selector: 'sales-lead-details',
  templateUrl: './sales-lead-details.component.pug',
})
export class SalesLeadDetailsComponent extends AuditedViewAbstract<SalesLeadDto> implements AfterViewInit {
  rating: CompanyRatingVersionDto;
  clientInfo: ClientInfoDto;
  SalesLeadProduct = SalesLeadProduct;
  readonly BusinessObjectTypeSALESLEAD = BusinessObjectType.SALES_LEAD;
  readonly ElementaryRight = ElementaryRight;
  readonly ContactNoteElementaryRight = ContactNoteElementaryRight;
  readonly salesUserRange: UserRange = 'sales';

  constructor(
    route: ActivatedRoute,
    protected service: SalesLeadService,
    router: RouterService,
    translateService: TranslateService,
    growlService: GrowlService,
    loggedUserService: LoggedUserService,
    private ratingService: CompanyRatingService,
    private companyService: CompanyService
  ) {
    super(route, service, router, translateService, growlService, loggedUserService);
    this.selectorNameList = ['CurrentInsurer', 'Client'];
    this.initializeSelectorEmitters(true);
  }

  ngAfterViewInit(): void {
    this.saveButton.hidden = true;
    this.cancelButton.hidden = true;
  }

  toDetailsRouterFunction(saved: SalesLeadDto) {
    this.router.toSalesLeadDetails(saved.id);
  }

  loadRating(clientId: number) {
    if (this.current.product?.id === SalesLeadProduct.CREDIT_INSURANCE) {
      this.ratingService.getLastCompanyRatingVersion(clientId, RatingType.ST_INSURANCE).subscribe(
        (rating) => {
          this.rating = rating;
        },
        (error) => (this.rating = undefined)
      );
    } else {
      this.ratingService.getLastCompanyRatingVersion(clientId).subscribe(
        (rating) => {
          this.rating = rating;
        },
        (error) => (this.rating = undefined)
      );
    }
  }

  loadClientInfo(clientId: number) {
    this.companyService.getClientInfo(clientId).subscribe(
      (clientInfo) => {
        this.clientInfo = clientInfo;
      },
      (error) => (this.clientInfo = undefined)
    );
  }

  toCRM() {
    this.router.toCompanyCrm(this.current.client.id);
  }

  currentInsurerSelect(c: CompanySimpleDto) {
    this.current.currentInsurer = <CompanyIdDto>{id: c.id, registrationName: c.registrationName};
  }

  initWithoutId() {
    super.initWithoutId();
    this.openSelector('Client');
  }

  onLoad() {
    super.onLoad();
    this.loadClientInfo(this.current.client.id);
    this.loadRating(this.current.client.id);
  }

  onClientSelect(company: CompanyIdDto) {
    this.loadClientInfo(company.id);
    this.loadRating(company.id);
    this.service.initialVersion().subscribe((s) => {
      this.current = s;
      this.current.client = company;
    });
    this.saveButton.hidden = false;
    this.cancelButton.hidden = false;
  }

  onCurrentSet() {
    super.onCurrentSet();
    this.saveButton.hidden = this.current.deleted;
    this.cancelButton.hidden = this.current.deleted;
    this.newVersionButton.hidden = this.current.deleted;
  }
}
