import {ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {
  CountryRatingService,
  DictionaryBaseService,
  DictionaryService,
  GrowlService,
  UserService,
} from '../../bonding_shared/services';
import {GroupAssignmentRuleService} from '../../bonding_shared/services/group-assignment-rule.service';
import {DictionaryBaseDto, GroupAssignmentRuleDto, UserGroupIdDto} from '../../bonding_shared/model';
import {NgForm} from '@angular/forms';
import {AbstractAssignmentRulesComponent} from './abstract-assigment-rules.component';

@Component({
  selector: 'group-assignment-rules',
  templateUrl: 'group-assignment-rules.component.pug',
})
export class GroupAssignmentRulesComponent extends AbstractAssignmentRulesComponent implements OnInit {
  groups: UserGroupIdDto[];
  group: UserGroupIdDto;
  rules: GroupAssignmentRuleDto[] = [];
  enabledCategories: string[] = [
    'K_COL',
    'K_LIM',
    'K_CLA',
    'K_DUW',
    'K_POL_ADM',
    'K_POL_PHO',
    'K_POL_SAL',
    'K_POL_UND',
  ];
  @ViewChild('ngForm', {static: true}) ngForm: NgForm;
  rule: GroupAssignmentRuleDto;

  constructor(
    private rulesService: GroupAssignmentRuleService,
    protected growlService: GrowlService,
    protected dictionaryService: DictionaryBaseService,
    protected dictionaryFullService: DictionaryService,
    protected userService: UserService,
    protected countryRatingService: CountryRatingService,
    private cd: ChangeDetectorRef
  ) {
    super(growlService, dictionaryService, userService);
    this.saveButton.hidden = false;
    this.cancelButton.hidden = false;
    this.objectNotFound = false;
  }

  ngOnInit(): void {
    this.form = this.ngForm.form;
  }

  resolveClass() {
    if (this.profileCategory.code.startsWith('K_POL')) {
      return 'pl.accuratus.isa.assignment.dto.PolicyGroupAssignmentRuleDto';
    }
    if (this.profileCategory.code.startsWith('K_COL')) {
      return 'pl.accuratus.isa.assignment.dto.CollectionGroupAssignmentRuleDto';
    }
    if (this.profileCategory.code.startsWith('K_CLA') || this.profileCategory.code.startsWith('K_DUW')) {
      return 'pl.accuratus.isa.assignment.dto.ClaimGroupAssignmentRuleDto';
    }
    if (this.profileCategory.code.startsWith('K_LIM')) {
      return 'pl.accuratus.isa.assignment.dto.LimitGroupAssignmentRuleDto';
    }
  }

  onCreateNewVersion() {
    this.rule = {
      clazz: this.resolveClass(),
      group: this.group,
      id: null,
      name: '',
    };
    this.form.markAsPristine();
    this.cd.detectChanges();
  }

  onSave() {
    this.serverErrors = undefined;
    this.showErrors = false;
    if (this.formValidates()) {
      this.inProgress = true;
      this.rulesService.save(this.rule).subscribe({
        next: (res) => {
          this.afterObjectSaved();
          this.form.markAsPristine();
          this.rule = res;
          this.rulesService.getRules(this.group.id).subscribe((res2) => {
            this.rules = res2;
          });
        },
        error: (error) => {
          this.serverErrors = error;
          this.inProgress = false;
          this.growlService.warning('Error during saving assignment rule');
          console.log(error);
        },
      });
    }
  }

  onCancel() {
    if (this.group && this.group.id) {
      if (this.rule.id) {
        this.rulesService.getRule(this.rule.id).subscribe((res) => {
          this.form.markAsPristine();
          this.rule = res;
          this.cd.detectChanges();
        });
      } else {
        this.form.markAsPristine();
        this.rule = null;
        this.cd.detectChanges();
      }
    }
  }

  isFormDirty(): boolean {
    return this.form.dirty;
  }

  userGroupChange(userGroup: UserGroupIdDto) {
    if (userGroup && userGroup.id) {
      this.rule = undefined;
      this.rulesService.getRules(this.group.id).subscribe((res) => {
        this.rules = res;
      });
    } else {
      this.rule = undefined;
    }
  }

  profileCategoryChanged(profileCategory: DictionaryBaseDto) {
    if (profileCategory && profileCategory.id) {
      this.rulesService.getGroupsForCategory(profileCategory.id).subscribe((res) => (this.groups = res));
      this.rule = undefined;
      this.group = undefined;
    } else {
      this.groups = [];
      this.group = undefined;
      this.rule = undefined;
    }
  }

  ruleSelected(selectedRule: GroupAssignmentRuleDto) {
    this.rulesService.getRule(selectedRule.id).subscribe((res) => {
      this.form.markAsPristine();
      this.rule = res;
      this.cd.detectChanges();
    });
  }

  newRuleCreationPossible() {
    return (
      this.profileCategory && this.group && (this.profileCategory.code.startsWith('K_POL') || this.rules.length === 0)
    );
  }

  loadMarketable() {
    this.countryRatingService.getMarketableCountries().subscribe((res) => {
      console.log(res);
    });
  }

  loadNonMarketable() {
    this.countryRatingService.getNonMarketableCountries().subscribe((res) => {
      console.log(res);
    });
  }
}
