import {Directive, Input} from '@angular/core';
import {DictionaryService, PolicyGroupAssignmentRuleDto, UserIdDto, UserService} from '../../bonding_shared';

@Directive()
export abstract class CommonPolicyAssignmentCriteria {
  public salesPersons: UserIdDto[] = [];
  public taskTypes = [];

  protected constructor(protected dictionaryFullService: DictionaryService, protected userService: UserService) {
    this.userService.getSalesUsers().subscribe((res) => {
      this.salesPersons = res;
      this.salesPersons.forEach((u) => (u.name = u.fullName));
    });
  }

  _rule: PolicyGroupAssignmentRuleDto;
  @Input()
  set rule(rule: PolicyGroupAssignmentRuleDto) {
    this._rule = rule;
    if (!rule.vip) {
      this._rule.vip = null;
    }
    if (!rule.salesPersons) {
      this._rule.salesPersons = [];
    }
    if (!rule.products) {
      this._rule.products = [];
    }
    if (!rule.taskTypes) {
      this._rule.taskTypes = [];
    }
  }

  get rule(): PolicyGroupAssignmentRuleDto {
    return this._rule;
  }
}
