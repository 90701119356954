import {Component} from '@angular/core';
import {PolicyContractViewService} from '../../../policy/services/policy-contract-view.service';
import {ControlContainer, NgForm} from '@angular/forms';
import {CompanySimpleDto, RelatedObject} from '../../../../bonding_shared';
import {InquirySectionDirective} from './inquiry-section';

@Component({
  selector: 'debtor-buyer-ultimate-user-inquiry-section',
  templateUrl: './debtor-buyer-ultimate-user-inquiry-section.component.pug',
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}],
})
export class DebtorBuyerUltimateUserInquirySectionComponent extends InquirySectionDirective {
  constructor(protected policyContractViewService: PolicyContractViewService) {
    super(policyContractViewService);
    this.selectorNameList = [RelatedObject.DEBTOR, RelatedObject.BUYER, RelatedObject.ULTIMATE_USER];
    this.initializeSelectorEmitters(true);
  }

  onSelectDebtor(company: CompanySimpleDto) {
    this.inquiry.debtor = company;
  }

  onSelectBuyer(company: CompanySimpleDto) {
    this.inquiry.buyer = company;
  }

  onSelectUltimateUser(company: CompanySimpleDto) {
    this.inquiry.ultimateUser = company;
  }
}
