import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import {AppConfigService, FeatureService, TaskDto, UserDto, UserNamePipe} from '../../../../../bonding_shared';
import {Feature} from '../../../../../bonding_shared/model/dictionary-ids';

@Component({
  selector: 'contact-note-task',
  templateUrl: './contact-note-task.component.pug',
})
export class ContactNoteTaskComponent implements OnInit {
  task: TaskDto;

  @Input() showErrors: boolean;

  @Input() createTask: boolean;

  @Input() applicationModuleId: number;

  filterTaskByModule = false;

  @ViewChild('ngForm', {static: true}) ngForm: NgForm;
  parentDictionaryForTaskType: string;

  constructor(public appService: AppConfigService, private featureService: FeatureService) {
    this.filterTaskByModule = featureService.get(Feature.FILTER_TASK_BY_MODULE);
  }

  ngOnInit(): void {
    this.task = this.initTask();
    if (this.filterTaskByModule) {
      this.parentDictionaryForTaskType = 'ApplicationModule';
    }
  }

  onTaskUserChange(item: UserDto) {
    this.task.user = item;
  }

  userFullName = (u: UserDto): string => {
    return new UserNamePipe().transform(u);
  };

  private initTask(): TaskDto {
    const t = <TaskDto>{};
    t.creationDate = new Date();
    t.dueDate = new Date();
    t.dueDate.setDate(t.creationDate.getDate() + 7);
    return t;
  }

  getRegexp() {
    return this.appService.credendo ? /^(?:REM|SLV|RCN)$/ : undefined;
  }
}
