import {Component, ViewChild} from '@angular/core';
import {
  ATableComponent,
  CompanyBaseDto,
  DictionaryBaseDto,
  PolicyGuarantorDto,
  RelatedObject,
  RouterService,
} from '../../../../bonding_shared';
import {PolicyContractViewService} from '../../../policy/services/policy-contract-view.service';
import {InquirySectionDirective} from './inquiry-section';
import {CompanyType, PolicyGuarantorRelationType} from '../../../../bonding_shared/model/dictionary-ids';

@Component({
  selector: 'guarantors-inquiry-section',
  templateUrl: './guarantors-inquiry-section.component.pug',
})
export class GuarantorsInquirySectionComponent extends InquirySectionDirective {
  @ViewChild('guarantorsList', {static: true}) guarantorsList: ATableComponent<PolicyGuarantorDto>;

  companyTypeIds = [CompanyType.BANK];

  constructor(protected policyContractViewService: PolicyContractViewService, public router: RouterService) {
    super(policyContractViewService);
    this.selectorNameList = [RelatedObject.GUARANTOR];
    this.initializeSelectorEmitters(true);
  }

  addGuarantor(company: CompanyBaseDto) {
    this.guarantorsList.onAddItem(<PolicyGuarantorDto>{
      company: company,
      selected: false,
      relationType: <DictionaryBaseDto>{id: PolicyGuarantorRelationType.PUBLIC, name: 'Public'},
    });
  }
}
