import {Component, Input} from '@angular/core';
import {DictionaryBaseDto, PolicyInquiryOfferDto} from '../../../bonding_shared';
import {InquiryProductType, RatingScale, DictionaryProfile} from '../../../bonding_shared/model/dictionary-ids';
import {PolicyContractViewSection, PolicyContractViewService} from '../../policy/services/policy-contract-view.service';

@Component({
  selector: 'premium-calculation-result',
  templateUrl: 'premium-calculation-result.component.html',
})
export class PremiumCalculationResultComponent {
  readonly RatingScale = RatingScale;
  readonly viewSection = PolicyContractViewSection;
  readonly DictionaryProfile = DictionaryProfile;

  _offer: PolicyInquiryOfferDto;

  @Input() set offer(offer: PolicyInquiryOfferDto) {
    this._offer = offer;
    this.setCalculationType();
    this.initPremiumAmountCurrencies();
  }
  get offer() {
    return this._offer;
  }

  premiumAmountCurrencies: DictionaryBaseDto[] = [];

  isCalculationTypeB = false;
  insuredAmountLabel: string;

  constructor(private policyContractViewService: PolicyContractViewService) {}

  initPremiumAmountCurrencies() {
    this.premiumAmountCurrencies = [];
    for (const amount of this.offer.mehibPremiumCalculationResult.premiumAmounts) {
      this.premiumAmountCurrencies.push(amount.currency);
    }
  }

  isSectionVisible(viewSection: PolicyContractViewSection, defaultVisibility = false) {
    return (
      this.offer.type &&
      this.policyContractViewService.isSectionVisible(this.offer.type.id, viewSection, defaultVisibility)
    );
  }

  setCalculationType() {
    this.isCalculationTypeB = this.offer.type.id === InquiryProductType.FOREIGN_INVESTMENT_B;
    this.setTypeSpecificLabels();
  }

  setTypeSpecificLabels() {
    this.insuredAmountLabel = this.isCalculationTypeB
      ? 'inquiry.calculator.capitalPlusYield'
      : 'inquiry.calculator.insuredAmount';
  }
}
