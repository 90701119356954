import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {SearchViewComponent} from '../../../bonding_shared/components/search';
import {BusinessLogCriteriaDto, BusinessLogDto, LocalDateTimeRange, UserBaseDto} from '../../../bonding_shared/model';
import {RouterService, SearchDataProvider} from '../../../bonding_shared/services';
import {BusinessLogGuiService} from '../services/business-log-gui.service';
import {DateUtils} from '../../../bonding_shared/utils/date-utils';

@Component({
  selector: 'business-log',
  templateUrl: './business-log.component.pug',
})
export class BusinessLogComponent implements OnInit {
  @ViewChild(SearchViewComponent, {static: true}) searchView: SearchViewComponent<
    BusinessLogCriteriaDto,
    BusinessLogDto
  >;
  dataProvider: SearchDataProvider<BusinessLogCriteriaDto, BusinessLogDto>;

  constructor(public router: RouterService, private service: BusinessLogGuiService) {
    this.dataProvider = service.searchDataProvider;
  }

  ngOnInit() {
    if (!this.dataProvider.searchCriteria.criteria) {
      this.initializeCriteria();
    }
  }

  initializeCriteria() {
    this.dataProvider.searchCriteria.criteria = <BusinessLogCriteriaDto>{};
    this.dataProvider.searchCriteria.criteria.user = <UserBaseDto>{};
    const today = DateUtils.today();
    this.dataProvider.searchCriteria.criteria.eventDateRange = <LocalDateTimeRange>{
      dateFrom: today,
      dateTo: today,
    };
    this.dataProvider.textSearch = false;
  }
}
