import {Component, OnInit, ViewChild} from '@angular/core';
import {SearchDataProvider} from '../../bonding_shared/services/search-data-provider';
import {BusinessObjectDto, EventCriteriaDto, EventViewDto} from '../../bonding_shared/model';
import {EventService} from '../../bonding_shared/services/event.service';
import {BusinessUtils} from '../../bonding_shared/utils/business-utils';
import {ActivatedRoute, Params} from '@angular/router';
import {ATableComponent} from '../../bonding_shared/components/aku-table';
import {withLatestFrom} from 'rxjs/operators';

@Component({
  selector: 'event',
  templateUrl: 'event.component.pug',
})
export class EventComponent implements OnInit {
  @ViewChild(ATableComponent, {static: true}) table: ATableComponent<EventViewDto>;
  dataProvider: SearchDataProvider<EventCriteriaDto, EventViewDto>;
  relatedTo: number;
  relatedToId: number;
  navLinkObjectId: number;
  navLinkObjectTypeId: number;

  constructor(private _route: ActivatedRoute, service: EventService) {
    this.dataProvider = BusinessUtils.createDataProvider(service);
  }

  ngOnInit() {
    this._route.queryParams.pipe(withLatestFrom(this._route.params)).subscribe(([queryParams, pathParams]) => {
      this.relatedTo = +pathParams['relatedTo'];
      this.relatedToId = +pathParams['relatedToId'];
      this.navLinkObjectTypeId = +queryParams['navLinkObjectTypeId'] ?? this.relatedTo;
      this.navLinkObjectId = +queryParams['navLinkObjectId'] ?? this.relatedToId;
      this.initializeView();
    });
  }

  initializeView() {
    this.dataProvider.searchCriteria.criteria.businessObject = <BusinessObjectDto>{
      relatedTo: {id: this.relatedTo},
      relatedToId: this.relatedToId,
    };
    setTimeout(() => this.table.search());
  }
}
