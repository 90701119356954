import {Component, Input, OnInit} from '@angular/core';
import {
  DictionaryDto,
  PolicyInquiryOfferDto,
  PolicyInquiryOfferSimpleDto,
  PolicyInquiryVersionDto,
  RouterService,
} from '../../../bonding_shared';
import {PolicyInquiryOfferVotingStatus, PolicyInquiryStatus} from '../../../bonding_shared/model/dictionary-ids';
import {PolicyContractViewSection, PolicyContractViewService} from '../../policy/services/policy-contract-view.service';

@Component({
  selector: 'inquiry-offers',
  templateUrl: 'inquiry-offers.component.html',
  styles: [],
})
export class InquiryOffersComponent implements OnInit {
  @Input() addOfferButton = true;

  @Input() set inquiry(value: PolicyInquiryVersionDto) {
    this._inquiry = value;
    if (this._inquiry) {
      this.offers = this._inquiry.offers;
    } else {
      this.offers = [];
    }
  }
  readonly viewSection = PolicyContractViewSection;
  offers: PolicyInquiryOfferDto[];
  private _inquiry: PolicyInquiryVersionDto;

  constructor(public router: RouterService, public policyContractViewService: PolicyContractViewService) {}

  ngOnInit() {}

  add() {
    this.router.toInquiryOfferDetails(this._inquiry.id, 0);
  }

  goToOffer(offerId: number) {
    this.router.toInquiryOfferDetails(this._inquiry.id, offerId);
  }

  onNewTypeSelected(type: DictionaryDto) {
    this.router.toNewInquiryOfferDetails(this._inquiry.id, type.id);
  }

  newOffer(offer?: PolicyInquiryOfferSimpleDto) {
    const sourceOfferId = offer ? offer.id : null;
    this.router.toNewInquiryOfferDetails(this._inquiry.id, this._inquiry.productType.id, sourceOfferId);
  }

  isNewOfferAllowed(): boolean {
    return (
      this._inquiry &&
      this._inquiry.status &&
      this._inquiry.status.id === PolicyInquiryStatus.POLICY_INQUIRY_ACCEPTED &&
      this.addOfferButton
    );
  }

  votingThumbUp(item: PolicyInquiryOfferSimpleDto) {
    return (
      item.votingStatus.id === PolicyInquiryOfferVotingStatus.ACCEPTED ||
      item.votingStatus.id === PolicyInquiryOfferVotingStatus.POLICY_CREATED
    );
  }
  votingThumbDown(item: PolicyInquiryOfferSimpleDto) {
    return item.votingStatus.id === PolicyInquiryOfferVotingStatus.REJECTED;
  }

  isNewOfferFromPreviousAvailable() {
    return this.offers && this.offers.length > 1;
  }

  isSectionVisible(viewSection: PolicyContractViewSection, defaultVisibility = false) {
    if (this._inquiry && this._inquiry.productType) {
      return this.policyContractViewService.isSectionVisible(
        this._inquiry.productType.id,
        viewSection,
        defaultVisibility
      );
    }
    return false;
  }
}
