import {AfterViewInit, Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {
  BackendError,
  ErrorReason,
  isErrorReasons,
  LimitDecisionDto,
  PolicyInquiryOfferEnvelopeDto,
  PolicyInquiryVersionDto,
} from '../../../bonding_shared/model';
import {
  GrowlService,
  InquiryService,
  LimitService,
  LoggedUserService,
  RouterService,
} from '../../../bonding_shared/services';
import {ATableComponent} from '../../../bonding_shared/components/aku-table';
import {BusinessUtils} from '../../../bonding_shared/utils/business-utils';
import {Observable, ReplaySubject} from 'rxjs';
import {isRegistrationCompletedAvailable} from '../domain/offer-allowance';
import {ConfirmDialogComponent} from '../../../bonding_shared';
import {mergeMap, tap} from 'rxjs/operators';

@Component({
  selector: 'rated-limits',
  templateUrl: 'rated-limits.component.pug',
})
export class RatedLimitsComponent implements AfterViewInit {
  self = this;

  @Input()
  inquiryVersion: PolicyInquiryVersionDto = null;
  @Input()
  pbgLayout: boolean;
  @Input()
  private offerId: number;

  @Output() offerRefresh = new EventEmitter<() => Observable<PolicyInquiryOfferEnvelopeDto>>();

  @ViewChild('limitsTableInOffer', {static: false}) limits: ATableComponent<LimitDecisionDto>;
  @ViewChild(ConfirmDialogComponent, {static: true}) confirmDialog: ConfirmDialogComponent;

  show: boolean;
  portalMode = false;
  readonly limitDecisions = new ReplaySubject<LimitDecisionDto[]>(1);
  resultNotEmpty: boolean | null = null;

  serverErrors: BackendError = null;

  constructor(
    private limitService: LimitService,
    private loggedUserService: LoggedUserService,
    public router: RouterService,
    private inquiryService: InquiryService,
    private growlService: GrowlService
  ) {
    this.portalMode = this.loggedUserService.portal;
  }

  ngAfterViewInit() {
    setTimeout(() => this.limitSearch(), 500);
  }

  private limitSearch() {
    this.serverErrors = null;

    this.inquiryService.getLimitPositiveDecisions(this.inquiryVersion.policyInquiry.id).subscribe({
      next: (result) => {
        this.resultNotEmpty = result && result.length > 0;
        this.limitDecisions.next(result);
      },
      error: (error) => {
        console.log('Rated limit search error:', error);
        this.serverErrors = error;
      },
    });
  }

  getBuyerName(limit: LimitDecisionDto) {
    return BusinessUtils.companyName(this.getBuyer(limit).company);
  }

  getBuyerCountry(limit: LimitDecisionDto): string {
    const buyer = this.getBuyer(limit);
    return (
      buyer && buyer.company && buyer.company.address.country && buyer.company.address.country.code.toLocaleLowerCase()
    );
  }

  getBuyer(limit: LimitDecisionDto) {
    return BusinessUtils.getThirdPartyCompany(limit.limitRequest.limit.buyer);
  }

  getLp(item) {
    if (this.limits) {
      return this.limits.getItemIndex(item) + 1;
    }
    return null;
  }

  isRegistrationCompletedAvailable() {
    return this.resultNotEmpty && isRegistrationCompletedAvailable(this.portalMode, this.inquiryVersion);
  }

  registrationCompleted() {
    this.inquiryService.registrationCompleted(this.inquiryVersion.id).subscribe({
      next: (_) => {
        this.inquiryVersion.limitsRegistrationCompleted = true;
        this.growlService.notice('Success!');
      },
      error: (error) => {
        let msg = 'Error!';
        if (isErrorReasons(error) && (<ErrorReason[]>error).length > 0) {
          msg = (<ErrorReason[]>error)[0].message;
        }
        this.growlService.error(msg, 'Error!');
      },
    });
  }

  cancelLimitDialog(limit: LimitDecisionDto) {
    this.confirmDialog.openAndExecuteOnConfirm(
      'limitDetails.cancelConfirmPopup.title',
      'limitDetails.cancelConfirmPopup.msg',
      () => this.cancelLimit(limit),
      () => void 0
    );
  }

  cancelLimit(limit: LimitDecisionDto) {
    this.offerRefresh.emit(() =>
      this.limitService.cancelLimitDecision(limit.limitRequest.id).pipe(
        tap({
          next: () => {
            this.limitDecisions.next([]);
            setTimeout((_) => this.limitSearch(), 500);
          },
          error: (error) => {
            console.log('Cancel limit error:', error);
          },
        }),
        mergeMap(() => this.inquiryService.getOffer(this.offerId))
      )
    );
  }
}
