import {Component, ViewChild} from '@angular/core';
import {SearchViewComponent} from '../../bonding_shared/components/search/search-view/search-view.component';
import {DictionaryBaseDto, SalesLeadCriteriaDto, SalesLeadDto} from '../../bonding_shared/model/dtos';
import {SalesLeadListComponent} from './components/sales-lead-list.component';
import {SalesLeadGuiService} from './services/sales-lead-gui.service';
import {SearchView} from '../../bonding_shared/components/search/search-view/search-view';
import {AppConfigService, RouterService, UserRange} from '../../bonding_shared/services';
import {SalesLeadProduct} from '../../bonding_shared/model/dictionary-ids';

@Component({
  selector: 'sales-lead-search',
  templateUrl: './sales-lead-search.component.pug',
})
export class SalesLeadSearchComponent extends SearchView {
  @ViewChild(SearchViewComponent, {static: true}) searchView: SearchViewComponent<SalesLeadCriteriaDto, SalesLeadDto>;
  @ViewChild(SalesLeadListComponent, {static: true}) salesLeadList: SalesLeadListComponent;

  readonly SalesLeadProduct = SalesLeadProduct;
  readonly salesUserRange: UserRange = 'sales';

  constructor(
    public guiService: SalesLeadGuiService,
    public router: RouterService,
    public appService: AppConfigService
  ) {
    super();
  }

  newExecuted(): void {
    this.router.toSalesLeadDetailsNew();
  }

  getCriteria() {
    return this.guiService.searchCriteria.dataProvider.searchCriteria.criteria;
  }

  onProductChange(d: DictionaryBaseDto) {
    if (!d) {
      this.getCriteria().productType = undefined;
    }
  }
}
