import {Component, OnInit, ViewChild} from '@angular/core';
import {RouterService} from '../../bonding_shared/services/router-service';
import {
  CountryRatingVersionBaseDto,
  CountryRatingVersionCriteriaDto,
  DictionaryBaseDto,
  LimitRequestDto,
  LocalDateRange,
  LocalDateTimeRange,
} from '../../bonding_shared/model/dtos';
import {CountryRatingGuiService} from './services/country-rating-gui.service';
import {SearchDataProvider} from '../../bonding_shared/services/search-data-provider';
import {CountryRatingService} from '../../bonding_shared/services/country-rating.service';
import {HttpParams} from '@angular/common/http';
import {AppConfigService, BusinessReportService} from '../../bonding_shared/services';
import {CountryRatingSettingType, ImportType, OECDRating} from '../../bonding_shared/model/dictionary-ids';
import {ReportDefinition} from '../../bonding_shared/model/report-ids';
import {CountryCategoryLimitsService} from '../../bonding_shared/services/country-category-limits.service';
import {ActivatedRoute, Params} from '@angular/router';
import {ATableComponent} from '../../bonding_shared';

@Component({
  selector: 'country-rating-search',
  templateUrl: './country-rating-search.component.pug',
})
export class CountryRatingSearchComponent implements OnInit {
  dataProvider: SearchDataProvider<CountryRatingVersionCriteriaDto, CountryRatingVersionBaseDto>;
  readonly ImportType = ImportType;
  ratingType: string;
  showOECD: boolean;
  showInsurer: boolean;
  @ViewChild(ATableComponent, {static: true}) countryRatingTable: ATableComponent<LimitRequestDto>;

  constructor(
    private route: ActivatedRoute,
    public router: RouterService,
    public guiService: CountryRatingGuiService,
    private ratingService: CountryRatingService,
    private appService: AppConfigService,
    private businessReportService: BusinessReportService,
    private countryCategoryLimitsService: CountryCategoryLimitsService
  ) {
    this.dataProvider = guiService.dataProvider;
  }

  ngOnInit() {
    this.route.params.subscribe((params) => this.initializeView(params));
  }

  initializeView(params: Params) {
    this.ratingType = params['type'];
    this.showOECD = this.isOECD(this.ratingType);
    this.showInsurer = this.isInsurer(this.ratingType);
    this.initializeCriteria();
  }

  isOECD(type: string): boolean {
    if (this.ratingType === 'ALL' || this.ratingType === 'OECD') {
      return true;
    }
    return false;
  }

  isInsurer(type: string): boolean {
    if (this.ratingType === 'ALL' || this.ratingType === 'INSURER') {
      return true;
    }
    return false;
  }

  initializeCriteria() {
    this.dataProvider.searchCriteria.criteria = <CountryRatingVersionCriteriaDto>{};
    this.dataProvider.searchCriteria.criteria.validFromRange = <LocalDateRange>{};
    this.dataProvider.searchCriteria.criteria.validToRange = <LocalDateRange>{};
    this.dataProvider.searchCriteria.criteria.nextReviewDate = <LocalDateRange>{};
    this.dataProvider.searchCriteria.criteria.creationDateRange = <LocalDateTimeRange>{};
    this.dataProvider.textSearch = false;
    this.dataProvider.searchCriteria.criteria.last = true;
    this.setSettingType();
    this.search();
  }

  private setSettingType() {
    if (this.ratingType === 'OECD') {
      this.dataProvider.searchCriteria.criteria.settingType = <DictionaryBaseDto>{id: CountryRatingSettingType.OECD};
    } else if (this.ratingType === 'INSURER') {
      this.dataProvider.searchCriteria.criteria.settingType = <DictionaryBaseDto>{id: CountryRatingSettingType.INSURER};
    } else if (this.ratingType === 'ALL') {
      this.dataProvider.searchCriteria.criteria.settingType = <DictionaryBaseDto>{id: CountryRatingSettingType.ALL};
    }
  }

  downloadCountryRatings() {
    console.log('download country ratings');
    this.businessReportService.generate(ReportDefinition.COUNTRY_RATINGS, 'country_ratings.xlsx', new HttpParams());
  }

  get kuke(): boolean {
    return this.appService.kuke;
  }

  get mehib(): boolean {
    return this.appService.mehib;
  }

  get credendo(): boolean {
    return this.appService.credendo;
  }

  public toCountryCategoryLimitDetails() {
    this.countryCategoryLimitsService.getLastVersion().subscribe(
      (l) => {
        if (l.size === 0) {
          this.router.toCountryCategoryLimitDetails();
        } else {
          this.router.toCountryCategoryLimitDetails(l.result[0].id);
        }
      },
      (error) => console.warn('getLastVersion Country Category Limit error: ', error)
    );
  }

  hideRatings() {
    if (this.mehib) {
      return new Set<number>([OECDRating.R0]);
    } else {
      return new Set<number>();
    }
  }

  search() {
    this.countryRatingTable.search();
  }
}
