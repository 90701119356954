<bon-section [title]="'inquiry.calculator.result.title' | translate">
  <div class="bon-card-group">
    <div class="bon-card-inner" *ngIf="isSectionVisible(viewSection.OFFER__VSKV_TA)">
      <num-row [(ngModel)]="offer.mehibPremiumCalculationResult.lengthOfDisbursementPeriodMonths" labelKey="inquiry.calculator.lengthOfDisbursementPeriodMonths"
               [disabled]="true" type="integer" name="lengthOfDisbursementPeriodMonths"></num-row>
      <num-row [(ngModel)]="offer.mehibPremiumCalculationResult.gracePeriodYears" labelKey="inquiry.calculator.gracePeriodYears"
               [disabled]="true" name="gracePeriodYears"></num-row>
    <!--  todo: identify the property from calculation to link with -->
      <num-row [(ngModel)]="offer.mehibPremiumCalculationResult.firstRepaymentDate" labelKey="inquiry.calculator.firstRepaymentDate"
               [disabled]="true" name="firstRepaymentDate"></num-row>
      <dict-row [(ngModel)]="offer.mehibPremiumCalculationResult.repaymentType" labelKey="offer.details.repaymentType"
                dictionary="RepaymentType" name="repaymentType" [disabled]="true"></dict-row>
    </div>
    <div class="bon-card-inner">
      <num-row *ngIf="isSectionVisible(viewSection.OFFER__MANUFACTURING_PERIOD)" name="lengthOfManufacturingPeriodMonths"
               [(ngModel)]="offer.mehibPremiumCalculationResult.lengthOfManufacturingPeriodMonths" type="decimal"
               labelKey="inquiry.calculator.lengthOfManufacturingPeriodMonths" [disabled]="true" ></num-row>
      <num-row [(ngModel)]="offer.mehibPremiumCalculationResult.politicalRiskCoverPercent"
               labelKey="inquiry.calculator.politicalRiskCoverPercent" name="politicalRiskCoverPercent" [disabled]="true"></num-row>
      <num-row *ngIf="isSectionVisible(viewSection.OFFER__COMMERCIAL_RISK_COVER, true)"
               [(ngModel)]="offer.mehibPremiumCalculationResult.commercialRiskCoverPercent" [disabled]="true"
               name="commercialRiskCoverPercent" labelKey="inquiry.calculator.commercialRiskCoverPercent" ></num-row>
      <num-row *ngIf="isSectionVisible(viewSection.OFFER__REPAYMENT_PERIOD, true)"
               [(ngModel)]="offer.mehibPremiumCalculationResult.lengthOfRepaymentPeriodYears" [disabled]="true" type="integer"
               labelKey="inquiry.calculator.lengthOfRepaymentPeriodYears" name="lengthOfRepaymentPeriodYears" ></num-row>
    </div>
    <div class="bon-card-inner">
      <dict-row *ngIf="isSectionVisible(viewSection.OFFER__BUYER_RISK, true)" dictionary="Rating" parentDictionary="RatingScale"
                [(ngModel)]="offer.mehibPremiumCalculationResult.appliedBuyerRisk" labelKey="inquiry.calculator.buyerRiskCategory"
                [disabled]="true" name="buyerRiskCategory" [parentDictionaryEntryId]="RatingScale.CC"></dict-row>
      <dict-row [(ngModel)]="offer.mehibPremiumCalculationResult.appliedCountryRisk" labelKey="inquiry.calculator.appliedCountryRiskCategory"
                dictionary="OECDRating" name="appliedOecdRating" [disabled]="true"></dict-row>
      <num-row [(ngModel)]="offer.mehibPremiumCalculationResult.appliedPremiumRate"
               labelKey="inquiry.calculator.appliedPremiumRateOffer" name="appliedPremiumRate" [disabled]="true" [numberOfDecimalPlaces]="4"></num-row>
      <num-row [ngModel]="offer.mehibPremiumCalculationResult.calculatedPremiumRate"
               labelKey="inquiry.calculator.calculatedPremiumRate" name="calculatedPremiumRate" [disabled]="true" [numberOfDecimalPlaces]="4"></num-row>
    </div>
    <div class="bon-card-inner" *ngIf="!isSectionVisible(viewSection.OFFER__VSKV_TA)">
      <!--always show 3 columns-->
    </div>
  </div>
</bon-section>

<bon-section [title]="'inquiry.calculator.result.insuredAmountAndInvoicedPremium' | translate">
  <div class="bon-card-group">
    <div class="bon-card-inner">
      <ng-container *ngIf="offer.mehibPremiumCalculationResult?.premiumAmounts">
        <num-row *ngFor="let premiumAmount of offer.mehibPremiumCalculationResult.premiumAmounts"
                 [label]="(insuredAmountLabel | translate) + ' ' + premiumAmount.currency.code"
                 [name]="'insuredAmount'+premiumAmount.currency.code" [ngModel]="premiumAmount.insuredAmount" disabled="disabled">

        </num-row>
      </ng-container>
      <form-row label="inquiry.calculator.insuredAmountCurrency">
        <item-combo class="bon-input-size" [items]="premiumAmountCurrencies" nullLabel="--"
                    [(ngModel)]="offer.insuredAmountCurrency" name="insuredAmountCurrency" label="code">
        </item-combo>
      </form-row>
    </div>
    <div class="bon-card-inner">
      <ng-container *ngIf="offer.mehibPremiumCalculationResult?.premiumAmounts">
        <num-row *ngFor="let premiumAmount of offer.mehibPremiumCalculationResult.premiumAmounts"
                 [label]="('inquiry.calculator.upFrontPremium' | translate) + ' ' + premiumAmount.currency.code"
                 [name]="'premiumAmount'+premiumAmount.currency.code" [ngModel]="premiumAmount.upfrontPremium" disabled="disabled">
        </num-row>
      </ng-container>
      <form-row labelKey="common.currency">
        <item-combo class="bon-input-size" [items]="premiumAmountCurrencies" nullLabel="--"
                    [(ngModel)]="offer.policyInquiryVersion.currency" name="inquiryCurrency" label="code">
        </item-combo>
      </form-row>
    </div>
  </div>
</bon-section>
