import {ClientCompanySearchComponent} from './components/client-company-search.component';
import {ClientCompanyDetailsComponent} from './components/client-company-details.component';
import {ClientCompanyAccessGuard} from '../company/_guards/client-company-access.guard';

export const ClientCompanyRoutes = [
  {
    path: 'client-company-details/:id',
    component: ClientCompanyDetailsComponent,
    canActivate: [ClientCompanyAccessGuard],
  },
  {
    path: 'client-company-details',
    component: ClientCompanyDetailsComponent,
    canActivate: [ClientCompanyAccessGuard],
  },
  {
    path: 'client-company-search',
    component: ClientCompanySearchComponent,
    canActivate: [ClientCompanyAccessGuard],
  },
];
