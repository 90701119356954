import {Component} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {RepositoryService} from '../../services';

@Component({
  selector: 'repo-downloader',
  template: '',
})
export class RepoDownloaderComponent {
  constructor(private repositoryService: RepositoryService, private route: ActivatedRoute) {
    this.route.queryParams.subscribe((params) => {
      this.repositoryService.getRepositoryDocument(params['repositoryId'], params['documentName']);
    });
  }
}
