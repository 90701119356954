import {Component, Input} from '@angular/core';
import {UserIdDto} from '../../bonding_shared/model';
import {IMultiSelectOption, IMultiSelectSettings} from '../../bonding_shared/components/multiselect-dropdown';
import {ControlContainer, UntypedFormGroup, NgForm} from '@angular/forms';

@Component({
  selector: 'user-assignment-criteria',
  template: `
    <div class="bon-row" style="max-height: 550px; overflow-y: auto;">
      <a-table
        [formModel]="dataForm"
        [groupName]="labelKey"
        [items]="items"
        [deleteButton]="true"
        [editable]="false"
        [addButton]="false"
        buttonsCellWidth="59px"
      >
        <column property="fullName" [labelKey]="labelKey" [editable]="false"></column>
      </a-table>
    </div>
    <div class="bon-row">
      <ss-multiselect-dropdown
        [(ngModel)]="items"
        [options]="users"
        [settings]="multiSelectSettings"
        [name]="labelKey + 'Dropdown'"
      >
      </ss-multiselect-dropdown>
    </div>
  `,
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}],
})
export class UserAssignmentCriteriaComponent {
  @Input() items: UserIdDto[];
  @Input() labelKey: string;
  @Input() dataForm: UntypedFormGroup;
  @Input() users: IMultiSelectOption[];

  multiSelectSettings: IMultiSelectSettings = {
    enableSearch: true,
    dynamicTitleMaxItems: 0,
    buttonClasses: 'bon-btn-warning',
    showCheckAll: true,
    showUncheckAll: true,
  };
}
