import {Component, OnInit, ViewChild} from '@angular/core';
import {SalesRepVersionService} from '../../bonding_shared/services/sales-rep-version.service';
import {ActivatedRoute, Params} from '@angular/router';
import {RouterService} from '../../bonding_shared/services/router-service';
import {DetailsView} from '../../bonding_shared/components/details-view/details-view';
import {GrowlService} from '../../bonding_shared/services/growl/growl.service';
import {
  BondingContractInquiryVersionSimpleDto,
  BusinessObjectDto,
  BusObjContractVersionRefDto,
  BusObjMasterPolicyRefDto,
  BusObjPolicyInquiryRefDto,
  BusObjRefDto,
  ContractVersionSimpleDto,
  PolicyInquiryVersionSimpleDto,
  SalesRepBaseDto,
  SalesRepRelationVersionDto,
  SalesRepVersionDto,
} from '../../bonding_shared/model';
import {PolicyContractVersionSimpleDto} from '../../bonding_shared/model/dtos';
import {
  BONDING_TYPES,
  BusinessObjectType,
  POLICY_TYPES,
  SalesRepElementaryRight,
  SalesRepRelationStatus,
} from '../../bonding_shared/model/dictionary-ids';
import {SalesRepRelationVersionService} from '../../bonding_shared/services/sales-rep-relation-version.service';
import {NgForm} from '@angular/forms';
import {BusinessObjectUtils} from '../../bonding_shared/utils/business-object-utils';
import {BusinessUtils} from '../../bonding_shared/utils/business-utils';
import {BusinessObjectService} from '../../bonding_shared/services/business-object.service';
import {SalesRepGuiService} from './services/sales-rep-gui.service';
import {AppConfigService} from '../../bonding_shared/services';
import {TextSearchCriteria} from '../../bonding_shared/components/search/model/text-search-criteria';
import {SalesRegionService} from '../../bonding_shared/services/sales-region.service';
import {ConfirmDialogComponent} from '../../bonding_shared';

@Component({
  selector: 'sales-rep-relation-details',
  templateUrl: './sales-rep-relation-details.component.pug',
})
export class SalesRepRelationDetailsComponent extends DetailsView implements OnInit {
  readonly SalesRepElementaryRight = SalesRepElementaryRight;
  readonly BusinessObjectType = BusinessObjectType;
  readonly LAST_ACTIVE: TextSearchCriteria = <TextSearchCriteria>{last: true, active: true};

  version: SalesRepRelationVersionDto;
  versions: SalesRepRelationVersionDto[];
  salesRegionName: String = undefined;
  queryParams: Params = {};

  @ViewChild('form', {static: true}) ngForm: NgForm;
  PolicyInquiryVersionSimpleDto: PolicyInquiryVersionSimpleDto;
  @ViewChild('deleteConfirm', {static: true}) confirmDialog: ConfirmDialogComponent;

  constructor(
    private salesRepVersionService: SalesRepVersionService,
    protected salesRepRelationVersionService: SalesRepRelationVersionService,
    private route: ActivatedRoute,
    private salesRegionService: SalesRegionService,
    public routerService: RouterService,
    private businessObjectService: BusinessObjectService,
    protected growlService: GrowlService,
    private appService: AppConfigService,
    public saleRepRelVerService: SalesRepRelationVersionService
  ) {
    super(growlService);
    this.saveButton.hidden = false;
    this.cancelButton.hidden = false;
    this.initializeSelectors();
    this.version = SalesRepGuiService.emptyRelationVersion();
    this.versions = [];
  }

  ngOnInit() {
    this.form = this.ngForm.form;

    this.route.params.subscribe((params) => {
      if (params['relationVersionId'] === 'new') {
        this.route.queryParams.subscribe((queryParams) => {
          this.queryParams = queryParams;
          if (queryParams['salesRepId']) {
            this.salesRepVersionService
              .getLastByParentId(+queryParams['salesRepId'])
              .subscribe((version) => (this.salesRep = version.salesRep));
          }
          this.businessObject.relatedTo.id = +queryParams['relatedToTypeId'];
          if (queryParams['relatedToId']) {
            this.businessObjectService
              .getDto(+queryParams['relatedToTypeId'], +queryParams['relatedToId'])
              .subscribe((bo) => (this.businessObject = bo));
          }
        });
      } else {
        this.loadVersion(+params['relationVersionId']);
      }
    });
  }

  get salesRep(): SalesRepBaseDto {
    return this.version.salesRepRelation.salesRep;
  }

  set salesRep(value: SalesRepBaseDto) {
    this.version.salesRepRelation.salesRep = value;
  }

  get businessObject(): BusinessObjectDto {
    return this.version.salesRepRelation.businessObject;
  }

  set businessObject(value: BusinessObjectDto) {
    this.version.salesRepRelation.businessObject = value;
  }

  get policy(): BusObjMasterPolicyRefDto {
    if (this.businessObject.relatedTo.id === BusinessObjectType.MASTER_POLICY) {
      return <BusObjMasterPolicyRefDto>this.businessObject.reference;
    } else {
      return undefined;
    }
  }

  set policy(value: BusObjMasterPolicyRefDto) {
    this.businessObject.relatedToId = value.masterPolicyId;
    this.businessObject.relatedTo.id = BusinessObjectType.MASTER_POLICY;
    this.businessObject.reference = value;
  }

  get contract(): BusObjContractVersionRefDto {
    if (this.businessObject.relatedTo.id === BusinessObjectType.CONTRACT) {
      return <BusObjContractVersionRefDto>this.businessObject.reference;
    } else {
      return undefined;
    }
  }

  set contract(value: BusObjContractVersionRefDto) {
    this.businessObject.relatedToId = value.contractId;
    this.businessObject.relatedTo.id = BusinessObjectType.CONTRACT;
    this.businessObject.reference = value;
  }

  get policyInquiry(): BusObjPolicyInquiryRefDto {
    if (this.businessObject.relatedTo.id === BusinessObjectType.POLICY_INQUIRY_VERSION) {
      return <BusObjPolicyInquiryRefDto>this.businessObject.reference;
    } else {
      return undefined;
    }
  }

  toPolicyInquiryDetails() {
    if (this.appService.kuke) {
      if (this.policyInquiry.inquiryType === 'POLICY_ANNEX') {
        this.routerService.toPolicyAnnexInquiryDetails(this.policyInquiry.id);
      } else if (this.policyInquiry.inquiryType === 'RENEWAL_INQUIRY') {
        this.routerService.toPolicyRenewalInquiryDetails(this.policyInquiry.id);
      } else {
        this.routerService.toPolicyInquiryDetails(this.policyInquiry.id);
      }
    } else {
      this.routerService.toPolicyInquiryDetails(this.policyInquiry.id);
    }
  }

  get policyInquiryLabel(): string {
    if (this.appService.kuke) {
      const inquiryType = this.policyInquiry?.inquiryType;
      if (inquiryType === 'POLICY_ANNEX') {
        return 'inquiry.annexName';
      } else if (inquiryType === 'RENEWAL_INQUIRY') {
        return 'inquiry.renewalName';
      }
    }
    return 'salesRep.relationDetails.policyInquiry';
  }

  set policyInquiry(value: BusObjPolicyInquiryRefDto) {
    this.businessObject.relatedToId = value.id;
    this.businessObject.relatedTo.id = BusinessObjectType.POLICY_INQUIRY_VERSION;
    this.businessObject.reference = value;
  }

  get bondingContractInquiry() {
    if (this.businessObject.relatedTo.id === BusinessObjectType.BONDING_CONTRACT_INQUIRY_VERSION) {
      return <BusObjRefDto>this.businessObject.reference;
    } else {
      return undefined;
    }
  }

  set bondingContractInquiry(value: BusObjRefDto) {
    this.businessObject.relatedToId = value.id;
    this.businessObject.relatedTo.id = BusinessObjectType.BONDING_CONTRACT_INQUIRY_VERSION;
    this.businessObject.reference = value;
  }

  initializeSelectors() {
    this.selectorNameList = ['Policy', 'Contract', 'SalesRep', 'PolicyInquiry', 'BondingContractInquiry'];
    this.initializeSelectorEmitters(true);
  }

  loadVersion(versionId: number) {
    this.salesRepRelationVersionService.getById<SalesRepRelationVersionDto>(versionId).subscribe((relation) => {
      this.setRelation(relation);
      this.refreshVersionList();
    });
  }

  setRelation(relationVersion: SalesRepRelationVersionDto) {
    this.showErrors = false;
    this.version = relationVersion;
    this.handleButtons();
    if (relationVersion.id) {
      this.refreshVersionList();
    }
  }

  onSave() {
    this.serverErrors = undefined;
    if (!this.formValidates()) {
      return;
    }
    this.inProgress = true;
    this.salesRepRelationVersionService.save(this.version).subscribe({
      next: (saved) => {
        this.afterObjectSaved(this.version.id, () => this.routerService.toSalesRepRelationDetails(saved.id));
        this.setRelation(saved);
      },
      error: (error) => this.handleServerError(error),
    });
  }

  onCancel(): void {
    this.clearErrors();
    this.version = SalesRepGuiService.emptyRelationVersion();
    this.ngOnInit();
  }

  onCreateNewVersion() {
    this.clearErrors();
    this.newVersion();
  }

  newVersion() {
    this.salesRepRelationVersionService
      .newVersion<SalesRepRelationVersionDto>(this.version.salesRepRelation.id)
      .subscribe((relation) => this.setRelation(relation));
  }

  initialDataFilled() {
    return this.salesRep.id && this.businessObject.relatedTo.id && this.businessObject.relatedToId;
  }

  initialVersion() {
    if (this.initialDataFilled()) {
      this.salesRepRelationVersionService
        .initialVersion(this.salesRep.id, this.businessObject.relatedTo.id, this.businessObject.relatedToId)
        .subscribe((bv) => this.setRelation(bv));
    }
  }

  onSelectPolicy(pcv: PolicyContractVersionSimpleDto) {
    this.policy = BusinessObjectUtils.policyVersionToMasterPolicyRef(pcv);
    this.initialVersion();
  }

  onSelectContract(cv: ContractVersionSimpleDto) {
    this.contract = BusinessObjectUtils.contractVersionToRef(cv);
    this.initialVersion();
  }

  onSelectPolicyInquiry(cv: PolicyInquiryVersionSimpleDto) {
    this.policyInquiry = BusinessObjectUtils.policyInquiryVersionToRef(cv);
    this.initialVersion();
  }

  onSelectBondingContractInquiry(bciv: BondingContractInquiryVersionSimpleDto) {
    this.bondingContractInquiry = BusinessObjectUtils.bondingContractInquiryVersionToRef(bciv);
    this.initialVersion();
  }

  onSelectSalesRep(salesRepVersion: SalesRepVersionDto) {
    this.salesRep = salesRepVersion.salesRep;
    this.initialVersion();
  }

  onSelectVersion(linkVersion: SalesRepRelationVersionDto) {
    this.serverErrors = undefined;
    this.setRelation(linkVersion);
  }

  refreshVersionList() {
    const index = this.versions.findIndex((version) => version.id === this.version.id);
    if (index >= 0) {
      this.versions[index] = this.version;
      this.handleButtons();
    } else {
      this.loadVersionList();
    }
  }

  loadVersionList() {
    const dataProvider = BusinessUtils.createDataProvider<SalesRepRelationVersionDto, SalesRepRelationVersionDto>(
      this.salesRepRelationVersionService
    );
    dataProvider.searchCriteria.criteria = SalesRepGuiService.emptyRelationVersion();
    dataProvider.searchCriteria.criteria.salesRepRelation.id = this.version.salesRepRelation.id;
    dataProvider.search().subscribe((list) => {
      this.versions = list.result;
      this.handleButtons();
    });
  }

  handleButtons() {
    this.saveButton.disabled = this.version.id && this.version.activated;
    this.newVersionButton.disabled = !this.version.last;
    this.newVersionButton.hidden = !this.version.id || !this.version.activated;
    this.deleteButton.hidden = !this.canDeleteRelation(this.versions);
  }

  canDeleteRelation(versions: SalesRepRelationVersionDto[]): boolean {
    if (
      this.versions.length === 1 &&
      versions[this.versions.length - 1].status.id === SalesRepRelationStatus.INACTIVE
    ) {
      return true;
    }
    return false;
  }

  checkUserSalesRegionName(): boolean {
    if (this.salesRegionName === undefined) {
      this.salesRegionService.getUserRegion(this.version.salesRepRelation.salesRep.user.id).subscribe((result) => {
        this.salesRegionName = result;
      });
    }
    return this.salesRegionName !== undefined;
  }

  onDelete() {
    const confirmationPromise: Promise<boolean> = this.confirmDialog.open(
      'salesRep.relationDetails.removeConfirmationTitle',
      'salesRep.relationDetails.removeConfirmationMessage'
    );
    confirmationPromise.then((result) => {
      this.deleteRelation(result);
    });
  }

  deleteRelation(shouldDelete: boolean) {
    if (shouldDelete) {
      this.saleRepRelVerService.deleteRelation(this.version.id).subscribe({
        next: (res) => this.afterDeleteRelation(this.version.salesRepRelation.salesRep.id),
        error: (error) => (this.serverErrors = error),
      });
    }
  }

  afterDeleteRelation(salesRepId: number) {
    this.routerService.toSalesRepPreview(salesRepId);
  }

  createUpdateRightFunc(relationVersion: SalesRepRelationVersionDto): string {
    const relatedToId = relationVersion?.salesRepRelation?.businessObject?.relatedTo?.id;
    if (!relatedToId) {
      return undefined;
    }
    if (POLICY_TYPES.includes(relatedToId)) {
      return SalesRepElementaryRight.SALES_REP_RELATION_CREATE_UPDATE_POLICIES;
    }
    if (BONDING_TYPES.includes(relatedToId)) {
      return SalesRepElementaryRight.SALES_REP_RELATION_CREATE_UPDATE_BONDING;
    }
  }
}
