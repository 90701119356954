import {AfterViewInit, Component, ElementRef, Input, Renderer2, ViewChild} from '@angular/core';
import {TabView} from 'primeng/tabview';
import {ChangePasswordComponent} from '../../../bonding_shared/components/user/change-password.component';

@Component({
  selector: 'offer-tab-layout',
  templateUrl: './offer-tab-layout.component.pug',
})
export class OfferTabLayoutComponent implements AfterViewInit {
  @ViewChild(TabView, {static: true}) private tabView: TabView;
  @Input() showClausesTab = true;

  constructor(private renderer: Renderer2) {}

  ngAfterViewInit() {
    this.renderer.removeClass(this.tabView.el.nativeElement.firstChild, 'ui-widget');
  }
}
