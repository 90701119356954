import {TreatyDetailsComponent} from './treaty-details.component';
import {TreatySearchComponent} from './treaty-search.component';
import {TreatySearchGuard} from './_guards';

export const TreatyRoutes = [
  {
    path: 'treaty-details/:treatyId',
    component: TreatyDetailsComponent,
  },
  {
    path: 'treaty-version-details/:id',
    component: TreatyDetailsComponent,
  },
  {
    path: 'treaty-search',
    component: TreatySearchComponent,
    canActivate: [TreatySearchGuard],
  },
];
