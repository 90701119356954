import {ContactNoteDetailsComponent} from './contact-note-details.component';
import {ContactNoteDetailsGuard} from './_guards/contact-note-details.guard';

export const ContactNoteRoutes = [
  {
    path: 'contact-note-details/:noteId',
    component: ContactNoteDetailsComponent,
    canActivate: [ContactNoteDetailsGuard],
  },
];
