<search-view [objectName]="'User'" [customTitle]="labelKeyCustomTitle" newObjectLabelKey="user.search.newUser"
             [dataProvider]="dataProvider" [searchModeSwitchable]="false"
             [newButton]="appService.mehib || appService.ecg || (appService.credendo && !employeeSearchView)
                || (appService.kuke && (loggedUserService.hasLoggedUserRole(KUKE_SUPERADMIN) || loggedUserService.hasRight(EXTERNAL_USER_ACCESS)))">
  <div class="search-criteria bon-card-group">
    <div class="bon-card-inner">
      <input-row *ngIf="!employeeSearchView" labelKey="user.search.criteria.companyName"
                 [(ngModel)]="dataProvider.searchCriteria.criteria.company.registrationName"
                 name="companyName"></input-row>
      <dict-row *ngIf="!employeeSearchView" labelKey="user.search.criteria.type"
                [(ngModel)]="dataProvider.searchCriteria.criteria.userType"
                [ngModelOptions]="{standalone: true}" (changeItem)="userTypeChanged($event)"
                [disabled]="userTypeLimitation" dictionary="UserType" name="userType"
                nullLabelKey="user.search.all.type"></dict-row>
      <form-row labelKey="user.search.criteria.businessUnit"
                *ngIf="appService.credendo || appService.mehib || (appService.kuke && dataProvider.searchCriteria.criteria.userType?.id === UserType.INTRANET)">
        <business-unit-id-selector class="bon-input-size"
                                   [(ngModel)]="dataProvider.searchCriteria.criteria.businessUnit"
                                   name="businessUnit" (changeItem)="buChanged()" [ngModelOptions]="{standalone: true}"
                                   nullLabelKey="user.search.all.businessUnit">
        </business-unit-id-selector>
      </form-row>
      <checkbox-row labelKey="user.search.criteria.extranetClient"
                    *ngIf="appService.kuke && dataProvider.searchCriteria.criteria.userType?.id === UserType.EXTRANET"
                    [(ngModel)]="dataProvider.searchCriteria.criteria.extranetClient"
                    [ngModelOptions]="{standalone: true}"></checkbox-row>
      <dict-row labelKey="user.search.criteria.profile" [(ngModel)]="dataProvider.searchCriteria.criteria.role"
                [ngModelOptions]="{standalone: true}"
                dictionary="UserRole" [hiddenIds]="hiddenUserRoles" name="userRole"
                nullLabelKey="user.search.all.role"></dict-row>
      <form-row labelKey="user.search.criteria.group">
        <item-combo class="bon-input-size" [items]="userGroups"
                    [(ngModel)]="dataProvider.searchCriteria.criteria.userGroup"
                    nullLabelKey="user.search.all.group">
          <ng-template let-item="item">{{item.name}}</ng-template>
        </item-combo>
      </form-row>
      <dict-row *ngIf="employeeSearchView" labelKey="user.search.criteria.businessLine"
                [(ngModel)]="dataProvider.searchCriteria.criteria.employee.businessLine"
                dictionary="BusinessLine" name="businessLine" nullLabelKey="user.search.all.businessLine"></dict-row>
    </div>
    <div class="bon-card-inner">
      <input-row labelKey="user.search.criteria.login" [(ngModel)]="dataProvider.searchCriteria.criteria.login"
                 name="login"></input-row>
      <input-row labelKey="user.search.criteria.firstName" [(ngModel)]="dataProvider.searchCriteria.criteria.name"
                 name="name"></input-row>
      <input-row labelKey="user.search.criteria.lastName" [(ngModel)]="dataProvider.searchCriteria.criteria.familyName"
                 name="familyName"></input-row>
      <boolean-combo-row *ngIf="!employeeSearchView && appService.credendo" labelKey="user.data.employee"
                         [(ngModel)]="dataProvider.searchCriteria.criteria.checkIsEmployee"
                         name="isEmployee"></boolean-combo-row>
    </div>

    <div class="bon-card-inner">
      <input-row *ngIf="employeeSearchView" labelKey="user.search.criteria.position"
                 [(ngModel)]="dataProvider.searchCriteria.criteria.employee.position"
                 name="position"></input-row>
      <form-row labelKey="user.search.criteria.statusFilter.title">
        <string-combo class="bon-input-size"
                      [(ngModel)]="dataProvider.searchCriteria.criteria.userStatusFilter"
                      [items]="statusFilters"></string-combo>
      </form-row>
    </div>
  </div>

  <div class="search-results">
    <a-table [pagination]="true" [pageSize]="20" [selection]="false" [dataProvider]="dataProvider"
             [sortable]='!dataProvider.textSearch'>
      <column labelKey="user.search.result.login" property="login" sort="login" [link]="true"
              (linkClick)="router.toUserDetails($event.id)" initialSort="UP"></column>
      <column labelKey="user.search.result.firstName" property="name" sort="name"></column>
      <column labelKey="user.search.result.lastName" property="familyName" sort="familyName"></column>
      <column *ngIf="!employeeSearchView" labelKey="user.search.result.registrationDate" property="registrationDate"
              type="date"
              sort="registrationDate"></column>
      <column *ngIf="!employeeSearchView" labelKey="user.search.result.companyName" title="Company name"
              property="company.registrationName"
              sort="company.registrationName" [link]="true"
              (linkClick)="router.toCompanyPreview($event.company.id)"></column>
      <column *ngIf="employeeSearchView" labelKey="user.search.criteria.statusFilter.title" property="status"
              sort="status" dictionary="UserStatus"></column>
      <column *ngIf="employeeSearchView" labelKey="user.search.criteria.businessUnit" property="businessUnit.name"
              sort="businessUnit.name"></column>
      <column *ngIf="employeeSearchView" labelKey="user.search.criteria.position" property="employee.position"
              sort="employee.position"></column>
    </a-table>
  </div>

  <div class="additional-buttons" *ngIf="appService.kuke">
    <button type="button" class="bon-btn-info" (click)="updateAllFromAd()">
      {{ 'user.search.updateFromAD' | translate }}
    </button>
  </div>
  <div class="additional-buttons" *ngIf="appService.credendo && !employeeSearchView">
    <div class="btn-group" dropdown>
      <button type="button" class="bon-btn-info dropdown-toggle" dropdownToggle>
        New broker user
        <span class="caret">
        </span>
      </button>
      <ul class="dropdown-menu" role="menu">
        <li role="menuitem" *ngFor="let bu of businessUnitBrokersConfiguration">
          <a class="dropdown-item" (click)="newBrokerUser(bu.businessUnitCode)">{{ bu.businessUnit | translate }}</a>
        </li>
      </ul>
    </div>
    <div class="btn-group" dropdown>
      <button type="button" class="bon-btn-info dropdown-toggle" dropdownToggle>
        New client user
        <span class="caret">

        </span>
      </button>
      <ul class="dropdown-menu" role="menu">
        <li role="menuitem" *ngFor="let bu of businessUnitClientsConfiguration">
          <a class="dropdown-item" (click)="newClientUser(bu.businessUnitCode)">{{ bu.businessUnit | translate }}</a>
        </li>
      </ul>
    </div>
  </div>

</search-view>
