import {AfterViewInit, Component, ViewChild} from '@angular/core';
import {
  AppConfigService,
  DictionaryBaseService,
  LoggedUserService,
  RouterService,
  SearchDataProvider,
} from '../../../../bonding_shared/services';
import {
  ATableComponent,
  DictionaryBaseDto,
  PolicyClauseTypeCriteriaDto,
  PolicyClauseTypeSimpleDto,
} from '../../../../bonding_shared';
import {PolicyClauseTypeService} from '../../../../bonding_shared/services/policy-clause-type.service';
import {BusinessUtils} from '../../../../bonding_shared/utils/business-utils';
import {GlobalConditionsOfInsurance} from '../../../../bonding_shared/model/dictionary-ids';
import {PolicyClauseTypeGuiService} from '../services/policyclausetype-gui.service';

@Component({
  selector: 'policyclausetype-search',
  templateUrl: './policyclausetype-search.component.pug',
})
export class PolicyClauseTypeSearchComponent implements AfterViewInit {
  @ViewChild(ATableComponent, {static: true}) table: ATableComponent<PolicyClauseTypeSimpleDto>;
  fields: DictionaryBaseDto[];
  gcis: DictionaryBaseDto[];

  private allowedGcis = [
    GlobalConditionsOfInsurance.KUKE_PNW_2013,
    GlobalConditionsOfInsurance.KUKE_OP_2016_01,
    GlobalConditionsOfInsurance.KUKE_OP_2018_12,
    GlobalConditionsOfInsurance.KUKE_SP_2017_06,
    GlobalConditionsOfInsurance.KUKE_PBG_99_IN_P_2020,
    GlobalConditionsOfInsurance.KUKE_FK_2016_11,
    GlobalConditionsOfInsurance.KUKE_FE_2016_11,
  ];

  constructor(
    private dictionaryBaseService: DictionaryBaseService,
    public policyClauseTypeService: PolicyClauseTypeGuiService,
    public appService: AppConfigService,
    private loggedUserService: LoggedUserService,
    public router: RouterService
  ) {
    dictionaryBaseService
      .getDictionaryBase('GlobalConditionsOfInsurance')
      .subscribe((list) => (this.gcis = list.filter((g) => this.allowedGcis.includes(g.id))));
  }

  ngAfterViewInit() {
    if (!this.kuke || this.portal) {
      this.table.search();
    }
  }

  get criteria() {
    return this.policyClauseTypeService.dataProvider.searchCriteria.criteria;
  }

  getGCIs(item: PolicyClauseTypeSimpleDto): string {
    let s = '';
    item.gcis.forEach((g) => (s += g.name + ', '));
    return s.substring(0, s.length - 2);
  }

  get kuke(): boolean {
    return this.appService.kuke;
  }

  get portal(): boolean {
    return this.loggedUserService.portal;
  }
}
