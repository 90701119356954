import {UserDetailsComponent} from './user-details.component';
import {UserSearchComponent} from './user-search.component';
import {UserCreationComponent} from './comands/user-creation.component';
import {UserAccessGuard} from './components/user-access-guard';
import {UserSearchGuard} from './components/user-search-guard';
import {AdUserDetailsComponent} from './ad-user-details.component';

export const UserRoutes = [
  {
    path: 'user-search',
    component: UserSearchComponent,
    canActivate: [UserSearchGuard],
  },
  {
    path: 'user-search/:employee',
    component: UserSearchComponent,
    canActivate: [UserSearchGuard],
  },
  {
    path: 'user-details/:id',
    component: UserDetailsComponent,
    canActivate: [UserAccessGuard],
  },
  {
    path: 'ad-user-details/:id',
    component: AdUserDetailsComponent,
    canActivate: [UserAccessGuard],
  },
  {
    path: 'user-creation/:type/:businessUnit',
    component: UserCreationComponent,
  },
];
