<!--suppress HtmlUnknownAttribute, TypeScriptUnresolvedVariable, AngularInvalidExpressionResultType -->
<details-view [title]="'Kalkulator'" objectName='Inquiry' [parentComponent]="self"
              [versionListHidden]="true" [fixedBlockHidden]="false">
  <div class="details-content">
    <ng-container *ngIf="!loaded; then thenBlock else elseBlock">
    </ng-container>
    <ng-template  #elseBlock>
      <div class="bon-label-190">
        <form role="form" style="padding-top: 10px;" class="inputs-disabled">
          <bon-section labelKey="inquiry.calculator.inquirySection.title" *ngIf="inquiry">
            <div class="bon-card-group">
              <div class="bon-card-inner">
                <dict-row labelKey="inquiry.status" [ngModel]="inquiry.status" dictionary = 'PolicyInquiryStatus' [disabled]='true' name='status'></dict-row>
              </div>
              <div class="bon-card-inner">
                <input-row labelKey="inquiry.inquiryNumber" [ngModel]="inquiry.policyInquiry?.number" name="number" [disabled]='true'></input-row>
                <date-row labelKey="inquiry.creationDate" [ngModel]="inquiry.creationDate" name="creationDate" [disabled]='true'></date-row>
              </div>
              <div class="bon-card-inner">
                <div class="bon-row">
                  <label class="bon-label">{{'inquiry.salesperson' | translate}}</label>
                  <span>{{inquiry.salesPerson | username}}</span>
                </div>
                <input-row label="E-mail" [ngModel]="inquiry?.salesPerson?.email" name="adviserEmail" [readonly]='true'></input-row>
              </div>
            </div>
          </bon-section>
          <bon-section labelKey="policyInquiry.details.potentialClient" *ngIf="inquiry">
            <div class="bon-card-group">
              <div class="bon-card-inner">
                <company-info #companyInfo [companyId]="inquiry.client.company.id" [showLink]="true" [showNaceCodes]="true" [showSegment]="true"></company-info>
              </div>
            </div>
          </bon-section>
          <bon-section labelKey="inquiry.calculator.tariffRate">
            <div class="bon-card-group">
              <div class="bon-card-inner">
                <percent-row labelKey="inquiry.calculator.tariffRate" name="tariffRate" [formControl]="$any(form.controls.tariffRate)"
                             [showErrors]="showErrors" [numberOfDecimalPlaces]="3"></percent-row>
              </div>
              <div class="bon-card-inner">
                <date-row labelKey="inquiry.calculator.creationDate" name="creationDate"
                          [formControl]="$any(form.controls.creationDate)" [showErrors]="showErrors">
                </date-row>

              </div>
            </div>
          </bon-section>
          <bon-section labelKey="inquiry.calculator.dataSection.title">
            <div class="bon-card-group">
              <div class="bon-card-inner">
                <percent-row labelKey="inquiry.calculator.exportRate" name="exportRate" [formControl]="$any(form.controls.exportRate)"
                             [showErrors]="showErrors" [numberOfDecimalPlaces]="4"></percent-row>
                <input-row labelKey="inquiry.calculator.riskApetite" name="riskApetite" [formControl]="$any(form.controls.riskApetite)"
                           [showErrors]="showErrors"></input-row>
                <percent-row labelKey="inquiry.calculator.currentInsurersRate" name="currentInsurersRate" [formControl]="$any(form.controls.currentInsurersRate)"
                             [showErrors]="showErrors" numberOfDecimalPlaces="3"></percent-row>
                <num-row labelKey="inquiry.calculator.declaredTurnover" name="declaredTurnover" [formControl]="$any(form.controls.declaredTurnover)"
                         [showErrors]="showErrors"></num-row>
              </div>
              <div class="bon-card-inner">
                <percent-row labelKey="inquiry.calculator.domesticRate" name="domesticRate" [formControl]="form.controls.domesticRate"
                             [showErrors]="showErrors" [numberOfDecimalPlaces]="4"></percent-row>
                <num-row labelKey="inquiry.calculator.riskApetiteFactor" name="riskApetiteFactor" [formControl]="form.controls.riskApetiteFactor"
                         [showErrors]="showErrors" [minValue]="0" [maxValue]="1"></num-row>
                <num-row labelKey="inquiry.calculator.tofactor" name="tofactor" [formControl]="form.controls.tofactor"
                         [showErrors]="showErrors"></num-row>
              </div>
              <div class="bon-card-inner">
                <num-row labelKey="inquiry.calculator.sectorFactor" name="sectorFactor" [formControl]="form.controls.sectorFactor"
                         [showErrors]="showErrors" numberOfDecimalPlaces="3"></num-row>
                <dict-row labelKey="offer.details.edgeOfferDetailsSection.naceCode" [formControl]="form.controls.naceCode"
                          dictionary = 'NaceCode' name='naceCode'></dict-row>
              </div>
            </div>
            <div class="bon-card-group">
              <table class="table">
                <thead class="table-header">
                <tr class="table-header">
                  <th><div>{{'inquiry.calculator.tableYearEx.policyPeriodWithCurrentInsurer' | translate}}</div></th>
                  <th><div>{{'inquiry.calculator.tableYearEx.premium' | translate}}</div></th>
                  <th><div>{{'inquiry.calculator.tableYearEx.indemnifications' | translate}}</div></th>
                  <th><div>{{'inquiry.calculator.tableYearEx.recoveries' | translate}}</div></th>
                  <th><div>{{'inquiry.calculator.tableYearEx.claims' | translate}}</div></th>
                  <th><div>{{'inquiry.calculator.tableYearEx.claimsRatio' | translate}}</div></th>
                </tr>
                </thead>
                <tbody>
                <tr class="hover-table-row">
                  <td class="bon-table-cell"><div>{{'inquiry.calculator.tableYearEx.last3YearInCompetition' | translate}}</div></td>
                  <td class="bon-table-cell"><num-input type="decimal" [formControl]="form.controls.tableYearEx.get('last3YearInCompetition.premium')" ></num-input></td>
                  <td class="bon-table-cell"><num-input type="decimal" [formControl]="form.controls.tableYearEx.get('last3YearInCompetition.indemnifications')"></num-input></td>
                  <td class="bon-table-cell"><num-input type="decimal" [formControl]="form.controls.tableYearEx.get('last3YearInCompetition.recoveries')" ></num-input></td>
                  <td class="bon-table-cell"><num-input type="decimal" [formControl]="form.controls.tableYearEx.get('last3YearInCompetition.claims')" ></num-input></td>
                  <td class="bon-table-cell"><num-input type="decimal" [formControl]="form.controls.tableYearEx.get('last3YearInCompetition.claimsRatio')"
                                                        numberOfDecimalPlaces="1" baseMultiplier="0.01"></num-input></td>
                </tr>
                <tr class="hover-table-row">
                  <td class="bon-table-cell"><div>{{'inquiry.calculator.tableYearEx.last2YearInCompetition' | translate}}</div></td>
                  <td class="bon-table-cell"><num-input type="decimal" [formControl]="form.controls.tableYearEx.get('last2YearInCompetition.premium')" ></num-input></td>
                  <td class="bon-table-cell"><num-input type="decimal" [formControl]="form.controls.tableYearEx.get('last2YearInCompetition.indemnifications')" ></num-input></td>
                  <td class="bon-table-cell"><num-input type="decimal" [formControl]="form.controls.tableYearEx.get('last2YearInCompetition.recoveries')" ></num-input></td>
                  <td class="bon-table-cell"><num-input type="decimal" [formControl]="form.controls.tableYearEx.get('last2YearInCompetition.claims')" ></num-input></td>
                  <td class="bon-table-cell"><num-input type="decimal" [formControl]="form.controls.tableYearEx.get('last2YearInCompetition.claimsRatio')"
                                                        numberOfDecimalPlaces="1" baseMultiplier="0.01"></num-input></td>
                </tr>
                <tr class="hover-table-row">
                  <td class="bon-table-cell"><div>{{'inquiry.calculator.tableYearEx.lastYearInCompetition' | translate}}</div></td>
                  <td class="bon-table-cell"><num-input type="decimal" [formControl]="form.controls.tableYearEx.get('lastYearInCompetition.premium')" ></num-input></td>
                  <td class="bon-table-cell"><num-input type="decimal" [formControl]="form.controls.tableYearEx.get('lastYearInCompetition.indemnifications')"></num-input></td>
                  <td class="bon-table-cell"><num-input type="decimal" [formControl]="form.controls.tableYearEx.get('lastYearInCompetition.recoveries')" ></num-input></td>
                  <td class="bon-table-cell"><num-input type="decimal" [formControl]="form.controls.tableYearEx.get('lastYearInCompetition.claims')" ></num-input></td>
                  <td class="bon-table-cell"><num-input type="decimal" [formControl]="form.controls.tableYearEx.get('lastYearInCompetition.claimsRatio')"
                                                        numberOfDecimalPlaces="1" baseMultiplier="0.01"></num-input></td>
                </tr>
                </tbody>
              </table>
            </div>
            <div class="bon-card-group">
              <table class="table">
                <thead class="table-header">
                <tr class="table-header">
                  <th><div>{{'inquiry.calculator.tableYearNoIns.notInsuredPeriod' | translate}}</div></th>
                  <th><div>{{'inquiry.calculator.tableYearNoIns.premium' | translate}}</div></th>
                  <th><div>{{'inquiry.calculator.tableYearNoIns.lostReceivables' | translate}}</div></th>
                  <th><div>{{'inquiry.calculator.tableYearNoIns.claimsRatio' | translate}}</div></th>
                </tr>
                </thead>
                <tbody>
                <tr class="hover-table-row">
                  <td class="bon-table-cell"><div>{{'inquiry.calculator.tableYearNoIns.last3YearInCompetition' | translate}}</div></td>
                  <td class="bon-table-cell"><num-input type="decimal" [formControl]="form.controls.tableYearNoIns.get('last3YearInCompetition.premium')" ></num-input></td>
                  <td class="bon-table-cell"><num-input type="decimal" [formControl]="form.controls.tableYearNoIns.get('last3YearInCompetition.lostReceivables')" ></num-input></td>
                  <td class="bon-table-cell"><num-input type="decimal" [formControl]="form.controls.tableYearNoIns.get('last3YearInCompetition.claimsRatio')"
                                                        numberOfDecimalPlaces="1" baseMultiplier="0.01"></num-input></td>
                </tr>
                <tr class="hover-table-row">
                  <td class="bon-table-cell"><div>{{'inquiry.calculator.tableYearNoIns.last2YearInCompetition' | translate}}</div></td>
                  <td class="bon-table-cell"><num-input type="decimal" [formControl]="form.controls.tableYearNoIns.get('last2YearInCompetition.premium')" ></num-input></td>
                  <td class="bon-table-cell"><num-input type="decimal" [formControl]="form.controls.tableYearNoIns.get('last2YearInCompetition.lostReceivables')"></num-input></td>
                  <td class="bon-table-cell"><num-input type="decimal" [formControl]="form.controls.tableYearNoIns.get('last2YearInCompetition.claimsRatio')"
                                                        numberOfDecimalPlaces="1" baseMultiplier="0.01"></num-input></td>
                </tr>
                <tr class="hover-table-row">
                  <td class="bon-table-cell"><div>{{'inquiry.calculator.tableYearNoIns.lastYearInCompetition' | translate}}</div></td>
                  <td class="bon-table-cell"><num-input type="decimal" [formControl]="form.controls.tableYearNoIns.get('last2YearInCompetition.premium')" ></num-input></td>
                  <td class="bon-table-cell"><num-input type="decimal" [formControl]="form.controls.tableYearNoIns.get('last3YearInCompetition.lostReceivables')"></num-input></td>
                  <td class="bon-table-cell"><num-input type="decimal" [formControl]="form.controls.tableYearNoIns.get('lastYearInCompetition.claimsRatio')"
                                                        numberOfDecimalPlaces="1" baseMultiplier="0.01"></num-input></td>
                </tr>
                </tbody>
              </table>
            </div>
            <div class="bon-card-group" *ngIf="form.controls.tableYearKuke">
              <table class="table">
                <thead class="table-header">
                <tr class="table-header">
                  <th><div>{{'inquiry.calculator.tableYearKuke.period' | translate}}</div></th>
                  <th><div>{{'inquiry.calculator.tableYearKuke.premium' | translate}}</div></th>
                  <th><div>{{'inquiry.calculator.tableYearKuke.indemnifications' | translate}}</div></th>
                  <th><div>{{'inquiry.calculator.tableYearKuke.recoveries' | translate}}</div></th>
                  <th><div>{{'inquiry.calculator.tableYearKuke.openClaims' | translate}}</div></th>
                  <th><div>{{'inquiry.calculator.tableYearKuke.IBNRClaim' | translate}}</div></th>
                  <th><div>{{'inquiry.calculator.tableYearKuke.claimsRatio' | translate}}</div></th>
                </tr>
                </thead>
                <tbody>
                <tr class="hover-table-row"  *ngFor="let y of form.controls.tableYearKuke.controls; let i = index">
                  <td class="bon-table-cell"><div>{{i + 1}} rok</div></td>
                  <td class="bon-table-cell"><num-input type="decimal" [formControl]="y.get('premium')" ></num-input></td>
                  <td class="bon-table-cell"><num-input type="decimal" [formControl]="y.get('indemnifications')" ></num-input></td>
                  <td class="bon-table-cell"><num-input type="decimal" [formControl]="y.get('recoveries')" ></num-input></td>
                  <td class="bon-table-cell"><num-input type="decimal" [formControl]="y.get('openClaims')" ></num-input></td>
                  <td class="bon-table-cell"><num-input type="decimal" [formControl]="y.get('ibnrclaim')" ></num-input></td>
                  <td class="bon-table-cell"><num-input type="decimal" [formControl]="y.get('claimsRatio')" ></num-input></td>
                </tr>
                <tr class="hover-table-row" *ngIf="form.controls.tableYearSumKuke && form.controls.tableYearSumKuke.controls[0]" [formGroup]="form.controls.tableYearSumKuke.controls[0]">
                  <td class="bon-table-cell"><div>ŁĄCZNIE</div></td>
                  <td class="bon-table-cell"><num-input type="decimal" formControlName="premium" ></num-input></td>
                  <td class="bon-table-cell"><num-input type="decimal" formControlName="indemnifications" ></num-input></td>
                  <td class="bon-table-cell"><num-input type="decimal" formControlName="recoveries" ></num-input></td>
                  <td class="bon-table-cell"><num-input type="decimal" formControlName="openClaims" ></num-input></td>
                  <td class="bon-table-cell"><num-input type="decimal" formControlName="ibnrclaim" ></num-input></td>
                  <td class="bon-table-cell"><num-input type="decimal" formControlName="claimsRatio" ></num-input></td>
                </tr>
                </tbody>
              </table>
            </div>
            <div class="bon-card-group">
              <div class="bon-card-inner">
                <num-row labelKey="inquiry.calculator.numberOfYears" name="numberOfYears" [formControl]="form.controls.numberOfYears"
                         [showErrors]="showErrors"></num-row>
              </div>
              <div class="bon-card-inner">
                <num-row labelKey="inquiry.calculator.adjustedClaimsRatio" name="adjustedClaimsRatio" [formControl]="form.controls.adjustedClaimsRatio"
                         [showErrors]="showErrors" type="decimal" numberOfDecimalPlaces="3"></num-row>
              </div>
              <div class="bon-card-inner">
                <num-row labelKey="inquiry.calculator.claimsFactor" name="claimsFactor" [formControl]="form.controls.claimsFactor"
                         [showErrors]="showErrors" type="decimal" numberOfDecimalPlaces="3"></num-row>
              </div>
            </div>
          </bon-section>
          <bon-section labelKey="inquiry.calculator.balanceSection.averageOutstandingsBalance4quarters" *ngIf="isBalanceVisible()">
            <div class="bon-card-group">
              <table class="table">
                <tbody>
                <tr class="hover-table-row">
                  <td class="bon-table-cell">
                    <num-input type="integer"
                               [formControl]="form.controls.balance.controls.averageOutstandingsBalance1quarters"
                    ></num-input>
                    <error-message [control]="form.controls.balance.controls.averageOutstandingsBalance1quarters"
                                   [show]="showErrors"></error-message>
                  </td>
                  <td class="bon-table-cell">
                    <num-input type="integer" [formControl]="form.controls.balance.controls.averageOutstandingsBalance2quarters"
                    ></num-input>
                    <error-message [control]="form.controls.balance.controls.averageOutstandingsBalance2quarters"
                                   [show]="showErrors"></error-message>
                  </td>
                  <td class="bon-table-cell">
                    <num-input type="integer" [formControl]="form.controls.balance.controls.averageOutstandingsBalance3quarters"
                    ></num-input>
                    <error-message [control]="form.controls.balance.controls.averageOutstandingsBalance3quarters"
                                   [show]="showErrors"></error-message>
                  </td>
                  <td class="bon-table-cell">
                    <num-input type="integer" [formControl]="form.controls.balance.controls.averageOutstandingsBalance4quarters"
                    ></num-input>
                    <error-message [control]="form.controls.balance.controls.averageOutstandingsBalance4quarters"
                                   [show]="showErrors"></error-message>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </bon-section>
          <bon-section labelKey="inquiry.calculator.balanceSection.outstandingsBalance" *ngIf="isBalanceVisible()">
            <div class="bon-card-group">
              <div class="bon-card-inner bon-label-300">
                <num-row labelKey="inquiry.calculator.balanceSection.turnoverForLast4Quarters"
                         name="turnoverForLast4Quarters"
                         [showErrors]="showErrors"
                         [formControl]="form.controls.balance.controls.turnoverForLast4Quarters"
                ></num-row>
                <!-- [formControl]="form.controls.balance.controls.turnoverForLast4Quarters" -->
                <num-row labelKey="inquiry.calculator.balanceSection.averageOutstandingsBalance"
                         name="averageOutstandingsBalance"
                         [formControl]="form.controls.balance.controls.averageOutstandingsBalance"
                         [control]="form.controls.balance.controls.averageOutstandingsBalance"
                         [showErrors]="showErrors"></num-row>
              </div>
              <div class="bon-card-inner bon-label-300">
                <num-row labelKey="inquiry.calculator.balanceSection.premiumCalculationBase"
                         name="premiumCalculationBase"
                         [formControl]="form.controls.balance.controls.premiumCalculationBase"
                         [showErrors]="showErrors"></num-row>
                <!--<percent-row labelKey="inquiry.calculator.balanceSection.tariffRateBalance" name="tariffRateBalance"
                         [formControl]="form.controls.balance.controls.tariffRateBalance"
                         [showErrors]="showErrors"></percent-row>-->
                <percent-row labelKey="inquiry.calculator.balanceSection.tariffRateBalance" name="tariffRateBalance2"
                         [formControl]="form.controls.balance.controls.tariffRateBalance2"
                         [showErrors]="showErrors"></percent-row>
              </div>
              <div class="bon-card-inner bon-label-300">
                <num-row labelKey="inquiry.calculator.premiumsSection.expectedPremium" name="balanceExpectedPremium"
                         [formControl]="form.controls.balance.controls.balanceExpectedPremium"
                         [showErrors]="showErrors"></num-row>
                <num-row labelKey="inquiry.calculator.premiumsSection.minPremium" name="balanceMinPremium"
                         [formControl]="form.controls.balance.controls.balanceMinPremium"
                         [showErrors]="showErrors"></num-row>

              </div>
            </div>
          </bon-section>
          <bon-section>
            <div class="bon-card-group">
              <table class="table">
                <thead class="table-header">
                <tr class="table-header">
                  <th><div>{{'inquiry.calculator.turnoverPerCountryGroups' | translate}}</div></th>
                  <th><div>w PLN</div></th>
                  <th><div>w PLN %</div></th>
                </tr>
                </thead>
                <tbody>
                <tr class="hover-table-row" *ngFor="let i of table4">
                  <td class="bon-table-cell"><div>{{i}}</div></td>
                  <td class="bon-table-cell"><num-input type="integer"
                                                        [formControl]="form.controls.turnoverByGroupOfCountries.controls[i].controls.amount">
                  </num-input></td>
                  <td class="bon-table-cell"><num-input type="decimal"
                                                        [formControl]="form.controls.turnoverByGroupOfCountries.controls[i].controls.rate">
                  </num-input></td>
                </tr>
                </tbody>
              </table>
            </div>
            <div class="bon-card-group">
              <div class="bon-card-inner">
                <num-row labelKey="inquiry.calculator.aBCDCountriesFactor" name="aBCDCountriesFactor" [formControl]="form.controls.abcdcountriesFactor"
                         [showErrors]="showErrors" numberOfDecimalPlaces="3"></num-row>
                <num-row labelKey="inquiry.calculator.costFactor" name="costFactor" [formControl]="form.controls.costFactor"
                         [showErrors]="showErrors"></num-row>
              </div>
              <div class="bon-card-inner">
                <num-row labelKey="inquiry.calculator.countriesFactor" name="countriesFactor" [formControl]="form.controls.countriesFactor"
                         [showErrors]="showErrors" numberOfDecimalPlaces="3"></num-row>
                <num-row labelKey="inquiry.calculator.intermediaryFactor" name="intermediaryFactor" [formControl]="form.controls.intermediaryFactor"
                         [showErrors]="showErrors"></num-row>
              </div>
              <div class="bon-card-inner">
                <percent-row labelKey="inquiry.calculator.brokerIntermediaryCommission" name="brokerIntermediaryCommission" [formControl]="form.controls.brokerIntermediaryCommission"
                             [showErrors]="showErrors"></percent-row>
                <percent-row labelKey="inquiry.calculator.agentIntermediaryCommission" name="agentIntermediaryCommission" [formControl]="form.controls.agentIntermediaryCommission"
                             [showErrors]="showErrors"></percent-row>
                <percent-row labelKey="inquiry.calculator.intermediaryCommission" name="intermediaryCommission" [formControl]="form.controls.intermediaryCommission"
                             [showErrors]="showErrors"></percent-row>
              </div>
            </div>
          </bon-section>

          <bon-section labelKey="inquiry.calculator.brokerSection.title" *ngIf="inquiry?.id">
            <div class="bon-card-group">
              <div class="bon-card-inner">
                <broker-contract-relation-list [relatedToId]="inquiry.id" [relatedToTypeId]="BusinessObjectType.POLICY_INQUIRY_VERSION"
                                               [showBrokerColumns]="true" (dataProviderSearchFinished)="onBrokerListSearchFinished($event)"
                                               [businessStatusId]="BrokerRelationStatus.ACTIVE">
                </broker-contract-relation-list>
              </div>
            </div>
          </bon-section>

          <bon-section labelKey="inquiry.calculator.clauseSection.title">
            <div class="bon-card-group">
              <table class="table">
                <thead class="table-header">
                <tr class="table-header">
                  <th>
                    <div>{{'inquiry.calculator.clauseSection.number' | translate}}</div>
                  </th>
                  <th>
                    <div>{{'inquiry.calculator.clauseSection.name' | translate}}</div>
                  </th>
                  <th>
                    <div>{{'inquiry.calculator.clauseSection.rate' | translate}}</div>
                  </th>
                </tr>
                </thead>
                <tbody>
              <tr class="hover-table-row" *ngFor="let cr of clausesRates.controls; let i = index">
                  <td class="bon-table-cell">
                    {{cr.controls.typeCode.value}}
                  </td>
                  <td class="bon-table-cell">
                    {{cr.controls.name.value}}
                  </td>
                  <td class="bon-table-cell">
                    <num-input [formControl]="cr.controls.rate"
                               [numberOfDecimalPlaces]="3"></num-input>
                    <error-message [control]="cr.controls.rate"
                                   [show]="showErrors"></error-message>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </bon-section>

          <bon-section labelKey="inquiry.calculator.rebatesSection.title">
            <div class="bon-card-group">
              <div class="bon-card-inner">
                <percent-row labelKey="inquiry.calculator.rebatesSection.rebateFactor" name="rebateFactor"
                         [formControl]="form.controls.rebateFactor" [numberOfDecimalPlaces]="1"
                         [showErrors]="showErrors"></percent-row>
              </div>
              <div class="bon-card-inner">
                <percent-row labelKey="inquiry.calculator.rebatesSection.premiumRateAfterRebate" name="premiumRateAfterRebate"
                             [formControl]="form.controls.premiumRateAfterRebate" [numberOfDecimalPlaces]="4"
                             [showErrors]="showErrors"></percent-row>
              </div>
            </div>
          </bon-section>
          <bon-section labelKey="inquiry.calculator.premiumsSection.title">
            <div class="bon-card-group">
              <div class="bon-card-inner">
                <num-row labelKey="inquiry.calculator.premiumsSection.expectedPremium" name="expectedPremium"
                         [formControl]="form.controls.expectedPremium" [showErrors]="showErrors"></num-row>
                <num-row labelKey="inquiry.calculator.premiumsSection.xPremium" name="xPremium"
                         [formControl]="form.controls.xpremium"
                         [control]="form.controls.xpremium"
                         [showErrors]="showErrors"></num-row>

              </div>
              <div class="bon-card-inner">
                <percent-row labelKey="inquiry.calculator.premiumsSection.minPremiumAdjustFactor" name="minPremiumAdjustFactor"
                             [formControl]="form.controls.minPremiumAdjustFactor" [showErrors]="showErrors"></percent-row>
                <percent-row labelKey="inquiry.calculator.premiumsSection.selfRetention" name="selfRetention"
                             [formControl]="form.controls.selfRetention" [showErrors]="showErrors"></percent-row>
              </div>
              <div class="bon-card-inner">
                <num-row labelKey="inquiry.calculator.premiumsSection.minPremium" name="minPremium"
                         [formControl]="form.controls.minPremium"
                         [control]="form.controls.minPremium"
                         [showErrors]="showErrors" [disabled]="minPremiumDisabled()"></num-row>
              </div>
            </div>
            <div class="bon-card-group">
              <table class="table">
                <thead class="table-header">
                <tr class="table-header">
                  <th><div>{{'inquiry.calculator.premiumsSection.expectedXPremium' | translate}}</div></th>
                  <th><div>PLN</div></th>
                </tr>
                </thead>
                <tbody>
                <tr class="hover-table-row" *ngFor="let i of table5">
                  <td class="bon-table-cell"><div>{{i.label}}</div></td>
                  <td class="bon-table-cell">
                    <num-input type="integer" [formControl]="form.controls.expectedXPremiumTable.controls[i.key].controls.amount" [disabled]="expectedXPremiumTableDisabled()"></num-input>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </bon-section>
          <bon-section labelKey="inquiry.calculator.xPremiumSection.title">
            <div class="bon-card-group">
              <table class="table">
                <thead class="table-header">
                <tr class="table-header">
                  <th><div>Lp.</div></th>
                  <ng-container *ngIf="isXpremiumTableFull()">
                    <th>
                      <div>{{'inquiry.calculator.xPremiumSection.amountInCurrency' | translate}} </div>
                    </th>
                    <th>
                      <div>{{'inquiry.calculator.xPremiumSection.currency' | translate}}</div>
                    </th>
                    <th>
                      <div>{{'inquiry.calculator.xPremiumSection.fXrate' | translate}}</div>
                    </th>
                    <!--
                    <th>
                      <div>{{'inquiry.calculator.xPremiumSection.fXrateMultiplier' | translate}}</div>
                    </th>
                    -->
                  </ng-container>
                  <th><div>{{'inquiry.calculator.xPremiumSection.amountInPLN' | translate}}</div></th>
                </tr>
                </thead>
                <tbody>
                <tr class="hover-table-row" *ngFor="let i of table6">
                  <td class="bon-table-cell"><div>{{i}}</div></td>
                  <ng-container *ngIf="isXpremiumTableFull()">
                    <td class="bon-table-cell">
                      <num-input type="integer"
                                 [formControl]="form.controls.xpremiumTable.controls[i - 1].controls.amountInCurrency"
                      ></num-input>
                    </td>
                    <td class="bon-table-cell">
                      <!-- <num-input type="integer"
                                 [formControl]="form.controls.xpremiumTable.controls[i - 1].controls.currency"></num-input> -->
                      <dict-combo dictionary="Currency" label="code"
                                  [formControl]="form.controls.xpremiumTable.controls[i - 1].controls.currency"
                      ></dict-combo>
                    </td>
                    <td class="bon-table-cell">
                      <num-input type="integer"
                                 [formControl]="form.controls.xpremiumTable.controls[i - 1].controls.fxrate"
                      ></num-input>
                    </td>
                    <!--<td class="bon-table-cell">
                      <num-input type="integer"
                                 [formControl]="form.controls.xpremiumTable.controls[i - 1].controls.fxrateMultiplier"
                                 [disabled]="true"></num-input>
                    </td>-->
                  </ng-container>
                  <td class="bon-table-cell">
                    <num-input type="integer" [formControl]="form.controls.xpremiumTable.controls[i - 1].controls.amountInPLN"
                    ></num-input>
                    <error-message [control]="form.controls.xpremiumTable.controls[i - 1].controls.amountInPLN"
                                   [show]="showErrors"></error-message>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </bon-section>
        </form>
      </div>
    </ng-template>
    <ng-template #thenBlock>
      <div class="bon-card-group">
        <div class="bon-card-inner">
          <i class="fa fa-spinner fa-spin fa-2x"></i>
        </div>
      </div>
    </ng-template>
  </div>
  <div  class="additional-buttons">
    <button *hasRight="PolicyElementaryRight.POLICY_INQUIRY_CALCULATOR_RECALCULATE" (click)="onCalculate()"
            class="bon-btn-warning" translate="inquiry.calculator.recalculate" type="button" [disabled]="!isCalcEnabled()"></button>
  </div>
</details-view>

<ng-template #saleValuesTable let-inputTable='inputTable' let-items='items'>
  <a-table [tableName]="'salesValues' + inputTable" [formModel]="inputTable ? form.controls.salesValues : null" [items]="items"
           [deleteButton]="inputTable" [addButton]="false" [editable]="inputTable" [sortable]="false" [showAllErrors]="inputTable">
    <column property="country"  dictionary="Country" [editable]="false" cellWidth="15%"></column>
    <column property="selfRetention" type="number" title="inquiry.calculator.pbg.selfRetention"
            [editable]="false" cellWidth="15%"></column>
    <column property="salesSum" type="number" title="inquiry.calculator.pbg.salesSum" [required]="true"></column>
    <column labelKey="inquiry.calculator.pbg.maxPaymentDate" cellWidth="30%">
      <ng-template let-item="item" let-edition="edition" cellWidth="15%">
        <item-combo [items]="maxPaymentDates" [(ngModel)]="item.guiMaxPaymentDate"
                    name="maxPaymentDate" [presentationMode]="!edition">
          <ng-template let-item="item">Do {{item.id}} dni</ng-template>
        </item-combo>
      </ng-template>
    </column>
    <column *ngIf="!inputTable" property="price" type="number" title="common.price"></column>
    <column *ngIf="!inputTable" property="premium" type="number" title="common.premium"></column>
  </a-table>
</ng-template>
