import {Component, OnInit} from '@angular/core';
import {SearchView} from '../../bonding_shared/components/search/search-view/search-view';
import {
  EmployeeDto,
  LoggedUserService,
  RouterService,
  SearchCriteria,
  SearchDataProvider,
  UserDto,
  UserHolidaySettingsBaseDto,
  UserHolidaySettingsCriteriaDto,
  UserIdDto,
  UserNamePipe,
  UserRange,
  UserSimpleDto,
} from '../../bonding_shared';
import {UserHolidaySettingsService} from '../../bonding_shared/services/user-holiday-settings-service';
import {UserRole} from '../../bonding_shared/model/dictionary-ids';
import {AvailableYear} from './user-holiday-settings-details.component';

@Component({
  selector: 'user-holiday-settings-search',
  templateUrl: './user-holiday-settings-search.component.pug',
})
export class UserHolidaySettingsSearchComponent extends SearchView implements OnInit {
  dataProvider: SearchDataProvider<UserHolidaySettingsCriteriaDto, UserHolidaySettingsBaseDto>;
  readonly hrUserRange: UserRange = 'hr';
  readonly employeeUserRange: UserRange = 'employees';
  disableChangeEmployee = !this.loggedUserService.isHRRole();
  availableYears = this.userHolidaySettingsService.prepareAvailableYears();
  year: AvailableYear;

  constructor(
    public router: RouterService,
    private userHolidaySettingsService: UserHolidaySettingsService,
    private loggedUserService: LoggedUserService
  ) {
    super();
  }

  ngOnInit() {
    this.year = <AvailableYear>{};
    this.dataProvider = new SearchDataProvider<UserHolidaySettingsCriteriaDto, UserHolidaySettingsBaseDto>(
      this.userHolidaySettingsService
    );
    this.dataProvider.searchCriteria = <SearchCriteria<UserHolidaySettingsCriteriaDto>>{
      criteria: <UserHolidaySettingsCriteriaDto>{},
    };
    this.dataProvider.searchCriteria.criteria = <UserHolidaySettingsCriteriaDto>{};
    if (!this.loggedUserService.isHRRole()) {
      this.dataProvider.searchCriteria.criteria.user = this.getLoggedUser();
    } else {
      this.dataProvider.searchCriteria.criteria.user = <UserSimpleDto>{};
    }
    this.dataProvider.searchCriteria.criteria.user.employee = <EmployeeDto>{};
  }

  onUserChange(item: UserSimpleDto) {
    this.dataProvider.searchCriteria.criteria.user = item;
  }

  userFullName = (u: UserDto): string => {
    return new UserNamePipe().transform(u);
  };

  onUserCreatedByChange(item: UserIdDto) {
    this.dataProvider.searchCriteria.criteria.createdBy = item;
  }

  private getLoggedUser(): UserDto {
    return this.loggedUserService.getLoggedUserData();
  }

  onYearChange(value: AvailableYear) {
    this.dataProvider.searchCriteria.criteria.year = value.id;
  }
}
