import {Component, ViewChild} from '@angular/core';
import {
  CollectionPaymentScheduleDto,
  CollectionPaymentSchedulesDto,
  CollectionPaymentsDto,
  DictionaryBaseDto,
} from '../../bonding_shared/model';
import {CollectionAuditedViewAbstract} from './collection-audited-view-abstract';
import {ActivatedRoute} from '@angular/router';
import {CollectionVersionService} from '../../bonding_shared/services/collection-version.service';
import {CollectionPaymentsService, GrowlService, LoggedUserService, RouterService} from '../../bonding_shared/services';
import {TranslateService} from '@ngx-translate/core';
import {FormDialogComponent} from '../../bonding_shared/components/form-dialog';
import {GenerationResult} from '../../bonding_shared/components/instalment-generator/instalment-generator.component';
import {ConfirmDialogComponent} from '../../bonding_shared/components/confirm-dialog';
import {HolidayService} from '../../bonding_shared/services/holiday.service';
import {CollectionPaymentSchedulesService} from '../../bonding_shared/services/collection-payment-schedules.service';
import * as moment from 'moment';
import {CollectionGuiService} from './services/collection-gui.service';
import {CollectionElementaryRight} from '../../bonding_shared/model/dictionary-ids';
import {NumberUtils} from '../../bonding_shared/utils/number-utils';
import {DictionaryUtils} from '../../bonding_shared/utils/dictionary-utils';

@Component({
  selector: 'collection-payment-schedule',
  templateUrl: 'collection-payment-schedule.component.pug',
})
export class CollectionPaymentScheduleComponent extends CollectionAuditedViewAbstract<CollectionPaymentSchedulesDto> {
  @ViewChild(FormDialogComponent, {static: true}) scheduleCalculatorDialog: FormDialogComponent;
  @ViewChild(ConfirmDialogComponent, {static: true}) confirmDialog: ConfirmDialogComponent;
  generatorTotalAmount: number;
  generatorCurrency: DictionaryBaseDto;
  payments: CollectionPaymentsDto;

  constructor(
    route: ActivatedRoute,
    versionService: CollectionVersionService,
    parentService: CollectionPaymentSchedulesService,
    router: RouterService,
    translateService: TranslateService,
    growlService: GrowlService,
    loggedUserService: LoggedUserService,
    protected holidayService: HolidayService,
    private paymentsService: CollectionPaymentsService
  ) {
    super(route, versionService, parentService, router, translateService, growlService, loggedUserService);
  }

  protected beforeSave(after: () => void) {
    if (this.current.paymentSchedules.some((x) => this.isAfterProtractedDefaultTerm(x))) {
      this.confirmDialog
        .open(
          'collection.paymentSchedule.dueDateAfterProtractedDefaultTerm.dialogTitle',
          'collection.paymentSchedule.dueDateAfterProtractedDefaultTerm.dialogMsg'
        )
        .then((x) => {
          if (x) {
            after();
          }
        });
    } else {
      after();
    }
  }

  onVersionLoad() {
    if (this.isRecoveryBonding) {
      this.paymentsService.getFromRevision(this.parent.id).subscribe({
        next: (payments: CollectionPaymentsDto) => (this.payments = payments),
      });
    }
  }

  openScheduleCalculatorDialog(currency: DictionaryBaseDto) {
    this.generatorCurrency = currency;
    this.generatorTotalAmount = this.getUnscheduledBalance(currency);
    this.scheduleCalculatorDialog.open('collection.paymentSchedule.calculator');
  }

  onScheduleGenerated(result: GenerationResult[]) {
    const schedules = result.map(
      (r) =>
        <CollectionPaymentScheduleDto>{
          amountGross: NumberUtils.roundMoney(r.amount),
          dueDate: r.date,
          currency: this.generatorCurrency,
        }
    );
    this.current.paymentSchedules.push(...schedules);
    this.newVersion = true;
    this.scheduleCalculatorDialog.hide();
  }

  isAfterProtractedDefaultTerm(schedule: CollectionPaymentScheduleDto): boolean {
    return this.version.protractedDefaultTerm && moment(schedule.dueDate).isAfter(this.version.protractedDefaultTerm);
  }

  getUnscheduledBalance(currency: DictionaryBaseDto): number {
    const scheduled = this.current.paymentSchedules
      .filter((x) => x.currency.id === currency.id)
      .map((x) => x.amountGross)
      .reduce((a1, a2) => a1 + a2, 0);
    return this.calculateBalance(currency) - NumberUtils.roundMoney(scheduled);
  }

  calculateBalance(currency: DictionaryBaseDto): number {
    if (this.isRecoveryBonding()) {
      const mainDebtPayments = this.payments?.mainDebt || [];
      const totalPayments = mainDebtPayments.map((payment) => payment.amountGross).reduce(NumberUtils.sum, 0);
      return DictionaryUtils.equalsDict(this.getBondCurrency(), currency)
        ? this.version.parent.claimIndemnificationVersion.paymentAmount - totalPayments
        : 0;
    } else {
      return this.getInvoicesSum((x) => x.openAmountGross - (x.paidAmount || 0), currency);
    }
  }

  getAvailableScheduleCurrencies(): DictionaryBaseDto[] {
    if (this.isRecoveryBonding()) {
      return [this.getBondCurrency()];
    } else {
      return this.getAllInvoiceCurrencies().filter((x) => this.getUnscheduledBalance(x) > 0);
    }
  }

  addPaymentSchedule(paymentSchedule: CollectionPaymentScheduleDto) {
    paymentSchedule.currency = this.isRecoveryBonding() ? this.getBondCurrency() : this.version.currency;
  }

  changeActive(b: boolean) {
    this.current.active = b;
    this.onSave();
  }

  protected getEmpty(): CollectionPaymentSchedulesDto {
    return <CollectionPaymentSchedulesDto>{paymentSchedules: []};
  }

  isCreditInsurance() {
    return CollectionGuiService.isCreditInsurance(this.version.parent);
  }

  isRecoveryBonding(): boolean {
    return CollectionGuiService.isRecoveryBonding(this.parent);
  }

  getBondCurrency(): DictionaryBaseDto {
    return this.version?.parent?.claimIndemnificationVersion?.parent?.calculation?.currency;
  }

  hasRightToEdit() {
    return this.loggedUserService.hasRight(CollectionElementaryRight.COLLECTION_PAYMENT_SCHEDULE_CREATE_UPDATE);
  }
}
