import {Component, OnInit} from '@angular/core';
import {BackendError, GrowlService, ImageService, RepositoryService} from '../../bonding_shared';
import {DetailsView} from '../../bonding_shared/components/details-view/details-view';

@Component({
  selector: 'banner-management',
  templateUrl: 'banner-management.component.pug',
})
export class BannerManagementComponent extends DetailsView implements OnInit {
  allowedBannerTypes = 'image/png';
  bannerSrc: string;
  serverErrors: BackendError;
  file: any;
  buffer: any;
  newBannerSelected = false;

  ngOnInit(): void {
    this.bannerSrc = this.imageService.getBannerUrl();
  }

  constructor(
    protected growlService: GrowlService,
    private repositoryService: RepositoryService,
    private imageService: ImageService
  ) {
    super(growlService);
    this.hideButtons(true);
  }

  onBannerSelect(event: any) {
    this.file = event.srcElement.files[0];
    if (this.file !== undefined) {
      const reader = new FileReader();
      reader.readAsDataURL(this.file);
      reader.onload = (e: any) => {
        this.buffer = e.target.result;
        this.bannerSrc = this.buffer;
      };
    }
    event.target.value = '';
    this.newBannerSelected = true;
    this.handleButtons();
  }

  onSave() {
    const blob = new Blob([this.buffer], {type: 'application/octet-stream'});
    this.repositoryService.uploadBanner(this.file.type, this.file.size, blob).subscribe({
      next: (repositoryDocument) => {
        this.growlService.notice('Banner upload successful');
        this.newBannerSelected = false;
        this.handleButtons();
      },
      error: (error) => {
        this.growlService.error('Banner error', error);
      },
    });
  }

  onCancel() {
    this.file = undefined;
    this.buffer = undefined;
    this.bannerSrc = this.imageService.getBannerUrl();
    this.newBannerSelected = false;
    this.handleButtons();
  }

  protected handleButtons() {
    this.saveButton.hidden = !this.newBannerSelected;
    this.cancelButton.hidden = !this.newBannerSelected;
  }
}
