import {UserRoleQuotasComponent} from './user-role-quotas.component';
import {SearchIndexComponent} from './search-index.component';
import {BusinessLogComponent} from '../business-log/components/business-log.component';
import {BusinessParamsComponent} from './business-params.component';
import {RejectManagementComponent} from '../reject-management/components/reject-management.component';
import {BusinessLogGuard} from '../business-log/business-log-guard';
import {UserRoleQuotasGuard} from './_guards/user-role-quotas.guard';
import {RejectManagementGuard} from '../reject-management/components/reject-management-guard';
import {BusinessParamsGuard} from './_guards/business-params.guard';

export const AdministrationRoutes = [
  {
    path: 'user-role-quotas',
    component: UserRoleQuotasComponent,
    canActivate: [UserRoleQuotasGuard],
  },
  {
    path: 'search-index',
    component: SearchIndexComponent,
  },
  {
    path: 'business-log',
    component: BusinessLogComponent,
    canActivate: [BusinessLogGuard],
  },
  {
    path: 'business-params',
    component: BusinessParamsComponent,
    canActivate: [BusinessParamsGuard],
  },
  {
    path: 'reject-management',
    component: RejectManagementComponent,
    canActivate: [RejectManagementGuard],
  },
];
