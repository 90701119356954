import {Component, Input, ViewChild} from '@angular/core';
import {SalesLeadCriteriaDto, SalesLeadDto} from '../../../bonding_shared/model/dtos';
import {SearchDataProvider} from '../../../bonding_shared/services/search-data-provider';
import {ATableComponent} from '../../../bonding_shared/components/aku-table/a-table.component';
import {RouterService} from '../../../bonding_shared/services/router-service';

@Component({
  selector: 'sales-lead-list',
  templateUrl: './sales-lead-list.component.pug',
})
export class SalesLeadListComponent {
  @Input() pageSize: number = 20;
  @Input() dataProvider: SearchDataProvider<SalesLeadCriteriaDto, SalesLeadDto>;
  @ViewChild(ATableComponent, {static: true}) salesLeadTable: ATableComponent<SalesLeadDto>;

  constructor(public router: RouterService) {}
}
