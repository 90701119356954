import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {HashLocationStrategy, LocationStrategy} from '@angular/common';
import {RouterModule} from '@angular/router';
import {AppComponent} from './app.component';
import {routes} from './app.routes';

import {LoginModule} from '../../../../src/client/app/login/login.module';
import {PageNotFoundModule} from '../../../../src/client/app/404/404.module';

import {SharedModule} from '../../../../src/client/app/shared/shared.module';
import {BondingModule} from '../../../../src/client/app/bonding/bonding.module';
import {LoginBondingService} from '../../../../src/client/app/bonding_shared/services/login-bonding.service';
import {AbstractLoginService} from '../../../../src/client/app/login/login.service';
import {LoggedUserService} from '../../../../src/client/app/bonding_shared/services/logged-user.service';
import {HttpBackend, HttpClientModule} from '@angular/common/http';
import {APP_CONFIG} from '../environments/environment';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {PaginationModule} from 'ngx-bootstrap/pagination';

import {BsDatepickerModule} from 'ngx-bootstrap/datepicker';
import {ModalModule} from 'ngx-bootstrap/modal';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {PortalModule} from '../../../../src/client/app/portal/portal.module';
import {GrowlModule} from '../../../../src/client/app/bonding_shared/services/growl/growl.module';
import {TranslateCompiler, TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {CustomTranslateLoaderFactory} from '../../../../src/client/app/common/translate';
import {AppInitializerModule} from '../../../../src/client/app/common/initialize';
import {CustomTranslateCompiler} from '../../../../src/client/app/common/translate/custom-translate.compiler';
import {BondingContractOfferProposedCommissionService} from '../../../../src/client/app/bonding/bonding-contract/offer/services/bonding-contract-offer-proposed-commission.service';
import {BondingContractOfferAbstractService} from '../../../../src/client/app/bonding_shared/services/bonding-contract-offer-abstract.service';
import {BondingContractOfferService} from '../../../../src/client/app/bonding_shared/services/bonding-contract-offer.service';

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    BsDropdownModule.forRoot(),
    PaginationModule.forRoot(),
    ModalModule.forRoot(),
    RouterModule.forRoot(routes),
    LoginModule,
    PortalModule,
    PageNotFoundModule,
    BondingModule,
    BsDatepickerModule.forRoot(),
    SharedModule.forRoot(),
    GrowlModule.forRoot(),
    TranslateModule.forRoot({
      loader: {provide: TranslateLoader, useFactory: CustomTranslateLoaderFactory, deps: [HttpBackend]},
      compiler: {provide: TranslateCompiler, useClass: CustomTranslateCompiler},
      defaultLanguage: 'en',
    }),
    AppInitializerModule,
  ],
  declarations: [AppComponent],
  providers: [
    {
      provide: 'app.config',
      useValue: APP_CONFIG,
    },
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy,
    },
    {
      provide: AbstractLoginService,
      useClass: LoginBondingService,
    },
    {
      provide: BondingContractOfferAbstractService,
      useClass: BondingContractOfferService,
    },
    LoggedUserService,
    BondingContractOfferProposedCommissionService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
