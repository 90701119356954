import {Component, ViewChild} from '@angular/core';
import {RouterService} from '../../bonding_shared/services/router-service';
import {CollectionGuiService} from './services/collection-gui.service';
import {
  BackendError,
  CollectionVersionBaseDto,
  CollectionVersionCriteriaDto,
  CompanySimpleDto,
  DictionaryBaseDto,
  ExternalProviderBaseDto,
  LocalDateRange,
  PolicyContractBaseDto,
  PolicyContractVersionCriteriaDto,
  UserGroupBaseDto,
} from '../../bonding_shared/model';
import {ComboItem} from '../../bonding_shared/components/combos/string-combo.component';
import {ListEmitters} from '../../bonding_shared/components/details-view/list-emitters';
import {AppConfigService, LoggedUserService, UserRange, UserService} from '../../bonding_shared/services';
import {
  ApplicationModule,
  CollectionElementaryRight,
  CollectionStatusGroup,
  CollectionType,
  POLICY_CONTRACT_ACTIVATED_STATUSES,
} from '../../bonding_shared/model/dictionary-ids';
import {SearchViewComponent} from '../../bonding_shared/components/search';
import {ActivatedRoute} from '@angular/router';
import {IMultiSelectSettings} from '../../bonding_shared';
import {BusinessReportPortalService} from '../../portal/services/portal-business-report.service';

@Component({
  selector: 'collection-search',
  templateUrl: 'collection-search.component.pug',
})
export class CollectionSearchComponent extends ListEmitters {
  @ViewChild(SearchViewComponent, {static: true}) private searchView: SearchViewComponent<
    CollectionVersionCriteriaDto,
    CollectionVersionBaseDto
  >;

  versionPhases: ComboItem[] = [new ComboItem('ACTIVE', 'Current'), new ComboItem('LAST', 'Last')];
  groups: UserGroupBaseDto[];
  serverErrors: BackendError;

  readonly CollectionType = CollectionType;
  readonly PredefinedFilter = PredefinedFilter;
  readonly COLLECTION_CREATE_INITIAL = CollectionElementaryRight.COLLECTION_CREATE_INITIAL;
  readonly collectionUserRange: UserRange = 'collection';

  readonly statusGroupMultiselectSettings: IMultiSelectSettings = <IMultiSelectSettings>{
    dynamicTitleMaxItems: 1,
    showUncheckAll: true,
  };

  readonly activeStatusGroups = [
    <DictionaryBaseDto>{id: CollectionStatusGroup.PRELEGAL_ACTION},
    <DictionaryBaseDto>{id: CollectionStatusGroup.LEGAL_ACTION},
    <DictionaryBaseDto>{id: CollectionStatusGroup.BAILIFF},
    <DictionaryBaseDto>{id: CollectionStatusGroup.INSOLVENCY},
    <DictionaryBaseDto>{id: CollectionStatusGroup.RESTRUCTURING},
    <DictionaryBaseDto>{id: CollectionStatusGroup.ANALYSIS},
  ];

  readonly closedStatusGroups = [<DictionaryBaseDto>{id: CollectionStatusGroup.CLOSED}];

  isReadOnly = true;
  onlyOpen: boolean;
  onlyClosed: boolean;

  get policyCriteria(): any {
    if (!this.criteria.parent.creditor) {
      return <PolicyContractVersionCriteriaDto>{};
    }
    return <PolicyContractVersionCriteriaDto>{
      oneVersionForMaster: true,
      linkedCompanyId: this.criteria.parent.creditor && this.criteria.parent.creditor.id,
      versionValidFrom: <LocalDateRange>{dateTo: new Date()},
      statusIds: POLICY_CONTRACT_ACTIVATED_STATUSES,
    };
  }

  get criteria(): CollectionVersionCriteriaDto {
    return this.guiService.dataProvider.searchCriteria.criteria;
  }

  constructor(
    private portalBusinessReportService: BusinessReportPortalService,
    private route: ActivatedRoute,
    public router: RouterService,
    public guiService: CollectionGuiService,
    userService: UserService,
    public loggedUserService: LoggedUserService,
    public appService: AppConfigService
  ) {
    super();
    this.selectorNameList = ['Creditor', 'Policy', 'Claim', 'Debtor', 'ExternalProvider', 'CurrentCreditor'];
    this.route.queryParams.subscribe((params) => {
      this.isReadOnly = params.readonly === 'true';
    });
    this.initializeSelectorEmitters(true);
    if (!this.portal) {
      userService
        .getUserGroups()
        .subscribe(
          (x) =>
            (this.groups = x.filter(
              (g) => g.applicationModule && g.applicationModule.id === ApplicationModule.COLLECTION
            ))
        );
    }
    this.fillFixedCriteria();
  }

  newExecuted() {
    this.router.toCollectionDetailsNew();
  }

  selectExternalProvider(provider: ExternalProviderBaseDto) {
    this.criteria.externalProviderRelation.company = undefined;
    this.criteria.externalProviderRelation.provider = provider;
  }

  selectOtherCompany(company: CompanySimpleDto) {
    this.criteria.externalProviderRelation.provider = undefined;
    this.criteria.externalProviderRelation.company = company;
  }

  onOnlyOpenStateChange(checked: boolean) {
    if (checked) {
      this.criteria.statusGroups = [...this.activeStatusGroups];
      this.onlyClosed = false;
    } else {
      this.criteria.statusGroups = [];
    }
  }

  onOnlyClosedStateChange(checked: boolean) {
    if (checked) {
      this.criteria.statusGroups = [...this.closedStatusGroups];
      this.onlyOpen = false;
    } else {
      this.criteria.statusGroups = [];
    }
  }

  onOtherCreditorOnlyChange() {
    this.criteria.currentCreditor = null;
  }

  fillFixedCriteria() {
    if (this.portal) {
      this.criteria.parent.creditor = this.loggedUserService.getLoggedUserData().company;
    }
  }

  resetSearchCriteria() {
    this.guiService.resetSearchCriteria();
    this.fillFixedCriteria();
  }

  applyFilter(filter: PredefinedFilter) {
    this.resetSearchCriteria();
    this.onlyOpen = false;
    this.onlyClosed = false;
    switch (filter) {
      case PredefinedFilter.ACTIVE:
      case PredefinedFilter.FOLLOW_UP:
      case PredefinedFilter.WITHDRAWAL:
        this.criteria.statusGroups = [...this.activeStatusGroups];
        this.onlyOpen = true;
        break;
      case PredefinedFilter.CLOSED:
        this.criteria.statusGroups = [...this.closedStatusGroups];
        this.onlyClosed = true;
        break;
      case PredefinedFilter.PAYMENTS:
        break;
      default:
        const f: never = filter;
    }
    this.searchView.search();
  }

  get portal(): boolean {
    return this.loggedUserService.portal;
  }

  get kuke(): boolean {
    return this.appService.kuke;
  }

  get predefinedFilterVisible(): boolean {
    return this.portal && this.kuke;
  }

  onStatusesModelChange(model: DictionaryBaseDto[]) {
    const statusIds = model.map((s) => s.id);
    this.onlyOpen =
      statusIds.length === this.activeStatusGroups.length &&
      this.activeStatusGroups.every((s) => statusIds.includes(s.id));
    this.onlyClosed =
      statusIds.length === this.closedStatusGroups.length &&
      this.closedStatusGroups.every((s) => statusIds.includes(s.id));
  }

  policyContractSelected(policyContract: PolicyContractBaseDto) {
    this.criteria.masterPolicyContract = policyContract.masterPolicyContract;
  }

  policyContractFilterCleared() {
    this.criteria.masterPolicyContract = null;
  }

  generateReport() {
    this.portalBusinessReportService.generateCollectionExportReport('collections.xlsx');
  }

  public performInitialSearch(): boolean {
    return !this.kuke || this.portal;
  }
}

enum PredefinedFilter {
  FOLLOW_UP,
  PAYMENTS,
  WITHDRAWAL,
  ACTIVE,
  CLOSED,
}
