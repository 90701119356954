import {Component, OnInit} from '@angular/core';
import {GrowlService} from '../../../../bonding_shared/services';
import {DictionaryBaseDto, PolicyClauseTypeDto, StringUtils} from '../../../../bonding_shared';
import {PolicyClauseTypeService} from '../../../../bonding_shared/services/policy-clause-type.service';
import {ActivatedRoute} from '@angular/router';
import {DetailsView} from '../../../../bonding_shared/components/details-view/details-view';

@Component({
  selector: 'policyclausetype-preview',
  templateUrl: './policyclausetype-preview.component.pug',
})
export class PolicyClauseTypePreviewComponent extends DetailsView implements OnInit {
  type: PolicyClauseTypeDto;

  constructor(
    private policyClauseTypeService: PolicyClauseTypeService,
    private route: ActivatedRoute,
    protected growlService: GrowlService
  ) {
    super(growlService);
  }

  ngOnInit() {
    this.commonInit<PolicyClauseTypeDto>(this.route);
  }

  initViewWithId(id: number) {
    this.policyClauseTypeService.getById<PolicyClauseTypeDto>(id).subscribe((pll) => (this.type = pll));
  }

  get gci(): string {
    return this.type.gcis.map((g) => g.name).join(', ');
  }

  getClauseNumberList(list: DictionaryBaseDto[]) {
    return list.map((d) => StringUtils.shortCode(d.code)).join(', ');
  }
}
