import {Component, OnInit, ViewChild} from '@angular/core';
import {SalesRepVersionService} from '../../bonding_shared/services/sales-rep-version.service';
import {ActivatedRoute} from '@angular/router';
import {RouterService} from '../../bonding_shared/services/router-service';
import {DetailsView} from '../../bonding_shared/components/details-view/details-view';
import {GrowlService} from '../../bonding_shared/services/growl/growl.service';
import {SalesRepBaseDto, SalesRepVersionCriteriaDto, SalesRepVersionDto} from '../../bonding_shared/model';
import {BusinessUtils} from '../../bonding_shared/utils/business-utils';
import {SearchDataProvider} from '../../bonding_shared/services/search-data-provider';
import {ATableComponent} from '../../bonding_shared/components/aku-table/a-table.component';
import {NgForm} from '@angular/forms';
import {UserRange} from '../../bonding_shared';
import {SalesRepElementaryRight} from '../../bonding_shared/model/dictionary-ids';

@Component({
  selector: 'sales-rep-details',
  templateUrl: './sales-rep-details.component.pug',
})
export class SalesRepDetailsComponent extends DetailsView implements OnInit {
  salesRepVersion = <SalesRepVersionDto>{salesRep: {}};
  versionsDataProvider: SearchDataProvider<SalesRepVersionCriteriaDto, SalesRepVersionDto>;

  readonly salesUserRange: UserRange = 'sales';
  readonly SalesRepElementaryRight = SalesRepElementaryRight;

  @ViewChild('versions', {static: true}) versionsTable: ATableComponent<SalesRepVersionDto>;
  @ViewChild('form', {static: true}) ngForm: NgForm;

  constructor(
    private salesRepService: SalesRepVersionService,
    private route: ActivatedRoute,
    public routerService: RouterService,
    protected growlService: GrowlService
  ) {
    super(growlService);
    this.saveButton.hidden = false;
    this.cancelButton.hidden = false;
    this.newVersionButton.hidden = false;
    this.newVersionButton.disabled = true;
    this.versionsDataProvider = BusinessUtils.createDataProvider(this.salesRepService);
  }

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.initView(+params['versionId']);
    });
    this.form = this.ngForm.form;
  }

  initView(id: number) {
    this.serverErrors = undefined;
    if (id) {
      this.loadVersion(id, true);
    } else {
      this.salesRepService.initialVersion().subscribe((version) => (this.salesRepVersion = version));
    }
  }

  loadVersion(versionId: number, refreshVersionList?: boolean) {
    this.salesRepService
      .getById<SalesRepVersionDto>(versionId)
      .subscribe((bv) => this.setContract(bv, refreshVersionList));
  }

  setContract(bv: SalesRepVersionDto, refreshVersionList?: boolean) {
    console.log('setContract: ', bv);
    this.salesRepVersion = bv;
    this.handleButtons();
    if (refreshVersionList) {
      this.refreshVersionList();
    }
  }

  onSave() {
    if (!this.formValidates()) {
      return;
    }
    this.inProgress = true;
    const originalId = this.salesRepVersion.id;
    this.salesRepService.save(this.salesRepVersion).subscribe({
      next: (bcv) => {
        this.setContract(bcv, true);
        this.afterObjectSaved(originalId, () => this.routerService.toSalesRepDetails(bcv.id));
      },
      error: (error) => this.handleServerError(error),
    });
  }

  onCancel(): void {
    this.showErrors = undefined;
    if (this.salesRepVersion.id) {
      this.initView(this.salesRepVersion.id);
    } else if (this.versionsTable.items && this.versionsTable.items.length > 0) {
      this.initView(this.versionsTable.items[0].id);
    } else {
      this.initView(0);
    }
  }

  private refreshVersionList() {
    this.versionsDataProvider.searchCriteria.criteria.salesRep = <SalesRepBaseDto>{
      id: this.salesRepVersion.salesRep.id,
    };
    this.versionsTable.search();
  }

  onSelectVersion(bcv: SalesRepVersionDto) {
    this.loadVersion(bcv.id, false);
  }

  handleButtons() {
    this.saveButton.disabled = this.saveDisabled();
    this.newVersionButton.disabled = !this.salesRepVersion.id || !this.salesRepVersion.last;
  }

  onCreateNewVersion() {
    this.salesRepService
      .newVersion<SalesRepVersionDto>(this.salesRepVersion.salesRep.id)
      .subscribe((newVersion) => this.setContract(newVersion));
  }

  saveDisabled() {
    return !this.salesRepVersion || (this.salesRepVersion.id && !this.salesRepVersion.last);
  }
}
