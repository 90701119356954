import {Component, OnInit, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import {ActivatedRoute, Params} from '@angular/router';
import {
  ActivitySimpleDto,
  CollectionBaseDto,
  CollectionRequestBaseDto,
  CollectionRequestDecisionDto,
  CollectionRequestDto,
  CollectionVersionSimpleDto,
  DictionaryBaseDto,
  StateTransitionDto,
  UserSimpleDto,
} from '../../bonding_shared/model';
import {
  CollectionRequestsService,
  CollectionVersionService,
  GrowlService,
  LoggedUserService,
  RouterService,
} from '../../bonding_shared/services';
import {DetailsView} from '../../bonding_shared/components/details-view/details-view';
import {CollectionGuiService} from './services/collection-gui.service';
import {
  CollectionElementaryRight,
  CollectionRequestDecisionStatus,
  CollectionRequestDecisionType,
  Currency,
  ProfileQuotaType,
} from '../../bonding_shared/model/dictionary-ids';
import {Observable} from 'rxjs';

@Component({
  selector: 'collection-requests',
  templateUrl: 'collection-requests.component.pug',
})
export class CollectionRequestsComponent extends DetailsView implements OnInit {
  readonly ProfileQuotaType = ProfileQuotaType;
  request = CollectionGuiService.getNewRequest();
  requests: CollectionRequestBaseDto[];
  decision: CollectionRequestDecisionDto;
  collectionId: number;
  requestEdition = true;
  decisionEdition = false;
  showNewDecision = false;
  showTransitions = false;
  version: CollectionVersionSimpleDto = CollectionGuiService.getEmptyVersion();
  acceptanceUser: UserSimpleDto;

  readonly getDecisionTransitionsFunc: (obj) => Observable<StateTransitionDto[]> = function (
    collectionRequestDecision: CollectionRequestDecisionDto
  ) {
    return this.service.getDecisionTransitions(this.request.id, collectionRequestDecision);
  }.bind(this);

  @ViewChild('ngForm', {static: true}) ngForm: NgForm;

  get parent(): CollectionBaseDto {
    return this.version.parent;
  }

  set parent(parent: CollectionBaseDto) {
    this.version.parent = parent;
  }

  public get externalCommentRequired(): boolean {
    return this.request.acceptFurtherDeliveriesRequest;
  }

  constructor(
    growlService: GrowlService,
    public route: ActivatedRoute,
    private versionService: CollectionVersionService,
    public router: RouterService,
    private service: CollectionRequestsService,
    private loggedUserService: LoggedUserService
  ) {
    super(growlService);
    this.saveButton.hidden = false;
    this.cancelButton.hidden = false;
  }

  ngOnInit(): void {
    this.form = this.ngForm.form;
    this.route.params.subscribe((params) => this.initializeView(params));
  }

  loadLastCollectionVersion() {
    this.versionService.getLastVersionSimpleByParentId(this.collectionId).subscribe({
      next: (version) => {
        this.version = version;
      },
      error: (error) => this.handleServerError(error),
    });
  }

  initializeView(params: Params) {
    if (params['requestId']) {
      this.getRequest(+params['requestId'], () => this.getAll());
    } else {
      this.route.queryParams.subscribe((qp) => {
        this.collectionId = +qp['parentId'];
        this.getAll();
      });
    }
  }

  getAll() {
    this.service.getAll(this.collectionId).subscribe((r) => {
      this.requests = r;
      if (!this.request.id) {
        if (this.requests && this.requests.length) {
          this.getRequest(this.requests[this.requests.length - 1].id);
        } else {
          this.request = CollectionGuiService.getNewRequest();
        }
      }
    });
    this.loadLastCollectionVersion();
  }

  getRequest(requestId: number, callback?: () => void) {
    this.service.getRequest(requestId).subscribe((req) => {
      this.setRequest(req);
      if (callback) {
        callback();
      }
    });
  }

  setRequest(req: CollectionRequestDto) {
    this.request = req;
    if (req.collection) {
      this.collectionId = req.collection.id;
    }
    this.setDecision(req.decisions && req.decisions.length && req.decisions[req.decisions.length - 1]);
  }

  setDecision(dec: CollectionRequestDecisionDto) {
    this.decision = dec;
    this.handleEdition();
  }

  onSave() {
    this.serverErrors = undefined;
    this.showErrors = false;
    if (this.formValidates()) {
      this.inProgress = true;
      if (this.requestEdition) {
        this.service.saveRequest(this.collectionId, this.request).subscribe({
          next: (req) => {
            this.afterObjectSaved();
            if (!this.request.id) {
              this.requests.push(req);
            }
            this.setRequest(req);
          },
          error: (error) => this.handleServerError(error),
        });
      } else {
        this.service.saveDecision(this.request.id, this.decision).subscribe({
          next: (dec) => {
            this.afterObjectSaved();
            this.getRequest(this.request.id);
            this.getAll();
            if (dec.status.id === CollectionRequestDecisionStatus.SENT_TO_ACCEPTANCE) {
              this.growlService.warning('collection.requests.notSufficientRights');
            }
          },
          error: (error) => this.handleServerError(error),
        });
      }
    }
  }

  get portal(): boolean {
    return this.loggedUserService.portal;
  }

  isNotRefusal(): boolean {
    return (
      !this.decisionIs(CollectionRequestDecisionType.FURTHER_DELIVERIES_REFUSAL) &&
      !this.decisionIs(CollectionRequestDecisionType.COLLECTION_ON_HOLD_REFUSAL)
    );
  }

  isFurtherDeliveriesWithLimit(): boolean {
    return (
      this.decision &&
      this.decision.type &&
      [
        CollectionRequestDecisionType.FURTHER_DELIVERIES_WITH_LIMIT_APPROVAL,
        CollectionRequestDecisionType.WITHDRAWAL_EARLY_PAYMENT,
        CollectionRequestDecisionType.WITHDRAWAL_UNSATISFIED_CONDITIONS,
      ].includes(this.decision.type.id)
    );
  }

  isNotFurtherDeliveriesWithdrawalCreation(): boolean {
    return (
      !this.decision ||
      !this.decision.type ||
      !!this.decision.id ||
      ![
        CollectionRequestDecisionType.WITHDRAWAL_EARLY_PAYMENT,
        CollectionRequestDecisionType.WITHDRAWAL_UNSATISFIED_CONDITIONS,
      ].includes(this.decision.type.id)
    );
  }

  decisionIs(id: CollectionRequestDecisionType): boolean {
    return this.decision && this.decision.type && this.decision.type.id === id;
  }

  onCancel() {
    super.onCancel(this.route);
  }

  onNewRequest() {
    this.setRequest(CollectionGuiService.getNewRequest());
  }

  onNewDecision() {
    this.setDecision(<CollectionRequestDecisionDto>{from: this.request.from, to: this.request.to});
  }

  handleEdition(): void {
    this.decisionEdition = !this.portal && this.decision && !this.isDecisionNotActive();
    this.requestEdition = this.portal ? !this.request.id : !this.decision;
    this.saveButton.disabled = !(this.decisionEdition || this.requestEdition);
    this.showNewDecision = !!(!this.portal && this.request.id && (!this.decision || this.decision.id));
    this.showTransitions =
      !this.portal && this.decision && !!this.decision.id && this.isOwnerOfTask(this.decision.acceptanceTask);
  }

  isOwnerOfTask(task: ActivitySimpleDto): boolean {
    return task && task.user && task.user.id === this.loggedUserService.getLoggedUserData().id;
  }

  isDecisionNotActive(): boolean {
    return (
      this.decision &&
      this.decision.status &&
      [
        CollectionRequestDecisionStatus.ACCEPTED,
        CollectionRequestDecisionStatus.CLOSED,
        CollectionRequestDecisionStatus.TERMINATED,
      ].includes(this.decision.status.id)
    );
  }

  sendToAcceptance() {
    this.service.sendToAcceptance(this.decision.request.id, this.decision.id, this.acceptanceUser.id).subscribe({
      next: (res) => {
        this.decision.acceptanceTask = res;
        this.acceptanceUser = undefined;
        this.showSavedMsg();
      },
      error: (error) => this.handleServerError(error),
    });
  }

  get pickUser(): boolean {
    return (
      this.decision &&
      this.decision.status &&
      this.decision.status.id === CollectionRequestDecisionStatus.SENT_TO_ACCEPTANCE &&
      !this.decision.acceptanceTask
    );
  }

  get loadUsersWithQuotaFuncArgs(): any {
    const quotaTypeId = [
      CollectionRequestDecisionType.FURTHER_DELIVERIES_REFUSAL,
      CollectionRequestDecisionType.FURTHER_DELIVERIES_WITH_LIMIT_APPROVAL,
      CollectionRequestDecisionType.FURTHER_DELIVERIES_WITHOUT_LIMIT_APPROVAL,
    ].includes(this.decision.type.id)
      ? ProfileQuotaType.FURTHER_DELIVERIES
      : ProfileQuotaType.COLLECTION_ON_HOLD;

    return [this.version.id, quotaTypeId];
  }

  onDecisionSelected(decisionType: DictionaryBaseDto) {
    this.decision.limitCurrency =
      decisionType.id === CollectionRequestDecisionType.FURTHER_DELIVERIES_WITH_LIMIT_APPROVAL
        ? <DictionaryBaseDto>{id: Currency.PLN, code: 'PLN'}
        : undefined;
  }

  get hiddenDecisions() {
    const ids = new Set<number>();
    if (this.decision && !this.decision.id && this.request && !this.request.withdrawalDecisionAllowed) {
      ids.add(CollectionRequestDecisionType.WITHDRAWAL_EARLY_PAYMENT);
      ids.add(CollectionRequestDecisionType.WITHDRAWAL_UNSATISFIED_CONDITIONS);
    }
    return ids;
  }

  hasRightToEdit() {
    return this.loggedUserService.hasRight(CollectionElementaryRight.COLLECTION_REQUESTS_CREATE_UPDATE);
  }
}
