import {Directive, Input} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, ValidationErrors, Validator} from '@angular/forms';
import {isNumeric} from '../../utils/number-utils';

@Directive({
  selector: '[gtEqThanField]',
  providers: [{provide: NG_VALIDATORS, useExisting: GtEqThanFieldDirective, multi: true}],
})
export class GtEqThanFieldDirective implements Validator {
  @Input() gtEqThanField: any;

  validate(control: AbstractControl): ValidationErrors | null {
    if (control.parent && control.parent.get(this.gtEqThanField.field)) {
      let v: any = control.parent.get(this.gtEqThanField.field).value;
      if (isNumeric(v)) {
        v = <any>v * (this.gtEqThanField.baseMultiplier || 1);
        const controlValue = control.value * (this.gtEqThanField.baseMultiplier || 1);
        return controlValue < v ? {gtEqThanField: {requiredValue: v, actualValue: controlValue}} : null;
      } else {
        return null;
      }
    } else {
      return null;
    }
  }
}
