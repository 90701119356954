import {AfterViewInit, Component, Input} from '@angular/core';
import {EventViewDto, OfferService} from '../../../bonding_shared';

@Component({
  selector: 'offer-history',
  templateUrl: 'offer-history.component.pug',
})
export class OfferHistoryComponent implements AfterViewInit {
  private static readonly REFRESH_TIMEOUT = 2000;

  self = this;

  @Input()
  set offerId(offerid: number) {
    this._offerId = null;
    if (offerid) {
      this._offerId = offerid;
      this.refreshHistoryListOnInput();
    }
  }

  @Input()
  set offerStatusId(offerStatusId: number) {
    this._offerStatusId = null;
    if (offerStatusId) {
      this._offerStatusId = offerStatusId;
      this.refreshHistoryListOnInput();
    }
  }

  history: EventViewDto[] = null;

  private _offerId: number = null;
  private _offerStatusId: number = null;

  constructor(private offerService: OfferService) {}

  ngAfterViewInit() {}

  private refreshHistoryListOnInput() {
    if (this._offerId && this._offerStatusId) {
      this.refreshHistoryList();
    }
  }

  private refreshHistoryList() {
    this.history = null;
    if (this._offerId) {
      setTimeout(() => {
        this.offerService.getHistory(this._offerId).subscribe(
          (ds) => {
            this.history = ds;
          },
          (_) => {
            this.history = [];
          }
        );
      }, OfferHistoryComponent.REFRESH_TIMEOUT);
    }
  }
}
