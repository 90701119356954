import {Component, OnInit, ViewChild} from '@angular/core';
import {DictionaryBaseService, GrowlService, UserService} from '../../bonding_shared/services';
import {UserAssignmentRuleDto} from '../../bonding_shared/model';
import {NgForm} from '@angular/forms';
import {UserAssignmentRuleService} from '../../bonding_shared/services/user-assignment-rule.service';
import {AbstractAssignmentRulesComponent} from './abstract-assigment-rules.component';

@Component({
  selector: 'user-assignment-rules',
  templateUrl: 'user-assignment-rules.component.pug',
})
export class UserAssignmentRulesComponent extends AbstractAssignmentRulesComponent implements OnInit {
  rules: UserAssignmentRuleDto[];
  @ViewChild('ngForm', {static: true}) ngForm: NgForm;
  enabledCategories: string[] = ['K_CLA', 'K_POL_ADM', 'K_POL_UND', 'K_POL_PHO'];

  constructor(
    private rulesService: UserAssignmentRuleService,
    protected dictionaryService: DictionaryBaseService,
    protected userService: UserService,
    protected growlService: GrowlService
  ) {
    super(growlService, dictionaryService, userService);
    this.saveButton.hidden = false;
    this.cancelButton.hidden = false;
  }

  ngOnInit(): void {
    this.form = this.ngForm.form;
  }

  onSave() {
    this.serverErrors = undefined;
    this.showErrors = false;
    if (this.formValidates()) {
      this.inProgress = true;
      this.rulesService.update(this.rules).subscribe({
        next: (res) => {
          this.afterObjectSaved();
          this.form.markAsPristine();
        },
        error: (error) => {
          this.serverErrors = error;
          this.inProgress = false;
        },
      });
    }
  }

  onCancel() {
    this.form.markAsPristine();
    this.loadRules();
  }

  loadRules() {
    if (this.profileCategory) {
      this.rulesService.getRules(this.profileCategory.id).subscribe((res) => (this.rules = res));
    } else {
      this.rules = undefined;
    }
  }
}
