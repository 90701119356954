<div class="content-offer-list">
    <div>
        <div class="container">
            <div class="offer-fields">
                <div>Offer&nbsp;number</div>
                <div>Status</div>
                <div>Insurance period (months)</div>
                <div>Insurance cover (%)</div>
                <div *ngIf="isSectionVisible(viewSection.OFFER__NQL)">NQL</div>
                <div *ngIf="isSectionVisible(viewSection.OFFER__AAD, true)">AAD</div>
                <div>Global LOL</div>
                <div>Annual LOL</div>
                <div>Premium</div>
                <div>Premium rate (%)</div>
                <div>Invoiced premium</div>
                <div *ngIf="isSectionVisible(viewSection.OFFER__ROL)">ROL</div>
                <div *ngIf="isSectionVisible(viewSection.OFFER__MIN_RETENTION)">Minimum retention</div>
                <div *ngIf="isSectionVisible(viewSection.OFFER__MIN_RETENTION)">Minimum retention (%)</div>
                <div *ngIf="isSectionVisible(viewSection.OFFER__EE, true)">E&E</div>
                <div>Number of instalments</div>
                <div *ngIf="isSectionVisible(viewSection.OFFER__PROTRACTED_DEFAULT)">Protracted default (months)</div>
                <div *ngIf="isSectionVisible(viewSection.OFFER__RISK_ASSESSMENT)">Risk assessment</div>
                <div>Comment</div>
            </div>
            <div *ngFor="let item of offers; let i = index" class="offer-data">
                <div (click)="goToOffer(item.id)" class="a-link">{{item.number}}
                    <i class="fa" [ngClass]="{'fa-thumbs-o-up vote-up' : votingThumbUp(item), 'fa-thumbs-o-down vote-down' : votingThumbDown(item)}"
                       aria-hidden="true"></i></div>
                <dict-combo [ngModel]="item.status" disabled="true" dictionary="PolicyInquiryOfferStatus" class="bon-select-right"></dict-combo>
                <num-input [ngModel]="item.insurancePeriod" disabled="true" [type]="'integer'"></num-input>
                <num-input [ngModel]="item.insuranceCover" disabled="true" numberOfDecimalPlaces="5"></num-input>
                <num-input *ngIf="isSectionVisible(viewSection.OFFER__NQL, true)" [ngModel]="item.nql" disabled="true"></num-input>
                <num-input *ngIf="isSectionVisible(viewSection.OFFER__AAD, true)" [ngModel]="item.aad" disabled="true"></num-input>
                <num-input [ngModel]="item.globalLOL" disabled="true"></num-input>
                <num-input [ngModel]="item.annualLOL" disabled="true"></num-input>
                <num-input [ngModel]="item.premium" disabled="true"></num-input>
                <num-input [ngModel]="item.premiumRate" disabled="true" numberOfDecimalPlaces="4"></num-input>
                <num-input [ngModel]="item.invoicedPremium" disabled="true"></num-input>
                <num-input *ngIf="isSectionVisible(viewSection.OFFER__ROL)" [ngModel]="item.premium / (item.annualLOL || item.globalLOL)" disabled="true"></num-input>
                <num-input *ngIf="isSectionVisible(viewSection.OFFER__MIN_RETENTION)" [ngModel]="item.minRetention" disabled="true"></num-input>
                <num-input *ngIf="isSectionVisible(viewSection.OFFER__MIN_RETENTION)" [ngModel]="item.minRetentionPercent" disabled="true"></num-input>
                <num-input *ngIf="isSectionVisible(viewSection.OFFER__EE, true)" [ngModel]="item.ee" disabled="true"></num-input>
                <num-input [ngModel]="item.numberOfInstalments" disabled="true" type="integer"></num-input>
                <num-input *ngIf="isSectionVisible(viewSection.OFFER__PROTRACTED_DEFAULT)" [ngModel]="item.protractedDefault" disabled="true" [type]="'integer'"></num-input>
                <div *ngIf="isSectionVisible(viewSection.OFFER__RISK_ASSESSMENT)">{{item.riskAssessment?.name}}</div>
                <div>{{item.comment | slice:0:16}}{{item.comment?.length > 16?'...':''}}</div>
            </div>
        </div>
        <inquiry-offers-voting [offers]="offers"></inquiry-offers-voting>
        <div *ngIf="isNewOfferAllowed() && isNewOfferFromPreviousAvailable()" class="btn-group" dropdown>
          <button class="btn-sm btn-rounded btn-info fa fa-plus dropdown-toggle" type="button" dropdownToggle
                  [disabled]="false">
          </button>
          <ul class="dropdown-menu" role="menu" style="left: -100%;">
            <li role="menuitem" *ngFor="let offer of offers">
              <a class="dropdown-item" (click)="newOffer(offer)">{{offer.number}}</a>
            </li>
          </ul>
        </div>
        <button type="button" *ngIf="isNewOfferAllowed() && !isNewOfferFromPreviousAvailable()" (click)="newOffer()"
                class="btn-sm btn-rounded btn-info fa fa-plus" style="margin-left: 10px;"></button>

    </div>
</div>
<!-- <item-dropdown [items]="newTypes" (menuSelect)='onNewTypeSelected($event)' caption="+" [disabled]='!newTypes'
               [dropup]='false'></item-dropdown> -->


