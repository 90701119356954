import {FINoteSearchComponent} from './fi-note-search.component';
import {FINoteDetailsComponent} from './fi-note-details.component';

export const FINoteRoutes = [
  {
    path: 'fi-note-search/:category',
    component: FINoteSearchComponent,
  },
  {
    path: 'fi-note-details/:category/:id',
    component: FINoteDetailsComponent,
  },
];
