import {Component, OnInit} from '@angular/core';
import {RouterService} from '../../bonding_shared/services/router-service';
import {SearchDataProvider} from '../../bonding_shared/services/search-data-provider';
import {SalesRepGuiService} from './services/sales-rep-gui.service';
import {SalesRepVersionCriteriaDto, SalesRepVersionDto} from '../../bonding_shared/model';
import {ComboItem} from '../../bonding_shared/components/combos/string-combo.component';
import {LoggedUserService, UserRange, UserService} from '../../bonding_shared/services';
import {SalesRepElementaryRight} from '../../bonding_shared/model/dictionary-ids';

@Component({
  selector: 'sales-rep-search',
  templateUrl: 'sales-rep-search.component.html',
})
export class SalesRepSearchComponent implements OnInit {
  dataProvider: SearchDataProvider<SalesRepVersionCriteriaDto, SalesRepVersionDto>;
  versionPhases: ComboItem[] = [new ComboItem('ACTIVE', 'Current'), new ComboItem('LAST', 'Last')];
  hasViewRight: boolean;

  readonly salesUserRange: UserRange = 'sales';
  readonly SalesRepElementaryRight = SalesRepElementaryRight;

  constructor(
    public router: RouterService,
    public guiService: SalesRepGuiService,
    protected userService: UserService,
    loggedUserService: LoggedUserService
  ) {
    this.hasViewRight = loggedUserService.hasRight(SalesRepElementaryRight.SALES_REP_VIEW);
  }

  ngOnInit() {
    this.dataProvider = this.guiService.dataProvider;
  }
}
