<search-view [labelKey]="captionKey" [objectName]="'Financial note'" [dataProvider]="dataProvider"
             [newHandled]="false" (newExecuted)="newExecuted($event)" [newObjectLabelKey]="newObjectLabelKey"
             [newButtonDisabled] = "newDisabled">
  <div class="search-criteria bon-card-group">
    <div class="bon-card-inner">
        <input-row labelKey="fiNote.search.number" [(ngModel)]="dataProvider.searchCriteria.criteria.number" name="invoiceNumber">
        </input-row>
        <date-row labelKey="fiNote.search.dateFrom" [(ngModel)]="dataProvider.searchCriteria.criteria.dateFromRange.dateFrom"
                  type="date" name="dateFrom"> </date-row>
        <date-row labelKey="fiNote.search.dateTo" [(ngModel)]="dataProvider.searchCriteria.criteria.dateToRange.dateTo"
                  type="date" name="dateTo"> </date-row>
        <form-row labelKey="fiNote.search.businessUnit">
          <business-unit-id-selector class="bon-input-size" [(ngModel)]="dataProvider.searchCriteria.criteria.businessUnit"
                                     name="businessUnit" [nullLabel]="'fiNote.search.all' | translate"></business-unit-id-selector>
        </form-row>
        <form-row labelKey="fiNote.search.createdBy">
          <user-combo class="bon-input-size" [nullLabel]="'fiNote.search.all' | translate" [(ngModel)]="dataProvider.searchCriteria.criteria.createdBy"></user-combo>
        </form-row>
    </div>

    <div class="bon-card-inner bon-label-150" style="width:48%;">
        <input-row labelKey="fiNote.search.id" [(ngModel)]="dataProvider.searchCriteria.criteria.id" name="id" type="number">
        </input-row>
        <input-row [label]="'fiNote.search.clientName' | cattranslate: category" [(ngModel)]="dataProvider.searchCriteria.criteria.client.registrationName" name="clientName">
        </input-row>
        <input-row [label]="'fiNote.search.clientId' | cattranslate: category" [(ngModel)]="dataProvider.searchCriteria.criteria.client.id" name="clientId" type="number">
        </input-row>
        <date-range-row labelKey="fiNote.search.issueDateRange" [value]="dataProvider.searchCriteria.criteria.issueDateRange"></date-range-row>
        <date-range-row labelKey="fiNote.search.creationDateRange" [value]="dataProvider.searchCriteria.criteria.creationDateRange"></date-range-row>
    </div>
    <div class="bon-card-inner"></div>
  </div>

  <div class="search-results">
    <a-table [pagination]="true" [pageSize]="20" [selection]="false" [sortable]='!dataProvider.textSearch'>
      <column labelKey="fiNote.search.result.number" sort="number" [link]="true" (linkClick)="router.toFINoteDetails(categoryId, $event.id)">
        <ng-template let-item="item" let-edition="false">{{item.number ? item.number : 'fiNote.search.result.empty' | translate}}</ng-template>
      </column>
      <column [title]= "'fiNote.search.result.clientName' | cattranslate: category" property="client.registrationName" sort="client.registrationName" [link]="true"
              (linkClick)="router.toCompanyPreview($event.client.id)"></column>
      <column labelKey="fiNote.search.result.netAmount" property="netAmount" sort="netAmount" type="number"></column>
      <column labelKey="fiNote.search.result.currency" property="currency" sort="currency" dictionary="Currency" dictLabel="code"></column>
      <column labelKey="fiNote.search.result.creationDate" property="creationDate" sort="creationDate" type="date" initialSort="down"></column>
      <column labelKey="fiNote.search.result.issueDate" property="issueDate" sort="issueDate" type="date"></column>
      <column labelKey="fiNote.search.result.dateFrom" property="dateFrom" sort="dateFrom" type="date"></column>
      <column labelKey="fiNote.search.result.dateTo" property="dateTo" sort="dateTo" type="date"></column>
      <column labelKey="fiNote.search.result.businessUnit" property="businessUnit.name" sort="businessUnit.name"></column>
      <column labelKey="fiNote.search.result.createdBy" sort="createdBy.familyName">
        <ng-template let-item="item" let-edition="false">{{item?.createdBy?.familyName}}, {{item?.createdBy?.name}}</ng-template>
      </column>

    </a-table>
  </div>
  <div class="additional-buttons">
  </div>
</search-view>

