import {Component, OnInit} from '@angular/core';
import {RouterService} from '../../bonding_shared/services/router-service';
import {
  LocalDateRange,
  SearchCriteria,
  TrainingBaseDto,
  TrainingCriteriaDto,
  UserDto,
  UserIdDto,
  UserSimpleDto,
} from '../../bonding_shared/model/dtos';
import {SearchDataProvider} from '../../bonding_shared/services/search-data-provider';
import {TrainingService} from '../../bonding_shared/services/training-service.service';
import {SearchView} from '../../bonding_shared/components/search/search-view/search-view';
import {LoggedUserService, UserNamePipe, UserRange} from '../../bonding_shared';

@Component({
  selector: 'training-search',
  templateUrl: './training-search.component.html',
})
export class TrainingSearchComponent extends SearchView implements OnInit {
  dataProvider: SearchDataProvider<TrainingCriteriaDto, TrainingBaseDto>;
  readonly employeeUserRange: UserRange = 'employees';
  showButton = this.loggedUserService.isHRRole();
  disableChangeParticipant = !this.loggedUserService.isHRRole();

  constructor(
    public router: RouterService,
    public trainingService: TrainingService,
    private loggedUserService: LoggedUserService
  ) {
    super();
  }

  ngOnInit() {
    this.dataProvider = new SearchDataProvider<TrainingCriteriaDto, TrainingBaseDto>(this.trainingService);
    this.dataProvider.searchCriteria = <SearchCriteria<TrainingCriteriaDto>>{criteria: <TrainingCriteriaDto>{}};
    this.dataProvider.searchCriteria.criteria = <TrainingCriteriaDto>{};
    this.dataProvider.searchCriteria.criteria.dateFromInRange = <LocalDateRange>{};
    this.dataProvider.searchCriteria.criteria.dateToInRange = <LocalDateRange>{};
    if (!this.loggedUserService.isHRRole()) {
      this.dataProvider.searchCriteria.criteria.participant = this.getLoggedUser();
    } else {
      this.dataProvider.searchCriteria.criteria.participant = <UserSimpleDto>{};
    }
  }

  onUserCreatedByChange(item: UserIdDto) {
    this.dataProvider.searchCriteria.criteria.createdBy = item;
  }

  onUserParticipantChange(item: UserIdDto) {
    this.dataProvider.searchCriteria.criteria.participant = item;
  }

  userFullName = (u: UserDto): string => {
    return new UserNamePipe().transform(u);
  };

  private getLoggedUser(): UserDto {
    return this.loggedUserService.getLoggedUserData();
  }
}
