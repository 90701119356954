///<reference path="../../../../node_modules/@types/node/index.d.ts"/>
import {Component, ViewEncapsulation} from '@angular/core';
import {Config} from '../../../../src/client/app/shared/index';
import {AppReloadService} from '../../../../src/client/app/common/reload/app-reload.service';
import {AppConfigService} from '../../../../src/client/app/bonding_shared/services';

/**
 * This class represents the main application component. Within the @Routes annotation is the configuration of the
 * applications routes, configuring the paths for the lazy loaded components (HomeComponent, AboutComponent).
 */
@Component({
  selector: 'sd-app',
  templateUrl: 'app.component.html',
  encapsulation: ViewEncapsulation.None,
  providers: [AppReloadService],
})
export class AppComponent {
  private initTheme(appConfigService: AppConfigService) {
    const applyStyleSheet = (styleName: string) => {
      const head = document.getElementsByTagName('head')[0];
      const style = document.createElement('link');
      style.rel = 'stylesheet';
      style.href = `${styleName}.css`;
      head.appendChild(style);
    };

    if (appConfigService.themeColour) {
      // set specific theme
      applyStyleSheet(`app-${appConfigService.themeColour}`);
    } else {
      applyStyleSheet(`app-credendo-brown`);
    }
  }

  constructor(appReloadService: AppReloadService, appConfigService: AppConfigService) {
    console.log('Environment config', Config);
    appReloadService.runChecking();
    this.initTheme(appConfigService);
    // if (appConfigService.themeColour) {
    //   console.log('Style loading');
    //   require('style-loader!../../../../src/client/assets/theme/app-' + appConfigService.themeColour + '.scss');
    // } else {
    //   console.log('Style default loading');
    //   require('style-loader!../../../../src/client/assets/theme/app-credendo-brown.scss');
    // }
    // console.log('After loading');
  }
}
