import {Component, ViewChild} from '@angular/core';
import {
  AppConfigService,
  CacheService,
  ClaimBaseDto,
  ClaimService,
  ClaimVersionCriteriaDto,
  ClaimVersionDto,
  CollectionBaseDto,
  CollectionIdDto,
  CollectionSimpleDto,
  CollectionVersionBaseDto,
  CollectionVersionCriteriaDto,
  CollectionVersionDto,
  CollectionVersionService,
  CompanyBaseDto,
  CompanyIdDto,
  DictionaryBaseDto,
  DictionaryBaseService,
  FormatService,
  GrowlService,
  LoggedUserService,
  MasterPolicyContractBaseDto,
  PolicyContractBaseDto,
  PolicyContractIdDto,
  PolicyContractService,
  PolicyContractVersionService,
  RepositoryService,
  RouterService,
  SearchDataProvider,
  TemplateService,
} from '../../bonding_shared';
import {CollectionDetailsComponent} from './collection-details.component';
import {ActivatedRoute, Params} from '@angular/router';
import {ClaimRiskType, CollectionType, Currency} from '../../bonding_shared/model/dictionary-ids';
import {CollectionGuiService} from './services/collection-gui.service';
import {CollectionListComponent} from './components/shared/collection-list.component';
import {ClaimListComponent} from '../claim/components/shared/claim-list.component';
import {PortalCollectionClaimService} from '../../portal/services/portal-collection-claim.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'collection-related-cases',
  templateUrl: 'collection-related-cases.component.pug',
})
export class CollectionRelatedCasesComponent extends CollectionDetailsComponent {
  @ViewChild('relatedCases') relatedCases: CollectionListComponent;
  @ViewChild('notSoRelatedCases') notSoRelatedCases: CollectionListComponent;
  @ViewChild('relatedClaims') relatedClaims: ClaimListComponent;
  @ViewChild('notSoRelatedClaims') notSoRelatedClaims: ClaimListComponent;
  @ViewChild('recoveriesFromCollectionCase') recoveriesFromCollectionCase: CollectionListComponent;

  constructor(
    _route: ActivatedRoute,
    service: CollectionVersionService,
    policyContractService: PolicyContractService,
    policyContractVersionService: PolicyContractVersionService,
    guiService: CollectionGuiService,
    router: RouterService,
    translateService: TranslateService,
    growlService: GrowlService,
    templateService: TemplateService,
    loggedUserService: LoggedUserService,
    dictBaseService: DictionaryBaseService,
    cacheService: CacheService,
    formatService: FormatService,
    portalCollectionClaimService: PortalCollectionClaimService,
    private claimService: ClaimService,
    public appService: AppConfigService,
    repositoryService: RepositoryService
  ) {
    super(
      _route,
      service,
      policyContractService,
      guiService,
      policyContractVersionService,
      router,
      translateService,
      growlService,
      templateService,
      loggedUserService,
      dictBaseService,
      cacheService,
      formatService,
      portalCollectionClaimService,
      appService,
      repositoryService
    );
    this.hideButtons(true);
  }

  handleButtons() {
    // do not do anything
  }

  initializeView(params: Params) {
    this.dictBaseService
      .getDictionaryEntry('Currency', Currency.PLN)
      .subscribe((cur) => (this.equivalentCurrency = cur));
    const versionId = +params['versionId'];
    this.serverErrors = undefined;
    if (versionId) {
      this.getVersion(versionId);
    }
  }

  setVersion(version: CollectionVersionDto) {
    super.setVersion(version);
    setTimeout(() => {
      this.performSearch();
    });
  }

  private performSearch() {
    switch (this.version.parent.type.id) {
      case CollectionType.CREDIT_INSURANCE_BUYER_WITH_LIMIT:
      case CollectionType.CREDIT_INSURANCE_DISCRETIONARY_COVER:
      case CollectionType.CREDIT_INSURANCE_DISCRETIONARY_COVER_PLUS_LIMIT:
        this.searchForRelatedCases(
          this.relatedCases,
          this.version.id,
          CollectionType.CREDIT_INSURANCE,
          this.version.parent.debtor.id,
          null,
          this.version.parent.policy.masterPolicyContract.id,
          true
        );
        this.searchForRelatedCases(
          this.notSoRelatedCases,
          this.version.id,
          CollectionType.CREDIT_INSURANCE,
          this.version.parent.debtor.id,
          this.version.parent.creditor.id,
          this.version.parent.policy.masterPolicyContract.id,
          false
        );
        this.searchForRelatedClaims(this.version);
        this.searchForNotSoRelatedClaims(this.version);
        this.searchForRecoveriesFromCollectionCase(this.version);
        break;
      case CollectionType.RECOVERY_CREDIT_INSURANCE:
        this.searchForRelatedCases(
          this.relatedCases,
          this.version.id,
          [CollectionType.RECOVERY_CREDIT_INSURANCE],
          this.version.parent.debtor.id,
          this.version.parent.creditor.id,
          undefined,
          undefined,
          true
        );
        this.searchForRelatedCases(
          this.notSoRelatedCases,
          this.version.id,
          [CollectionType.RECOVERY_CREDIT_INSURANCE],
          this.version.parent.debtor.id,
          this.version.parent.creditor.id,
          undefined,
          undefined,
          false
        );
        break;
      case CollectionType.RECOVERY_BONDING:
        // impossible to do at this moment
        break;
      default:
        break;
    }
  }

  private searchForRelatedCases(
    list: CollectionListComponent,
    versionId: number,
    types: number[],
    debtorId: number,
    creditorId: number,
    masterPolicyContractId: number,
    samePolicy: boolean,
    sameClaimCollectionPolicy?: boolean
  ) {
    const provider = new SearchDataProvider<CollectionVersionCriteriaDto, CollectionVersionBaseDto>(this.service);
    provider.searchCriteria.criteria = <CollectionVersionCriteriaDto>{
      excludedId: versionId,
      parent: <CollectionSimpleDto>{},
      last: true,
    };
    if (types) {
      provider.searchCriteria.criteria.types = types;
    }
    if (masterPolicyContractId) {
      if (samePolicy) {
        provider.searchCriteria.criteria.parent.policy = <PolicyContractBaseDto>{
          masterPolicyContract: <MasterPolicyContractBaseDto>{
            id: masterPolicyContractId,
          },
        };
      } else {
        provider.searchCriteria.criteria.excludedMasterPolicyContractId = masterPolicyContractId;
      }
    }
    if (debtorId) {
      provider.searchCriteria.criteria.parent.debtor = <CompanyBaseDto>{
        id: debtorId,
      };
    }
    if (creditorId) {
      provider.searchCriteria.criteria.parent.creditor = <CompanyBaseDto>{
        id: creditorId,
      };
    }
    if (sameClaimCollectionPolicy !== undefined) {
      provider.searchCriteria.criteria.sameClaimCollectionPolicy = sameClaimCollectionPolicy;
    }
    setTimeout(() => {
      list.table.dataProvider = provider;
      list.table.search();
    });
  }

  private searchForRelatedClaims(version: CollectionVersionDto) {
    const provider = new SearchDataProvider<ClaimVersionCriteriaDto, ClaimVersionDto>(this.claimService);
    provider.searchCriteria.criteria = <ClaimVersionCriteriaDto>{
      claim: <ClaimBaseDto>{
        collection: <CollectionIdDto>{
          id: version.parent.id,
        },
      },
    };
    setTimeout(() => {
      this.relatedClaims.dataProvider = provider;
    });
  }

  private searchForNotSoRelatedClaims(version: CollectionVersionDto) {
    const provider = new SearchDataProvider<ClaimVersionCriteriaDto, ClaimVersionDto>(this.claimService);
    provider.searchCriteria.criteria = <ClaimVersionCriteriaDto>{
      claim: <ClaimBaseDto>{
        policyContract: <PolicyContractIdDto>{
          id: version.parent.policy.id,
        },
        risk: <CompanyIdDto>{
          id: version.parent.debtor.id,
        },
        mainBeneficiary: <CompanyIdDto>{
          id: version.parent.creditor.id,
        },
        riskType: <DictionaryBaseDto>{
          id: ClaimRiskType.PROD_RISK,
        },
      },
    };
    setTimeout(() => {
      this.notSoRelatedClaims.dataProvider = provider;
    });
  }

  private searchForRecoveriesFromCollectionCase(version: CollectionVersionDto) {
    const provider = new SearchDataProvider<CollectionVersionCriteriaDto, CollectionVersionBaseDto>(this.service);
    provider.searchCriteria.criteria = <CollectionVersionCriteriaDto>{
      parent: <CollectionSimpleDto>{
        claimCollection: <CollectionVersionBaseDto>{
          parent: <CollectionBaseDto>{
            number: version.parent.number,
          },
        },
      },
      last: true,
      types: CollectionType.RECOVERY_TYPES,
    };
    setTimeout(() => {
      this.recoveriesFromCollectionCase.table.dataProvider = provider;
      this.recoveriesFromCollectionCase.table.search();
    });
  }
}
