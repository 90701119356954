import {Component, Input, OnInit} from '@angular/core';
import {ClaimGroupAssignmentRuleDto, DictionaryBaseDto} from '../../bonding_shared/model';
import {ControlContainer, UntypedFormGroup, NgForm} from '@angular/forms';
import {DictionaryBaseService, LoggedUserService} from '../../bonding_shared';
import {forkJoin as observableForkJoin} from 'rxjs';
import {BusinessLineGroupCompetency} from '../../bonding_shared/model/dictionary-ids';

@Component({
  selector: 'claim-assignment-criteria',
  templateUrl: 'claim-assignment-criteria.component.pug',
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}],
})
export class ClaimAssignmentCriteriaComponent implements OnInit {
  @Input() rule: ClaimGroupAssignmentRuleDto;
  @Input() dataForm: UntypedFormGroup;
  @Input() showErrors: boolean;

  products: DictionaryBaseDto[] = [];

  constructor(private dictBaseService: DictionaryBaseService, private loggedUserService: LoggedUserService) {}

  ngOnInit(): void {
    observableForkJoin(
      this.dictBaseService.getDictionaryBase('PolicyContractType'),
      this.dictBaseService.getDictionaryEntry(
        'BusinessLineGroupCompetency',
        BusinessLineGroupCompetency.BONDING_COMMERCIAL,
        this.loggedUserService.getLoggedUserData().language.id
      ),
      this.dictBaseService.getDictionaryEntry(
        'BusinessLineGroupCompetency',
        BusinessLineGroupCompetency.BONDING_NON_COMMERCIAL,
        this.loggedUserService.getLoggedUserData().language.id
      )
    ).subscribe((res) => {
      this.products.push(...res[0]);
      this.products.push(res[1]);
      this.products.push(res[2]);
    });
  }
}
