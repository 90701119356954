import {Component, Input} from '@angular/core';
import {DictionaryBaseDto} from '../../bonding_shared/model';
import {IMultiSelectSettings} from '../../bonding_shared/components/multiselect-dropdown';
import {ControlContainer, UntypedFormGroup, NgForm} from '@angular/forms';
import {CountryRatingService} from '../../bonding_shared';

@Component({
  selector: 'dictionary-assignment-criteria',
  template: `
    <div class="bon-row" style="max-height: 550px; overflow-y: auto;">
      <a-table
        [formModel]="dataForm"
        [groupName]="labelKey"
        [items]="items"
        [deleteButton]="true"
        [editable]="false"
        [addButton]="false"
        buttonsCellWidth="59px"
      >
        <column property="name" [labelKey]="labelKey" [editable]="false"></column>
      </a-table>
    </div>
    <div class="bon-row">
      <ss-multiselect-dropdown
        [(ngModel)]="items"
        [options]="customDicts"
        [optionsDictName]="dictName"
        [settings]="multiSelectSettings"
        [name]="labelKey + 'Dropdown'"
      >
      </ss-multiselect-dropdown>
    </div>
    <div *ngIf="dictName === 'Country'">
      <button class="bon-btn-info add-del-countries-marketable-btn" (click)="loadMarketable()" translate>
        policyAssignmentRule.addMarketable
      </button>
      <button class="bon-btn-info add-del-countries-marketable-btn" (click)="removeMarketable()" translate>
        policyAssignmentRule.removeMarketable
      </button>
    </div>
    <div *ngIf="dictName === 'Country'">
      <button class="bon-btn-info add-del-countries-marketable-btn" (click)="loadNonMarketable()" translate>
        policyAssignmentRule.addNonMarketable
      </button>
      <button class="bon-btn-info add-del-countries-marketable-btn" (click)="removeNonMarketable()" translate>
        policyAssignmentRule.removeNonMarketable
      </button>
    </div>
  `,
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}],
})
export class DictionaryAssignmentCriteriaComponent {
  @Input() items: DictionaryBaseDto[];
  @Input() labelKey: string;
  @Input() dataForm: UntypedFormGroup;
  @Input() dictName: string;
  @Input() customDicts: DictionaryBaseDto[];

  multiSelectSettings: IMultiSelectSettings = {
    enableSearch: true,
    dynamicTitleMaxItems: 0,
    buttonClasses: 'bon-btn-warning',
    showCheckAll: true,
    showUncheckAll: true,
  };

  constructor(protected countryRatingService: CountryRatingService) {}

  loadMarketable() {
    this.countryRatingService.getMarketableCountries().subscribe((res) => {
      console.log(res);
      res.forEach((country) => {
        if (!this.items.find((c) => c.id === country.id)) {
          this.items.push(country);
        }
      });
    });
  }

  loadNonMarketable() {
    this.countryRatingService.getNonMarketableCountries().subscribe((res) => {
      console.log(res);
      res.forEach((country) => {
        if (!this.items.find((c) => c.id === country.id)) {
          this.items.push(country);
        }
      });
    });
  }

  removeNonMarketable() {
    this.countryRatingService.getNonMarketableCountries().subscribe((res) => {
      console.log(res);
      res.forEach((country) => {
        const index = this.items.findIndex((c) => c.id === country.id);
        if (index > -1) {
          this.items.splice(index, 1);
        }
      });
    });
  }

  removeMarketable() {
    this.countryRatingService.getMarketableCountries().subscribe((res) => {
      console.log(res);
      res.forEach((country) => {
        const index = this.items.findIndex((c) => c.id === country.id);
        if (index > -1) {
          this.items.splice(index, 1);
        }
      });
    });
  }
}
