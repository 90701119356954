import {Component} from '@angular/core';
import {BatchService, GrowlService} from '../../bonding_shared/services';

@Component({
  selector: 'search-index',
  templateUrl: './search-index.component.pug',
})
export class SearchIndexComponent {
  confirmed = false;
  pressed = false;

  constructor(private indexService: BatchService, private growlService: GrowlService) {}

  confirm() {
    this.confirmed = true;
  }

  indexRebuild() {
    this.pressed = true;
    this.indexService.rebuildIndexes().subscribe((next) => {
      this.growlService.notice('Search indexes rebuild started.');
    });
  }
}
