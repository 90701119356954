import {Component, Optional} from '@angular/core';
import {ValidatorFn, Validators} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {
  CellChangeEvent,
  CollectionExternalProviderRelationsService,
  CollectionVersionService,
  CompanyBaseDto,
  ExternalProviderRelationDto,
  ExternalProviderVersionBaseDto,
  ExternalProviderVersionService,
  GrowlService,
  LoggedUserService,
  RouterService,
} from '../../bonding_shared';
import {CollectionAuditedViewAbstract} from './collection-audited-view-abstract';
import {DateUtils} from '../../bonding_shared/utils/date-utils';
import {CollectionElementaryRight, ExternalProviderType} from '../../bonding_shared/model/dictionary-ids';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'collection-external-provider-relations',
  templateUrl: 'collection-external-provider-relations.component.pug',
})
export class CollectionExternalProviderRelationsComponent extends CollectionAuditedViewAbstract<
  ExternalProviderRelationDto[]
> {
  constructor(
    route: ActivatedRoute,
    versionService: CollectionVersionService,
    parentService: CollectionExternalProviderRelationsService,
    router: RouterService,
    translateService: TranslateService,
    growlService: GrowlService,
    @Optional() private externalProviderService: ExternalProviderVersionService,
    loggedUserService: LoggedUserService
  ) {
    super(route, versionService, parentService, router, translateService, growlService, loggedUserService);
    this.selectorNameList = ['Providers'];
    this.initializeSelectorEmitters(true);
  }

  protected getEmpty(): ExternalProviderRelationDto[] {
    return [];
  }

  addProvider(dto: ExternalProviderVersionBaseDto) {
    if (
      !this.current.map((x) => x.provider && x.provider.id).includes(dto.parent.id) &&
      !this.current.map((x) => x.company && x.company.id).includes(dto.parent.company.id)
    ) {
      this.externalProviderService
        .getCommission(dto.id, this.parent.debtor.address.country.id, this.version.balance, this.daysPastDue())
        .subscribe((c) =>
          this.current.push(<ExternalProviderRelationDto>{
            commissionFromProvider: c,
            vatRate: dto.vatRate,
            provider: dto.parent,
            orderDate: new Date(),
          })
        );
    }
  }

  addCompany(companyDto: CompanyBaseDto) {
    if (!this.current.map((x) => (x.company ? x.company.id : x.provider.company.id)).includes(companyDto.id)) {
      this.current.push(<ExternalProviderRelationDto>{company: companyDto, orderDate: new Date()});
    }
  }

  private daysPastDue(): number {
    const dueDates = this.version.collectionInvoices
      .filter((x) => x.totalBalance > 0)
      .map((x) => x.dueDate)
      .sort(DateUtils.sorter('dueDate'));
    return dueDates.length > 0 ? DateUtils.daysBeforeToday(dueDates[0]) : 0;
  }

  getCommissionRequiredValidator(): (ExternalProviderRelationDto) => ValidatorFn {
    return (r) => (control) =>
      r.provider &&
      r.provider.type.id === ExternalProviderType.AGENT &&
      !r.commissionFromProvider &&
      Validators.required(control);
  }

  isCompanyWithoutContract(r: ExternalProviderRelationDto): boolean {
    return !r.provider;
  }

  isCompanyWithContract(r: ExternalProviderRelationDto): boolean {
    return !!r.provider;
  }

  onOrderDateChange(event: CellChangeEvent<ExternalProviderRelationDto>) {
    if (event.value > event.item.closingDate) {
      event.item.closingDate = event.value;
    }
  }

  hasRightToEdit() {
    return this.loggedUserService.hasRight(
      CollectionElementaryRight.COLLECTION_EXTERNAL_PROVIDER_RELATIONS_CREATE_UPDATE
    );
  }
}
