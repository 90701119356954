import {AfterViewChecked, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {NgForm, UntypedFormGroup} from '@angular/forms';
import {
  AppConfigService,
  DictionaryBaseDto,
  DictionaryService,
  DocumentService,
  ErrorReason,
  GrowlService,
  InquiryService,
  LimitRequestCriteriaDto,
  LimitService,
  LoggedUserService,
  OfferService,
  PolicyCreationDataDto,
  PolicyInquiryOfferDto,
  PolicyInquiryOfferEnvelopeDto,
  PolicyInquiryOfferRejectionReasonDto,
  PolicyInquiryOfferSimpleDto,
  PolicyInquiryTypeEnum,
  PolicyInquiryVersionSimpleDto,
  RouterService,
  SearchCriteria,
  StringUtils,
  TemplateService,
  TemplateSimpleDto,
  UserService,
} from '../../../bonding_shared';
import {DetailsView} from '../../../bonding_shared/components/details-view/details-view';
import {Observable, Subscription, throwError} from 'rxjs';
import {
  BusinessObjectType,
  ContactNoteElementaryRight,
  DocumentType,
  InquiryProductType,
  isOfferRejectionState,
  KukeRepoOperation,
  OFFER_NEW_DOCUMENTS_STATUSES,
  OFFER_PENDING_STATUSES,
  PolicyElementaryRight,
  PolicyInquiryOfferAction,
  PolicyInquiryOfferStatus,
  ServiceNoteElementaryRight,
} from '../../../bonding_shared/model/dictionary-ids';
import {catchError, map, shareReplay} from 'rxjs/operators';
import {RelatedObject} from '../../../bonding_shared/components/related-object-info/model';
import * as _ from 'lodash';
import {PolicyContractGuiService} from '../../policy/services/policy-contract-gui.service';
import {Button} from '../../../bonding_shared/components/details-view/button';
import {
  canCalculate,
  ClientOfferAllowance,
  isNarrowEditable,
  isNotType,
  isStandardEditable,
  isType,
} from '../domain/offer-allowance';
import {DetailsViewComponent} from '../../../bonding_shared/components/details-view/details-view.component';
import {KukeOfferLifecycleComponent} from '../shared/kuke-offer-lifecycle.component';
import * as moment from 'moment';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'kuke-offer-details',
  templateUrl: 'kuke-offer-details.component.pug',
})
export class KukeOfferDetailsComponent extends DetailsView implements OnInit, OnDestroy, AfterViewChecked {
  readonly PolicyElementaryRight = PolicyElementaryRight;

  self = this;

  offerEnvelope: PolicyInquiryOfferEnvelopeDto;
  inquiryId: number; // inquiry version id
  offerId: number;
  private offerTypeId: number;
  private paramSubs: Subscription;

  protected isNewEdge: boolean;
  private sourceOfNewOfferId: number;

  templates: TemplateSimpleDto[] = [];
  pcvTemplate: TemplateSimpleDto;
  pDraftTemplate: TemplateSimpleDto;

  @ViewChild('detailsView', {static: true}) detailsViewComponent: DetailsViewComponent;
  @ViewChild('ngForm', {static: true}) ngForm: NgForm;
  @ViewChild(KukeOfferLifecycleComponent) private lifecycleComponent: KukeOfferLifecycleComponent;

  private set editionMode(em: boolean) {
    this.saveButton.hidden = !em;
  }

  private get editionMode() {
    return !this.saveButton.hidden;
  }

  private onCancelReturn: boolean | null = null;

  readonly BusinessObjectType = BusinessObjectType;
  readonly ContactNoteElementaryRight = ContactNoteElementaryRight;
  readonly ServiceNoteElementaryRight = ServiceNoteElementaryRight;
  private afterViewCheckedAction = null;

  readonly draftPolicyButtons: {
    draftPolicyGenerateButton: Button;
    pandaDraftPolicyButton: Button;
  } = {
    draftPolicyGenerateButton: new Button(
      'offer.button.draftPolicyGenerate',
      () => this.generateDraftPdf(),
      false,
      false,
      'calendar'
    ),
    pandaDraftPolicyButton: new Button(
      'offer.button.pandaDraftPolicyButton',
      () => this.generateDraftPdfAndSendPanda(),
      false,
      false,
      'calendar'
    ),
  };

  extendedRequired = false;
  private _isExistsLimitRequest: Observable<boolean> = null;

  constructor(
    private route: ActivatedRoute,
    public routerService: RouterService,
    private inquiryService: InquiryService,
    public offerService: OfferService,
    protected translateService: TranslateService,
    protected growlService: GrowlService,
    protected loggedUserService: LoggedUserService,
    protected userService: UserService,
    protected dictionaryService: DictionaryService,
    public appConfigService: AppConfigService,
    protected templateService: TemplateService,
    private documentService: DocumentService,
    private limitService: LimitService,
    protected guiService: PolicyContractGuiService,
    private cd: ChangeDetectorRef
  ) {
    super(growlService);

    this.hideButtons(false);
    this.deleteButton.hidden = true;
    this.newVersionButton.hidden = true;
    this.editionMode = false;

    this.form = new UntypedFormGroup({});

    this.selectorNameList = ['Policy', RelatedObject.SPECIAL_RISKS];
    this.initializeSelectorEmitters(true);
  }

  ngOnInit() {
    this.readParams();
  }

  readParams() {
    this.paramSubs = this.route.params.subscribe((params) => {
      if (+params['inquiryId'] > 0) {
        this.inquiryId = +params['inquiryId'];
      }
      if (+params['offerId'] > 0) {
        this.offerId = +params['offerId'];
      } else {
        this.offerId = 0;
      }
      if (+params['offerTypeId'] > 0) {
        this.offerTypeId = +params['offerTypeId'];
      } else {
        this.offerTypeId = 0;
      }
      if (params['edge']) {
        this.isNewEdge = 'true' === params['edge'];
      } else {
        this.isNewEdge = false;
      }
      if (params['parentOfferId']) {
        this.sourceOfNewOfferId = +params['parentOfferId'];
      } else {
        this.sourceOfNewOfferId = null;
      }

      this.initOfferField();
      this._isExistsLimitRequest = null;
    });
  }

  ngOnDestroy(): void {
    this.paramSubs.unsubscribe();
  }

  onSave(voteTask?: boolean) {
    if (this.inProgress) {
      console.warn('Parallel save clicks');
      return;
    }

    this.extendedRequired = false;
    this.sendOffer(() => this.inquiryService.saveOffer(this.offerEnvelope.offer, voteTask));
  }

  onLifecycle(action: DictionaryBaseDto, oldStatus: DictionaryBaseDto) {
    this.extendedRequired = false;

    const ofe = this.offerEnvelope.offer;
    const statutId = ofe.status.id;
    const setRejectionOnRejectionState = (r: PolicyInquiryOfferRejectionReasonDto) => {
      if (isOfferRejectionState(statutId)) {
        ofe.rejectionReason = r;
      }
    };
    const type = this.lifecycleComponent.rejectionTypeControl.value;
    if (type && type.id) {
      setRejectionOnRejectionState(
        _.assign(<PolicyInquiryOfferRejectionReasonDto>{}, this.lifecycleComponent.rejectionForm.getRawValue())
      );
    }
    const voteTask = false;
    let stateReject = null;
    const assigned =
      action && action.id === PolicyInquiryOfferAction.REGISTER
        ? this.lifecycleComponent.getRegisterAssingOptions()
        : null;
    const stateChangeAction = () =>
      this.sendOffer(
        () => this.inquiryService.saveOffer(ofe, voteTask, action, assigned),
        () => {
          setRejectionOnRejectionState(null);
          if (stateReject) {
            stateReject();
          }
        }
      );

    // Register after activation of validation
    if (action && action.id === PolicyInquiryOfferAction.REGISTER) {
      this.editionMode = true;
      this.extendedRequired = true;
      this.cd.detectChanges();
      this.form.updateValueAndValidity();
      this.ngForm.form.updateValueAndValidity();
      this.afterViewCheckedAction = stateChangeAction;
      return new Promise((resolve, reject) => {
        stateReject = reject;
      });
    } else {
      stateChangeAction();
    }
  }

  // onSave
  private sendOffer(func: () => Observable<PolicyInquiryOfferEnvelopeDto>, cleanOnError?: () => void) {
    this.showErrors = true;

    if (this.canSave() && (!this.form.valid || !this.ngForm.form.valid)) {
      this.showFormError();
      StringUtils.logFormInvalidFields(!this.form.valid ? this.form : this.ngForm.form);
      if (cleanOnError) {
        cleanOnError();
      }
    } else {
      this.offerRefresh(func, cleanOnError);
    }
  }

  ngAfterViewChecked() {
    if (this.afterViewCheckedAction !== null) {
      const action = this.afterViewCheckedAction;
      this.afterViewCheckedAction = null;
      action();
      this.cd.detectChanges();
    }
  }

  // onSave
  private onAfterSave(offer: PolicyInquiryOfferEnvelopeDto) {
    this.offerId = offer.offer.id;
    this.handleGetOffer(offer, false);
    this.showSavedMsg();
  }

  onCancel(route?: ActivatedRoute) {
    super.onCancel();
    // flag inProgress should prevent this case
    if (this.onCancelReturn === null) {
      throw new Error('onCancelReturn null');
    }
    if (!this.editionMode || this.onCancelReturn) {
      this.routerService.toInquiryDetails(this.getInquiry());
    } else {
      this.initOfferField();
    }
  }

  // ngOnInit
  private initOfferField() {
    this.offerEnvelope = <PolicyInquiryOfferEnvelopeDto>{offer: <PolicyInquiryOfferDto>{votes: {}}};
    this.inProgress = true;
    if (this.offerId === 0) {
      this.inquiryService
        .initOffer(this.inquiryId, this.offerTypeId, this.sourceOfNewOfferId, null, this.isNewEdge)
        .subscribe({
          next: (offer) => {
            this.handleGetOffer(offer, true);
            this.initNewOffer();
          },
          error: (error) => this.handleServerError(error),
          complete: () => (this.inProgress = false),
        });
    } else {
      this.inquiryService.getOffer(this.offerId).subscribe({
        next: (offer) => this.handleGetOffer(offer, false),
        error: (error) => this.handleServerError(error),
        complete: () => (this.inProgress = false),
      });
    }
  }

  private handleGetOffer(envelope: PolicyInquiryOfferEnvelopeDto, editionMode: boolean) {
    this.offerEnvelope = envelope;
    this.serverErrors = undefined;
    this.showErrors = false;
    this.editionMode = editionMode;
    // automatic enter in edit mode
    this.onCancelReturn = editionMode;
    this.form.patchValue(this.offerEnvelope.offer);

    let policyInquiryNumber: string;
    const setPolicyInquiryNumber = (inquiry: PolicyInquiryVersionSimpleDto) =>
      (policyInquiryNumber = inquiry.policyInquiry.number);

    if (envelope.offer.edge || this.isNewEdge) {
      this.loadTemplates();
      if (envelope.offer.policyInquiryVersion) {
        this.inquiryId = envelope.offer.policyInquiryVersion.id;
        setPolicyInquiryNumber(envelope.offer.policyInquiryVersion);
      }
    } else {
      this.loadTemplateForWording();
      this.loadTemplateForPolicyDraft();
      this.loadClientOfferTemplates();
      if (envelope.offer.parent && envelope.offer.parent.policyInquiryVersion) {
        this.inquiryId = envelope.offer.parent.policyInquiryVersion.id;
        setPolicyInquiryNumber(envelope.offer.parent.policyInquiryVersion);
      }
    }

    if (!this.inquiryId) {
      const inquiry = this.getInquiry();
      this.inquiryId = inquiry && inquiry.id;
      setPolicyInquiryNumber(inquiry);
    }
    if (this.lifecycleComponent) {
      this.lifecycleComponent.initNotAllLimitsDone(editionMode, policyInquiryNumber);
    } else {
      console.error('lifecycleComponent empty');
    }
    this.updateIssueStateWarnings();
  }

  private updateIssueStateWarnings() {
    // Presumptions that serverErrors is null
    if (this.offerEnvelope.issueState === 'NO_ADDITIONAL_INFO' || this.offerEnvelope.issueState === 'NO_PORTAL_ADMIN') {
      this.translateService.get('offer.issueStateWarnings.' + this.offerEnvelope.issueState).subscribe((tr) => {
        this.serverErrors = [
          <ErrorReason>{
            message: tr,
            severity: 'WARNING',
          },
        ];
      });
    }
  }

  private isExistsLimitRequest(policyInquiryNumber): Observable<boolean> {
    if (this._isExistsLimitRequest === null) {
      const limitSearchCriteria = <LimitRequestCriteriaDto>{};
      limitSearchCriteria.policyInquiryNumber = policyInquiryNumber;

      const searchCriteria = <SearchCriteria<LimitRequestCriteriaDto>>{};
      searchCriteria.criteria = limitSearchCriteria;
      searchCriteria.countOnly = true;

      this._isExistsLimitRequest = this.limitService.searchByCriteria(searchCriteria, null).pipe(
        map((limitResult) => limitResult.size > 0),
        catchError((err) => {
          console.log('limit not done search error', err);
          return throwError(err);
        }),
        shareReplay(1)
      );
    }
    return this._isExistsLimitRequest;
  }

  // ngOnInit
  private initNewOffer() {
    const offer = this.offerEnvelope.offer;
    offer.edge = this.isNewEdge;

    if (offer.edge || !ClientOfferAllowance.isClientOfferAllowed(this.offerEnvelope.offer.type)) {
      offer.parent = null;
      if (!offer.policyInquiryVersion) {
        console.warn('Empty policyInquiryVersion in new not client offer');
        offer.policyInquiryVersion = <PolicyInquiryVersionSimpleDto>{id: this.inquiryId};
      }
    } else {
      // client offers
      if (!offer.parent) {
        console.warn('Empty parent in new client offer');
        offer.parent = <PolicyInquiryOfferSimpleDto>{
          id: this.sourceOfNewOfferId,
          policyInquiryVersion: offer.policyInquiryVersion,
        };
      }
      offer.policyInquiryVersion = <PolicyInquiryVersionSimpleDto>{id: null};
    }
  }

  canSave() {
    return this.editionMode;
  }

  isNotSaved() {
    const oe = this.offerEnvelope;
    return !oe || !oe.offer || !oe.offer.id;
  }

  canCreatePolicy() {
    return (
      this.offerEnvelope &&
      this.offerEnvelope.offer &&
      this.isAcceptedClientNoPolicy() &&
      this.isInquiryType('POLICY_INQUIRY')
    );
  }

  createPolicy() {
    this.inProgress = true;
    const offer = <PolicyCreationDataDto>{id: this.offerEnvelope.offer.id};
    this.inquiryService.createPolicyFromOffer(offer).subscribe({
      next: (policyCreationInfo) => {
        this.guiService.justCreatedPolicy = policyCreationInfo;
        this.routerService.toPolicyContractPreview(policyCreationInfo.id);
      },
      error: (error) => this.handleServerError(error),
      complete: () => (this.inProgress = false),
    });
  }

  canCreateAnnexPolicy() {
    return (
      this.offerEnvelope &&
      this.offerEnvelope.offer &&
      !this.offerEnvelope.offer.edge &&
      this.isAccepted() &&
      (this.isInquiryType('POLICY_ANNEX') || this.isInquiryType('RENEWAL_INQUIRY'))
    );
  }

  private isAcceptedClientNoPolicy() {
    return (
      this.isAccepted() &&
      !this.offerEnvelope.offer.policyContractVersion &&
      !this.offerEnvelope.createdPolicy &&
      !this.offerEnvelope.offer.edge &&
      this.offerEnvelope.issueState === 'TASK_DONE'
    );
  }

  private isAccepted() {
    return (
      this.offerEnvelope.offer.status &&
      (this.offerEnvelope.offer.status.id === PolicyInquiryOfferStatus.ACCEPTED ||
        this.offerEnvelope.offer.status.id === PolicyInquiryOfferStatus.ACCEPTED_BY_CLIENT)
    );
  }

  createAnexPolicy() {
    this.inProgress = true;
    const offer = <PolicyCreationDataDto>{id: this.offerEnvelope.offer.id};
    this.inquiryService.createPolicyFromOffer(offer).subscribe({
      next: (policyCreationInfo) => {
        this.guiService.justCreatedPolicy = policyCreationInfo;
        this.routerService.toPolicyContractPreview(policyCreationInfo.id);
      },
      error: (error) => this.handleServerError(error),
      complete: () => (this.inProgress = false),
    });
  }

  canIssuePolicy() {
    if (
      !this.offerEnvelope ||
      !this.offerEnvelope.offer ||
      this.isInquiryType('POLICY_ANNEX') ||
      this.isInquiryType('RENEWAL_INQUIRY')
    ) {
      return false;
    }
    return this.offerEnvelope.issueState === 'READY' && !this.offerEnvelope.offer.edge;
  }

  getSectionTitle() {
    return this.offerEnvelope.offer.edge
      ? 'offer.details.edgeOfferSection.title'
      : 'offer.details.clientOfferSection.title';
  }

  issuePolicy() {
    this.sendOffer(() => this.inquiryService.issuePolicyFromOffer(this.offerEnvelope.offer.id));
  }

  sendToGroup(userGroup): void {
    this.offerEnvelope.offer.voting.userGroup = userGroup;
    this.onSave(true);
  }

  canCalculate(): Observable<boolean> {
    const eo = this.offerEnvelope;

    const inquiry = this.getInquiry();
    const policyInquiryNumber = inquiry && inquiry.policyInquiry && inquiry.policyInquiry.number;

    if (!policyInquiryNumber) {
      return Observable.create((observer) => observer.next(false));
    }

    return canCalculate(eo.offer, this.isExistsLimitRequest(policyInquiryNumber));
  }

  goToCalculator() {
    this.routerService.toCalculator(this.offerId);
  }

  isClientOffer(): boolean {
    const oe = this.offerEnvelope;
    if (oe && oe.offer) {
      return !oe.offer.edge;
    }
    return null;
  }

  getInquiry(): PolicyInquiryVersionSimpleDto {
    const offer = this.offerEnvelope && this.offerEnvelope.offer;
    return offer && (offer.policyInquiryVersion || (offer.parent && offer.parent.policyInquiryVersion));
  }

  private isInquiryType(t: PolicyInquiryTypeEnum) {
    const inq = this.getInquiry();
    return inq && inq.policyInquiry && inq.policyInquiry.inquiryType === t;
  }

  // TODO: check if there is configuration of document types for offer in DB - templates must be defined
  loadTemplates() {
    this.templates = [];
    this.loadTemplatesByType(DocumentType.WZ, BusinessObjectType.OFFER);
    this.loadTemplatesByType(DocumentType.WZRU, BusinessObjectType.OFFER);
    this.loadTemplatesByType(DocumentType.POWER_OF_ATTORNEY_OFFER, BusinessObjectType.OFFER);
  }

  loadClientOfferTemplates() {
    this.templates = [];
    this.loadTemplatesByType(DocumentType.DSP_COVER_TEMP, BusinessObjectType.OFFER);
    this.loadTemplatesByType(DocumentType.FRAMEWORK_AGREE, BusinessObjectType.OFFER);
    if (this.offerEnvelope.offer.type.id === InquiryProductType.KUKE_GSP_PBG) {
      this.loadTemplatesByType(DocumentType.PBG_ATTACHMENTS, BusinessObjectType.OFFER);
    }
  }

  newDocumentButtonVisible() {
    const edgeOfferCondition = this.isClientOffer() || this.newDocumentAvailable();
    return this.appConfigService.kuke && edgeOfferCondition;
  }

  loadTemplatesByType(type: DocumentType, boType: BusinessObjectType) {
    const selectors: DictionaryBaseDto[] = [this.offerEnvelope.offer.type];
    this.templateService
      .findByType(type, boType, null, selectors, null, null, null, null, false)
      .subscribe((result) => {
        if (result !== undefined && result !== null) {
          result.forEach((r) => this.templates.push(r));
        }
      });
  }

  private loadTemplateForWording() {
    this.inquiryService.loadTemplateForWording(this.offerEnvelope.offer).subscribe((result) => {
      this.pcvTemplate = result;
    });
  }

  private loadTemplateForPolicyDraft() {
    this.inquiryService.loadTemplateForPolicyDraft(this.offerEnvelope.offer).subscribe((result) => {
      this.pDraftTemplate = result;
    });
  }

  onPrintSelected(template: TemplateSimpleDto) {
    this.routerService.toDocumentDetailsNewOperations(
      KukeRepoOperation.PROPOSALS,
      template.id,
      this.offerId,
      template.type.id
    );
  }

  generatePvcPdf() {
    this.generatePdf(this.pcvTemplate);
  }

  generateDraftPdf() {
    const template: TemplateSimpleDto = this.pDraftTemplate;
    const oid = this.offerEnvelope.offer.id;
    this.inquiryService.getOfferDocument(oid, template.name + '.' + oid + '.pdf');
  }

  private generateDraftPdfAndSendPanda() {
    this.serverErrors = null;
    this.showErrors = false;
    this.inProgress = true;

    this.inquiryService.uploadDraftPolicy(this.pDraftTemplate.id, this.offerEnvelope.offer.id).subscribe({
      next: () => this.growlService.notice('Policy draft has been sent!'),
      error: (error) => {
        this.showErrors = true;
        this.serverErrors = error;
        this.inProgress = false;
      },
      complete: () => (this.inProgress = false),
    });
  }

  private generatePdf(template: TemplateSimpleDto) {
    const oid = this.offerEnvelope.offer.id;
    this.documentService.generatePdf(template.id, oid, template.name + '.' + oid + '.pdf');
  }

  onPrintSelectedWording(template: TemplateSimpleDto) {
    this.routerService.toDocumentDetailsNewOperations(
      KukeRepoOperation.WORDINGS,
      template.id,
      this.offerId,
      template.type.id
    );
  }

  offerRefresh(func: () => Observable<PolicyInquiryOfferEnvelopeDto>, cleanOnError?: () => void) {
    this.inProgress = true;
    func().subscribe({
      next: (offer) => this.onAfterSave(offer),
      error: (error) => {
        this.handleServerError(error);
        if (cleanOnError) {
          cleanOnError();
        }
      },
      complete: () => (this.inProgress = false),
    });
  }

  copyOffer() {
    const offer = this.offerEnvelope.offer;
    this.routerService.toNewKukeInquiryOfferDetails(this.inquiryId, offer.type.id, offer.edge, offer.id);
  }

  extendValidityPeriod() {
    this.inProgress = true;
    this.serverErrors = null;
    this.showErrors = true;

    this.offerService.extendValidityPeriod(this.offerEnvelope.offer.id).subscribe({
      next: (expirationDate) => {
        this.offerEnvelope.offer.expirationDate = moment(expirationDate).toDate();
        this.showSavedMsg();
      },
      error: (error) => {
        this.handleServerError(error);
      },
      complete: () => {
        this.inProgress = false;
        this.serverErrors = null;
        this.showErrors = false;
      },
    });
  }

  extendValidityPeriodVisible() {
    const oe = this.offerEnvelope;
    if (!oe || !oe.offer || !oe.offer.id || !oe.offer.expirationDate) {
      return false;
    }
    if (!oe.offer.businessStatus || oe.offer.businessStatus.id !== PolicyInquiryOfferStatus.ACTIVE) {
      return false;
    }
    return (
      this.isNotType(InquiryProductType.KUKE_KOM_EP) &&
      this.isNotType(InquiryProductType.KUKE_GSP_PNW) &&
      this.isNotType(InquiryProductType.KUKE_GSP_PNS)
    );
  }

  private isType(typeId: InquiryProductType) {
    return isType(this.getType(), typeId);
  }

  private isNotType(typeId: InquiryProductType) {
    return isNotType(this.getType(), typeId);
  }

  private getType() {
    const offer = this.offerEnvelope ? this.offerEnvelope.offer : null;
    return offer && offer.type;
  }

  generatePdfVisible() {
    if (!this.pcvTemplate) {
      return false;
    }
    return (this.docGenAvailabe() || this.isEndOfOffer()) && this.isNotType(InquiryProductType.KUKE_GSP_PNS);
  }

  onEdit() {
    this.editionMode = true;
  }

  isEditionAvailable(): boolean {
    const oe = this.offerEnvelope;
    return !!(
      !this.editionMode &&
      oe &&
      oe.offer &&
      oe.offer.businessStatus &&
      (isStandardEditable(oe.offer.businessStatus.id) || isNarrowEditable(oe.offer.businessStatus.id, this.getType()))
    );
  }

  isAvailablePandaDraftPolicy() {
    return true;
  }

  navigateToInquiry() {
    this.routerService.toInquiryDetails(this.getInquiry());
  }

  goToOffer(offerId: number) {
    this.routerService.toKukeInquiryOfferDetails(offerId);
  }

  get inquiryName(): String {
    if (this.getInquiry() && this.getInquiry().policyInquiry) {
      if (this.getInquiry().policyInquiry.inquiryType === 'POLICY_ANNEX') {
        return this.translateService.instant('inquiry.annexName');
      } else if (this.getInquiry().policyInquiry.inquiryType === 'RENEWAL_INQUIRY') {
        return this.translateService.instant('inquiry.renewalName');
      }
    }
    return this.translateService.instant('inquiry.name');
  }

  createRightFunc(o: PolicyInquiryOfferDto): string {
    if (o) {
      return o.edge
        ? PolicyElementaryRight.POLICY_INQUIRY_EDGE_OFFER_CREATE
        : PolicyElementaryRight.POLICY_INQUIRY_CLIENT_OFFER_CREATE;
    }
    return undefined;
  }

  updateRightFunc(o: PolicyInquiryOfferDto): string {
    if (o) {
      return o.edge
        ? PolicyElementaryRight.POLICY_INQUIRY_EDGE_OFFER_UPDATE
        : PolicyElementaryRight.POLICY_INQUIRY_CLIENT_OFFER_UPDATE;
    }
    return undefined;
  }

  isAvailableNewTask() {
    const oe = this.offerEnvelope;
    return OFFER_PENDING_STATUSES.includes(oe?.offer?.businessStatus?.id);
  }

  newDocumentAvailable() {
    const oe = this.offerEnvelope;
    return OFFER_NEW_DOCUMENTS_STATUSES.includes(oe?.offer?.businessStatus?.id);
  }

  docGenAvailabe() {
    const statusId = this.offerEnvelope?.offer?.businessStatus?.id;
    return statusId === PolicyInquiryOfferStatus.ACTIVE || statusId === PolicyInquiryOfferStatus.ACCEPTED_BY_CLIENT;
  }

  private isEndOfOffer() {
    const statusId = this.offerEnvelope?.offer?.businessStatus?.id;
    return statusId === PolicyInquiryOfferStatus.REJECTED_BY_CLIENT || statusId === PolicyInquiryOfferStatus.EXPIRED;
  }

  isActiveOffer() {
    const statusId = this.offerEnvelope?.offer?.businessStatus?.id;
    return statusId === PolicyInquiryOfferStatus.ACTIVE;
  }
}
