import {BondingContractInquirySearchComponent} from './bonding-contract-inquiry-search.component';
import {BondingContractInquiryDetailsComponent} from './bonding-contract-inquiry-details.component';
import {BondingContractInquiryTechnicalDetailsComponent} from './components/bonding-contract-inquiry-technical-details.component';
import {BondingContractInquiryDetailsGuard, BondingContractInquirySearchGuard} from './_guards';

export const BondingContractInquiryRoutes = [
  {
    path: 'bonding-contract-inquiry-search',
    component: BondingContractInquirySearchComponent,
    canActivate: [BondingContractInquirySearchGuard],
  },
  {
    path: 'bonding-contract-inquiry-details/:id',
    component: BondingContractInquiryDetailsComponent,
    canActivate: [BondingContractInquiryDetailsGuard],
  },
  {
    path: 'bonding-contract-inquiry-details',
    component: BondingContractInquiryDetailsComponent,
    canActivate: [BondingContractInquiryDetailsGuard],
  },
  {
    path: 'bonding-contract-inquiry-technical-details/:inquiryVersionId',
    component: BondingContractInquiryTechnicalDetailsComponent,
    canActivate: [BondingContractInquiryDetailsGuard],
  },
];
