import {Component} from '@angular/core';
import {PolicyContractViewService} from '../../../policy/services/policy-contract-view.service';
import {ControlContainer, NgForm} from '@angular/forms';
import {CompanySimpleDto, RelatedObject} from '../../../../bonding_shared';
import {InquirySectionDirective} from './inquiry-section';

@Component({
  selector: 'transaction-parties-inquiry-section',
  templateUrl: './transaction-parties-inquiry-section.component.pug',
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}],
})
export class TransactionPartiesInquirySectionComponent extends InquirySectionDirective {
  constructor(protected policyContractViewService: PolicyContractViewService) {
    super(policyContractViewService);
    this.selectorNameList = [
      RelatedObject.PROJECT_COMPANY,
      RelatedObject.TARGET_COMPANY,
      RelatedObject.TARGET_COMPANY_OWNER,
    ];
    this.initializeSelectorEmitters(true);
  }

  onSelectProjectCompany(company: CompanySimpleDto) {
    this.inquiry.projectCompany = company;
  }

  onSelectTargetCompany(company: CompanySimpleDto) {
    this.inquiry.targetCompany = company;
  }

  onSelectTargetCompanyOwner(company: CompanySimpleDto) {
    this.inquiry.targetCompanyOwner = company;
  }
}
