import {ExternalProviderSearchComponent} from './external-provider-search.component';
import {ExternalProviderDetailsComponent} from './external-provider-details.component';
import {ExternalProviderDetailsGuard, ExternalProviderSearchGuard} from './_guards';

export const ExternalProviderRoutes = [
  {
    path: 'external-provider-search',
    component: ExternalProviderSearchComponent,
    canActivate: [ExternalProviderSearchGuard],
  },
  {
    path: 'external-provider-details',
    component: ExternalProviderDetailsComponent,
    canActivate: [ExternalProviderDetailsGuard],
  },
  {
    path: 'external-provider-details/:versionId',
    component: ExternalProviderDetailsComponent,
    canActivate: [ExternalProviderDetailsGuard],
  },
];
