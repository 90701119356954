import {Component, Input} from '@angular/core';
import {
  BusinessObjectType,
  ImportType,
  InquiryProductType,
  LimitRequestType,
  PolicyInquiryStatus,
  RepoDocumentCategory,
  RepoDocumentElementaryRight,
} from '../../../bonding_shared/model/dictionary-ids';
import {
  AppConfigService,
  BusinessPropertiesService,
  InquiryService,
  PolicyInquiryVersionDto,
  RouterService,
} from '../../../bonding_shared';
import {isType} from '../domain/offer-allowance';

@Component({
  selector: 'inquiry-new-limit-dropdown',
  templateUrl: 'inquiry-new-limit-dropdown.component.pug',
})
export class InquiryNewLimitDropdownComponent {
  readonly self = this;
  private readonly newLimitActionsOpFkFe = [
    {id: 1, name: 'inquiry.newLimitButton.singleRequestStandard'},
    {id: 2, name: 'inquiry.newLimitButton.singleRequestFalcon'},
    {id: 3, name: 'inquiry.newLimitButton.massRequest'},
    {id: 4, name: 'inquiry.newLimitButton.sendDocument'},
  ];
  private readonly newLimitActionsOther = this.newLimitActionsOpFkFe.filter((i) => i.id !== 2);

  readonly BusinessObjectType = BusinessObjectType;

  @Input() set inquiry(inq: PolicyInquiryVersionDto) {
    this._inquiry = inq;
  }

  get inquiry() {
    return this._inquiry;
  }

  private _inquiry: PolicyInquiryVersionDto;

  constructor(
    private inquiryService: InquiryService,
    private routerService: RouterService,
    public appService: AppConfigService,
    protected businessPropertiesService: BusinessPropertiesService
  ) {}

  get newLimitActions() {
    if (!this.inquiry) {
      return null;
    }

    if (
      isType(this.inquiry.productType, InquiryProductType.KUKE_KOM_OP) ||
      isType(this.inquiry.productType, InquiryProductType.KUKE_KOM_FK) ||
      isType(this.inquiry.productType, InquiryProductType.KUKE_KOM_FE)
    ) {
      return this.newLimitActionsOpFkFe;
    }
    return this.newLimitActionsOther;
  }

  onNewLimit(id: number) {
    switch (id) {
      case 1:
        this.routerService.toLimitDetailsWithParams({
          policyInquiryId: this.inquiry.policyInquiry.id,
          typeId: LimitRequestType.STANDARD,
        });
        break;
      case 2:
        this.routerService.toLimitDetailsWithParams({
          policyInquiryId: this.inquiry.policyInquiry.id,
          typeId: LimitRequestType.FALCON,
        });
        break;
      case 3:
        this.routerService.toImporterWithParams({typeId: ImportType.POLICY_INQUIRY_LIMIT_REQUESTS});
        break;
      case 4:
        this.routerService.toRepositoryDocuments(
          BusinessObjectType.POLICY_INQUIRY_VERSION,
          this.inquiry.id,
          RepoDocumentElementaryRight.REPO_DOCUMENT_READ_POLICIES,
          RepoDocumentElementaryRight.REPO_DOCUMENT_WRITE_POLICIES,
          RepoDocumentCategory.EXTERNAL
        );
        break;
      default:
        console.log('unknown action');
    }
  }

  get kuke() {
    return this.appService.kuke;
  }

  get showNewLimitButton() {
    return (
      this.inquiry &&
      this.inquiry.id &&
      this.businessPropertiesService.properties &&
      this.businessPropertiesService.properties.policyInquiryProductsForLimit.includes(this.inquiry.productType.id) &&
      (!this.kuke || PolicyInquiryStatus.PENDING_STATUSES.includes(this.inquiry.businessStatus?.id))
    );
  }
}
