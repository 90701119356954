import {ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Params} from '@angular/router';
import {
  AppConfigService,
  ExternalProviderBaseDto,
  ExternalProviderCommissionGroupDto,
  ExternalProviderVersionBaseDto,
  ExternalProviderVersionDto,
  ExternalProviderVersionService,
  GrowlService,
  RouterService,
} from '../../bonding_shared';
import {DetailsView} from '../../bonding_shared/components/details-view/details-view';
import {NgForm} from '@angular/forms';
import {
  BusinessObjectType,
  CollectionElementaryRight,
  ContactNoteElementaryRight,
  RepoDocumentElementaryRight,
  ServiceContactElementaryRight,
} from '../../bonding_shared/model/dictionary-ids';
import {ExternalProviderGuiService} from './services/external-provider-gui.service';

interface VatRateOption {
  id: number;
  value: number;
  label?: string;
  labelKey?: string;
}

@Component({
  selector: 'external-provider-details',
  templateUrl: 'external-provider-details.component.pug',
  styles: ['.half_width { width: calc(50% - 85px)}', '.full_width { width: calc(100% - 150px)}'],
})
export class ExternalProviderDetailsComponent extends DetailsView implements OnInit {
  readonly BusinessObjectType = BusinessObjectType;
  readonly ContactNoteElementaryRight = ContactNoteElementaryRight;
  readonly ServiceContactElementaryRight = ServiceContactElementaryRight;
  readonly RepoDocumentElementaryRight = RepoDocumentElementaryRight;
  readonly CollectionElementaryRight = CollectionElementaryRight;

  version: ExternalProviderVersionDto;
  versions = <ExternalProviderVersionBaseDto[]>[];
  selectedCommissionGroup: ExternalProviderCommissionGroupDto;

  get parent(): ExternalProviderBaseDto {
    return this.version.parent;
  }

  set parent(value: ExternalProviderBaseDto) {
    this.version.parent = value;
  }

  _ngForm: NgForm;

  get ngForm() {
    return this._ngForm;
  }
  @ViewChild('ngForm', {static: false})
  set ngForm(ngForm: NgForm) {
    if (ngForm) {
      this._ngForm = ngForm;
      this.form = ngForm.form;
    }
  }
  vatRateOptions = [
    {id: 1, label: '23%', value: 23},
    {id: 2, label: '0%', value: 0},
    {id: 3, labelKey: 'common.other', value: undefined},
  ];
  vatRateOption: VatRateOption;

  constructor(
    private _route: ActivatedRoute,
    public service: ExternalProviderVersionService,
    public guiService: ExternalProviderGuiService,
    public router: RouterService,
    protected growlService: GrowlService,
    private cd: ChangeDetectorRef,
    public appConfig: AppConfigService
  ) {
    super(growlService);
    this.deleteButton = undefined;
  }

  ngOnInit() {
    this._route.params.subscribe((params) => this.initializeView(params));
  }

  initializeView(params: Params) {
    const versionId = +params['versionId'];
    this.serverErrors = undefined;
    if (versionId) {
      this.getVersion(versionId);
    } else {
      this.setVersion(ExternalProviderGuiService.getEmptyVersion());
    }
  }

  onSave() {
    this.serverErrors = undefined;
    this.showErrors = false;
    if (this.formValidates()) {
      this.inProgress = true;
      this.service.save(this.version).subscribe({
        next: (version) => {
          if (version.warnings && version.warnings.length) {
            this.inProgress = false;
            this.growlService.warning(version.warnings.map((e) => e.message).join('\n'));
          } else {
            this.afterObjectSaved(this.version.id, () => this.router.toExternalProviderDetails(version.id));
          }
          this.setVersion(version);
        },
        error: (error) => {
          this.serverErrors = error;
          this.inProgress = false;
        },
      });
    }
  }

  handleButtons() {
    this.newVersionButton.hidden = !this.version.id;
    this.newVersionButton.disabled = !this.version.last;
    this.saveButton.hidden = !!this.version.id || !this.isInitialized();
    this.cancelButton.hidden = !!this.version.id;
  }

  isInitialized() {
    return this.version.status;
  }

  loadInitialVersion() {
    this.service.initialVersion(this.parent).subscribe((version) => this.setVersion(version));
  }

  onCreateNewVersion() {
    this.showErrors = false;
    this.service.newVersion<ExternalProviderVersionDto>(this.parent.id).subscribe((version) => {
      this.setVersion(version);
    });
  }

  onCancel() {
    super.onCancel(this._route);
  }

  getVersion(versionId: number) {
    this.service.getById<ExternalProviderVersionDto>(versionId).subscribe({
      next: (version) => {
        this.setVersion(version);
      },
      error: () => {
        this.objectNotFound = true;
      },
    });
  }

  setVersion(version: ExternalProviderVersionDto) {
    this.version = version;
    this.objectNotFound = false;
    this.inProgress = false;
    this.handleButtons();
    this.refreshVersions();
    this.selectedCommissionGroup = undefined;
    this.handleVatRateCombo();
    this.cd.detectChanges();
  }

  refreshVersions() {
    const index = this.versions.findIndex((version) => version.id === this.version.id);
    if (index >= 0) {
      this.versions[index] = this.version;
    } else {
      this.service.getVersionsByParentId(this.parent.id).subscribe((res) => (this.versions = res.result));
    }
  }

  printCountries(g: ExternalProviderCommissionGroupDto): string {
    return g.countries && g.countries.map((c) => c.name).join(', ');
  }

  onVatRateSelect(vatRateOption: VatRateOption) {
    this.vatRateOption = vatRateOption;
    this.version.vatRate = vatRateOption.value;
  }

  showVatInput() {
    return this.vatRateOption && this.vatRateOption.value === undefined;
  }

  private handleVatRateCombo() {
    this.vatRateOption =
      this.vatRateOptions.find((vr) => vr.value === this.version.vatRate) ||
      this.vatRateOptions.find((vr) => vr.value === undefined);
  }

  toServiceContacts() {
    this.router.toServiceContacts({boTypeId: BusinessObjectType.EXTERNAL_PROVIDER, boId: this.version.parent.id});
  }
}
