import {ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {Location} from '@angular/common';
import {ActivatedRoute, Params} from '@angular/router';
import {RouterService} from '../../bonding_shared/services/router-service';
import {TranslateService} from '@ngx-translate/core';
import {GrowlService} from '../../bonding_shared/services/growl/growl.service';
import {AbstractInvoiceDetailsComponent} from '../../bonding_shared/components/shared/abstract-invoice-details.component';
import {
  AppConfigService,
  BusinessPropertiesService,
  BusinessReportService,
  DictionaryBaseService,
  LoggedUserService,
  PropertyService,
} from '../../bonding_shared/services';
import {
  BusinessUnitDto,
  BusinessUnitIdDto,
  CompanySimpleDto,
  Criteria,
  DictionaryBaseDto,
  FINoteDto,
  FINoteNumberingBagCriteriaDto,
  FINoteNumberingBagIdDto,
  FINoteNumberingBagSimpleDto,
  InvoiceItemDto,
  InvoiceItemFINoteCriteriaDto,
  InvoiceItemInvoiceCriteriaDto,
  InvoiceItemSimpleDto,
  InvoiceNumberingBagIdDto,
  ReportDefinitionSimpleDto,
  SearchCriteria,
} from '../../bonding_shared/model';
import {ConfirmDialogComponent} from '../../bonding_shared/components/confirm-dialog';
import {LifecycleDropdownSimpleComponent} from '../../bonding_shared/components/lifecycle-dropdown';
import {FINoteGuiService} from './services/fi-note-gui.service';
import {FINoteService} from '../../bonding_shared/services/fi-note.service';
import {NgForm} from '@angular/forms';
import {DictionaryUtils} from '../../bonding_shared/utils/dictionary-utils';
import {InvoiceItemListComponent} from '../invoice-item/components/invoice-item-list.component';
import {
  AppProperty,
  FinancialDocumentCategory,
  FINoteCategory,
  InvoiceCategory,
  InvoiceStatus,
} from '../../bonding_shared/model/dictionary-ids';
import {FINoteNumberingBagService} from '../../bonding_shared/services/fi-note-numbering-bag.service';

@Component({
  selector: 'fi-note-details',
  templateUrl: 'fi-note-details.component.html',
})
export class FINoteDetailsComponent extends AbstractInvoiceDetailsComponent implements OnInit {
  @ViewChild('removeConfirm')
  protected removeConfirmDialog: ConfirmDialogComponent;
  @ViewChild(LifecycleDropdownSimpleComponent)
  protected lifecycleDropdownSimpleComponent: LifecycleDropdownSimpleComponent;
  @ViewChild(InvoiceItemListComponent)
  protected invoiceItemListComponent: InvoiceItemListComponent;
  protected itemCategoryId: FinancialDocumentCategory;
  protected invoiceCriteria: InvoiceItemInvoiceCriteriaDto;
  protected fiNoteCriteria: InvoiceItemFINoteCriteriaDto;
  fiNote: FINoteDto;
  protected fiNoteNumberingBags: FINoteNumberingBagSimpleDto[];
  protected reportDefinitions: ReportDefinitionSimpleDto[];

  constructor(
    public fiNoteService: FINoteService,
    private fiNoteGuiService: FINoteGuiService,
    private fiNoteNumberingBagService: FINoteNumberingBagService,
    protected route: ActivatedRoute,
    public router: RouterService,
    protected propertyService: PropertyService,
    protected businessPropertiesService: BusinessPropertiesService,
    protected translateService: TranslateService,
    protected growlService: GrowlService,
    protected dictionaryBaseService: DictionaryBaseService,
    private location: Location,
    private cd: ChangeDetectorRef,
    protected loggedUserService: LoggedUserService,
    protected businessReportService: BusinessReportService,
    private appService: AppConfigService
  ) {
    super(
      propertyService,
      businessPropertiesService,
      translateService,
      growlService,
      dictionaryBaseService,
      route,
      loggedUserService,
      businessReportService
    );
    this.selectorNameList = ['Client'];
    this.initializeSelectorEmitters(true);
    this.invoiceCriteria = <InvoiceItemInvoiceCriteriaDto>{
      inInvoice: true,
    };
  }

  ngOnInit() {
    super.ngOnInit();
    const sc = <SearchCriteria<FINoteNumberingBagCriteriaDto>>{};
    sc.criteria = <FINoteNumberingBagCriteriaDto>{};
    this.fiNoteNumberingBagService.search(sc).subscribe(
      (sr) => {
        this.fiNoteNumberingBags = sr.result;
      },
      (error) => {
        console.log('Error during fiNoteNumberingBagService.search for ' + sc);
        this.serverErrors = error;
      }
    );
  }

  initializeView(params: Params, force?: boolean) {
    super.initializeView(params);
    if (this.categoryId === FINoteCategory.INTERMEDIARY_INVOICE) {
      this.itemCategoryId = InvoiceCategory.BROKER;
    } else {
      this.itemCategoryId = InvoiceCategory.INVOICE;
    }

    const id = +params['id'];
    if (this.fiNote && id === this.fiNote.id && !force) {
      return;
    }
    if (id > 0) {
      this.loadFINote(id);
    } else {
      this.createFINote();
    }
  }

  @ViewChild(NgForm)
  set parentForm(form: NgForm) {
    if (form) {
      this.form = form.form;
    }
  }

  onSave() {
    delete this.serverErrors;
    this.showErrors = true;
    if (!this.form.valid) {
      console.log('save FINote item validation error');
      return;
    }

    return new Promise((resolve, reject) => {
      if (DictionaryUtils.inArr(this.fiNote.status, this.REMOVE_STATUSES)) {
        this.removeConfirmDialog.openAndExecuteOnConfirm(
          'fiNote.details.removeConfirmation',
          'fiNote.details.removeConfirmationMessage',
          this.save.bind(this),
          reject
        );
      } else {
        this.save();
      }
    });
  }

  protected propertiesLoaded() {
    this.fillDefaults();
  }

  protected onCompanySelect(company: CompanySimpleDto) {
    this.fiNote.client = company;
  }

  private createFINote() {
    const fiNote = <FINoteDto>{
      category: <DictionaryBaseDto>{id: this.categoryId},
      status: <DictionaryBaseDto>{id: InvoiceStatus.ISSUED},
      invoiceItems: [],
    };
    this.setFINote(fiNote);
    this.fillDefaults();
  }

  private fillDefaults() {
    if (this.appProperties && this.businessProperties && this.fiNote && !this.fiNote.id) {
      const buId = this.appProperties[AppProperty.INVOICE_BROKER_NOTE_ASSIGN_TO_BU_ID];

      if (buId) {
        this.fiNote.businessUnit = <BusinessUnitIdDto>{id: buId};
      }
      this.fiNote.currency = this.businessProperties.systemCurrency;
    }
  }

  private loadFINote(id) {
    console.log('Loading FINote id' + id);
    this.fiNoteService.getFiNote(id).subscribe(
      (fiNote) => {
        this.setFINote(fiNote);
      },
      (error) => {
        console.log('Error during fiNoteService.getFiNote for id = ' + id);
        this.serverErrors = error;
      }
    );
  }

  private setFINote(fiNote: FINoteDto) {
    this.fiNoteCriteria = this.createFiNoteCriteria(fiNote);
    this.fiNote = fiNote;
    this.loadReportDefinitions();
    this.cd.detectChanges();
    console.log('FINote id ' + fiNote.id + ' loaded');
  }

  private loadReportDefinitions() {
    if (!this.fiNote || !this.fiNote.id) {
      this.reportDefinitions = [];
      return;
    }
    this.fiNoteService.getReportDefinitions(this.fiNote.id).subscribe(
      (reportDefinitions) => {
        this.reportDefinitions = reportDefinitions;
      },
      (error) => {
        console.log('Error during fiNoteService.getReportDefinitions');
        this.serverErrors = error;
      }
    );
  }

  private createFiNoteCriteria(fiNote: FINoteDto): InvoiceItemFINoteCriteriaDto {
    const criteria = <InvoiceItemFINoteCriteriaDto>{
      fiNoteCategoryId: this.categoryId,
      inFiNote: false,
    };
    if (fiNote.id) {
      criteria.fiNoteIds = <Criteria<number[]>>{
        negation: true,
        value: [fiNote.id],
      };
    }
    return criteria;
  }

  protected onBusinessUnitChange(businessUnit: BusinessUnitDto) {
    this.fiNote.numberingBag = <InvoiceNumberingBagIdDto>{};
  }

  protected onAddInvoiceItem(invoiceItem: InvoiceItemSimpleDto) {
    this.addInvoiceItem(invoiceItem);
    this.invoiceItemListComponent.search();
  }

  protected invoiceAll(invoiceItems: InvoiceItemSimpleDto[]) {
    for (const invoiceItem of invoiceItems) {
      this.addInvoiceItem(invoiceItem);
    }
    this.invoiceItemListComponent.search();
  }

  private addInvoiceItem(invoiceItem: InvoiceItemSimpleDto) {
    if (this.fiNote.invoiceItems.length === 0) {
      this.fiNote.currency = invoiceItem.currency;
    } else if (!DictionaryUtils.equalsDict(this.fiNote.currency, invoiceItem.currency)) {
      this.serverErrors = this.translateService.instant('fiNote.details.differentCurrencyError');
      return;
    }
    this.fiNote.invoiceItems.push(invoiceItem);
  }

  protected onDeleteInvoiceItem(invoiceItem: InvoiceItemDto) {
    const itemIdx = this.fiNote.invoiceItems.indexOf(invoiceItem);
    if (itemIdx < 0) {
      console.log('Invoice item was not found in invoice position!');
      return;
    }
    this.fiNote.invoiceItems.splice(itemIdx, 1);
    this.invoiceItemListComponent.search();
  }

  protected goToBusinessObject(item: InvoiceItemDto) {
    if (item.businessObject.relatedTo.id && item.businessObject.relatedToId) {
      this.router.goToBusinessObject(item.businessObject.relatedTo.id, item.businessObject.relatedToId);
    } else {
      this.growlService.error('Bad link - business object type or id is missing!');
    }
  }

  private save() {
    this.serverErrors = '';

    if (this.fiNote.numberingBag) {
      this.fiNote.numberingBag = <FINoteNumberingBagIdDto>{
        id: this.fiNote.numberingBag.id,
        pattern: this.fiNote.numberingBag.pattern,
      };
    }

    if (this.fiNote.id > 0) {
      const toBeRemoved = DictionaryUtils.inArr(this.fiNote.status, this.REMOVE_STATUSES);
      this.fiNoteService.update(this.fiNote).subscribe(
        (fiNote) => {
          if (toBeRemoved) {
            this.growlService.notice('invoice.details.removed');
            window.history.back();
          } else {
            this.setFINote(fiNote);
            this.invoiceItemListComponent.search();
            this.showSavedMsg();
          }
        },
        (error) => (this.serverErrors = error)
      );
    } else {
      this.fiNoteService.create(this.fiNote).subscribe(
        (fiNote) => {
          this.setFINote(fiNote);
          this.invoiceItemListComponent.search();
          this.showSavedMsg();
          this.router.toFINoteDetails(this.categoryId, this.fiNote.id);
        },
        (error) => (this.serverErrors = error)
      );
    }
  }

  public get kuke(): boolean {
    return this.appService.kuke;
  }

  public getLabelKeyForCreateInvoice(): string {
    if (this.isIntermediaryInvoiceAndKuke()) {
      return 'fiNote.details.registrationDate';
    } else {
      return 'fiNote.details.creationDate';
    }
  }

  public isIntermediaryInvoiceAndKuke() {
    return this.categoryId === FINoteCategory.INTERMEDIARY_INVOICE && this.kuke;
  }
}
