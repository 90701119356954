import {ServiceContactsComponent} from './service-contacts.component';
import {ServiceContactsGuard} from './_guards/service-contacts.guard';

export const ServiceContactsRoutes = [
  {
    path: 'service-contacts',
    component: ServiceContactsComponent,
    canActivate: [ServiceContactsGuard],
  },
];
