import {CollectionSearchComponent} from './collection-search.component';
import {CollectionDetailsComponent} from './collection-details.component';
import {CollectionPaymentsComponent} from './collection-payments.component';
import {CollectionPaymentScheduleComponent} from './collection-payment-schedule.component';
import {CollectionExternalProviderRelationsComponent} from './collection-external-provider-relations.component';
import {CollectionEstimatedCostsComponent} from './collection-estimated-costs.component';
import {CollectionAccountedCostsComponent} from './collection-accounted-costs.component';
import {CollectionRequestsComponent} from './collection-requests.component';
import {CollectionHistoryComponent} from './collection-history.component';
import {CollectionCostsInvoicingComponent} from './collection-costs-invoicing.component';
import {CollectionRelatedCasesComponent} from './collection-related-cases.component';
import {
  CollectionCostsGuard,
  CollectionDetailsGuard,
  CollectionExternalProviderRelationsGuard,
  CollectionHistoryGuard,
  CollectionPaymentScheduleGuard,
  CollectionPaymentsGuard,
  CollectionRelatedCasesGuard,
  CollectionRequestsGuard,
  CollectionSearchGuard,
} from './_guards';

export const CollectionRoutes = [
  {
    path: 'collection-search',
    component: CollectionSearchComponent,
    canActivate: [CollectionSearchGuard],
  },
  {
    path: 'collection-details',
    component: CollectionDetailsComponent,
    canActivate: [CollectionDetailsGuard],
  },
  {
    path: 'collection-details/:versionId',
    component: CollectionDetailsComponent,
    canActivate: [CollectionDetailsGuard],
  },
  {
    path: 'collection-related-cases/:versionId',
    component: CollectionRelatedCasesComponent,
    canActivate: [CollectionRelatedCasesGuard],
  },
  {
    path: 'collection-payments/:allocation/:parentId',
    component: CollectionPaymentsComponent,
    canActivate: [CollectionPaymentsGuard],
  },
  {
    path: 'collection-payments/:parentId',
    component: CollectionPaymentsComponent,
    canActivate: [CollectionPaymentsGuard],
  },
  {
    path: 'collection-payment-schedule/:parentId',
    component: CollectionPaymentScheduleComponent,
    canActivate: [CollectionPaymentScheduleGuard],
  },
  {
    path: 'collection-external-provider-relations/:parentId',
    component: CollectionExternalProviderRelationsComponent,
    canActivate: [CollectionExternalProviderRelationsGuard],
  },
  {
    path: 'collection-estimated-costs/:parentId',
    component: CollectionEstimatedCostsComponent,
    canActivate: [CollectionCostsGuard],
  },
  {
    path: 'collection-accounted-costs/:parentId',
    component: CollectionAccountedCostsComponent,
    canActivate: [CollectionCostsGuard],
  },
  {
    path: 'collection-costs-invoicing/:costsId',
    component: CollectionCostsInvoicingComponent,
    canActivate: [CollectionCostsGuard],
  },
  {
    path: 'collection-requests',
    component: CollectionRequestsComponent,
    canActivate: [CollectionRequestsGuard],
  },
  {
    path: 'collection-requests/:requestId',
    component: CollectionRequestsComponent,
    canActivate: [CollectionRequestsGuard],
  },
  {
    path: 'collection-history',
    component: CollectionHistoryComponent,
    canActivate: [CollectionHistoryGuard],
  },
];
