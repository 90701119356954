import {Component, Input} from '@angular/core';
import {
  ComboItem,
  DictionaryService,
  LimitRequestCriteriaDto,
  LimitService,
  OfferService,
  PolicyInquiryOfferEnvelopeDto,
  SearchCriteria,
  StateTransitionDto,
} from '../../../bonding_shared';
import {
  DictionaryPropertyType,
  InquiryProductType,
  isOfferRejectionState,
  PolicyInquiryOfferStatus,
  UserGroup,
  UserRole,
} from '../../../bonding_shared/model/dictionary-ids';
import {AbstractControl, UntypedFormControl, UntypedFormGroup, ValidationErrors, Validators} from '@angular/forms';
import {isType} from '../domain/offer-allowance';
import {Assigned} from '../../../bonding_shared/services/inquiry-abstract.service';

@Component({
  selector: 'kuke-offer-lifecycle',
  templateUrl: 'kuke-offer-lifecycle.component.pug',
})
export class KukeOfferLifecycleComponent {
  @Input()
  set offerEnvelope(e: PolicyInquiryOfferEnvelopeDto) {
    this._offerEnvelope = e;
  }

  @Input()
  inProgress = false;
  @Input() saveFunc: Function;
  @Input() isClientOffer: boolean;

  public rejectionForm: UntypedFormGroup;
  public assingOptionsForm: UntypedFormControl;
  showRejectionErrors = false;
  public rejectionTypeControl: UntypedFormControl;
  offerRejectionReasonTextTypeIds: number[] = null;
  private notAllLimitsDone: boolean | null = null;
  private _offerEnvelope;

  readonly assingOptions = [
    new ComboItem('', 'Samodzielna'),
    new ComboItem('Role_' + UserRole.DSP_MASTER, 'Dyrektor DSP'),
    new ComboItem('Role_' + UserRole.RBS_DIRECTOR, 'Dyrektor RBS'),
    new ComboItem('Group_' + UserGroup.PUW1, 'DOK'),
    new ComboItem('Role_' + UserRole.DSP_MASTER + ',' + 'Group_' + UserGroup.PUW1, 'DOK i Dyrektor DSP'),
    new ComboItem('Role_' + UserRole.RBS_DIRECTOR + ',' + 'Group_' + UserGroup.PUW1, 'DOK i Dyrektor RBS'),
  ];

  get offerEnvelope() {
    return this._offerEnvelope;
  }

  constructor(
    private dictionaryService: DictionaryService,
    private limitService: LimitService,
    public offerService: OfferService
  ) {
    this.rejectionTypeControl = new UntypedFormControl({value: '', disabled: false}, Validators.required);
    this.rejectionForm = new UntypedFormGroup({
      type: this.rejectionTypeControl,
      comment: new UntypedFormControl({value: '', disabled: false}, (control) =>
        this.requiredRejectionComment(control)
      ),
    });

    this.assingOptionsForm = new UntypedFormControl({value: this.assingOptions[0].value, disabled: false});

    this.dictionaryService.getDictionary('OfferRejectionReasonType').subscribe((d) => {
      this.offerRejectionReasonTextTypeIds = d
        .filter(
          (df) => df.properties[DictionaryPropertyType.POLICY_INQUIRY_OFFER_REJECTION_REASON_TEXT_REQUIRED] === 'TRUE'
        )
        .map((df) => df.id);
    });
  }

  confirmationValidInput(): (transition: StateTransitionDto) => () => boolean {
    return (t) => {
      if (this.isShowRejectionConfirmation(t)) {
        return () => this.validRejectionInput();
      }
      return () => true;
    };
  }

  isShowRejectionConfirmation(transition: StateTransitionDto): boolean {
    if (!transition) {
      return false;
    }
    return this.isClientOffer && isOfferRejectionState(transition.newStatus.id);
  }

  validRejectionInput(): boolean {
    this.rejectionForm.controls['comment'].updateValueAndValidity();
    const valid = this.rejectionForm.valid;
    this.showRejectionErrors = !valid;
    return valid;
  }

  isShowStateConfirmation(): (transition: StateTransitionDto) => boolean {
    return (t) =>
      this.isShowRejectionConfirmation(t) || this.isShowAcceptanceConfirmation(t) || this.isShowRegisterConfirmation(t);
  }

  isShowAcceptanceConfirmation(transition: StateTransitionDto): boolean {
    if (!transition) {
      return false;
    }

    return (
      transition.newStatus.id === PolicyInquiryOfferStatus.ACCEPTED_BY_CLIENT &&
      this.isType(InquiryProductType.KUKE_KOM_EP) &&
      this.notAllLimitsDone === true
    );
  }

  isShowRegisterConfirmation(transition: StateTransitionDto): boolean {
    if (!transition) {
      return false;
    }

    return transition.newStatus.id === PolicyInquiryOfferStatus.ACTIVE && this.offerEnvelope?.offer?.edge;
  }

  rejectionTextRequired(): boolean {
    const type = this.rejectionTypeControl.value;
    if (type && type.id && this.offerRejectionReasonTextTypeIds) {
      return this.offerRejectionReasonTextTypeIds.indexOf(type.id) > -1;
    }
    return false;
  }

  requiredRejectionComment(control: AbstractControl): ValidationErrors | null {
    if (this.rejectionTextRequired()) {
      return Validators.required(control);
    }
    return null;
  }

  private isType(typeId: InquiryProductType) {
    return isType(this.getType(), typeId);
  }

  private getType() {
    const offer = this.offerEnvelope ? this.offerEnvelope.offer : null;
    return offer && offer.type;
  }

  public initNotAllLimitsDone(editionMode: boolean, policyInquiryNumber) {
    if (!editionMode && this.isType(InquiryProductType.KUKE_KOM_EP)) {
      this.notAllLimitsDone = true;

      const limitSearchCriteria = <LimitRequestCriteriaDto>{};
      limitSearchCriteria.pending = true;
      limitSearchCriteria.policyInquiryNumber = policyInquiryNumber;

      const searchCriteria = <SearchCriteria<LimitRequestCriteriaDto>>{};
      searchCriteria.criteria = limitSearchCriteria;
      searchCriteria.countOnly = true;

      this.limitService.searchByCriteria(searchCriteria, null).subscribe(
        (limitResult) => (this.notAllLimitsDone = limitResult.size > 0),
        (error) => console.error('limit not done search error', error)
      );
    }
  }

  getRegisterAssingOptions(): Assigned {
    const v = this.assingOptionsForm.value as string;
    if (v === '') {
      return null;
    }
    return v.split(',').reduce(
      (acc, val) => {
        const typeAndVal = val.split('_');
        if (typeAndVal[0] === 'Group') {
          acc.group = +typeAndVal[1];
        }
        if (typeAndVal[0] === 'Role') {
          acc.role = +typeAndVal[1];
        }
        return acc;
      },
      {role: null, group: null}
    );
  }

  public customLifecycleConfirmationTextKey(): (transition: StateTransitionDto) => string {
    return (transition: StateTransitionDto) => {
      if (this.isShowRegisterConfirmation(transition)) {
        return ' ';
      }
      return null;
    };
  }

  public customTitleConfirmationTextKey(): (transition: StateTransitionDto) => string {
    return (transition: StateTransitionDto) => {
      if (this.isShowRegisterConfirmation(transition)) {
        return 'Wybierz tryb akceptacji';
      }
      return null;
    };
  }
}
