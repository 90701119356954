import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Params} from '@angular/router';
import {
  CollectionInvoiceDto,
  CollectionPaymentDto,
  CollectionPaymentsDto,
  CollectionPaymentsService,
  CollectionRawAggregatePaymentDto,
  CollectionVersionService,
  DictionaryBaseDto,
  DictionaryBaseService,
  FormDialogComponent,
  GrowlService,
  LoggedUserService,
  RouterService,
} from '../../bonding_shared';
import {CollectionAuditedViewAbstract} from './collection-audited-view-abstract';
import {
  CollectionElementaryRight,
  CollectionPaymentAllocation,
  CollectionPaymentMainDebtType,
  CollectionType,
  Currency,
} from '../../bonding_shared/model/dictionary-ids';
import {DateUtils} from '../../bonding_shared/utils/date-utils';
import {CollectionGuiService} from './services/collection-gui.service';
import {NumberUtils} from '../../bonding_shared/utils/number-utils';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'collection-payments',
  templateUrl: 'collection-payments.component.pug',
})
export class CollectionPaymentsComponent
  extends CollectionAuditedViewAbstract<CollectionPaymentsDto>
  implements OnInit
{
  @ViewChild('totalRepaymentDialog', {static: true}) totalRepaymentDialog: FormDialogComponent;
  @ViewChild('partialRepaymentWithSelectionDialog', {static: true})
  partialRepaymentWithSelectionDialog: FormDialogComponent;
  @ViewChild('partialRepaymentWithoutSelectionDialog', {static: true})
  partialRepaymentWithoutSelectionDialog: FormDialogComponent;
  @ViewChild('correctionDialog', {static: true}) correctionDialog: FormDialogComponent;

  readonly CollectionType = CollectionType;
  readonly CollectionPaymentAllocation = CollectionPaymentAllocation;
  readonly CollectionElementaryRight = CollectionElementaryRight;

  dialogPaymentDate: Date;
  dialogPaymentInsurerDate: Date;
  dialogPaymentType: DictionaryBaseDto;
  dialogExchangeRate: number;
  dialogEquivalentAmount: number;
  partialRepaymentAmount: number;
  partialRepaymentAmountMax: number;
  partialRepaymentInput: PaymentInvoice[];
  partialRepaymentSelected: PaymentInvoice[] = [];
  allocation: 'INSURER' | 'INSURED' | 'GENERAL';
  dialogShowErrors = false;
  recoveryBondingSummary: RecoveryBondingSummary[] = [];

  constructor(
    route: ActivatedRoute,
    versionService: CollectionVersionService,
    parentService: CollectionPaymentsService,
    router: RouterService,
    translateService: TranslateService,
    growlService: GrowlService,
    private dictService: DictionaryBaseService,
    loggedUserService: LoggedUserService
  ) {
    super(route, versionService, parentService, router, translateService, growlService, loggedUserService);
  }

  initializeView(params: Params) {
    super.initializeView(params);
    this.allocation = (params['allocation'] && params['allocation'].toUpperCase()) || 'GENERAL';
  }

  extraInitialization() {
    super.extraInitialization();
    this.dictService
      .getDictionaryEntry('CollectionPaymentMainDebtType', CollectionPaymentMainDebtType.PAYMENT)
      .subscribe((dict) => (this.dialogPaymentType = dict));
  }

  openTotalRepaymentDialog() {
    this.dialogShowErrors = false;
    this.dialogExchangeRate = undefined;
    this.dialogEquivalentAmount = undefined;
    this.dialogPaymentDate = undefined;
    this.dialogPaymentInsurerDate = undefined;
    this.totalRepaymentDialog.open('collection.payments.totalRepayment').then((answer) => {
      if (answer) {
        this.createPayments(this.getUnpaidInvoices());
        this.updateEquivalentAmountAndDate();
        this.addNewRawPayments(
          this.getTotalBalance(this.current.equivalentCurrency),
          this.current.equivalentCurrency,
          this.dialogPaymentDate,
          this.dialogPaymentInsurerDate,
          this.dialogEquivalentAmount,
          this.current.equivalentCurrency,
          this.dialogExchangeRate,
          this.dialogPaymentType
        );
      }
    });
  }

  createPayments(collectionInvoices: (CollectionInvoiceDto | PaymentInvoice)[]) {
    this.current.mainDebt.push(
      ...collectionInvoices.map(
        (i) =>
          <CollectionPaymentDto>{
            id: undefined,
            amountGross: NumberUtils.roundMoney(i['currentPaid'] || this.getOpenAmountGross(i) - this.getPaidAmount(i)),
            currency: i.currency,
            dateOfPayment: this.dialogPaymentDate,
            dateOfPaymentInsurer: this.dialogPaymentInsurerDate,
            registrationDate: new Date(),
            invoice: i,
            type: this.dialogPaymentType,
            exchangeRate: this.dialogExchangeRate,
            equivalentAmount: null,
            allocation: <DictionaryBaseDto>{id: CollectionPaymentAllocation[this.allocation]},
          }
      )
    );
    this.newVersion = true;
  }

  openPartialRepaymentDialog(currency: DictionaryBaseDto, withSelection: boolean) {
    this.dialogShowErrors = false;
    this.partialRepaymentAmount = undefined;
    this.partialRepaymentAmountMax = this.getTotalBalance(currency);
    this.partialRepaymentSelected = [];
    this.partialRepaymentInput = withSelection ? <PaymentInvoice[]>this.getUnpaidInvoices(currency) : undefined;
    const dialog = withSelection
      ? this.partialRepaymentWithSelectionDialog
      : this.partialRepaymentWithoutSelectionDialog;
    this.dialogExchangeRate = undefined;
    this.dialogEquivalentAmount = undefined;
    this.dialogPaymentDate = undefined;
    this.dialogPaymentInsurerDate = undefined;
    dialog.open('collection.payments.partialRepayment').then((answer) => {
      if (answer) {
        this.createPayments(withSelection ? this.partialRepaymentSelected : this.autoSelectInvoices(currency));
        this.updateEquivalentAmountAndDate();
        this.addNewRawPayments(
          NumberUtils.roundMoney(this.partialRepaymentAmount),
          currency,
          this.dialogPaymentDate,
          this.dialogPaymentInsurerDate,
          this.dialogEquivalentAmount,
          this.current.equivalentCurrency,
          this.dialogExchangeRate,
          this.dialogPaymentType
        );
      }
    });
  }

  openCorrectionDialog() {
    this.dialogShowErrors = false;
    this.dialogExchangeRate = undefined;
    this.dialogEquivalentAmount = undefined;
    this.dialogPaymentDate = undefined;
    this.dialogPaymentInsurerDate = undefined;
    this.correctionDialog.open('collection.payments.correction').then((answer) => {
      if (answer) {
        this.updateEquivalentAmountAndDate();
        this.addNewRawPayments(
          NumberUtils.roundMoney(this.dialogEquivalentAmount),
          this.current.equivalentCurrency,
          this.dialogPaymentDate,
          this.dialogPaymentInsurerDate,
          NumberUtils.roundMoney(this.dialogEquivalentAmount),
          this.current.equivalentCurrency,
          this.dialogExchangeRate,
          undefined
        );
      }
    });
  }

  updateEquivalentAmountAndDate() {
    this.current.equivalentAmount = NumberUtils.roundMoney(
      (this.current.equivalentAmount || 0.0) + (this.dialogEquivalentAmount || 0.0)
    );
    this.current.equivalentLastPaymentAmount = NumberUtils.roundMoney(this.dialogEquivalentAmount);
    this.current.equivalentLastPaymentDate = this.dialogPaymentDate;
    this.newVersion = true;
  }

  getPartialPaymentRest(): number {
    return NumberUtils.roundMoney(
      this.partialRepaymentAmount -
        this.partialRepaymentSelected.map((i) => i.currentPaid || 0.0).reduce((a, b) => a + b, 0.0)
    );
  }

  partialPaymentSelect(i: PaymentInvoice) {
    i.currentPaid = this.getBalance(i, this.getPartialPaymentRest());
  }

  getBalance(i: CollectionInvoiceDto, threshold: number): number {
    const invoiceBalance = NumberUtils.roundMoney(this.getOpenAmountGross(i) - this.getPaidAmount(i));
    return threshold > invoiceBalance ? invoiceBalance : threshold;
  }

  autoSelectInvoices(currency: DictionaryBaseDto): PaymentInvoice[] {
    const result = [];
    let amount = this.partialRepaymentAmount;
    const oldestFirst = this.getUnpaidInvoices(currency).sort(DateUtils.sorter('issueDate'));
    for (const i of oldestFirst) {
      const b = this.getBalance(i, amount);
      if (b === 0) {
        break;
      }
      i.currentPaid = b;
      result.push(i);
      amount -= b;
    }
    return result;
  }

  getUnpaidInvoices(currency?: DictionaryBaseDto): PaymentInvoice[] {
    return this.getInvoices(currency)
      .filter((x) => this.getOpenAmountGross(x) > this.getPaidAmount(x) && !x.inRecovery)
      .map((x) => this.toPaymentInvoice(x));
  }

  private toPaymentInvoice(i: CollectionInvoiceDto): PaymentInvoice {
    const res = <PaymentInvoice>i;
    res.currentBalance = this.getOpenAmountGross(i) - this.getPaidAmount(i);
    return res;
  }

  getOpenAmountGross(i: CollectionInvoiceDto) {
    switch (this.allocation) {
      case CollectionPaymentAllocation[CollectionPaymentAllocation.INSURER]:
        return i.insurersOpenAmountGross;
      case CollectionPaymentAllocation[CollectionPaymentAllocation.INSURED]:
        return i.insuredsOpenAmountGross;
      default:
        return i.openAmountGross;
    }
  }

  // override to subtract current payments
  getTotalBalance(currency: DictionaryBaseDto): number {
    return NumberUtils.roundMoney(
      this.getInvoices(currency)
        .map((x) => this.getOpenAmountGross(x))
        .reduce((a1, a2) => a1 + a2, 0.0) - this.sumPayments((x) => x.currency && x.currency.id === currency.id)
    );
  }

  getPaidAmount(i: CollectionInvoiceDto): number {
    return this.sumPayments((x) => x.invoice && x.invoice.id === i.id);
  }

  private sumPayments(f: (x: CollectionPaymentDto) => boolean): number {
    const value = this.current.mainDebt
      .filter((x) => f(x))
      .filter((x) => this.allocation === CollectionPaymentAllocation[x.allocation.id])
      .map((x) => x.amountGross)
      .reduce((a1, a2) => a1 + a2, 0.0); // possible float number approximation error
    return NumberUtils.roundMoney(value);
  }

  protected getEmpty(): CollectionPaymentsDto {
    return <CollectionPaymentsDto>{
      mainDebt: [],
      other: [],
      equivalentAmount: 0.0,
      equivalentCurrency: <DictionaryBaseDto>{id: Currency.PLN},
    };
  }

  protected handleSaved(saved: CollectionPaymentsDto): void {
    super.handleSaved(saved);
    this.loadLastCollectionVersion();
  }

  paymentEditable(p: CollectionPaymentDto): boolean {
    return !(this.portal && p.id);
  }

  onDialogFormValidates(isValid: boolean) {
    this.dialogShowErrors = !isValid;
  }

  onAddOtherPayment(payment: CollectionPaymentDto) {
    payment.currency = this.version.currency;
    payment.allocation = <DictionaryBaseDto>{id: CollectionPaymentAllocation[this.allocation]};
  }

  getCollectionBalanceMode(): string {
    return CollectionGuiService.getCollectionBalanceMode(this.version);
  }

  isCreditInsurance() {
    return CollectionGuiService.isCreditInsurance(this.version.parent);
  }

  hasRightToEditSimple() {
    return this.loggedUserService.hasRight(CollectionElementaryRight.COLLECTION_PAYMENTS_CREATE_UPDATE);
  }

  hasRightToEditFull() {
    return this.loggedUserService.hasRight(CollectionElementaryRight.COLLECTION_PAYMENTS_CREATE_UPDATE_FULL);
  }

  get isRecoveryAndAllocationInsurer(): boolean {
    return (
      this.version &&
      CollectionGuiService.isRecoveryCreditInsurance(this.version.parent) &&
      this.allocation === CollectionPaymentAllocation[CollectionPaymentAllocation.INSURER]
    );
  }

  get isRecoveryBonding(): boolean {
    return this.version && CollectionGuiService.isRecoveryBonding(this.version.parent);
  }

  refreshRecoveryBondingSummary() {
    const indemnificationVersion = this.version?.parent?.claimIndemnificationVersion;
    if (!indemnificationVersion) {
      this.recoveryBondingSummary = [];
      return;
    }
    const paymentAmount = indemnificationVersion.paymentAmount;
    const paidAmount = this.current?.mainDebt?.map((payment) => payment.amountGross)?.reduce((a, b) => a + b, 0) ?? 0;
    const lastPayment = !!this.current.mainDebt
      ? this.current.mainDebt.reduce((a, b) => (a.dateOfPayment > b.dateOfPayment ? a : b))
      : null;
    this.recoveryBondingSummary = [
      {
        currency: indemnificationVersion.parent.calculation.currency.code,
        total: indemnificationVersion.paymentAmount,
        lastPaymentAmount: lastPayment?.amountGross,
        lastPaymentDate: lastPayment?.dateOfPayment,
        paid: paidAmount,
        balance: paymentAmount - paidAmount,
      },
    ];
  }

  onAddRecoveryBondingPayment(payment: CollectionPaymentDto) {
    payment.indemnification = this.version.parent.claimIndemnificationVersion;
    payment.currency = this.version.parent.claimIndemnificationVersion.parent.calculation.currency;
    payment.allocation = <DictionaryBaseDto>{id: CollectionPaymentAllocation.GENERAL};
  }

  addNewRawPayments(
    amount: number,
    amountCurrency: DictionaryBaseDto,
    dateOfPayment: Date,
    dateOfPaymentInsurer: Date,
    equivalentAmount: number,
    equivalentCurrency: DictionaryBaseDto,
    exchangeRate: number,
    paymentType: DictionaryBaseDto
  ) {
    if (!this.isRecoveryAndAllocationInsurer) {
      return;
    } else if (!this.current.rawAggregatePayments) {
      this.current.rawAggregatePayments = [];
    }
    this.current.rawAggregatePayments.push(<CollectionRawAggregatePaymentDto>{
      id: undefined,
      amount: NumberUtils.roundMoney(amount),
      amountCurrency: amountCurrency,
      dateOfPayment: dateOfPayment,
      dateOfPaymentInsurer: dateOfPaymentInsurer,
      equivalentAmount: NumberUtils.roundMoney(equivalentAmount),
      equivalentCurrency: equivalentCurrency,
      exchangeRate: exchangeRate,
      type: paymentType,
    });
  }

  onLoad() {
    super.onLoad();
    if (this.isRecoveryBonding) {
      this.refreshRecoveryBondingSummary();
    }
  }

  onVersionLoad() {
    if (this.isRecoveryBonding) {
      this.refreshRecoveryBondingSummary();
    }
  }

  get mainDebtLabel(): string {
    if (this.isCreditInsurance()) {
      return this.translateService.instant('collection.payments.mainDebtInCredit');
    } else {
      return this.translateService.instant('collection.payments.mainDebt');
    }
  }
}
type PaymentInvoice = CollectionInvoiceDto & {currentBalance: number; currentPaid: number};

interface RecoveryBondingSummary {
  currency: string;
  total: number;
  lastPaymentAmount: number;
  lastPaymentDate: Date;
  paid: number;
  balance: number;
}
