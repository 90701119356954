import {Component, Input, OnInit} from '@angular/core';
import {DictionaryBaseDto, LimitGroupAssignmentRuleDto} from '../../bonding_shared/model';
import {GroupAssignmentRuleService} from '../../bonding_shared/services/group-assignment-rule.service';
import {ControlContainer, UntypedFormGroup, NgForm} from '@angular/forms';
import {DictionaryService} from '../../bonding_shared/services';
import {LimitRequestType} from '../../bonding_shared/model/dictionary-ids';

@Component({
  selector: 'limit-assignment-criteria',
  templateUrl: 'limit-assignment-criteria.component.pug',
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}],
})
export class LimitAssignmentCriteriaComponent implements OnInit {
  @Input() rule: LimitGroupAssignmentRuleDto;
  @Input() dataForm: UntypedFormGroup;

  naceCodes: DictionaryBaseDto[];
  limitRequestTypes: DictionaryBaseDto[];

  constructor(private rulesService: GroupAssignmentRuleService, private dictionaryService: DictionaryService) {}

  ngOnInit(): void {
    this.rulesService.getMainNaceCodes().subscribe((res) => (this.naceCodes = res));
    this.dictionaryService
      .getDictionary('LimitRequestType')
      .subscribe((res) => (this.limitRequestTypes = res.filter((r) => r.id !== LimitRequestType.FARTHER_DELIVERIES)));
  }
}
