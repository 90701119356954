import {Component, OnInit} from '@angular/core';
import {
  BankAccountData,
  CollectionAccountedCostDto,
  CompanySimpleDto,
  DictionaryBaseDto,
} from '../../bonding_shared/model';
import {TranslateService} from '@ngx-translate/core';
import {GrowlService, RouterService} from '../../bonding_shared/services';
import {CollectionCostCalcType, Currency} from '../../bonding_shared/model/dictionary-ids';
import {ActivatedRoute, Params} from '@angular/router';
import {CollectionCostsInvoicingService} from '../../bonding_shared/services/collection-costs-invoicing.service';
import {DetailsView} from '../../bonding_shared/components/details-view/details-view';
import {DictionaryUtils} from '../../bonding_shared/utils/dictionary-utils';

@Component({
  selector: 'collection-costs-invoicing',
  templateUrl: 'collection-costs-invoicing.component.pug',
})
export class CollectionCostsInvoicingComponent extends DetailsView implements OnInit {
  readonly ACCOUNT_CURRENCY = [Currency.PLN, Currency.EUR, Currency.USD];

  accountedCosts: CollectionAccountedCostDto;
  bankAccounts: BankAccountData[];
  selectedBankAccount: BankAccountData;
  constructor(
    private route: ActivatedRoute,
    private invoicingService: CollectionCostsInvoicingService,
    public router: RouterService,
    private translateService: TranslateService,
    growlService: GrowlService
  ) {
    super(growlService);
    this.selectorNameList = ['Creditor'];
    this.initializeSelectorEmitters(true);
  }

  ngOnInit(): void {
    this.route.params.subscribe((params) => this.initializeView(params));
  }

  initializeView(params: Params) {
    const costsId = +params['costsId'];
    this.selectedBankAccount = <BankAccountData>{};
    this.invoicingService.getAccountedCosts(costsId).subscribe((a) => {
      this.accountedCosts = a;
      if (this.isInvoice()) {
        this.invoicingService
          .getCollectionBankAccounts(this.accountedCosts.collection.businessUnit.id, this.accountedCosts.currency.id)
          .subscribe((ba) => {
            this.bankAccounts = ba;
            if (this.bankAccounts && this.bankAccounts.length > 0) {
              this.selectedBankAccount = this.bankAccounts[0];
            }
          });
      } else {
        this.selectedBankAccount = this.createBankAccountData(this.accountedCosts);
        if (!this.selectedBankAccount.accountCurrency) {
          if (DictionaryUtils.inArr(this.accountedCosts.currency, this.ACCOUNT_CURRENCY)) {
            this.selectedBankAccount.accountCurrency = this.accountedCosts.currency;
          } else {
            this.selectedBankAccount.accountCurrency = <DictionaryBaseDto>{
              id: Currency.PLN,
              code: 'PLN',
            };
          }
        }
      }
    });
  }

  setBuyer(buyer: CompanySimpleDto) {
    this.accountedCosts.buyer = buyer;
  }

  invoiceCosts(costs: CollectionAccountedCostDto) {
    this.inProgress = true;
    delete this.serverErrors;
    if (this.selectedBankAccount) {
      costs.bankName = this.selectedBankAccount.bankName;
      costs.iban = this.selectedBankAccount.iban;
      costs.bic = this.selectedBankAccount.bic;
      costs.invoiceCurrency = this.selectedBankAccount.accountCurrency;
    }
    this.invoicingService.invoiceCosts(costs).subscribe({
      next: (c) => this.afterInvoice(c),
      error: (error) => {
        this.inProgress = false;
        this.handleServerError(error);
      },
    });
  }

  documentType(): string {
    if (this.isInvoice()) {
      return this.translateService.instant('common.invoice');
    } else {
      return this.translateService.instant('common.note');
    }
  }

  isInvoice(): boolean {
    const estimatedCosts = this.accountedCosts.estimatedCost;
    const calcType = estimatedCosts.calcType;
    return (
      calcType.id === CollectionCostCalcType.INSURER_COSTS ||
      calcType.id === CollectionCostCalcType.COSTS_REIMBURSED_BY_INSURER
    );
  }

  calcCreditorShare(): number {
    return 100 - this.accountedCosts.insurerShare;
  }

  calcInsurerParticipationAmount(): number {
    return (this.accountedCosts.insurerShare * this.accountedCosts.approvedAmount) / 100 || 0;
  }

  calcCreditorParticipationAmount(): number {
    return this.accountedCosts.approvedAmount - this.calcInsurerParticipationAmount() || 0;
  }

  calcInvoicingAmount(): number {
    return this.isInvoice() ? this.calcCreditorParticipationAmount() : this.calcInsurerParticipationAmount();
  }

  private afterInvoice(costs: CollectionAccountedCostDto): void {
    this.accountedCosts = costs;
    this.selectedBankAccount = this.createBankAccountData(this.accountedCosts);
    this.showErrors = false;
    this.inProgress = false;
    this.showSavedMsg();
  }

  private createBankAccountData(costs: CollectionAccountedCostDto): BankAccountData {
    return <BankAccountData>{
      bankName: costs.bankName,
      iban: costs.iban,
      bic: costs.bic,
      accountCurrency: costs.invoiceCurrency,
    };
  }

  public showSavedMsg(): void {
    this.growlService.notice('collectionCosts.invoicesCostsNotification');
    this.inProgress = false;
  }
}
