import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Params} from '@angular/router';
import {DetailsView} from '../../../../bonding_shared/components/details-view/details-view';
import {
  CountryRatingService,
  CountryRatingVersionDto,
  DictionaryBaseDto,
  DictionaryBaseService,
  GrowlService,
  InquiryService,
  MehibPremiumCalculationCriteriaDto,
  MehibPremiumCalculationResultDto,
  OfferCalculationEnvelopeDto,
  PolicyInquiryOfferEnvelopeDto,
  PremiumAmountDto,
  RouterService,
} from '../../../../bonding_shared';
import {
  CountryRatingSettingType,
  CountryRiskMitigation,
  CreditEnhancementSecurity,
  Currency,
  DictionaryProfile,
  ExportRiskCategory,
  InquiryProductType,
  InsuranceCoverOfPremium,
  OECDRating,
  PolicyInquiryOfferVotingStatus,
  Rating,
  RatingScale,
  RepaymentType,
} from '../../../../bonding_shared/model/dictionary-ids';
import {NgForm} from '@angular/forms';
import {NumberUtils} from '../../../../bonding_shared/utils/number-utils';
import {DateUtils} from '../../../../bonding_shared/utils/date-utils';
import {forkJoin} from 'rxjs';

@Component({
  selector: 'mehib-calculator-details',
  templateUrl: 'mehib-calculator-details.component.pug',
  styles: ['.bon-card-group {margin-top: 15px; margin-bottom: 15px}', 'table td {border-top: none}'],
})
export class MehibCalculatorDetailsComponent extends DetailsView implements OnInit {
  @ViewChild('ngForm', {static: true}) ngForm: NgForm;

  readonly RatingScale = RatingScale;
  readonly VSKV_PRODUCTS = new Set<number>([
    InquiryProductType.BUYER_CREDIT_V,
    InquiryProductType.SUPPLIER_CREDIT_S,
    InquiryProductType.SUPPLIER_CREDIT_DISCOUNTING_KV,
    InquiryProductType.TIED_AID_TAI,
  ]);

  private readonly YCLR = new Map<number, number>([
    [ExportRiskCategory.LOW, 0.001],
    [ExportRiskCategory.MEDIUM, 0.005],
    [ExportRiskCategory.HIGH, 0.025],
  ]);

  private readonly NTV = new Map<number, number>([
    [ExportRiskCategory.LOW, 0.05],
    [ExportRiskCategory.MEDIUM, 0.15],
    [ExportRiskCategory.HIGH, 0.45],
  ]);
  readonly DictionaryProfile = DictionaryProfile;

  isCalculationTypeG = false;
  isCalculationTypeB = false;
  isCalculationTypeVSKV = false;
  isCalculationTypeKV = false;
  isCalculationTypeTA = false;

  countryRatingVersion = <CountryRatingVersionDto>{};

  premiumCalculationCriteria = <MehibPremiumCalculationCriteriaDto>{};
  premiumCalculationResult: MehibPremiumCalculationResultDto;

  calculatedPremiumRate: number;

  offerId: number;
  inquiryId: number;
  offerEnvelope: PolicyInquiryOfferEnvelopeDto;

  applicableInterestRateEditable = false;
  awlNonConstantRepaymentEditable = false;
  localCurrencyFactorEditable = false;

  escrowAmountByCreditAmount: number;
  escrowDiscountGiven: number;
  assetBasedSecurityDiscountEditable = false;
  fixedAssetSecurityDiscountEditable = false;
  creditEnhancementsFactor: number;

  insuredInsurancePremiumPartEditable = false;

  requiredRepaymentYears = false;

  averageWeightedLife: number;
  lengthOfTheRepaymentPeriod: number;
  horizonOfRisk: number;

  lengthOfManufacturingPeriodYears: number;
  lengthOfDisbursementPeriodYears: number;

  mprPremiumRate: number; // G56 | Calculated MPR premium rate | Számított MPR díjtétel
  kvPerformanceRiskPremiumRate: number; // G57 | Calculated KV performance risk premium rate | Számított KV teljesítési kockázat díjtétel
  totalPremiumRate: number; // G 59 | Calculated total premium rate |	Számított teljes díjtétel

  insuredInsurancePremium: number; // G71
  upfrontPremium: number; // G74

  localCurrencyFactor: number;
  showCountryRiskMitigationDiscountErrors = false;
  showGivenUpfrontPremiumRateGtEqError = false;

  financedPartByExportContract: number; // G65
  localCostByExportContract: number; // G67
  financedPartLocalCostCapitalizedInterest: number; // G70
  premiumCalculationBasis: number; // G72

  calculatedPremiumRateLabel: string;
  appliedPremiumRateLabel: string;
  currencyLabel: string;
  insuredAmountLabel: string;
  // premiumAmountLabel: string;

  defaultCurrencies: DictionaryBaseDto[] = [];
  defaultCurrencyIds: number[] = [];

  constructor(
    protected growlService: GrowlService,
    private route: ActivatedRoute,
    protected countryRatingService: CountryRatingService,
    public routerService: RouterService,
    private dictionaryBaseService: DictionaryBaseService,
    private inquiryService: InquiryService
  ) {
    super(growlService);
    this.saveButton.hidden = false;
    this.saveButton.disabled = true;
    this.cancelButton.hidden = false;
  }

  ngOnInit() {
    this.readOnlyMode = true;
    this.handleButtons();
    this.form = this.ngForm.form;
    this.route.params.subscribe((params) => this.initializeView(params));
  }

  initializeView(params: Params) {
    this.offerId = +params['offerId'];
    this.inquiryId = +params['inquiryId'];

    this.inProgress = true;
    forkJoin([
      this.inquiryService.getOffer(this.offerId),
      this.inquiryService.getCalculate(this.offerId),
      this.dictionaryBaseService.getDictionaryBaseFiltered(
        'Currency',
        null,
        null,
        DictionaryProfile.MEHIB_MAIN_CURRENCY
      ),
    ]).subscribe({
      next: ([offerEnvelope, calc, currencies]) => {
        this.setOffer(offerEnvelope);
        this.setCalculation(calc);
        this.initCurrencies(currencies);
      },
      error: (error) => this.handleServerError(error),
      complete: () => (this.inProgress = false),
    });
  }

  private initCurrencies(currencies: DictionaryBaseDto[]) {
    this.defaultCurrencies = currencies;
    for (const cur of this.defaultCurrencies) {
      this.defaultCurrencyIds.push(cur.id);
    }
  }

  private setCalculation(res: OfferCalculationEnvelopeDto) {
    if (res && res.mehibPremiumCalculationCriteria) {
      this.premiumCalculationCriteria = res.mehibPremiumCalculationCriteria;
      this.premiumCalculationCriteria.calculationDate = this.premiumCalculationResult.calculationDate;
      this.recalculateFinancedPartByExportContract();
      this.recalculateCreditEnhancementsFactor();
      this.updateCountryRiskCategory();
      this.calculateFinancedPartLocalCostCapitalizedInterest();
      this.recalculateUpfrontPremium();
      this.calculateLengthOfDisbursementPeriodYears();
      this.recalculateLocalCurrencyFactor();
      this.recalculateAverageWeightedLife();
      this.updateAmountByCreditAmount();
      this.recalculateLocalCostByExportContract();
      this.localCurrencyFactorEditable =
        this.premiumCalculationCriteria.countryRiskMitigation?.id === CountryRiskMitigation.LOCAL;
      this.assetBasedSecurityDiscountEditable =
        this.premiumCalculationCriteria.creditEnhancementSecurity?.id === CreditEnhancementSecurity.ASSET_BASED;
      this.fixedAssetSecurityDiscountEditable =
        this.premiumCalculationCriteria.creditEnhancementSecurity?.id === CreditEnhancementSecurity.FIXED_ASSET;
      this.applicableInterestRateEditable =
        this.premiumCalculationCriteria.repaymentType?.id === RepaymentType.CONSTANT_ANNUITY;
      this.awlNonConstantRepaymentEditable = this.premiumCalculationCriteria.repaymentType?.id === RepaymentType.OTHER;
    } else {
      this.initCalculationData();
    }
  }

  private setOffer(offerEnvelope: PolicyInquiryOfferEnvelopeDto) {
    this.offerEnvelope = offerEnvelope;
    this.premiumCalculationCriteria.inquiryTypeId = offerEnvelope.offer.type.id;
    if (offerEnvelope.offer.premiumCalculationResult) {
      this.premiumCalculationResult = offerEnvelope.offer.mehibPremiumCalculationResult;
      this.saveButton.disabled = true;
    } else {
      this.resetPremiumCalculationResults();
    }
    this.setCalculationType();
    this.initCurrency();
  }

  initCalculationData() {
    this.premiumCalculationCriteria.countryRiskMitigation = <DictionaryBaseDto>{id: CountryRiskMitigation.NONE};
    this.premiumCalculationCriteria.escrowAmount = 0;
    this.premiumCalculationCriteria.escrowDiscountRequested = 0;
    this.premiumCalculationCriteria.creditEnhancementSecurity = <DictionaryBaseDto>{id: CreditEnhancementSecurity.NONE};
    this.premiumCalculationCriteria.proceedsOrReceivablesDiscount = 0;
    this.premiumCalculationCriteria.insuranceCoverOfPremium = <DictionaryBaseDto>{id: InsuranceCoverOfPremium.NO};
    this.recalculateCreditEnhancementsFactor();
  }

  initCurrency() {
    this.premiumCalculationCriteria.currency = this.isCalculationTypeG
      ? <DictionaryBaseDto>{id: Currency.HUF}
      : this.offerEnvelope.offer.policyInquiryVersion.currency;
  }

  onSave(): void {
    super.onSave();
    this.inProgress = true;
    this.serverErrors = null;

    this.inquiryService.saveMehibPremiumCalculation(this.offerId, this.premiumCalculationCriteria).subscribe({
      next: () => {
        this.showSavedMsg('inquiry.calculator.premiumCalculationSaved');
        this.inProgress = false;
      },
      error: (error) => this.handleServerError(error),
    });

    this.readOnlyMode = true;
    this.handleButtons();
  }

  goToOffer() {
    this.routerService.toInquiryOfferDetails(this.inquiryId, this.offerId);
  }

  onCalculate() {
    if (this.recalculationDisabled()) {
      return;
    }
    this.showErrors = true;
    if (!this.form.valid) {
      this.showFormError();
      return;
    }
    this.inProgress = true;
    this.serverErrors = null;
    this.inquiryService.calculateMehibPremiumAmounts(this.premiumCalculationCriteria).subscribe({
      next: (result) => {
        this.premiumCalculationResult = result;
        this.growlService.notice('inquiry.calculator.succeeded');
        this.showErrors = false;
        this.inProgress = false;
        this.saveButton.disabled = false;
      },
      error: (error) => {
        this.handleServerError(error);
        this.saveButton.disabled = true;
      },
    });
  }

  calculatePremiumTypeG() {
    if (this.isPremiumCriteriaTypeGComplete()) {
      this.calculatePremium();
    }
  }

  calculatePremium() {
    this.inProgress = true;
    this.inquiryService.calculateMehibPremium(this.premiumCalculationCriteria).subscribe({
      next: (premiumRate) => {
        this.calculatedPremiumRate = premiumRate;
        this.premiumCalculationCriteria.appliedPremiumRate = premiumRate;
        this.inProgress = false;
      },
      error: (error) => {
        console.log('calculatePremiumRate', error);
        this.growlService.error('Error calculating premium rate!');
        this.inProgress = false;
      },
    });
  }

  updateCountryRiskCategory() {
    this.inProgress = true;
    this.countryRatingService
      .getCurrentVersion(this.premiumCalculationCriteria.riskCountry.id, CountryRatingSettingType.OECD)
      .subscribe({
        next: (rating) => {
          this.countryRatingVersion = rating;
          this.inProgress = false;
        },
        error: (error) => {
          this.handleServerError(error);
          this.inProgress = false;
        },
      });
  }

  updateCountryRating() {
    this.inProgress = true;
    this.countryRatingService
      .getCurrentVersion(this.premiumCalculationCriteria.riskCountry.id, CountryRatingSettingType.OECD)
      .subscribe({
        next: (rating) => {
          this.countryRatingVersion = rating;
          this.premiumCalculationCriteria.appliedCountryRisk = this.countryRatingVersion.oecdRating;
          if (this.premiumCalculationCriteria.appliedCountryRisk.id === OECDRating.NR) {
            this.premiumCalculationCriteria.appliedCountryRisk = null;
          }
          this.inProgress = false;
        },
        error: (error) => {
          this.handleServerError(error);
          this.inProgress = false;
        },
      });
  }

  changeAppliedCountryRisk() {
    const buyerRiskId = this.premiumCalculationCriteria.appliedBuyerRisk?.id;
    if (Array.from(this.unavailableRating(this.premiumCalculationCriteria.appliedCountryRisk)).includes(buyerRiskId)) {
      this.premiumCalculationCriteria.appliedBuyerRisk = null;
    }
    this.calculatePremiumRate();
  }

  resetPremiumCalculationResults() {
    this.saveButton.disabled = true;
    this.premiumCalculationResult = <MehibPremiumCalculationResultDto>{};
    this.premiumCalculationResult.premiumAmounts = [];
    for (const currency of this.defaultCurrencies) {
      this.premiumCalculationResult.premiumAmounts.push(<PremiumAmountDto>{currency: currency});
    }
    if (
      this.premiumCalculationCriteria &&
      this.premiumCalculationCriteria.currency &&
      !this.defaultCurrencyIds.includes(this.premiumCalculationCriteria.currency.id)
    ) {
      this.premiumCalculationResult.premiumAmounts.push(<PremiumAmountDto>{
        currency: this.premiumCalculationCriteria.currency,
      });
    }
  }

  handleCurrencyChange(currency: DictionaryBaseDto) {
    if (this.premiumCalculationResult.premiumAmounts.length > 3) {
      if (this.defaultCurrencyIds.includes(currency.id)) {
        this.premiumCalculationResult.premiumAmounts.pop();
      } else if (!(this.premiumCalculationResult.premiumAmounts[3].currency.id === currency.id)) {
        this.premiumCalculationResult.premiumAmounts[3] = <PremiumAmountDto>{currency: currency};
      } else {
        return;
      }
    } else {
      if (this.defaultCurrencyIds.includes(currency.id)) {
        return;
      } else {
        this.premiumCalculationResult.premiumAmounts.push(<PremiumAmountDto>{currency: currency});
      }
    }
  }

  unavailableRating(oecdRating: DictionaryBaseDto) {
    if (!oecdRating) {
      return new Set();
    }
    switch (oecdRating.id) {
      case OECDRating.R5:
        return new Set([Rating.CC5]);
      case OECDRating.R6:
        return new Set([Rating.CC4, Rating.CC5]);
      case OECDRating.R7:
        return new Set([Rating.CC3, Rating.CC4, Rating.CC5]);
      default:
        return new Set();
    }
  }

  hideNrAppliedCountryRiskCategory() {
    return new Set([OECDRating.NR]);
  }

  setCalculationType() {
    this.isCalculationTypeG = this.offerEnvelope.offer.type.id === InquiryProductType.PRESHIPMENT_RISK_G;
    this.isCalculationTypeB = this.offerEnvelope.offer.type.id === InquiryProductType.FOREIGN_INVESTMENT_B;
    this.isCalculationTypeVSKV = this.VSKV_PRODUCTS.has(this.offerEnvelope.offer.type.id);
    this.isCalculationTypeKV = this.offerEnvelope.offer.type.id === InquiryProductType.SUPPLIER_CREDIT_DISCOUNTING_KV;
    this.isCalculationTypeTA = this.offerEnvelope.offer.type.id === InquiryProductType.TIED_AID_TAI;
    this.setTypeSpecificLabels();
  }

  setTypeSpecificLabels() {
    this.calculatedPremiumRateLabel = this.isCalculationTypeB
      ? 'inquiry.calculator.calculatedAnnualPremiumRate'
      : 'inquiry.calculator.calculatedPremiumRate';
    this.appliedPremiumRateLabel = this.isCalculationTypeB
      ? 'inquiry.calculator.appliedPremiumRate'
      : 'inquiry.calculator.appliedPremiumRate';
    this.currencyLabel = this.isCalculationTypeB ? 'inquiry.calculator.investmentCurrency' : 'common.currency';
    this.insuredAmountLabel = this.isCalculationTypeB
      ? 'inquiry.calculator.capitalPlusYield'
      : 'inquiry.calculator.insuredAmount';
    // this.premiumAmountLabel = this.isCalculationTypeB ? 'inquiry.calculator.upFrontPremium' : 'inquiry.calculator.upFrontPremium';
  }

  calculatePremiumRate() {
    if (this.isCalculationTypeB) {
      this.calculatePremiumTypeB();
    } else if (this.isCalculationTypeG) {
      this.calculatePremiumTypeG();
    } else if (this.isCalculationTypeVSKV) {
      this.calculateMprPremiumRate();
    }
  }

  calculatePremiumTypeB() {
    if (this.isPremiumCriteriaTypeBComplete()) {
      this.calculatePremium();
    }
  }

  onPoliticalRiskCoverPercentChange() {
    this.calculateKvPerformanceRiskPremiumRate();
    this.calculatePremiumRate();
  }

  onCommercialRiskCoverPercentChange() {
    this.calculateKvPerformanceRiskPremiumRate();
    this.calculatePremiumRate();
  }

  onRepaymentTypeChanged() {
    if (this.premiumCalculationCriteria.repaymentType.id === RepaymentType.OTHER) {
      this.awlNonConstantRepaymentEditable = true;
      this.applicableInterestRateEditable = false;
      this.requiredRepaymentYears = false;
      this.premiumCalculationCriteria.applicableInterestRate = null;
    } else if (this.premiumCalculationCriteria.repaymentType.id === RepaymentType.EQUAL_CAPITAL) {
      this.awlNonConstantRepaymentEditable = false;
      this.applicableInterestRateEditable = false;
      this.requiredRepaymentYears = true;
      this.premiumCalculationCriteria.awlNonConstantRepayment = null;
      this.premiumCalculationCriteria.applicableInterestRate = null;
    } else {
      this.awlNonConstantRepaymentEditable = false;
      this.applicableInterestRateEditable = true;
      this.requiredRepaymentYears = true;
      this.premiumCalculationCriteria.awlNonConstantRepayment = null;
    }
    this.recalculateAverageWeightedLife();
  }

  onCountryRiskMitigationChanged() {
    this.localCurrencyFactorEditable =
      this.premiumCalculationCriteria.countryRiskMitigation.id === CountryRiskMitigation.LOCAL;
    this.recalculateLocalCurrencyFactor();
    this.recalculateCreditEnhancementsFactor();
  }

  updateAmountByCreditAmount() {
    if (this.premiumCalculationCriteria.escrowAmount && this.financedPartLocalCostCapitalizedInterest) {
      this.escrowAmountByCreditAmount =
        this.premiumCalculationCriteria.escrowAmount / this.financedPartLocalCostCapitalizedInterest;
    } else {
      this.escrowAmountByCreditAmount = null;
    }
    this.updateEscrowDiscountGiven();
  }

  updateEscrowDiscountGiven() {
    if (this.escrowAmountByCreditAmount && this.premiumCalculationCriteria.escrowDiscountRequested) {
      this.escrowDiscountGiven = Math.min(
        this.escrowAmountByCreditAmount,
        this.premiumCalculationCriteria.escrowDiscountRequested
      );
    } else {
      this.escrowDiscountGiven = null;
    }
    this.recalculateCreditEnhancementsFactor();
  }

  onCreditEnhancementsSecurityChanged() {
    if (this.premiumCalculationCriteria.creditEnhancementSecurity.id === CreditEnhancementSecurity.ASSET_BASED) {
      this.assetBasedSecurityDiscountEditable = true;
      this.fixedAssetSecurityDiscountEditable = false;
      this.premiumCalculationCriteria.fixedAssetSecurityDiscount = null;
    } else if (this.premiumCalculationCriteria.creditEnhancementSecurity.id === CreditEnhancementSecurity.FIXED_ASSET) {
      this.fixedAssetSecurityDiscountEditable = true;
      this.assetBasedSecurityDiscountEditable = false;
      this.premiumCalculationCriteria.assetBasedSecurityDiscount = null;
    } else {
      this.assetBasedSecurityDiscountEditable = false;
      this.fixedAssetSecurityDiscountEditable = false;
      this.premiumCalculationCriteria.fixedAssetSecurityDiscount = null;
      this.premiumCalculationCriteria.assetBasedSecurityDiscount = null;
    }
    this.recalculateCreditEnhancementsFactor();
  }

  recalculateCreditEnhancementsFactor() {
    if (this.premiumCalculationCriteria.countryRiskMitigation?.id === CountryRiskMitigation.OFFSHORE) {
      this.creditEnhancementsFactor = 0;
    } else {
      this.creditEnhancementsFactor = Math.min(
        0.35,
        NumberUtils.notNull(this.escrowDiscountGiven) +
          NumberUtils.notNull(this.premiumCalculationCriteria.assetBasedSecurityDiscount) +
          NumberUtils.notNull(this.premiumCalculationCriteria.fixedAssetSecurityDiscount) +
          NumberUtils.notNull(this.premiumCalculationCriteria.proceedsOrReceivablesDiscount)
      );
    }
    this.calculateMprPremiumRate();
  }

  onInsuranceCoverOfPremiumChange() {
    if (this.premiumCalculationCriteria.insuranceCoverOfPremium.id === InsuranceCoverOfPremium.PARTLY) {
      this.insuredInsurancePremiumPartEditable = true;
    } else {
      this.insuredInsurancePremiumPartEditable = false;
      this.premiumCalculationCriteria.insuredInsurancePremiumPart = null;
    }
    this.recalculateUpfrontPremium();
  }

  onGivenUpfrontPremiumRateChange() {
    this.showGivenUpfrontPremiumRateGtEqError =
      this.form.controls['givenUpfrontPremiumRate']?.errors?.gtEqThanField != null;
    this.recalculateUpfrontPremium();
  }

  recalculateUpfrontPremium() {
    if (this.premiumCalculationCriteria.insuranceCoverOfPremium.id == null) {
      this.upfrontPremium = null;
    } else {
      const insuredPremiumPart =
        this.premiumCalculationCriteria.insuranceCoverOfPremium.id === InsuranceCoverOfPremium.PARTLY
          ? this.premiumCalculationCriteria.insuredInsurancePremiumPart
          : 0;
      const givenRate = this.premiumCalculationCriteria.givenUpfrontPremiumRate;
      const upfrontPremiumRate =
        this.premiumCalculationCriteria.insuranceCoverOfPremium.id === InsuranceCoverOfPremium.YES
          ? givenRate / 100 / (1 - givenRate / 100)
          : givenRate / 100;
      this.upfrontPremium =
        (this.premiumCalculationCriteria.financedPartOfExportContract +
          this.premiumCalculationCriteria.localCost +
          this.premiumCalculationCriteria.capitalizedInterest +
          insuredPremiumPart) *
        upfrontPremiumRate;
    }
    this.recalculateInsuredInsurancePremium();
  }

  recalculateInsuredInsurancePremium() {
    if (this.premiumCalculationCriteria.insuranceCoverOfPremium.id == null) {
      this.insuredInsurancePremium = null;
    } else {
      const insuredPremiumPart =
        this.premiumCalculationCriteria.insuranceCoverOfPremium.id === InsuranceCoverOfPremium.PARTLY
          ? this.premiumCalculationCriteria.insuredInsurancePremiumPart
          : 0;
      this.insuredInsurancePremium =
        this.premiumCalculationCriteria.insuranceCoverOfPremium.id === InsuranceCoverOfPremium.YES
          ? this.upfrontPremium
          : insuredPremiumPart;
    }
    this.recalculatePremiumCalculationBasis();
  }

  recalculatePremiumCalculationBasis() {
    this.premiumCalculationBasis =
      this.premiumCalculationCriteria.financedPartOfExportContract +
      this.premiumCalculationCriteria.localCost +
      this.premiumCalculationCriteria.capitalizedInterest +
      this.insuredInsurancePremium;
  }

  onExportContractValueChange() {
    this.updateAmountByCreditAmount();
    this.recalculateFinancedPartOfExportContract();
    this.recalculateLocalCostByExportContract();
    this.calculateFinancedPartLocalCostCapitalizedInterest();
    this.recalculatePremiumCalculationBasis();
    this.recalculateUpfrontPremium();
  }

  onFinancedPartByExportContractChange() {
    this.recalculateFinancedPartOfExportContract();
    this.calculateFinancedPartLocalCostCapitalizedInterest();
    this.recalculatePremiumCalculationBasis();
    this.recalculateUpfrontPremium();
  }

  onLocalCostChange() {
    this.recalculateLocalCostByExportContract();
    this.recalculateFinancedPartOfExportContract();
    this.calculateFinancedPartLocalCostCapitalizedInterest();
    this.recalculatePremiumCalculationBasis();
    this.recalculateUpfrontPremium();
  }

  onCapitalizedInterestChange() {
    this.calculateFinancedPartLocalCostCapitalizedInterest();
    this.recalculatePremiumCalculationBasis();
    this.recalculateUpfrontPremium();
  }

  private recalculateFinancedPartByExportContract() {
    this.financedPartByExportContract =
      (this.premiumCalculationCriteria.financedPartOfExportContract /
        this.premiumCalculationCriteria.exportContractValue) *
      100;
  }

  private recalculateFinancedPartOfExportContract() {
    this.premiumCalculationCriteria.financedPartOfExportContract =
      (this.financedPartByExportContract * this.premiumCalculationCriteria.exportContractValue) / 100;
  }

  recalculateLocalCostByExportContract() {
    this.localCostByExportContract =
      (this.premiumCalculationCriteria.localCost / this.premiumCalculationCriteria.exportContractValue) * 100;
  }

  calculateFinancedPartLocalCostCapitalizedInterest() {
    this.financedPartLocalCostCapitalizedInterest =
      this.premiumCalculationCriteria.financedPartOfExportContract +
      this.premiumCalculationCriteria.localCost +
      this.premiumCalculationCriteria.capitalizedInterest;
    this.updateAmountByCreditAmount();
  }

  recalculateAverageWeightedLife() {
    if (this.isAwwlCriteriaComplete()) {
      this.inProgress = true;
      this.inquiryService.calculateAwwl(this.premiumCalculationCriteria).subscribe({
        next: (awwl) => {
          this.averageWeightedLife = awwl;
          this.inProgress = false;
          this.recalculateLengthOfTheRepaymentPeriod();
        },
        error: (error) => {
          console.log('calculateAwwl', error);
          this.growlService.error('Error calculating AWWL!');
          this.inProgress = false;
        },
      });
    } else {
      this.averageWeightedLife = null;
      this.recalculateLengthOfTheRepaymentPeriod();
    }
  }

  recalculateLengthOfTheRepaymentPeriod() {
    if (this.averageWeightedLife < 0.5) {
      this.lengthOfTheRepaymentPeriod = this.averageWeightedLife;
    } else {
      this.lengthOfTheRepaymentPeriod = this.recalculateFutamido(this.averageWeightedLife);
    }
    this.recalculateHorizonOfRisk();
  }

  recalculateHorizonOfRisk() {
    if (this.isCalculationTypeG) {
      this.horizonOfRisk = this.premiumCalculationCriteria.lengthOfManufacturingPeriodMonths / 12;
    } else if (this.isCalculationTypeVSKV) {
      if (this.lengthOfTheRepaymentPeriod == null) {
        this.horizonOfRisk = null;
      } else {
        this.horizonOfRisk = this.lengthOfDisbursementPeriodYears * 0.5 + this.lengthOfTheRepaymentPeriod;
      }
      this.calculateKvPerformanceRiskPremiumRate();
    }
    this.calculatePremiumRate();
  }

  calculateLengthOfDisbursementPeriodYears() {
    this.lengthOfDisbursementPeriodYears = this.premiumCalculationCriteria.lengthOfDisbursementPeriodMonths / 12;
    this.recalculateHorizonOfRisk();
  }

  calculateLengthOfManufacturingPeriodYearsYears() {
    this.lengthOfManufacturingPeriodYears = this.premiumCalculationCriteria.lengthOfManufacturingPeriodMonths / 12;
    this.recalculateHorizonOfRisk();
  }

  recalculateLocalCurrencyFactor() {
    if (this.premiumCalculationCriteria.countryRiskMitigation.id === CountryRiskMitigation.LOCAL) {
      this.localCurrencyFactor = Math.min(0.2, this.premiumCalculationCriteria.countryRiskMitigationDiscount);
    } else {
      this.localCurrencyFactor = null;
      this.premiumCalculationCriteria.countryRiskMitigationDiscount = null;
    }
    this.showCountryRiskMitigationDiscountErrors =
      this.premiumCalculationCriteria.countryRiskMitigationDiscount != null;
    this.calculateMprPremiumRate();
  }

  calculateMprPremiumRate() {
    if (this.isMprCriteriaComplete()) {
      this.inProgress = true;
      this.inquiryService.calculateMprPremiumRate(this.premiumCalculationCriteria).subscribe({
        next: (mprRate) => {
          this.mprPremiumRate = mprRate;
          this.calculateTotalPremiumRate();
          this.inProgress = false;
        },
        error: (error) => {
          console.log('calculateMprPremiumRate', error);
          this.growlService.error('Error calculating MPR rate!');
          this.mprPremiumRate = null;
          this.inProgress = false;
        },
      });
    } else {
      this.mprPremiumRate = null;
      this.totalPremiumRate = null;
      this.premiumCalculationCriteria.calculatedTotalPremiumRate = this.totalPremiumRate;
    }
  }

  private calculateYCLR() {
    if (this.premiumCalculationCriteria.exporterCreditRiskCategory != null) {
      return this.YCLR.get(this.premiumCalculationCriteria.exporterCreditRiskCategory.id);
    } else {
      return null;
    }
  }

  private calculateNTV() {
    if (this.premiumCalculationCriteria.exportContractCategory != null) {
      return this.NTV.get(this.premiumCalculationCriteria.exportContractCategory.id);
    } else {
      return null;
    }
  }

  private calculateNtvRetention() {
    return (
      1 -
      Math.max(
        this.premiumCalculationCriteria.politicalRiskCoverPercent / 100,
        this.premiumCalculationCriteria.commercialRiskCoverPercent / 100
      )
    );
  }

  private calculateKvNTD() {
    return (
      ((this.premiumCalculationCriteria.lengthOfDisbursementPeriodMonths / 12) * this.calculateYCLR() * 0.1 +
        this.calculateNTV() * this.calculateYCLR() * 0.6) *
      (1 - this.calculateNtvRetention()) *
      100
    );
  }

  calculateKvPerformanceRiskPremiumRate() {
    if (this.premiumCalculationCriteria.inquiryTypeId === InquiryProductType.SUPPLIER_CREDIT_DISCOUNTING_KV) {
      this.kvPerformanceRiskPremiumRate = this.calculateKvNTD();
    } else {
      this.kvPerformanceRiskPremiumRate = 0;
    }
    this.calculateTotalPremiumRate();
  }

  calculateTotalPremiumRate() {
    let factor = 1;
    if (this.premiumCalculationCriteria.inquiryTypeId === InquiryProductType.TIED_AID_TAI) {
      factor = 1 - this.premiumCalculationCriteria.creditConcessionalityLevel / 100;
    }
    this.totalPremiumRate = (this.mprPremiumRate + this.kvPerformanceRiskPremiumRate) * factor;
    this.premiumCalculationCriteria.calculatedTotalPremiumRate = this.totalPremiumRate;
    this.updateGivenUpfrontPremiumRate();
  }

  updateGivenUpfrontPremiumRate() {
    this.premiumCalculationCriteria.givenUpfrontPremiumRate = this.totalPremiumRate;
  }

  isPremiumCriteriaTypeBComplete() {
    return (
      this.premiumCalculationCriteria.appliedCountryRisk != null &&
      this.premiumCalculationCriteria.insuranceCoverPercent != null &&
      this.premiumCalculationCriteria.riskSurcharge != null
    );
  }

  isPremiumCriteriaTypeGComplete() {
    return (
      this.premiumCalculationCriteria.appliedCountryRisk != null &&
      this.premiumCalculationCriteria.appliedBuyerRisk != null &&
      this.premiumCalculationCriteria.politicalRiskCoverPercent != null &&
      this.premiumCalculationCriteria.commercialRiskCoverPercent != null &&
      this.premiumCalculationCriteria.lengthOfManufacturingPeriodMonths != null
    );
  }

  isAwwlCriteriaComplete() {
    const equalCapitalCriteriaComplete =
      this.premiumCalculationCriteria.lengthOfRepaymentPeriodYears != null &&
      this.premiumCalculationCriteria.numberOfRepaymentsPerYear != null;
    const constantAnnuityCriteriaComplete =
      equalCapitalCriteriaComplete &&
      this.premiumCalculationCriteria.applicableInterestRate != null &&
      this.premiumCalculationCriteria.repaymentType != null;
    const otherCriteriaComplete =
      constantAnnuityCriteriaComplete && this.premiumCalculationCriteria.awlNonConstantRepayment != null;
    switch (this.premiumCalculationCriteria.repaymentType.id) {
      case RepaymentType.EQUAL_CAPITAL:
        return equalCapitalCriteriaComplete;
      case RepaymentType.CONSTANT_ANNUITY:
        return constantAnnuityCriteriaComplete;
      case RepaymentType.OTHER:
        return otherCriteriaComplete;
      default:
        console.error('AwwlCriteria: Wrong repayment type');
        return false;
    }
  }

  isMprCriteriaComplete() {
    return (
      (this.premiumCalculationCriteria.awlNonConstantRepayment != null || this.isAwwlCriteriaComplete()) &&
      this.premiumCalculationCriteria.appliedBuyerRisk != null &&
      this.premiumCalculationCriteria.appliedCountryRisk != null &&
      this.premiumCalculationCriteria.commercialRiskCoverPercent != null &&
      this.premiumCalculationCriteria.countryRiskMitigation != null &&
      this.premiumCalculationCriteria.creditEnhancementSecurity != null &&
      this.premiumCalculationCriteria.escrowAmount != null &&
      this.premiumCalculationCriteria.escrowDiscountRequested != null &&
      this.premiumCalculationCriteria.exportContractValue != null &&
      this.premiumCalculationCriteria.inquiryTypeId != null &&
      this.premiumCalculationCriteria.lengthOfRepaymentPeriodYears != null &&
      this.premiumCalculationCriteria.lengthOfDisbursementPeriodMonths != null &&
      this.premiumCalculationCriteria.numberOfRepaymentsPerYear != null &&
      this.premiumCalculationCriteria.politicalRiskCoverPercent != null &&
      this.premiumCalculationCriteria.productQuality != null &&
      this.premiumCalculationCriteria.repaymentType != null &&
      ((this.premiumCalculationCriteria.creditEnhancementSecurity.id === CreditEnhancementSecurity.ASSET_BASED &&
        this.premiumCalculationCriteria.assetBasedSecurityDiscount != null) ||
        (this.premiumCalculationCriteria.creditEnhancementSecurity.id === CreditEnhancementSecurity.FIXED_ASSET &&
          this.premiumCalculationCriteria.fixedAssetSecurityDiscount != null) ||
        this.premiumCalculationCriteria.creditEnhancementSecurity.id === CreditEnhancementSecurity.NONE)
    );
  }

  private recalculateFutamido(awl: number): number {
    return 2 * (awl - 0.25);
  }

  recalculationDisabled() {
    return !(this.offerEnvelope?.offer?.votingStatus?.id === PolicyInquiryOfferVotingStatus.NOT_SENT);
  }

  private updateCalculationDate() {
    this.inProgress = true;
    this.serverErrors = null;

    this.inquiryService.updateCalculationDate().subscribe({
      next: (calculationDate) => {
        this.premiumCalculationCriteria.calculationDate = DateUtils.toDate(calculationDate);
        this.inProgress = false;
      },
      error: (error) => {
        this.handleServerError(error);
        this.saveButton.disabled = true;
      },
    });
    this.calculatePremiumRate();
  }

  onEdit(): void {
    this.readOnlyMode = false;
    this.handleButtons();
    this.updateCalculationDate();
  }

  onCancel(): void {
    this.readOnlyMode = true;
    this.handleButtons();
    this.route.params.subscribe((params) => this.initializeView(params));
  }

  public handleButtons(): void {
    this.editButton.hidden = !this.readOnlyMode;
    this.editButton.disabled = !this.readOnlyMode;
    this.saveButton.hidden = this.readOnlyMode;
    this.cancelButton.hidden = this.readOnlyMode;
  }
}
