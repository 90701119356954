import {Component, Input} from '@angular/core';
import {ControlContainer, NgForm, UntypedFormGroup} from '@angular/forms';
import {DictionaryService, UserService} from '../../bonding_shared';
import {CommonPolicyAssignmentCriteria} from './common-policy-assignment-criteria';
import {TaskType} from '../../bonding_shared/model/dictionary-ids';

@Component({
  selector: 'policy-underwriting-assignment-criteria',
  templateUrl: 'policy-underwriting-assignment-criteria.component.pug',
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}],
})
export class PolicyUnderwritingAssignmentCriteriaComponent extends CommonPolicyAssignmentCriteria {
  @Input() dataForm: UntypedFormGroup;
  @Input() showErrors: boolean;

  constructor(protected dictionaryFullService: DictionaryService, protected userService: UserService) {
    super(dictionaryFullService, userService);
    this.dictionaryFullService.getDictionaryEntry('TaskType', TaskType.ZPO5).subscribe((res) => {
      this.taskTypes = [res];
    });
  }
}
