import {Routes} from '@angular/router';

import {LoginRoutes} from '../../../../src/client/app/login/index';
import {BondingRoutes} from '../../../../src/client/app/bonding/bonding.routes';
import {PageNotFoundRoutes, PageNotFoundComponent} from '../../../../src/client/app/404/index';

export const routes: Routes = [
  ...LoginRoutes,
  ...BondingRoutes,
  ...PageNotFoundRoutes,
  {path: '**', component: PageNotFoundComponent},
];
