import {PolicyInquiryOfferDto, PolicyInquiryVersionSimpleDto} from '../../../bonding_shared';

export const OfferCommon = {
  POLICYINSURANCERATE_NUMBEROFDECIMALPLACES: 3,
  TARIFFRATE__NUMBEROFDECIMALPLACES: 3,
};

export function getInquiryVersion(offer: PolicyInquiryOfferDto): PolicyInquiryVersionSimpleDto {
  if (!offer) {
    return null;
  }
  const getIfId = (inqp: PolicyInquiryVersionSimpleDto) => (inqp && inqp.id ? inqp : null);
  return getIfId(offer.policyInquiryVersion) || getIfId(offer.parent && offer.parent.policyInquiryVersion);
}
