import {Component, OnInit} from '@angular/core';
import {RouterService} from '../../bonding_shared/services/router-service';
import {
  EmployeeDto,
  LocalDateRange,
  SearchCriteria,
  UserDto,
  UserHolidayBaseDto,
  UserHolidayCriteriaDto,
  UserIdDto,
  UserSimpleDto,
} from '../../bonding_shared/model/dtos';
import {SearchDataProvider} from '../../bonding_shared/services/search-data-provider';
import {UserHolidayService} from '../../bonding_shared/services/user-holiday-service';
import {SearchView} from '../../bonding_shared/components/search/search-view/search-view';
import {LoggedUserService, UserNamePipe, UserRange} from '../../bonding_shared';

@Component({
  selector: 'user-holiday-search',
  templateUrl: './user-holiday-search.component.pug',
})
export class UserHolidaySearchComponent extends SearchView implements OnInit {
  dataProvider: SearchDataProvider<UserHolidayCriteriaDto, UserHolidayBaseDto>;
  readonly hrUserRange: UserRange = 'hr';
  readonly employeeUserRange: UserRange = 'employees';
  disableChangeEmployee = !this.loggedUserService.isHRRole();
  showNewButton = this.showButtonAddNewHoliday();

  constructor(
    public router: RouterService,
    private userholidayService: UserHolidayService,
    private loggedUserService: LoggedUserService
  ) {
    super();
  }

  ngOnInit() {
    this.dataProvider = new SearchDataProvider<UserHolidayCriteriaDto, UserHolidayBaseDto>(this.userholidayService);
    this.dataProvider.searchCriteria = <SearchCriteria<UserHolidayCriteriaDto>>{criteria: <UserHolidayCriteriaDto>{}};
    this.dataProvider.searchCriteria.criteria = <UserHolidayCriteriaDto>{};
    this.dataProvider.searchCriteria.criteria.dateFromInRange = <LocalDateRange>{};
    this.dataProvider.searchCriteria.criteria.dateToInRange = <LocalDateRange>{};
    if (!this.loggedUserService.isHRRole()) {
      this.dataProvider.searchCriteria.criteria.user = this.getLoggedUser();
    } else {
      this.dataProvider.searchCriteria.criteria.user = <UserSimpleDto>{};
    }
    this.dataProvider.searchCriteria.criteria.user.employee = <EmployeeDto>{};
  }

  onUserCreatedByChange(item: UserIdDto) {
    this.dataProvider.searchCriteria.criteria.createdBy = item;
  }

  onUserChange(item: UserSimpleDto) {
    this.dataProvider.searchCriteria.criteria.user = item;
  }

  userFullName = (u: UserDto): string => {
    return new UserNamePipe().transform(u);
  };

  private getLoggedUser(): UserDto {
    return this.loggedUserService.getLoggedUserData();
  }

  private showButtonAddNewHoliday(): boolean {
    return this.getLoggedUser().employee?.id > 0 || this.loggedUserService.isHRRole();
  }
}
