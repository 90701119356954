import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Params} from '@angular/router';
import {NgForm, UntypedFormBuilder} from '@angular/forms';
import {
  AppConfigService,
  CacheService,
  CompanyService,
  CompanySimpleDto,
  ContactPersonDto,
  DictionaryBaseService,
  DictionaryDto,
  DictionaryService,
  DocumentService,
  GrowlService,
  LoggedUserService,
  PolicyInquiryCompanyDto,
  PolicyInquiryVersionDto,
  RelatedObject,
  RouterService,
  UserService,
} from '../../bonding_shared';
import {DetailsView} from '../../bonding_shared/components/details-view/details-view';
import {
  BusinessObjectType,
  ContactNoteElementaryRight,
  ContactPersonType,
  PolicyElementaryRight,
  PolicyInquiryCompanyRole,
  PolicyInquiryStatus,
  RepoDocumentElementaryRight,
  ServiceContactElementaryRight,
  ServiceNoteElementaryRight,
} from '../../bonding_shared/model/dictionary-ids';
import {InquiryAbstractService} from '../../bonding_shared/services/inquiry-abstract.service';
import {VisibilityService} from './services/visibility.service';
import {InquiryCountryInitialiazerService} from './services/inquiry-countries-initialiazer.service';
import {DictionaryUtils} from '../../bonding_shared/utils/dictionary-utils';

@Component({
  selector: 'renewal-inquiry-details',
  templateUrl: './renewal-inquiry-details.component.pug',
  providers: [InquiryCountryInitialiazerService],
})
export class RenewalInquiryDetailsComponent extends DetailsView implements OnInit, AfterViewInit {
  inquiry: PolicyInquiryVersionDto;

  readonly: boolean;
  statements: {[key: string]: boolean | null};
  private _editMode = false;

  readonly BusinessObjectType = BusinessObjectType;
  readonly ContactNoteElementaryRight = ContactNoteElementaryRight;
  readonly ServiceNoteElementaryRight = ServiceNoteElementaryRight;
  readonly RepoDocumentElementaryRight = RepoDocumentElementaryRight;
  readonly PolicyElementaryRight = PolicyElementaryRight;
  readonly ServiceContactElementaryRight = ServiceContactElementaryRight;

  public getCustomTransitions = this.inquiryService.getTransitionsForVersion.bind(this.inquiryService);

  @ViewChild('ngForm') ngForm: NgForm;

  private registeredStatus: DictionaryDto;

  visible: any = {};

  constructor(
    protected route: ActivatedRoute,
    public routerService: RouterService,
    protected documentService: DocumentService,
    protected dictionaryService: DictionaryService,
    protected dictionaryBaseService: DictionaryBaseService,
    protected companyService: CompanyService,
    protected visibilityService: VisibilityService,
    protected formBuilder: UntypedFormBuilder,
    protected userService: UserService,
    protected loggedUserService: LoggedUserService,
    protected inquiryService: InquiryAbstractService,
    protected growlService: GrowlService,
    protected appService: AppConfigService,
    protected cacheService: CacheService,
    private countryInitialiazerService: InquiryCountryInitialiazerService
  ) {
    super(growlService);
    this.hideButtons(false);
    this.deleteButton.hidden = true;
    this.newVersionButton.hidden = true;
    this.newVersionButton.disabled = true;

    this.selectorNameList = [RelatedObject.SUBINSUREDS];
    this.initializeSelectorEmitters(true);
  }

  ngOnInit() {
    console.log('START ngOnInit()');
    this.saveButton.hidden = true;
    this.serverErrors = undefined;
    this.route.params.subscribe((params) => this.initializeView(params));
    console.log('END ngOnInit()');
  }

  private getInquiryType() {
    return this.inquiry && this.inquiry.productType;
  }

  ngAfterViewInit() {
    this.form = this.ngForm.form;
  }

  initializeView(params: Params, force?: boolean) {
    console.log('initializeView, params', params);
    const id = +params['id'];
    const clientId = +params['clientId'];
    const readFromCache = params['readFromCache'] && params['readFromCache'].toLowerCase() === 'true';
    if (this.inquiry && id === this.inquiry.id && !force) {
      return;
    }
    this.dictionaryService
      .getDictionaryEntry('PolicyInquiryStatus', PolicyInquiryStatus.POLICY_INQUIRY_REGISTERED)
      .subscribe((s) => (this.registeredStatus = s));
    this.initializeInquiry(id, clientId, readFromCache);
  }

  initializeInquiry(inquiryVersionId: number, clientId?: number, readFromCache?: boolean) {
    console.log('initializeInquiry, params', inquiryVersionId, clientId, readFromCache);
    if (!inquiryVersionId) {
      console.log('initialize version');
      if (!readFromCache) {
        this.inquiryService.initialVersion(clientId).subscribe((inqV) => {
          this.setInquiry(inqV);
        });
      } else {
        this.setInquiry(this.cacheService.inquiry);
      }
    } else {
      this.loadInquiry(inquiryVersionId);
    }
  }

  onSave() {
    if (this.inProgress) {
      console.warn('Parallel save clicks');
      return;
    }

    console.log('onSave, inquiry:', this.inquiry);
    this.showErrors = true;
    if (this.formValidates()) {
      this.inProgress = true;
      this.inquiryService.save(this.inquiry).subscribe(
        (inquiry) => {
          this.setInquiry(inquiry);
          this.serverErrors = undefined;
          this.showErrors = false;
          this.editMode = false;
          if (inquiry.warnings && inquiry.warnings.length) {
            this.growlService.warning(inquiry.warnings.map((e) => e.message).join('\n'));
          }
          this.showSavedMsg();
        },
        (error) => this.handleServerError(error),
        () => {
          this.inProgress = false;
          this.routerService.toPolicyRenewalInquiryDetails(this.inquiry.id);
        }
      );
      this.showErrors = false;
    }
  }

  onCancel(): void {
    super.onCancel(this.route);
    this.editMode = false;
  }

  onDelete() {
    console.log('Delete inquiry button pressed!');
    this.inProgress = true;
    this.showErrors = false;
    this.inquiryService.deleteInquiry(this.inquiry.policyInquiry.id).subscribe(
      (c) => {
        this.inProgress = false;
        this.growlService.notice('Renewal inquiry has been deleted!');
        this.routerService.toPolicyContractPreview(this.inquiry.basePolicyContractVersion.id);
      },
      (error) => {
        this.serverErrors = error;
        this.inProgress = false;
      }
    );
  }

  get editMode(): boolean {
    return this._editMode;
  }

  set editMode(mode: boolean) {
    this._editMode = mode;
    this.handleButtons();
  }

  loadInquiry(versionId: number) {
    this.inProgress = true;
    this.inquiryService.getById<PolicyInquiryVersionDto>(versionId).subscribe(
      (inquiry) => {
        this.setInquiry(inquiry);
        this.inProgress = false;
      },
      (error) => {
        this.handleServerError(error);
      }
    );
  }

  showSavedMsg() {
    this.growlService.notice('inquiry.saved');
  }

  showFormError(extractInfo?: string) {
    this.growlService.error('The form has errors!');
    if (extractInfo) {
      this.growlService.error(extractInfo);
    }
    this.inProgress = false;
  }

  private setInquiry(inqV: PolicyInquiryVersionDto) {
    this.inquiry = inqV;
    this.cacheService.inquiry = inqV;
    this.newVersionButton.disabled = !inqV || !inqV.id;
    this.readonly =
      (this.inquiry && this.inquiry.status && this.inquiry.status.id === PolicyInquiryStatus.POLICY_INQUIRY_ACCEPTED) ||
      (this.inquiry.status.id === PolicyInquiryStatus.POLICY_INQUIRY_REGISTERED && this.portal);

    this.visible = this.visibilityService.getVisibility(this.inquiry.policyInquiry.productType.id);
    this.handleButtons();

    this.countryInitialiazerService.initMarketNonMarketCountries(this.getInquiryType(), null, this.inquiry, true);
  }

  handleButtons() {
    this.saveButton.hidden = !this._editMode;
    this.deleteButton.hidden = this.readonly || this.inquiry.status.id !== PolicyInquiryStatus.POLICY_INQUIRY;
    this.cancelButton.hidden = !this._editMode;
  }

  goToServiceDetails() {
    const salesPerson = this.inquiry.salesPerson;
    const mainSalesRepUserId = salesPerson && salesPerson.id;
    this.routerService.toPolicyInquiryTechnicalDetails(
      this.inquiry.id,
      mainSalesRepUserId,
      this.inquiry?.businessStatus?.id
    );
  }

  isNewOfferAllowed(): boolean {
    return (
      this.inquiry && this.inquiry.status && this.inquiry.status.id === PolicyInquiryStatus.POLICY_INQUIRY_REGISTERED
    );
  }

  newOffer() {
    this.routerService.toNewKukeInquiryOfferDetails(this.inquiry.id, this.inquiry.productType.id, true, null);
  }

  get portal(): boolean {
    return false;
  }

  showOffer() {
    return this.inquiry && this.inquiry.id && !this.portal;
  }

  toPolicyInquiryServiceContacts() {
    this.routerService.toServiceContacts({
      boTypeId: BusinessObjectType.POLICY_INQUIRY,
      boId: this.inquiry.policyInquiry.id,
    });
  }

  addSubinsured(company: CompanySimpleDto) {
    const subinsured = <PolicyInquiryCompanyDto>{company: company};
    this.inquiryService.setCompanyRole(subinsured, PolicyInquiryCompanyRole.SUBINSURED);

    if (!this.inquiry.subinsureds) {
      this.inquiry.subinsureds = [];
    }
    this.inquiry.subinsureds = [...this.inquiry.subinsureds, subinsured];
  }

  subinsuredEmpty(): boolean {
    return this.inquiryService.subinsuredEmpty(this.inquiry);
  }

  contactPersonRowEditable(cp: ContactPersonDto): boolean {
    return !(
      this.appService.kuke &&
      this.loggedUserService.portal &&
      DictionaryUtils.in(cp.type, ContactPersonType.MAIN, ContactPersonType.PORTAL_ADMIN)
    );
  }
}
