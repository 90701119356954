import {SalesRepSearchComponent} from './sales-rep-search.component';
import {SalesRepDetailsComponent} from './sales-rep-details.component';
import {SalesRepPreviewComponent} from './sales-rep-preview.component';
import {SalesRepRelationDetailsComponent} from './sales-rep-relation-details.component';
import {SalesRepDetailsGuard, SalesRepPreviewGuard, SalesRepRelationDetailsGuard, SalesRepSearchGuard} from './_guards';

export const SalesRepRoutes = [
  {
    path: 'sales-rep-search',
    component: SalesRepSearchComponent,
    canActivate: [SalesRepSearchGuard],
  },
  {
    path: 'sales-rep-details/:versionId',
    component: SalesRepDetailsComponent,
    canActivate: [SalesRepDetailsGuard],
  },
  {
    path: 'sales-rep-preview/:salesRepId',
    component: SalesRepPreviewComponent,
    canActivate: [SalesRepPreviewGuard],
  },
  {
    path: 'sales-rep-relation-details/:relationVersionId',
    component: SalesRepRelationDetailsComponent,
    canActivate: [SalesRepRelationDetailsGuard],
  },
];
