<!--<link href='https://fonts.googleapis.com/css?family=Abel' rel='stylesheet'>-->
<!--<link href='https://fonts.googleapis.com/css?family=Archivo Black' rel='stylesheet'>-->


<div class="login-page animate">
  <div class="row">
    <div class="col-lg-4 col-lg-offset-4 main-logo">
      <strong>{{appConfigService.displayName}}</strong>
      <form [formGroup]='form' >
        <label class="caption-txt" translate>login.remindPassword.title</label>
        <div class="form-content" (keyup.enter)="sentPasswordEmail()">
          <div class="form-group">
            <input id="recoveryLoginInput" name="sentPassloginInput" type="text" [formControl]="form.controls.login"
                   class="form-control input-underline input-lg" [placeholder]="'login.loginPlaceholder' | translate">
            <error-message [control]="form.controls.login" [show]="showErrors"></error-message>
          </div>
          <div class="form-group">
            <input id="emailInput" name="sentPassEmailInput" type="email" [formControl]="form.controls.email"
                   class="form-control input-underline input-lg" [placeholder]="'common.email' | translate">
            <error-message [control]="form.controls.email" [show]="showErrors"></error-message>
          </div>
        </div>
        <span class="invalid font-medium" *ngFor="let err of serverErrors">
            <h5>{{err.message | translate}}</h5>
        </span>
        <div><a id="loginBtn" class="btn rounded-btn" (click)="sentPasswordEmail()" tabindex="0" translate>login.remindPassword.sendButton</a></div>
      </form>
    </div>
  </div>
</div>
