import {PolicyClauseDetailsComponent} from './components/policyclause-details.component';
import {POLICY_CLAUSE_DETAILS} from '../../../bonding_shared';
import {PolicyClauseDetailsGuard} from './_guards';
import {PolicyClauseTypeSearchComponent} from './components/policyclausetype-search.component';
import {PolicyClauseTypePreviewComponent} from './components/policyclausetype-preview.component';

export const PolicyClauseRoutes = [
  {
    path: POLICY_CLAUSE_DETAILS + '/:id',
    component: PolicyClauseDetailsComponent,
    canActivate: [PolicyClauseDetailsGuard],
  },
  {
    path: POLICY_CLAUSE_DETAILS,
    component: PolicyClauseDetailsComponent,
    canActivate: [PolicyClauseDetailsGuard],
  },
  {
    path: 'policyclausetype-search',
    component: PolicyClauseTypeSearchComponent,
    canActivate: [PolicyClauseDetailsGuard],
  },
  {
    path: 'policyclausetype-preview/:id',
    component: PolicyClauseTypePreviewComponent,
    canActivate: [PolicyClauseDetailsGuard],
  },
];
