import {Component, Input} from '@angular/core';
import {ClientInfoDto, CompanyRatingVersionDto} from '../../../../bonding_shared/model/dtos';
import {ClientSegment} from '../../../../bonding_shared/model/dictionary-ids';
import {AppConfigService} from '../../../../bonding_shared/services';

@Component({
  selector: 'company-segment-info',
  templateUrl: './company-segment-info.component.pug',
})
export class CompanySegmentInfoComponent {
  @Input() rating: CompanyRatingVersionDto = undefined;
  @Input() clientInfo: ClientInfoDto = undefined;

  constructor(public appService: AppConfigService) {}

  getSegmentImage() {
    if (this.clientInfo && this.clientInfo.segment) {
      if (this.clientInfo.segment.id === ClientSegment.PLATINIUM) {
        return 'segment_platinium.png';
      }
      if (this.clientInfo.segment.id === ClientSegment.GOLD) {
        return 'segment_gold.png';
      }
      if (this.clientInfo.segment.id === ClientSegment.SILVER) {
        return 'segment_silver.png';
      }
      if (this.clientInfo.segment.id === ClientSegment.BRONZE) {
        return 'segment_bronze.png';
      }
      return '';
    }
    return '';
  }
}
