import {Component, OnInit, ViewChild} from '@angular/core';
import {DetailsView} from '../../bonding_shared/components/details-view/details-view';
import {DictionaryBaseService, GrowlService, LoggedUserService, UserService} from '../../bonding_shared/services';
import {TranslateService} from '@ngx-translate/core';
import {BusinessParamService} from '../../bonding_shared/services/business-param.service';
import {NgForm} from '@angular/forms';
import {BusinessParamDto, BusinessParamHistoryDto, DictionaryBaseDto} from '../../bonding_shared/model';
import {FormDialogComponent} from '../../bonding_shared/components/form-dialog';
import {ElementaryRight} from '../../bonding_shared/model/dictionary-ids';

@Component({
  selector: 'business-params',
  templateUrl: './business-params.component.pug',
})
export class BusinessParamsComponent extends DetailsView implements OnInit {
  readonly BUSINESS_PARAM_UPDATE = ElementaryRight.BUSINESS_PARAM_UPDATE;
  @ViewChild('ngForm', {static: true}) ngForm: NgForm;
  params: BusinessParamDto[];
  modules: DictionaryBaseDto[];
  applicationModule: DictionaryBaseDto;
  historyElements: BusinessParamHistoryDto[];
  self = this;

  editedParamId = undefined;

  @ViewChild(FormDialogComponent, {static: true}) historyDialog: FormDialogComponent;

  constructor(
    protected translateService: TranslateService,
    protected growlService: GrowlService,
    private userService: UserService,
    protected businessParamService: BusinessParamService,
    protected dictionaryService: DictionaryBaseService,
    public loggedUserService: LoggedUserService
  ) {
    super(growlService);
    this.dictionaryService.getDictionaryBase('ApplicationModule').subscribe((d) => {
      this.userService.getAvailableModules().subscribe((res) => {
        this.modules = d.filter((m) => res.includes(m.id, 0));
      });
    });
  }

  ngOnInit(): void {
    this.saveButton.hidden = true;
    this.cancelButton.hidden = true;
  }

  onAppModuleChange(appModule: DictionaryBaseDto) {
    this.params = undefined;
    this.serverErrors = null;
    this.editedParamId = undefined;
    this.businessParamService.findByApplicationModule(appModule.id).subscribe((res) => {
      this.params = res;
    });
  }

  getHistory(applicationModuleId: number, typeId: number) {
    this.businessParamService.getHistory(applicationModuleId, typeId).subscribe((res) => {
      this.historyElements = res;
      this.historyDialog.open(this.translateService.instant('businessParams.history'));
    });
  }

  goToEdit(id: number) {
    if (this.editedParamId) {
      this.restoreParamAndEditAnother(this.editedParamId, id);
    } else {
      this.editedParamId = id;
    }
    this.serverErrors = null;
    this.showErrors = false;
  }

  restoreParamAndEditAnother(restoreParamId: number, editedParamId: number) {
    this.businessParamService.getById<BusinessParamDto>(restoreParamId).subscribe((res) => {
      this.params[this.params.findIndex((p) => p.id === restoreParamId)] = res;
      this.editedParamId = editedParamId;
    });
  }

  save(param: BusinessParamDto) {
    this.businessParamService.save(param).subscribe({
      next: (res) => {
        this.serverErrors = null;
        this.params[this.params.findIndex((p) => p.id === param.id)] = res;
        this.growlService.notice('businessParams.success');
        this.editedParamId = undefined;
      },
      error: (error) => (this.serverErrors = error),
    });
  }

  cancel(param: BusinessParamDto) {
    this.serverErrors = null;
    this.showErrors = false;
    this.restoreParamAndEditAnother(param.id, undefined);
  }

  isParamInEdition(param: BusinessParamDto): boolean {
    return param.id === this.editedParamId;
  }
}
