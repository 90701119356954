import {Component, OnInit, ViewChild} from '@angular/core';
import {DetailsView} from '../../bonding_shared/components/details-view/details-view';
import {
  DictionaryBaseDto,
  GrowlService,
  LoggedUserService,
  RouterService,
  StringUtils,
  UserDto,
  UserHolidayDto,
  UserNamePipe,
  UserRange,
  UserSimpleDto,
} from '../../bonding_shared';
import {ActivatedRoute, Params} from '@angular/router';
import {UserHolidayService} from '../../bonding_shared/services/user-holiday-service';
import {NgForm} from '@angular/forms';
import {HolidayStatus, UserRole} from '../../bonding_shared/model/dictionary-ids';

@Component({
  selector: 'user-holiday-details',
  templateUrl: 'user-holiday-details.component.pug',
})
export class UserHolidayDetailsComponent extends DetailsView implements OnInit {
  public holiday: UserHolidayDto;
  public holidays: UserHolidayDto[];
  @ViewChild('ngForm', {static: true}) public ngForm: NgForm;
  readonly employeeUserRange: UserRange = 'employees';
  readonly hrUserRange: UserRange = 'hr';
  disableChangeEmployee = !this.loggedUserService.isHRRole();
  showAcceptanceButton = false;
  showAcceptAndRejectButtons = false;

  constructor(
    protected growlService: GrowlService,
    private userHolidayService: UserHolidayService,
    private loggedUserService: LoggedUserService,
    private route: ActivatedRoute,
    private router: RouterService
  ) {
    super(growlService);
    this.saveButton.hidden = false;
    this.cancelButton.hidden = false;
  }

  ngOnInit() {
    this.route.params.subscribe((params) => this.initializeView(params));
  }

  initializeView(params: Params) {
    this.cancelButton.hidden = true;
    const holidayId = +params['id'];
    if (holidayId === 0) {
      this.holiday = <UserHolidayDto>{};
      this.holiday.user = this.getLoggedUser();
      this.getUserHoliday(this.holiday.user.id);
    } else {
      this.userHolidayService.getById<UserHolidayDto>(holidayId).subscribe(
        (h) => {
          this.holiday = h;
          this.refresh(h.user.id);
        },
        (error) => {
          this.handleServerError(error);
        }
      );
    }
  }

  public onSave(): void {
    this.serverErrors = undefined;
    if (!this.ngForm.form.valid) {
      this.showFormError();
      StringUtils.logFormInvalidFields(this.ngForm.form);
      return;
    }
    if (this.dateInRage(this.holiday.dateFrom, this.holiday.dateTo, this.holiday.id)) {
      this.growlService.error('Your holiday request overlaps other holiday');
      return;
    }
    this.holiday.status = <DictionaryBaseDto>{id: HolidayStatus.DRAFT};
    this.inProgress = true;
    this.save();
  }

  private save(): void {
    const originalId = this.holiday.id;
    this.userHolidayService.save<UserHolidayDto>(this.holiday).subscribe({
      next: (h) => {
        this.holiday = h;
        if (originalId > 0) {
          this.refresh(h.user.id);
        }
        this.afterObjectSaved(originalId, () => this.router.toHolidayDetails(h.id));
      },
      error: (error) => {
        this.handleServerError(error);
      },
    });
  }

  private refresh(userId: number) {
    this.getUserHoliday(userId);
    this.showAcceptanceButton = this.showAcceptance();
    this.showAcceptAndRejectButtons = this.showButtonForHRRole();
  }

  onUserChange(item: UserSimpleDto) {
    this.holiday.user = item;
    this.getUserHoliday(item.id);
  }

  userFullName = (u: UserDto): string => {
    return new UserNamePipe().transform(u);
  };

  private getUserHoliday(userId: number) {
    this.holidays = null;
    this.userHolidayService.getUserHolidays(userId).subscribe((res) => (this.holidays = res.result));
  }

  private dateInRage(from: Date, to: Date, holidayId: number): boolean {
    return this.holidays
      .filter((h) => h.id !== holidayId)
      .filter((h) => h.status.id !== HolidayStatus.REJECTED)
      .some((user) => {
        return (
          (user.dateFrom <= from && user.dateTo >= to) ||
          (user.dateFrom <= from && user.dateTo >= from) ||
          (user.dateFrom <= to && user.dateTo >= to)
        );
      });
  }

  private getLoggedUser(): UserDto {
    return this.loggedUserService.getLoggedUserData();
  }

  private showAcceptance(): boolean {
    return this.holiday && this.holiday.id > 0 && this.holiday.status.id === HolidayStatus.DRAFT;
  }

  private showButtonForHRRole(): boolean {
    return this.loggedUserService.isHRRole() && this.holiday.status.id === HolidayStatus.WAITING_FOR_ACCEPTANCE;
  }

  public sendToAcceptance() {
    this.serverErrors = undefined;
    this.holiday.status = <DictionaryBaseDto>{id: HolidayStatus.WAITING_FOR_ACCEPTANCE};
    this.inProgress = true;
    this.save();
  }

  public accept() {
    this.serverErrors = undefined;
    this.holiday.status = <DictionaryBaseDto>{id: HolidayStatus.ACCEPTED};
    this.holiday.acceptedBy = this.getLoggedUser();
    this.holiday.acceptanceDate = new Date();
    this.inProgress = true;
    this.save();
  }

  public reject() {
    this.serverErrors = undefined;
    this.holiday.status = <DictionaryBaseDto>{id: HolidayStatus.REJECTED};
    this.holiday.acceptedBy = this.getLoggedUser();
    this.holiday.acceptanceDate = new Date();
    this.inProgress = true;
    this.save();
  }
}
