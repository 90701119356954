import {Component, OnInit, ViewChild} from '@angular/core';
import {DetailsView} from '../../bonding_shared/components/details-view/details-view';
import {GrowlService, LoggedUserService, RouterService, SearchDataProvider} from '../../bonding_shared/services';
import {TranslateService} from '@ngx-translate/core';
import {ActivatedRoute, Params} from '@angular/router';
import {BatchService} from '../../bonding_shared/services/batch.service';
import {BatchExecutionDto, RejectCriteriaDto, RejectDto} from '../../bonding_shared/model';
import {Button} from '../../bonding_shared/components/details-view/button';
import {ElementaryRight} from '../../bonding_shared/model/dictionary-ids';
import {StringUtils} from '../../bonding_shared/utils';
import {ATableComponent} from '../../bonding_shared/components/aku-table';
import {MigrationRejectService} from './service/migration-reject.service';

@Component({
  selector: 'batch-execution-details',
  templateUrl: './batch-execution-details.component.pug',
})
export class BatchExecutionDetailsComponent extends DetailsView implements OnInit {
  execution: BatchExecutionDto;

  // default page size for reject search
  _pageSize = 20;

  StringUtils = StringUtils;
  migrationBatch = false;

  successes: any = 'Unknown';
  rejects: any = 'Unknown';
  searchDataProvider: SearchDataProvider<RejectCriteriaDto, RejectDto>;
  @ViewChild('rejectsTable') rejectsTable: ATableComponent<RejectDto>;

  constructor(
    protected translateService: TranslateService,
    protected growlService: GrowlService,
    public router: RouterService,
    protected loggedUserService: LoggedUserService,
    private batchService: BatchService,
    private migrationRejectService: MigrationRejectService,
    private _route: ActivatedRoute
  ) {
    super(growlService);
    this.newVersion2Button = new Button(
      this.translateService.instant('batch.execution.details.reRun'),
      this.onReRun.bind(this),
      false,
      false,
      'paperclip'
    );
    this.newVersion2Button.hidden = !this.loggedUserService.hasRight(ElementaryRight.BATCH_RUN);
    this.newVersion2Button.icon = 'fa-play-circle-o';
    this.searchDataProvider = new SearchDataProvider<RejectCriteriaDto, RejectDto>(this.migrationRejectService);
  }

  onReRun(): void {
    this.batchService.run(this.execution.name, this.execution.parameters).subscribe((res) => {
      this.growlService.notice('Batch ' + this.execution.name + ' rerun started');
      console.log('Batch reRun', this.execution);
    });
  }

  ngOnInit(): void {
    this._route.params.subscribe((params) => this.initializeView(params));
  }

  initializeView(params: Params) {
    const id = +params['id'];
    const name: string = params['name'];
    if (name) {
      this.batchService.getLastExecution(name).subscribe((exec) => {
        this.checkMigration(exec);
        this.execution = exec;
        this.searchDataProvider.searchCriteria.criteria = <RejectCriteriaDto>{};
        this.searchDataProvider.searchCriteria.criteria.jobInstanceId = exec.id;
        this.searchDataProvider.textSearch = false;
        if (this.rejectsTable) {
          this.rejectsTable.search(this._pageSize);
        }
      });
    }
    if (id) {
      this.batchService.getExecution(id).subscribe((exec) => {
        this.execution = exec;
        this.checkMigration(exec);
        this.searchDataProvider.searchCriteria.criteria = <RejectCriteriaDto>{};
        this.searchDataProvider.searchCriteria.criteria.jobInstanceId = exec.id;
        this.searchDataProvider.textSearch = false;
        if (this.rejectsTable) {
          this.rejectsTable.search(this._pageSize);
        }
      });
    }
  }

  checkMigration(ex: BatchExecutionDto) {
    this.migrationBatch = ex.name.includes('migrate');
    if (this.migrationBatch) {
      this.refresh(ex);
    }
  }

  refresh(ex: BatchExecutionDto) {
    this.migrationRejectService.getSuccessCount(ex.id).subscribe((res) => {
      this.successes = res;
    });
    this.migrationRejectService.getRejectCount(ex.id).subscribe((res) => {
      this.rejects = res;
    });
  }

  loadRejects() {
    if (this.rejectsTable) {
      this.rejectsTable.search(this._pageSize);
    }
  }

  downloadRejects() {
    this.migrationRejectService.getRejectXls(this.execution.id, this.execution.name);
  }
}
