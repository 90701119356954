import {Component} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {RouterService} from '../../../../bonding_shared/services/router-service';
import {SalesRepRelationVersionService} from '../../../../bonding_shared/services';
import {SalesRepRelationListComponent} from './sales-rep-relation-list.component';
import {BusinessObjectType} from '../../../../bonding_shared/model/dictionary-ids';

@Component({
  selector: 'sales-rep-relation-bonding-contract-inquiry-list',
  templateUrl: './sales-rep-relation-list.component.pug',
})
export class SalesRepRelationBondingContractInquiryListComponent extends SalesRepRelationListComponent {
  constructor(
    protected salesRepRelationVersionService: SalesRepRelationVersionService,
    public router: RouterService,
    protected translateService: TranslateService
  ) {
    super(salesRepRelationVersionService, router, translateService);
    this.relatedToTypeId = BusinessObjectType.BONDING_CONTRACT_INQUIRY_VERSION;
    this.type = 'bondingContractInquiry';
  }
}
