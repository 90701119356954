import {Component} from '@angular/core';
import {
  CompanySimpleDto,
  DictionaryBaseDto,
  DictionaryBaseService,
  PolicyInquiryCoInsurerCompanyDto,
  RelatedObject,
  RouterService,
} from '../../../../bonding_shared';
import {PolicyContractViewService} from '../../../policy/services/policy-contract-view.service';
import {InquirySectionDirective} from './inquiry-section';
import {PolicyInquiryCompanyRole} from '../../../../bonding_shared/model/dictionary-ids';

@Component({
  selector: 'inquiry-co-insurers-section',
  templateUrl: './inquiry-co-insurers-section.component.pug',
})
export class InquiryCoInsurersSectionComponent extends InquirySectionDirective {
  constructor(
    protected policyContractViewService: PolicyContractViewService,
    public router: RouterService,
    protected dictionaryBaseService: DictionaryBaseService
  ) {
    super(policyContractViewService);
    this.selectorNameList = [RelatedObject.CO_INSURERS];
    this.initializeSelectorEmitters(true);
  }

  addCoInsurer(company: CompanySimpleDto) {
    this.inquiry.coInsurers.push(<PolicyInquiryCoInsurerCompanyDto>{
      role: <DictionaryBaseDto>{id: PolicyInquiryCompanyRole.CO_INSURER},
      company: company,
    });
  }
}
