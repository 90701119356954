import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AppConfigService, GrowlService, LoggedUserService, RouterService} from '../bonding_shared/services';
import {ChangePasswordRequestDto, ErrorReason} from '../bonding_shared/model';
import {PasswordResetService} from '../portal/services/password-reset.service';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {CustomValidators} from '../bonding_shared/validators';
import {Subscription} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'reset-password',
  templateUrl: 'reset-password.component.html',
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
  private dto: ChangePasswordRequestDto = <ChangePasswordRequestDto>{};
  public showErrors = false;
  private paramsSubscription: Subscription;
  public form: UntypedFormGroup;
  public serverErrors: ErrorReason[];
  private afterResetMessage = 'Please, log in with your new password';
  public inProgress = false;

  constructor(
    protected route: ActivatedRoute,
    protected _router: Router,
    protected _loggedUserService: LoggedUserService,
    public appConfigService: AppConfigService,
    protected routerService: RouterService,
    protected passwordResetService: PasswordResetService,
    protected growlService: GrowlService,
    protected translateService: TranslateService
  ) {
    const passValidator = this.appConfigService.kuke
      ? CustomValidators.kukePasswordStrength
      : CustomValidators.credendoPasswordStrength;
    this.form = new UntypedFormGroup(
      {
        newPassword: new UntypedFormControl('', Validators.compose([Validators.required, passValidator])),
        retypePassword: new UntypedFormControl('', Validators.required),
      },
      CustomValidators.passwordConfirmValidation
    );
  }

  ngOnInit() {
    this.paramsSubscription = this.route.params.subscribe((params) => {
      this.dto.login = params['login'];
      this.dto.expiryTime = params['expiryTime'];
      this.dto.token = params['token'];
      this.translateService.use(params['lang']);
    });
  }

  ngOnDestroy() {
    if (this.paramsSubscription) {
      this.paramsSubscription.unsubscribe();
    }
  }

  resetPassword() {
    if (!this.form.valid) {
      this.showErrors = true;
      return;
    }
    this.dto.newPassword = this.form.get('newPassword').value;
    this.inProgress = true;
    this.passwordResetService.resetPassword(this.dto).subscribe({
      next: (_) => {
        this.inProgress = false;
        this.growlService.notice('Your password has been successfully changed');
        this._router.navigate(['login', this.afterResetMessage]);
      },
      error: (error) => {
        this.inProgress = false;
        this.serverErrors = error;
      },
    });
  }
}
