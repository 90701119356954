import {Component} from '@angular/core';
import {CompanyBaseDto, PolicyExporterDto, RelatedObject, RouterService} from '../../../../bonding_shared';
import {PolicyContractViewService} from '../../../policy/services/policy-contract-view.service';
import {InquirySectionDirective} from './inquiry-section';

@Component({
  selector: 'exporters-inquiry-section',
  templateUrl: './exporters-inquiry-section.component.pug',
})
export class ExportersInquirySectionComponent extends InquirySectionDirective {
  constructor(protected policyContractViewService: PolicyContractViewService, public router: RouterService) {
    super(policyContractViewService);
    this.selectorNameList = [RelatedObject.EXPORTER];
    this.initializeSelectorEmitters(true);
  }

  addExporter(company: CompanyBaseDto) {
    this.inquiry.exporters.push(<PolicyExporterDto>{company: company, selected: false});
  }
}
