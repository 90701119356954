/**
 * Created by jakubowski on 01.09.2017.
 */
import {NG_VALUE_ACCESSOR, ControlValueAccessor, AbstractControl} from '@angular/forms';
import {forwardRef, Component, Input} from '@angular/core';

const PASSWORD_ROW_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => PasswordRowComponent),
  multi: true,
};

@Component({
  selector: 'password-row',
  template: `
    <div *ngIf="control" class="bon-row">
      <label class="bon-label">{{ labelKey ? (labelKey | translate) : label }}</label>
      <input
        type="password"
        [value]="value"
        (input)="onInput($event)"
        [disabled]="disabled"
        [readonly]="readonly"
        class="bon-input"
        autocomplete="new-password"
      />
      <error-message [control]="control" [show]="showErrors"></error-message>
    </div>
    <div *ngIf="!control" class="bon-row">
      <label class="bon-label">{{ labelKey ? (labelKey | translate) : label }}</label>
      <input
        type="password"
        [ngModel]="value"
        (input)="onInput($event)"
        (blur)="onTouchedListeners()"
        [disabled]="disabled"
        [readonly]="readonly"
        class="bon-input"
        autocomplete="new-password"
      />
    </div>
  `,
  providers: [PASSWORD_ROW_CONTROL_VALUE_ACCESSOR],
})
export class PasswordRowComponent implements ControlValueAccessor {
  @Input() control: AbstractControl;
  @Input() label: string;
  @Input() labelKey: string;
  @Input() disabled: any;
  @Input() readonly: any;
  @Input() showErrors: boolean;

  private onChangeListeners: Function;
  public onTouchedListeners: Function;
  public value: string;

  writeValue(obj: any): void {
    if (obj === undefined) {
      this.value = '';
    } else {
      this.value = obj;
    }
  }

  registerOnChange(fn: any): void {
    this.onChangeListeners = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedListeners = fn;
  }

  onInput(event: Event) {
    this.value = (<any>event.target).value;
    this.onChangeListeners(this.value);
  }

  /**
   * Needed to be able to disable model-validated components. Such components must be disabled in FormGroup definition:
   *
   *  Example:
   * form = new FormGroup({
   *     first: new FormControl({value: 'Nancy', disabled: true}, Validators.required),
   *     last: new FormControl('Drew', Validators.required)
   *   });
   *
   * @param disabled
   */
  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }
}
