import {CountryRatingDetailsComponent} from './country-rating-details.component';
import {CountryRatingSearchComponent} from './country-rating-search.component';
import {CountryRatingSearchGuard} from './_guards';
import {CountryCategoryLimitsDetailsComponent} from './country-category-limits-details.component';

export const CountryRatingRoutes = [
  {
    path: 'country-category-limits-new',
    component: CountryCategoryLimitsDetailsComponent,
  },
  {
    path: 'country-category-limits-version/:versionId',
    component: CountryCategoryLimitsDetailsComponent,
  },
  {
    path: 'country-rating-details/:id/:type',
    component: CountryRatingDetailsComponent,
  },
  {
    path: 'country-rating-search/:type',
    component: CountryRatingSearchComponent,
    canActivate: [CountryRatingSearchGuard],
  },
];
