import {Component, Input, OnInit} from '@angular/core';
import {RouterService} from '../../../bonding_shared/services/router-service';
import {DictionaryService} from '../../../bonding_shared/services/dictionary.service';
import {PolicyInquiryOfferDto, VoteDto} from '../../../bonding_shared/model/dtos';
import {FormatService} from '../../../bonding_shared/services';

@Component({
  selector: 'inquiry-offers-voting',
  templateUrl: 'inquiry-offers-voting.component.html',
  styles: [],
})
export class InquiryOffersVotingComponent implements OnInit {
  @Input() set offers(o: PolicyInquiryOfferDto[]) {
    this._offers = o;
    this.updateVotes();
  }

  get offers() {
    return this._offers;
  }

  private _offers: PolicyInquiryOfferDto[] = null;
  voters: string[] = [];
  voting: {[key: number]: boolean[]} = {}; // key is offer id

  constructor(
    public router: RouterService,
    private dictionaryService: DictionaryService,
    private formatService: FormatService
  ) {}

  ngOnInit() {}

  updateVotes() {
    this.voters = [];
    this.voting = {};

    const votesIdSet = new Set<number>();
    const voteByUserId = new Map<number, VoteDto>();
    const addUser = (offerId: number, v: VoteDto) => {
      votesIdSet.add(v.user.id);
      voteByUserId.set(v.user.id, v);
    };

    this.iterOverVotes(addUser);

    const userPosition = new Map<number, number>();
    const userIdSorted: number[] = Array.from(votesIdSet).sort();
    let vote: VoteDto;
    for (let i in userIdSorted) {
      userPosition.set(<number>userIdSorted[i], +i);
      vote = voteByUserId.get(userIdSorted[i]);
      this.voters.push(vote.user.login + ' ' + this.formatService.formatDate(vote.date));
    }

    // init data structure
    let ofes = this.offers;
    if (ofes) {
      for (let ofe of ofes) {
        this.voting[ofe.id] = [];
        this.voting[ofe.id].length = userPosition.size;
      }
    }

    const addVoting = (offerId: number, v: VoteDto) => {
      let up = userPosition.get(v.user.id);
      this.voting[offerId][up] = v.approve;
    };

    this.iterOverVotes(addVoting);
  }

  private iterOverVotes(func: Function) {
    const offers = this.offers;
    if (offers) {
      for (let offer of offers) {
        const votes = offer.votes;
        if (votes) {
          // @ts-ignore
          for (let vote of votes) {
            func(offer.id, vote);
          }
        }
      }
    }
  }
}
