import {Component, OnInit} from '@angular/core';
import {DetailsView} from '../../../bonding_shared/components/details-view/details-view';
import {ActivatedRoute, Params} from '@angular/router';
import {AppConfigService, GrowlService, RouterService} from '../../../bonding_shared';
import {
  BusinessObjectType,
  PolicyInquiryStatus,
  SalesRepElementaryRight,
} from '../../../bonding_shared/model/dictionary-ids';

@Component({
  selector: 'inquiry-technical-details',
  templateUrl: 'inquiry-technical-details.component.html',
})
export class InquiryTechnicalDetailsComponent extends DetailsView implements OnInit {
  inquiryId: number;
  mainSalesRepUserId: number;
  private statusId: number;

  readonly BusinessObjectType = BusinessObjectType;
  readonly SalesRepElementaryRight = SalesRepElementaryRight;

  constructor(
    private route: ActivatedRoute,
    public routerService: RouterService,
    protected growlService: GrowlService,
    public appService: AppConfigService
  ) {
    super(growlService);
    this.saveButton.hidden = true;
    this.cancelButton.hidden = true;
  }

  ngOnInit() {
    this.route.params.subscribe((params) => this.initializeView(params));

    this.route.queryParams.subscribe((params) => {
      this.mainSalesRepUserId = +params['mainSalesRepUserId'];
      this.statusId = +params['statusId'];
    });
  }

  initializeView(params: Params) {
    this.inquiryId = +params['inquiryId'];
  }

  private isPending() {
    return PolicyInquiryStatus.PENDING_STATUSES.includes(this.statusId);
  }

  isAddSalesRepAvailable() {
    return this.isPending();
  }

  isAddBrokerAvailable() {
    return this.isPending() || this.statusId === PolicyInquiryStatus.POLICY_INQUIRY;
  }
}
