import {AfterViewInit, Component, EventEmitter, Input, Output} from '@angular/core';
import {OfferDecisionDto, PolicyInquiryOfferEnvelopeDto, PolicyInquiryVersionDto} from '../../../bonding_shared/model';
import {DictionaryService, OfferService, RouterService} from '../../../bonding_shared/services';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {PolicyElementaryRight, PolicyInquiryOfferStatus} from '../../../bonding_shared/model/dictionary-ids';
import {InquiryCountries} from '../domain/inquiry-countries';

@Component({
  selector: 'offer-decisions',
  templateUrl: 'offer-decisions.component.pug',
})
export class OfferDecisionsComponent implements AfterViewInit {
  readonly PolicyElementaryRight = PolicyElementaryRight;

  self = this;

  @Input()
  set offerId(offerid: number) {
    this._offerId = null;
    this.decisionsExists = false;
    if (offerid) {
      this._offerId = offerid;
      this.refreshDecisionsListOnInput();
    }
  }

  @Input()
  set offerStatusId(offerStatusId: number) {
    this._offerStatusId = null;
    if (offerStatusId) {
      this._offerStatusId = offerStatusId;
      this.refreshDecisionsListOnInput();
    }
  }

  @Input()
  set inquiry(inq: PolicyInquiryVersionDto) {
    if (inq && inq.countries) {
      InquiryCountries.existsNonMarketCountry(this.dictionaryService, inq).subscribe(
        (e) => (this.existsNonMarketCountry = e)
      );
    }
  }

  @Input() set edge(edge: boolean) {
    this.decisionRight = edge
      ? PolicyElementaryRight.POLICY_INQUIRY_EDGE_OFFER_DECISION
      : PolicyElementaryRight.POLICY_INQUIRY_CLIENT_OFFER_DECISION;
  }

  @Output() offerRefresh = new EventEmitter<() => Observable<PolicyInquiryOfferEnvelopeDto>>();

  portalMode = false;
  decisions: OfferDecisionDto[] = null;
  existsNonMarketCountry = false;
  decisionsExists = false;
  decisionRight = PolicyElementaryRight.POLICY_INQUIRY_EDGE_OFFER_DECISION;

  private _offerId: number = null;
  private _offerStatusId: number = null;

  constructor(
    public router: RouterService,
    private offerService: OfferService,
    private dictionaryService: DictionaryService
  ) {}

  ngAfterViewInit() {}

  getDecisionLabel(d: OfferDecisionDto) {
    if (!d) {
      return null;
    }
    if (d.decisionFor === true) {
      return 'offer.details.decisionsOffersSection.decisionFor';
    }
    if (d.decisionFor === false) {
      return 'offer.details.decisionsOffersSection.decisionAgain';
    }
    return null;
  }

  accept(d: OfferDecisionDto) {
    return this.sendDecision(d, true);
  }

  reject(d: OfferDecisionDto) {
    return this.sendDecision(d, false);
  }

  private sendDecision(d: OfferDecisionDto, up: boolean) {
    this.offerRefresh.emit(() => {
      d.decisionFor = up;
      this.decisions = null;
      return this.offerService.decideOffer(d).pipe(
        tap({
          next: (val) => {
            this.refreshDecisionsList();
          },
          error: (error) => {
            this.decisions = [];
          },
        })
      );
    });
  }

  private refreshDecisionsListOnInput() {
    if (this._offerId && this._offerStatusId && this._offerStatusId !== PolicyInquiryOfferStatus.NEW) {
      this.refreshDecisionsList();
    }
  }

  private refreshDecisionsList() {
    this.decisions = null;
    if (this._offerId) {
      setTimeout(() => {
        this.offerService.getDecisions(this._offerId).subscribe(
          (ds) => {
            this.decisions = ds;
            this.setDecisionsExists();
          },
          (err) => {
            console.log('Getting decisions problem', err);
            this.decisions = [];
          }
        );
      }, 500);
    }
  }

  private setDecisionsExists() {
    this.decisionsExists = this.decisionsExists || (this.decisions && this.decisions.length > 0);
  }

  canDecide(d: OfferDecisionDto) {
    return (
      d &&
      (d.decisionFor === undefined || d.decisionFor === null) &&
      this._offerStatusId === PolicyInquiryOfferStatus.FOR_DECISION
    );
  }
}
