import {InquiryDetailsComponent} from './inquiry-details.component';
import {InquirySearchComponent} from './inquiry-search.component';
import {OfferDetailsComponent} from './offer-details.component';
import {KukeOfferDetailsComponent} from './kuke/kuke-offer-details.component';
import {InquiryTechnicalDetailsComponent} from './components/inquiry-technical-details.component';
import {CalculatorDetailsComponent} from './kuke/calculator/calculator-details.component';
import {AnnexInquiryDetailsComponent} from './annex-inquiry-details.component';
import {RenewalInquiryDetailsComponent} from './renewal-inquiry-details.component';
import {
  AnnexInquiryDetailsGuard,
  InquiryCalculatorGuard,
  InquiryDetailsGuard,
  InquiryOfferDetailsGuard,
  InquiryTechnicalDetailsGuard,
  RenewalInquiryDetailsGuard,
} from './_guards';
import {CalculatorPbGDetailsComponent} from './kuke/calculator/calculator-pbg-details.component';
import {MehibCalculatorDetailsComponent} from './mehib/calculator/mehib-calculator-details.component';
import {EcgCalculatorDetailsComponent} from './ecg/calculator/ecg-calculator-details.component';

export const InquiryRoutes = [
  {
    path: 'inquiry-details/:id',
    component: InquiryDetailsComponent,
    canActivate: [InquiryDetailsGuard],
  },
  {
    path: 'inquiry-details/:id/:clientId',
    component: InquiryDetailsComponent,
    canActivate: [InquiryDetailsGuard],
  },
  {
    path: 'inquiry-details/:id/:clientId/:typeId/:buId',
    component: InquiryDetailsComponent,
    canActivate: [InquiryDetailsGuard],
  },
  {
    path: 'annex-inquiry-details/:id',
    component: AnnexInquiryDetailsComponent,
    canActivate: [AnnexInquiryDetailsGuard],
  },
  // TODO ANGULAR MIGRATION , not compiling Error during template compile of 'AppModule'
  // Function calls are not supported in decorators but 'View' was called in 'routes'
  // Changed to static value
  {
    path: 'renewal-inquiry-details/:id',
    component: RenewalInquiryDetailsComponent,
    canActivate: [RenewalInquiryDetailsGuard],
  },
  // {
  //   path: View.INQUIRY_DETAILS_NEW.name + '/:readFromCache',
  //   component: InquiryDetailsComponent,
  //  canActivate: [InquiryDetailsGuard]
  // },
  //   Function calls are not supported in decorators but 'View' was called in 'routes'
  {
    path: 'inquiry-details-newclient/:readFromCache',
    component: InquiryDetailsComponent,
  },
  {
    path: 'inquiry-details-subinsured/:readFromCache',
    component: InquiryDetailsComponent,
  },
  {
    path: 'renewal-inquiry-details/:id',
    component: RenewalInquiryDetailsComponent,
  },
  {
    path: 'inquiry-search',
    component: InquirySearchComponent,
  },
  {
    path: 'inquiry-offer-details/:inquiryId/offer/:offerId',
    component: OfferDetailsComponent,
    canActivate: [InquiryOfferDetailsGuard],
  },
  {
    path: 'inquiry-offer-details/:inquiryId/newoffer/:offerTypeId',
    component: OfferDetailsComponent,
    canActivate: [InquiryOfferDetailsGuard],
  },
  {
    path: 'inquiry-offer-details/:inquiryId/newoffer/:offerTypeId/sourceoffer/:sourceOfferId',
    component: OfferDetailsComponent,
    canActivate: [InquiryOfferDetailsGuard],
  },
  {
    path: 'inquiry-offer-details/:inquiryId/newoffer/:offerTypeId/sourceoffer/:sourceOfferId/:blogOffer',
    component: OfferDetailsComponent,
    canActivate: [InquiryOfferDetailsGuard],
  },
  {
    path: 'kuke-inquiry-offer-details/:offerId',
    component: KukeOfferDetailsComponent,
    canActivate: [InquiryOfferDetailsGuard],
  },
  {
    path: 'kuke-inquiry-offer-details/:offerId/:inquiryId',
    component: KukeOfferDetailsComponent,
    canActivate: [InquiryOfferDetailsGuard],
  },
  {
    path: 'kuke-inquiry-offer-details/:inquiryId/newoffer/:offerTypeId/:edge/:parentOfferId',
    component: KukeOfferDetailsComponent,
    canActivate: [InquiryOfferDetailsGuard],
  },
  {
    path: 'inquiry-details-technical-details/:inquiryId',
    component: InquiryTechnicalDetailsComponent,
    canActivate: [InquiryTechnicalDetailsGuard],
  },
  {
    path: 'inquiry-details-calculator-details/:offerId',
    component: CalculatorDetailsComponent,
    canActivate: [InquiryCalculatorGuard],
  },
  {
    path: 'inquiry-details-calculator-pbg-details',
    component: CalculatorPbGDetailsComponent,
    canActivate: [InquiryCalculatorGuard],
  },
  {
    path: 'mehib-calculator-details/:inquiryId/:offerId',
    component: MehibCalculatorDetailsComponent,
    canActivate: [InquiryCalculatorGuard],
  },
  {
    path: 'ecg-calculator-details/:inquiryId/:offerId',
    component: EcgCalculatorDetailsComponent,
    canActivate: [InquiryCalculatorGuard],
  },
];
