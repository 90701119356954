import {GroupAssignmentRulesComponent} from './group-assignment-rules.component';
import {UserAssignmentRulesComponent} from './user-assignment-rules.component';
import {AssignmentRuleAccessGuard} from './assignment-rule-access-guard';

export const AssignmentRuleRoutes = [
  {
    path: 'group-assignment-rules',
    component: GroupAssignmentRulesComponent,
    canActivate: [AssignmentRuleAccessGuard],
  },
  {
    path: 'user-assignment-rules',
    component: UserAssignmentRulesComponent,
    canActivate: [AssignmentRuleAccessGuard],
  },
];
