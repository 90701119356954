import {BondingContractOfferDetailsComponent} from './bonding-contract-offer-details.component';
import {BONDING_CONTRACT_OFFER_DETAILS} from '../../../bonding_shared/services';
import {BondingContractOfferDetailsGuard} from './_guards';

export const BondingContractOfferRoutes = [
  {
    path: BONDING_CONTRACT_OFFER_DETAILS + '/:id',
    component: BondingContractOfferDetailsComponent,
    canActivate: [BondingContractOfferDetailsGuard],
  },
  {
    path: BONDING_CONTRACT_OFFER_DETAILS,
    component: BondingContractOfferDetailsComponent,
    canActivate: [BondingContractOfferDetailsGuard],
  },
];
