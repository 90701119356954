/**
 *
 */
import {Component, EventEmitter, Input, OnInit, Optional, Output} from '@angular/core';
import {
  BusinessPropertiesService,
  BusinessUnitIdDto,
  CompanyCriteriaDto,
  CompanyDto,
  CompanyService,
  DictionaryBaseDto,
  DictionaryBaseService,
  DictionaryService,
  LoggedUserService,
  PolicyContractService,
  PolicyInquiryFalconDto,
  PolicyInquiryReceivableDto,
  PolicyInquiryStatementDto,
  PolicyInquiryVersionDto,
  RouterService,
  SalesRepRelationVersionService,
  SearchDataProvider,
  SettableObjectType,
  UserDto,
  UserService,
  View,
} from '../../../bonding_shared';
import {ControlContainer, NgForm, UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {
  COMPANY_TYPE_INSURERS,
  Currency,
  InquiryProductType,
  PolicyInquiryStatementType,
} from '../../../bonding_shared/model/dictionary-ids';
import {AbstractInquiryProduct} from './abstract-inquiry.product';
import {VisibilityService} from '../services/visibility.service';
import {ActivatedRoute, Params} from '@angular/router';
import * as moment from 'moment';
import {isType} from '../domain/offer-allowance';
import {InquiryCountryInitialiazerService} from '../services/inquiry-countries-initialiazer.service';
import {PortalSalesRepRelationVersionService} from '../../../portal/services/portal-sales-rep-relation-version.service';
import {isExceptedEdition} from '../domain/inquiry-common';

@Component({
  selector: 'inquiry-product-kuke',
  templateUrl: './inquiry-product-kuke.component.pug',
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}],
  styles: ['.bon-header {margin-top: 15px;}'],
  providers: [InquiryCountryInitialiazerService],
})
export class InquiryProductKukeComponent extends AbstractInquiryProduct implements OnInit {
  readonly policyValidFromMax: Date = moment().add(30, 'days').toDate();
  readonly policyValidFromMin: Date = moment().subtract(30, 'days').toDate();

  salesRepUsers: UserDto[] = null;

  get inquiry() {
    return this._inquiry;
  }

  @Input()
  set inquiry(inq: PolicyInquiryVersionDto) {
    this._inquiry = inq;
    this.loadSalesUsers();
    this.loadSalesRepUsers();

    this.statements = {
      dataConsistentWithFacts: this.getStatement(PolicyInquiryStatementType.DATA_CONSISTENT_WITH_FACTS),
      documentaryConditions: this.getStatement(PolicyInquiryStatementType.FAMILIARIZATION_WITH_DOCUMENTARY_CONDITIONS),
      conditionsOfPolishness: this.getStatement(
        PolicyInquiryStatementType.GETTING_TO_KNOW_THE_CONDITIONS_OF_POLISHNESS
      ),
      consentToContact: this.getStatement(
        PolicyInquiryStatementType.CONSENT_TO_CONTACT_THE_CONTRACTOR_USING_THE_CUSTOMERS_NAME
      ),
      processingPersonalData: this.getStatement(
        PolicyInquiryStatementType.INFORMATION_CLAUSE_REGARDING_THE_PROCESSING_OF_PERSONAL_DATA
      ),
      sharingInformation: this.getStatement(
        PolicyInquiryStatementType.CLAUSE_OF_CONSENT_FOR_SHARING_INFORMATION_COVERED_BY_INSURANCE_SECRECY
      ),
      russianEmbargo: this.getStatement(PolicyInquiryStatementType.RUSSIAN_EMBARGO),
      marketingConsent: this.getStatement(PolicyInquiryStatementType.MARKETING_CONSENT),
    };

    this.selfRetention = null;
    if (inq && inq.selfRetention) {
      if (inq.selfRetention === 5) {
        this.selfRetention = this.selfRetentions[0];
      } else if (inq.selfRetention === 10) {
        this.selfRetention = this.selfRetentions[1];
      }
    }

    this.visible = this.visibilityService.getVisibility(this.getInquiryTypeId());

    if (!this.visible.buyers && inq.outstandingsBuyers && inq.outstandingsBuyers.length > 0) {
      inq.outstandingsBuyers = [];
    }

    if (!inq) {
      this.receivable = null;
    } else if (!this.visible.receivables) {
      this.receivable = null;
    } else {
      if (!inq.receivables || inq.receivables.length === 0) {
        inq.receivables = [<PolicyInquiryReceivableDto>{}];
      }
      if (inq.receivables.length > 1) {
        console.log('ERROR: inquiry receivables more than one: ', inq.receivables.length);
      }
      this.receivable = inq.receivables[0];
    }
  }

  @Output() countriesInitiated = new EventEmitter<any>();

  statements: {[key: string]: boolean | null};

  selfRetention;
  selfRetentions = [
    {id: 0, value: 5},
    {id: 1, value: 10},
  ];

  receivable: PolicyInquiryReceivableDto;

  private readonly openCollectionFormGroup = new UntypedFormGroup({});
  private readonly individualLossesAggregatedFormGroup = new UntypedFormGroup({});

  falconLimitAllowed: Boolean = false;

  devMode = false;

  constructor(
    dictionaryBaseService: DictionaryBaseService,
    router: RouterService,
    private dictionaryService: DictionaryService,
    private comService: CompanyService,
    private formBuilder: UntypedFormBuilder,
    @Optional() private salesRepRelationVersionService: SalesRepRelationVersionService,
    @Optional() private portalSalesRepRelationVersionService: PortalSalesRepRelationVersionService,
    protected visibilityService: VisibilityService,
    private route: ActivatedRoute,
    public loggedUserService: LoggedUserService,
    policyContractService: PolicyContractService,
    businessPropertiesService: BusinessPropertiesService,
    private countryInitialiazerService: InquiryCountryInitialiazerService,
    private userService: UserService
  ) {
    super(
      dictionaryBaseService,
      router,
      loggedUserService,
      policyContractService,
      businessPropertiesService,
      visibilityService
    );
    this.initInsurers();
  }

  private initInsurers() {
    const comSearchDataProvider = new SearchDataProvider<CompanyCriteriaDto, CompanyDto>(this.comService);
    comSearchDataProvider.searchCriteria.criteria = <CompanyCriteriaDto>{companyTypes: COMPANY_TYPE_INSURERS};
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.form.addControl('outstandingsBuyers', new UntypedFormGroup({}));
    this.route.queryParams.subscribe((params) => this.readParams(params));

    if (this.visible.currentInsurer) {
      this.setValidatorsOnInsured();
    }

    this.countryInitialiazerService.initMarketNonMarketCountries(
      this.getInquiryType(),
      this.countriesInitiated,
      this.inquiry,
      false
    );

    if (this.visible.falconLimitAllowed) {
      if (this.inquiry.falcon) {
        this.falconLimitAllowed = true;
      }
      this.updateAttachemntsOfFalconForm();
    }
  }

  private readParams(params: Params) {
    this.devMode = !!params['devMode'];
  }

  onStatementChange() {
    if (!this.inquiry.statements) {
      this.inquiry.statements = [];
    }

    this.rewriteStatement(
      PolicyInquiryStatementType.DATA_CONSISTENT_WITH_FACTS,
      this.statements.dataConsistentWithFacts
    );

    this.rewriteStatement(
      PolicyInquiryStatementType.FAMILIARIZATION_WITH_DOCUMENTARY_CONDITIONS,
      this.statements.documentaryConditions
    );

    this.rewriteStatement(
      PolicyInquiryStatementType.GETTING_TO_KNOW_THE_CONDITIONS_OF_POLISHNESS,
      this.statements.conditionsOfPolishness
    );

    this.rewriteStatement(
      PolicyInquiryStatementType.CONSENT_TO_CONTACT_THE_CONTRACTOR_USING_THE_CUSTOMERS_NAME,
      this.statements.consentToContact
    );

    this.rewriteStatement(
      PolicyInquiryStatementType.INFORMATION_CLAUSE_REGARDING_THE_PROCESSING_OF_PERSONAL_DATA,
      this.statements.processingPersonalData
    );

    this.rewriteStatement(
      PolicyInquiryStatementType.CLAUSE_OF_CONSENT_FOR_SHARING_INFORMATION_COVERED_BY_INSURANCE_SECRECY,
      this.statements.sharingInformation
    );

    this.rewriteStatement(PolicyInquiryStatementType.RUSSIAN_EMBARGO, this.statements.russianEmbargo);
    this.rewriteStatement(PolicyInquiryStatementType.MARKETING_CONSENT, this.statements.marketingConsent);
  }

  private rewriteStatement(typeId: number, value: boolean) {
    const statements = this.inquiry.statements;
    const index = statements.findIndex((st) => st.statementType.id === typeId);
    if (index === -1) {
      const statement: PolicyInquiryStatementDto = {
        id: null,
        statementType: <DictionaryBaseDto>{id: typeId},
        value: value,
        version: 1,
      };
      statements.push(statement);
    } else {
      statements[index].value = value;
    }
  }

  private getStatement(typeId: number) {
    const stats = this._inquiry && this._inquiry.statements;
    if (stats) {
      const stat = stats.find((st) => st.statementType.id === typeId);
      if (stat !== undefined) {
        return stat.value;
      }
    }
    return null;
  }

  onChangeSelfRetention() {
    this._inquiry.selfRetention = this.selfRetention.value;
  }

  private getInquiryTypeId() {
    const type = this.getInquiryType();
    return type && type.id;
  }

  private getInquiryType() {
    return this._inquiry && this._inquiry.productType;
  }

  protected loadSalesUsers() {
    const businessUnit: BusinessUnitIdDto = this.inquiry.businessUnit;
    this.userService.getSalesUsers(businessUnit).subscribe((users) => {
      this.salesUsers = users;
    });
  }

  private loadSalesRepUsers() {
    if (!this.salesRepUsers && this.inquiry.id) {
      const salesService = this.portalSalesRepRelationVersionService || this.salesRepRelationVersionService;
      if (salesService) {
        salesService.getSalesRepUsers(this.inquiry.id).subscribe({
          next: (result) => {
            if (result && result.length > 0) {
              this.salesRepUsers = result;
            } else {
              this.salesRepUsers = [];
            }
          },
          error: (error) => {
            this.salesRepUsers = [];
            console.error('loadSalesRepUsers error', error);
          },
        });
      }
    }
  }

  get isSalesRepUsersEditable() {
    const sru = this.salesRepUsers;
    return (!this.readonly || isExceptedEdition(this.inquiry?.status?.id, true)) && sru && sru.length > 0;
  }

  onChangeReceivablesLastThreeYearsInsured() {
    this.setValidatorsOnInsured();
  }

  private setValidatorsOnInsured() {
    if (!this.inquiry) {
      return;
    }
    const f = this.form;
    if (this.inquiry.receivablesLastThreeYearsInsured) {
      f.addControl('openCollection', this.openCollectionFormGroup);
      f.removeControl('individualLossesAggregated');
    } else {
      f.addControl('individualLossesAggregated', this.individualLossesAggregatedFormGroup);
      f.removeControl('openCollection');
    }
  }

  isYearTableNotFull(items: any[]): boolean {
    return !items || items.length < 3;
  }

  isEmptyYearDate(item: any) {
    return item && !item.yearDate;
  }

  onChangeFalconLimitAllowed() {
    this.updateAttachemntsOfFalconForm();
  }

  private updateAttachemntsOfFalconForm() {
    if (this.falconLimitAllowed && !this.inquiry.falcon) {
      this.inquiry.falcon = <PolicyInquiryFalconDto>{
        currency: <DictionaryBaseDto>{id: Currency.PLN.valueOf()},
        amount: 50000,
        loanPeriodDays: 60,
      };
    }
  }

  public beforeSave(inquiry: PolicyInquiryVersionDto) {
    if (!this.falconLimitAllowed) {
      inquiry.falcon = null;
    }
  }

  isAddOfferButton(): boolean {
    return !this.policy;
  }

  private isType(typeId: InquiryProductType): boolean {
    const inq = this.inquiry;
    return isType(inq && inq.productType, typeId);
  }

  protected initPolicySearchCriteria() {}

  // plannedSalesInTheNext12MonthsSectionVisible() {
  //   return (
  //     this.isType(InquiryProductType.KUKE_KOM_EP) === true ||
  //     this.isType(InquiryProductType.KUKE_KOM_FK) === true ||
  //     this.isType(InquiryProductType.KUKE_GSP_PBG) === true ||
  //     this.isType(InquiryProductType.KUKE_GSP_PNS) === true
  //   );
  // }

  isPaymentTermsVisible() {
    return (
      (this.visible && this.visible.receivables) ||
      this.isType(InquiryProductType.KUKE_KOM_EP) ||
      this.isType(InquiryProductType.KUKE_KOM_FK) ||
      this.isType(InquiryProductType.KUKE_KOM_FE) === true
    );
  }

  createNewSubinsured() {
    this.router.toCompanyDetailsNew(View.INQUIRY_DETAILS_NEW_SUBINSURED.id, SettableObjectType.INQUIRY_SUBINSURED);
  }

  isMaxCreditLimitRequired() {
    return (
      this.inquiry.inquiryType === 'POLICY_INQUIRY' &&
      (this.isType(InquiryProductType.KUKE_KOM_OP) || this.isType(InquiryProductType.KUKE_KOM_SP))
    );
  }

  isMaxPaymentTermDaysLongRequired() {
    return (
      this.inquiry.inquiryType === 'POLICY_INQUIRY' &&
      (this.isType(InquiryProductType.KUKE_KOM_OP) ||
        this.isType(InquiryProductType.KUKE_KOM_FK) ||
        this.isType(InquiryProductType.KUKE_KOM_FE) ||
        this.isType(InquiryProductType.KUKE_KOM_SP) ||
        this.isType(InquiryProductType.KUKE_KOM_EP))
    );
  }
}
