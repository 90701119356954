import {Directive, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {RouterService} from '../../bonding_shared/services/router-service';
import {TranslateService} from '@ngx-translate/core';
import {GrowlService} from '../../bonding_shared/services/growl/growl.service';
import {
  CollectionBaseDto,
  CollectionInvoiceDto,
  CollectionVersionSimpleDto,
  DictionaryBaseDto,
} from '../../bonding_shared/model';
import {CollectionVersionService} from '../../bonding_shared/services/collection-version.service';
import {CollectionAuditedService} from '../../bonding_shared/services/collection-audited.service';
import {CollectionGuiService} from './services/collection-gui.service';
import {LoggedUserService} from '../../bonding_shared/services';
import {AuditedViewAbstract} from '../../bonding_shared/components/details-view/audited-view-abstract';
import * as _ from 'lodash';
import * as moment from 'moment';
import {NumberUtils} from '../../bonding_shared/utils/number-utils';

@Directive()
export abstract class CollectionAuditedViewAbstract<T> extends AuditedViewAbstract<T> implements OnInit {
  version: CollectionVersionSimpleDto = CollectionGuiService.getEmptyVersion();

  get parent(): CollectionBaseDto {
    return this.version.parent;
  }
  set parent(parent: CollectionBaseDto) {
    this.version.parent = parent;
  }

  protected constructor(
    _route: ActivatedRoute,
    protected versionService: CollectionVersionService,
    auditedService: CollectionAuditedService<T>,
    router: RouterService,
    translateService: TranslateService,
    growlService: GrowlService,
    loggedUserService: LoggedUserService
  ) {
    super(_route, auditedService, router, translateService, growlService, loggedUserService, 'parentId');
  }

  extraInitialization() {
    super.extraInitialization();
    this.loadLastCollectionVersion();
  }

  loadLastCollectionVersion() {
    this.versionService.getLastVersionSimpleByParentId(this.id).subscribe(
      (version) => {
        this.version = version;
        this.onVersionLoad();
      },
      (error) => this.handleServerError(error)
    );
  }

  areTheSameCurrencies(array: {currency: DictionaryBaseDto}[]): boolean {
    return (
      this.version.currency &&
      array &&
      array.every((inv) => inv.currency && inv.currency.id === this.version.currency.id)
    );
  }

  getUnpaidInvoiceCurrencies(): DictionaryBaseDto[] {
    return this.getAllInvoiceCurrencies().filter((x) => this.getTotalBalance(x) > 0);
  }

  getAllInvoiceCurrencies(): DictionaryBaseDto[] {
    return _.uniqBy(
      this.version.collectionInvoices.map((x) => x.currency),
      (x) => x.id
    );
  }

  getInvoices(currency?: DictionaryBaseDto, minPaymentRegistrationDate?: Date): CollectionInvoiceDto[] {
    return this.version.collectionInvoices.filter(
      (collectionInvoice) =>
        (!currency || collectionInvoice.currency.id === currency.id) &&
        (!minPaymentRegistrationDate ||
          (collectionInvoice.lastPaymentRegistrationDate &&
            minPaymentRegistrationDate &&
            moment(collectionInvoice.lastPaymentRegistrationDate).isSameOrAfter(minPaymentRegistrationDate)))
    );
  }

  getInvoicesSum(
    mapper: (CollectionInvoiceDto) => number,
    currency?: DictionaryBaseDto,
    minPaymentRegistrationDate?: Date
  ): number {
    const invoices = this.getInvoices(currency, minPaymentRegistrationDate);
    return NumberUtils.roundMoney(invoices.map(mapper).reduce((a1, a2) => a1 + a2, 0));
  }

  getTotalBalance(currency: DictionaryBaseDto): number {
    return this.getInvoicesSum((x) => x.openAmountGross - (x.paidAmount || 0), currency);
  }

  getTotalPaid(currency: DictionaryBaseDto, minPaymentRegistrationDate?: Date): number {
    return this.getInvoicesSum((x) => x.paidAmount || 0, currency, minPaymentRegistrationDate);
  }

  onVersionLoad() {}
}
