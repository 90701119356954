import {Component, OnInit, ViewChild} from '@angular/core';
import {RouterService} from '../../../bonding_shared/services/router-service';
import {SearchView} from '../../../bonding_shared/components/search/search-view/search-view';
import {ThirdPartyGuiService} from '../services/thirdparty-gui.service';
import {SearchViewComponent} from '../../../bonding_shared/components/search/search-view/search-view.component';
import {ThirdPartyBaseDto, ThirdPartyCriteriaDto, ThirdPartyDto} from '../../../bonding_shared/model/dtos';
import {ThirdpartySearchListComponent} from './thirdparty-search-list.component';
import {SearchDataProvider} from '../../../bonding_shared/services/search-data-provider';
import {AppConfigService, LoggedUserService} from '../../../bonding_shared/services';
import {ListEmitters} from '../../../bonding_shared/components/details-view/list-emitters';

@Component({
  selector: 'thirdparty-search',
  templateUrl: './thirdparty-search.component.pug',
})
export class ThirdPartySearchComponent extends ListEmitters implements OnInit {
  @ViewChild(SearchViewComponent, {static: true}) searchView: SearchViewComponent<
    ThirdPartyCriteriaDto,
    ThirdPartyBaseDto
  >;
  @ViewChild(ThirdpartySearchListComponent, {static: true}) thirdPartyList: ThirdpartySearchListComponent;
  dataProvider: SearchDataProvider<ThirdPartyCriteriaDto, ThirdPartyBaseDto>;
  portalMode = true;

  constructor(
    public guiService: ThirdPartyGuiService,
    public router: RouterService,
    private loggedUserService: LoggedUserService,
    public appService: AppConfigService
  ) {
    super();
    this.portalMode = loggedUserService.portal;
  }

  ngOnInit() {
    this.dataProvider = this.guiService.dataProvider;
  }
}
