import {Component, Input} from '@angular/core';
import {SalesLeadDto} from '../../../../../bonding_shared';

@Component({
  selector: 'contact-note-lead-source',
  templateUrl: './contact-note-lead-source.component.pug',
})
export class ContactNoteLeadSourceComponent {
  @Input() salesLead: SalesLeadDto;
  @Input() showErrors: boolean;

  constructor() {}
}
