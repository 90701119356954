import {Component} from '@angular/core';
import {
  ComboItem,
  ExternalProviderVersionCriteriaDto,
  ExternalProviderVersionDto,
  RouterService,
  SearchDataProvider,
} from '../../bonding_shared';
import {ExternalProviderGuiService} from './services/external-provider-gui.service';
import {ListEmitters} from '../../bonding_shared/components/details-view/list-emitters';
import {CollectionElementaryRight} from '../../bonding_shared/model/dictionary-ids';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'external-provider-search',
  templateUrl: 'external-provider-search.component.pug',
})
export class ExternalProviderSearchComponent extends ListEmitters {
  readonly CollectionElementaryRight = CollectionElementaryRight;

  dataProvider: SearchDataProvider<ExternalProviderVersionCriteriaDto, ExternalProviderVersionDto>;
  versionPhases: ComboItem[] = [
    new ComboItem('ACTIVE', this.translateService.instant('common.current')),
    new ComboItem('LAST', this.translateService.instant('common.last')),
  ];
  readonly allLabel = this.translateService.instant('All');

  get criteria(): ExternalProviderVersionCriteriaDto {
    return this.guiService.dataProvider.searchCriteria.criteria;
  }

  constructor(
    public router: RouterService,
    public guiService: ExternalProviderGuiService,
    private translateService: TranslateService
  ) {
    super();
    this.selectorNameList = ['Company'];
    this.initializeSelectorEmitters(true);
  }

  newExecuted() {
    this.router.toExternalProviderDetailsNew();
  }
}
