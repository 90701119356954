<div class="bon-card" style="width: 100%;">
  <div class="bon-fixed-header">
    <div class="bon-title" translate>{{title | translate}}</div>
    <div class="bon-fixed-block">
      <div class="card-block">
        <div class="float-left">
          <button class="bon-btn-success btn-widest" type="button" (click)="saveButton()" [disabled]="inProgress" translate>
            Create and send confirmation email
          </button>
          <button class="bon-btn-cancel btn-wider" type="button" (click)="cancelButton()" translate>
            Cancel
          </button>
        </div>
        <div class="clear-both"></div>
      </div>
    </div>
  </div>
  <div class="scrollable-content" style="margin-top: 100px;">

    <div class="details-content">
      <backend-error [errors]="serverErrors"></backend-error>
      <form [formGroup]="form">
        <bon-section labelKey="common.section.details">
          <div class="bon-card-group" style="padding-bottom: 25px;">
            <div class="bon-card-inner">
              <input-row labelKey="common.firstName" formControlName="name"
                         [showErrors]="showErrors" [control]="form.controls.name"></input-row>
              <input-row labelKey="common.lastName" formControlName="familyName"
                         [showErrors]="showErrors" [control]="form.controls.familyName"></input-row>
              <input-row labelKey="common.email" formControlName="email"
                         [showErrors]="showErrors" [control]="form.controls.email"></input-row>
              <dict-row labelKey="user.data.language" [showErrors]="showErrors" [control]="form.controls.language"
                        [codeRegexp]="langCodeRegexp" dictionary="Language" name="language" (changeItem)="onLanguageChange($event)"></dict-row>
            </div>
            <div class="bon-card-inner">
              <company-section label="{{'Company' | translate}}" [clearable]="false"
                               [openSelectorEmitter]="companySelectorEmitter" [showLink]="true"
                               [invalid]="showErrors && !user.company" formControlName="company"></company-section>
            </div>
          </div>
        </bon-section>

        <div class="bon-card-group">
          <div class="bon-card-inner">
            <company-selector-simple (selectItem)="form.controls.company.setValue($event)" title="{{'common.company' | translate}}"
                                     [open]="companySelectorEmitter" [companyType]="companyType"
                                     [showDuplicates]="false" [searchModeSwitchable]="true">
            </company-selector-simple>
          </div>
        </div>

      </form>
    </div>
  </div>
</div>
