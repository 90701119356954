import {Component, Input} from '@angular/core';
import {ControlContainer, NgForm, UntypedFormGroup} from '@angular/forms';
import {DictionaryService, UserService} from '../../bonding_shared';
import {CommonPolicyAssignmentCriteria} from './common-policy-assignment-criteria';

@Component({
  selector: 'policy-assignment-criteria',
  templateUrl: 'policy-assignment-criteria.component.pug',
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}],
})
export class PolicyAssignmentCriteriaComponent extends CommonPolicyAssignmentCriteria {
  @Input() dataForm: UntypedFormGroup;
  @Input() showErrors: boolean;

  constructor(protected dictionaryFullService: DictionaryService, protected userService: UserService) {
    super(dictionaryFullService, userService);
  }
}
