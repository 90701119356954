import {Component, OnInit} from '@angular/core';
import {DetailsView} from '../../../../bonding_shared/components/details-view/details-view';
import {ActivatedRoute, Params} from '@angular/router';
import {
  AppConfigService,
  BondingContractInquiryBaseDto,
  GrowlService,
  LoggedUserService,
  RouterService,
  UserService,
  UserSimpleDto,
} from '../../../../bonding_shared';
import {BusinessObjectType} from '../../../../bonding_shared/model/dictionary-ids';
import {BondingContractInquiryService} from '../../../../bonding_shared/services/bonding-contract-inquiry.service';

@Component({
  selector: 'bonding-contract-inquiry-technical-details',
  templateUrl: 'bonding-contract-inquiry-technical-details.component.pug',
})
export class BondingContractInquiryTechnicalDetailsComponent extends DetailsView implements OnInit {
  inquiryVersionId: number;
  mainSalesRepUserId: number;
  public managers: UserSimpleDto[];
  inquiry: BondingContractInquiryBaseDto;

  readonly BusinessObjectType = BusinessObjectType;

  constructor(
    private route: ActivatedRoute,
    public router: RouterService,
    protected growlService: GrowlService,
    public appService: AppConfigService,
    private loggedUserService: LoggedUserService,
    private userService: UserService,
    private bondingContractInquiryService: BondingContractInquiryService
  ) {
    super(growlService);
    this.saveButton.hidden = this.portal;
    this.cancelButton.hidden = this.portal;
  }

  ngOnInit() {
    this.route.params.subscribe((params) => this.initializeView(params));

    this.route.queryParams.subscribe((params) => {
      this.mainSalesRepUserId = +params['mainSalesRepUserId'];
    });
  }

  initializeView(params: Params) {
    this.inquiryVersionId = +params['inquiryVersionId'];
    this.bondingContractInquiryService.getParentByVersionId(this.inquiryVersionId).subscribe({
      next: (pll) => {
        this.inquiry = pll;
        this.objectNotFound = false;
        this.userService.getBondingSupport(this.inquiry.businessUnit?.id).subscribe((res) => {
          this.managers = res;
        });
      },
      error: () => this.onError(),
    });
  }

  onSave() {
    this.bondingContractInquiryService.setManager(this.inquiry?.id, this.inquiry?.manager?.id).subscribe({
      next: (pll) => {
        this.inquiry = pll;
        this.onSuccess();
      },
      error: () => this.onError(),
    });
  }

  onSuccess() {
    this.growlService.notice('Success!');
    this.objectNotFound = false;
  }
  onError() {
    this.objectNotFound = true;
    this.growlService.error('error.objectNotFound');
  }

  toBrokerContractRelationDetails() {
    this.router.toBrokerContractRelationDetails(
      0,
      this.inquiryVersionId,
      BusinessObjectType.BONDING_CONTRACT_INQUIRY_VERSION,
      0
    );
  }

  toSalesRepDetails() {
    this.router.toSalesRepRelationDetailsNew(
      this.inquiryVersionId,
      BusinessObjectType.BONDING_CONTRACT_INQUIRY_VERSION,
      undefined
    );
  }

  get portal(): boolean {
    return this.loggedUserService.portal;
  }
}
