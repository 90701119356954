import {ServiceNotesComponent} from './service-notes.component';
import {ServiceNotesGuard} from './_guards/service-notes.guard';

export const ServiceNotesRoutes = [
  {
    path: 'service-notes/:businessObjectType/:businessObjectId',
    component: ServiceNotesComponent,
    canActivate: [ServiceNotesGuard],
  },
  {
    path: 'service-notes/:businessObjectType/:businessObjectId/:returnId',
    component: ServiceNotesComponent,
    canActivate: [ServiceNotesGuard],
  },
];
