import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Params} from '@angular/router';
import {RouterService} from '../../bonding_shared/services/router-service';
import {DetailsView} from '../../bonding_shared/components/details-view/details-view';
import {DictionaryBaseDto, DictionaryDto, UserDto} from '../../bonding_shared/model/dtos';
import {UserService} from '../../bonding_shared/services/user.service';
import {UserDataComponent} from './components/user-data.component';
import {DictionaryService} from '../../bonding_shared/services/dictionary.service';
import {UserGroupBaseDto} from '../../bonding_shared/model';
import {GrowlService} from '../../bonding_shared/services/growl/growl.service';
import {StringUtils} from '../../bonding_shared/utils';
import {ConfirmDialogComponent} from '../../bonding_shared/components/confirm-dialog';
import {mergeMap} from 'rxjs/operators';
import {from, Observable, of} from 'rxjs';
import {AppConfigService, LoggedUserService} from '../../bonding_shared/services';
import {Language, UserStatus, UserType} from '../../bonding_shared/model/dictionary-ids';
import * as moment from 'moment';

@Component({
  selector: 'user-details',
  templateUrl: 'user-details.component.html',
})
export class UserDetailsComponent extends DetailsView implements OnInit {
  readonly UserType = UserType;
  readonly UserStatus = UserStatus;
  _userDataComponent: UserDataComponent;

  @ViewChild(ConfirmDialogComponent, {static: true}) confirmDialog: ConfirmDialogComponent;
  private _user = <UserDto>{roles: []};
  get user(): UserDto {
    return this._user;
  }

  set user(value: UserDto) {
    this.saveButton.hidden =
      value.status?.id === UserStatus.DELETED_IN_AD || value.status?.id === UserStatus.DISABLED_IN_AD;
    this._user = value;
    this.userBeforeChanges = JSON.parse(JSON.stringify(value));
  }

  private userBeforeChanges: UserDto;
  roles: DictionaryDto[];
  groups: UserGroupBaseDto[];
  userCompleted = false;
  rolesCompleted = false;
  adFunc;

  constructor(
    private dictionaryService: DictionaryService,
    public userService: UserService,
    private route: ActivatedRoute,
    private routerService: RouterService,
    protected growlService: GrowlService,
    public appService: AppConfigService,
    protected loggedUserService: LoggedUserService
  ) {
    super(growlService);
    this.saveButton.hidden = false;
    this.cancelButton.hidden = false;
    this.adFunc = this.userService.getActiveDirectoryTransitions.bind(this.userService);
  }

  ngOnInit() {
    this.route.params.subscribe((params) => this.initializeView(params));
  }

  get userDataComponent() {
    return this._userDataComponent;
  }

  @ViewChild(UserDataComponent, {static: false})
  set userDataComponent(cmp: UserDataComponent) {
    if (!cmp) {
      console.log('Pushing empty user data component, doing nothing');
      return;
    }
    console.log('Pushing user data component');
    this._userDataComponent = cmp;
  }

  initializeView(params: Params) {
    const id = +params['id'];
    if (id > 0) {
      this.userService.getUser(id).subscribe({
        next: (user) => {
          this.user = user;
          this.user.password = null;
        },
        error: () => console.log('Error on getUser'),
        complete: () => (this.userCompleted = true),
      });
    } else {
      this.userCompleted = true;
      if (this.appService.kuke) {
        this.user.userType = <DictionaryBaseDto>{id: UserType.EXTRANET};
        this.user.language = <DictionaryBaseDto>{id: Language.POLISH};
      }
    }
    this.dictionaryService.getDictionary('UserType').subscribe({
      next: (result) => (this.roles = result),
      error: () => console.log('Error on getDictionary UserType'),
      complete: () => (this.rolesCompleted = true),
    });
    this.userService.getUserGroups().subscribe((groups) => (this.groups = groups));
  }

  onSave(): Promise<void> {
    this.userDataComponent.showErrors = true;
    // TODO check it
    if (!this.kukeExternal()) {
      this.userDataComponent.form.controls['businessUnit'].setValue(this.user.businessUnit);
      this.userDataComponent.form.controls['businessUnit'].updateValueAndValidity();
    }
    const userData = this.userDataComponent.user;
    if (!this.userDataComponent.form.valid || !userData.roles || userData.roles.length === 0) {
      console.log('save user validation error ');
      StringUtils.logFormInvalidFieldsRecursive(this.userDataComponent.form);
      return;
    }
    this.userDataComponent.showErrors = false;
    this.userDataComponent.cleanRetype();
    delete this.serverErrors;

    return new Promise((resolve, reject) => {
      this.confirmEditUserWithSalesRep().subscribe((save) => {
        if (save) {
          this.inProgress = true;
          this.userService.saveUser(userData).subscribe({
            next: (user) => {
              if (user.absentFrom) {
                user.absentFrom = moment(user.absentFrom).utc(true).toDate();
              }
              if (user.absentTo) {
                user.absentTo = moment(user.absentTo).utc(true).toDate();
              }
              if (this.loggedUserService.getLoggedUserData().login === this.user.login) {
                this.loggedUserService.getLoggedUserData().absentFrom = this.user.absentFrom;
                this.loggedUserService.getLoggedUserData().absentTo = this.user.absentTo;
              }
              this.user = user;
              this.showSavedMsg();
              this.routerService.toUserDetails(user.id);
            },
            error: (error) => {
              this.handleServerError(error);
              this.user.password = null;
            },
          });
        } else {
          reject();
        }
      });
    });
  }

  onCancel(): void {
    if (this.user.id && this.user.id > 0) {
      this.userService.getUser(this.user.id).subscribe((user) => (this.user = user));
    } else {
      this.user = <UserDto>{roles: []};
      this.userDataComponent.showErrors = false;
      this.initializeView(this.route.snapshot.params['id']);
    }
  }

  showSavedMsg() {
    this.growlService.notice('User is saved!');
    this.inProgress = false;
  }

  showErrorMsg() {
    this.growlService.error('Error when saving User!');
    this.inProgress = false;
  }

  showFormError() {
    this.growlService.error('The form has errors!');
  }

  checkSaveResult(error: any) {
    console.log('Save error: ' + error);
    this.userDataComponent.errorMessage = error;
    if (this.userDataComponent.errorMessage) {
      this.showErrorMsg();
    } else {
      this.showSavedMsg();
    }
  }

  private confirmEditUserWithSalesRep(): Observable<boolean> {
    if (!this.user.id) {
      return of(true);
    }
    return this.userService.salesRepUserChanges(this.user).pipe(
      mergeMap((showWarning) => {
        if (showWarning) {
          return from(
            this.confirmDialog.open(
              'user.details.salesRepConfirmationDialog.title',
              'user.details.salesRepConfirmationDialog.message'
            )
          );
        } else {
          return of(true);
        }
      })
    );
  }

  generateHistoryReport() {
    this.userService.getUserHistoryReport(this.user.id, this.user.login);
  }

  updateUserFromAD() {
    this.userService.updateUserFromAD(this.user.id).subscribe((user) => (this.user = user));
  }

  kukeExternal() {
    return !this.isIntranetUser() && this.appService.kuke;
  }

  ecgExternal() {
    return !this.isIntranetUser() && this.appService.ecg;
  }

  sendActivationLink() {
    this.userService.sendActivationLink(this.user.id).subscribe((user) => (this.user = user));
  }

  private isIntranetUser(): boolean {
    const u = this._user;
    return u && u.userType && u.userType.id === UserType.INTRANET;
  }
}
