import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Params} from '@angular/router';
import {DetailsView} from '../../../../bonding_shared/components/details-view/details-view';
import {
  BrokerContractRelationVersionSimpleDto,
  CalcProblem,
  ClausesRate,
  ErrorReason,
  OfferCalculationDto,
  OfferCalculationEnvelopeDto,
  PolicyInquiryOfferDto,
  PolicyInquiryOfferEnvelopeDto,
  PolicyInquiryVersionDto,
  SearchResult,
  SQForYearsDto,
  SQYear,
  YearKuke,
} from '../../../../bonding_shared/model/dtos';
import {TranslateService} from '@ngx-translate/core';
import {GrowlService} from '../../../../bonding_shared/services/growl/growl.service';
import {InquiryService} from '../../../../bonding_shared/services/inquiry.service';
import {AbstractControl, UntypedFormArray, UntypedFormBuilder, ValidationErrors, Validators} from '@angular/forms';
import {
  BrokerRelationStatus,
  BrokerType,
  BusinessObjectType,
  InquiryProductType,
  OFFER_CALCULABLE_STATUSES,
  PolicyElementaryRight,
} from '../../../../bonding_shared/model/dictionary-ids';
import {CompanyInfoComponent} from '../../../company/components/shared/company-info.component';
import {StringUtils} from '../../../../bonding_shared/utils';
import {BackendError} from '../../../../bonding_shared/model';
import {forkJoin as observableForkJoin} from 'rxjs';
import {IMultiSelectSettings, IMultiSelectTexts, LoggedUserService, RouterService} from '../../../../bonding_shared';

@Component({
  selector: 'calculator-details',
  templateUrl: 'calculator-details.component.html',
  styles: ['.bon-card-group {margin-top: 15px; margin-bottom: 15px}', 'table td {border-top: none}'],
})
export class CalculatorDetailsComponent extends DetailsView implements OnInit {
  offerId: number;

  inquiry: PolicyInquiryVersionDto = null;
  loaded = false;
  offer: PolicyInquiryOfferDto;
  problems: CalcProblem[] = null;

  readonly PolicyElementaryRight = PolicyElementaryRight;
  readonly BusinessObjectType = BusinessObjectType;
  readonly table4 = ['a', 'b', 'c', 'd', 'polska', 'x1', 'x2'];
  readonly table5 = [
    {key: 'limit1', label: 'na najwyższym limicie'},
    {key: 'limit2', label: 'na 2 najwyższych limitach'},
    {key: 'limit3', label: 'na 3 najwyższych limitach'},
  ];
  readonly table6 = Array.from(Array(3).keys()).map((v) => v + 1);

  readonly multiSelectSettings: IMultiSelectSettings = {
    enableSearch: true,
    dynamicTitleMaxItems: 0,
    buttonClasses: 'bon-btn-warning',
    selectionLimit: 1000,
    numSelectedOff: true,
  };
  readonly BrokerRelationStatus = BrokerRelationStatus;
  marketSelectTexts: IMultiSelectTexts = {defaultTitle: 'policyInquiry.details.countries.addMarket'};

  @ViewChild('companyInfo')
  set companyInfoComponent(cmp: CompanyInfoComponent) {
    if (cmp) {
      this._companyInfoComponent = cmp;
    }
  }

  get companyInfoComponent() {
    return this._companyInfoComponent;
  }
  _companyInfoComponent: CompanyInfoComponent;
  clausesRates: UntypedFormArray;
  // countries: DictionaryBaseDto[] = null;

  constructor(
    private route: ActivatedRoute,
    public routerService: RouterService,
    protected translateService: TranslateService,
    protected growlService: GrowlService,
    private inquiryService: InquiryService,
    private formBuilder: UntypedFormBuilder,
    loggedUserService: LoggedUserService
  ) {
    super(growlService);
    this.saveButton.hidden = !loggedUserService.hasRight(PolicyElementaryRight.POLICY_INQUIRY_CALCULATOR_SAVE);
    this.cancelButton.hidden = !loggedUserService.hasRight(PolicyElementaryRight.POLICY_INQUIRY_CALCULATOR_SAVE);
    this.clausesRates = formBuilder.array([]);

    this.initOtherForm();
  }

  private initOtherForm() {
    const formBuilder = this.formBuilder;
    this.form = formBuilder.group({
      tariffRate: [{value: null, disabled: true}],
      creationDate: [{value: new Date(), disabled: true}],
      exportRate: [{value: null, disabled: true}],
      riskApetite: [{value: null, disabled: true}],
      currentInsurersRate: [{value: null, disabled: true}],
      declaredTurnover: [{value: null, disabled: true}],
      domesticRate: [{value: null, disabled: true}],
      naceCode: [{value: null, disabled: true}],
      riskApetiteFactor: [{value: null, disabled: true}],
      tofactor: [{value: null, disabled: true}],
      sectorFactor: [{value: null, disabled: true}],
      tableYearEx: formBuilder.group({
        last3YearInCompetition: formBuilder.group({
          premium: [{value: null, disabled: this.dataFetchedFromExternal()}],
          indemnifications: [{value: null, disabled: this.dataFetchedFromExternal()}],
          recoveries: [{value: null, disabled: this.dataFetchedFromExternal()}],
          claims: [{value: null, disabled: this.dataFetchedFromExternal()}],
          claimsRatio: [{value: null, disabled: this.dataFetchedFromExternal()}],
        }),
        last2YearInCompetition: formBuilder.group({
          premium: [{value: null, disabled: this.dataFetchedFromExternal()}],
          indemnifications: [{value: null, disabled: this.dataFetchedFromExternal()}],
          recoveries: [{value: null, disabled: this.dataFetchedFromExternal()}],
          claims: [{value: null, disabled: this.dataFetchedFromExternal()}],
          claimsRatio: [{value: null, disabled: this.dataFetchedFromExternal()}],
        }),
        lastYearInCompetition: formBuilder.group({
          premium: [{value: null, disabled: this.dataFetchedFromExternal()}],
          indemnifications: [{value: null, disabled: this.dataFetchedFromExternal()}],
          recoveries: [{value: null, disabled: this.dataFetchedFromExternal()}],
          claims: [{value: null, disabled: this.dataFetchedFromExternal()}],
          claimsRatio: [{value: null, disabled: this.dataFetchedFromExternal()}],
        }),
      }),
      tableYearNoIns: formBuilder.group({
        last3YearInCompetition: formBuilder.group({
          premium: [{value: null, disabled: this.dataFetchedFromExternal()}],
          lostReceivables: [{value: null, disabled: this.dataFetchedFromExternal()}],
          claimsRatio: [{value: null, disabled: this.dataFetchedFromExternal()}],
        }),
        last2YearInCompetition: formBuilder.group({
          premium: [{value: null, disabled: this.dataFetchedFromExternal()}],
          lostReceivables: [{value: null, disabled: this.dataFetchedFromExternal()}],
          claimsRatio: [{value: null, disabled: this.dataFetchedFromExternal()}],
        }),
        lastYearInCompetition: formBuilder.group({
          premium: [{value: null, disabled: this.dataFetchedFromExternal()}],
          lostReceivables: [{value: null, disabled: this.dataFetchedFromExternal()}],
          claimsRatio: [{value: null, disabled: this.dataFetchedFromExternal()}],
        }),
      }),
      numberOfYears: [{value: null, disabled: true}],
      adjustedClaimsRatio: [{value: null, disabled: true}],
      claimsFactor: [{value: null, disabled: true}],
      abcdcountriesFactor: [{value: null, disabled: true}],
      costFactor: [{value: null, disabled: true}], // wartosc domyslna
      countriesFactor: [{value: null, disabled: true}],
      intermediaryFactor: [{value: null, disabled: true}],
      intermediaryCommission: [{value: null, disabled: true}],
      brokerIntermediaryCommission: [{value: null, disabled: true}],
      agentIntermediaryCommission: [{value: null, disabled: true}],
      rebateFactor: ['', Validators.required],
      premiumRateAfterRebate: [{value: null, disabled: true}],
      expectedPremium: [{value: null, disabled: true}],
      xpremium: ['', Validators.required],
      minPremiumAdjustFactor: ['', Validators.required],
      selfRetention: ['', Validators.required],
      minPremium: [],
      turnoverPerCountryGroups: [],
      turnoverByGroupOfCountries: formBuilder.group(
        this.table4.reduce((accum, v) => {
          accum[v] = formBuilder.group({
            amount: [{value: 0, disabled: true}],
            rate: [{value: null, disabled: true}],
          });
          return accum;
        }, <any>{})
      ),
      balance: formBuilder.group({
        averageOutstandingsBalance1quarters: [{value: '', disabled: true}],
        averageOutstandingsBalance2quarters: [{value: '', disabled: true}],
        averageOutstandingsBalance3quarters: [{value: '', disabled: true}],
        averageOutstandingsBalance4quarters: [{value: '', disabled: true}],
        turnoverForLast4Quarters: [{value: '', disabled: true}],
        averageOutstandingsBalance: [{value: null, disabled: true}],
        premiumCalculationBase: [{value: null, disabled: true}],
        tariffRateBalance: [{value: null, disabled: true}],
        tariffRateBalance2: [{value: null, disabled: true}],
        balanceExpectedPremium: [{value: null, disabled: true}],
        balanceMinPremium: [{value: null, disabled: true}],
      }),
      expectedXPremiumTable: formBuilder.group(
        this.table5.reduce((accum, v) => {
          accum[v.key] = formBuilder.group({
            amount: [0],
          });
          return accum;
        }, <any>{})
      ),
      xpremiumTable: formBuilder.array(
        this.table6.map((_) =>
          formBuilder.group({
            amountInCurrency: [{value: null, disabled: true}],
            currency: [{value: null, disabled: true}],
            fxrate: [{value: null, disabled: true}],
            fxrateMultiplier: [],
            amountInPLN: [{value: '', disabled: this.isXpremiumTableFull() || this.dataFetchedFromExternal()}],
          })
        )
      ),
      clausesRates: this.clausesRates,
    });
  }

  ngOnInit() {
    this.route.params.subscribe((params) => this.initializeView(params));
  }

  initializeView(params: Params) {
    // this.cd.detach();
    this.offerId = +params['offerId'];

    if (this.offerId === 0) {
    } else {
      this.inProgress = true;
      this.inquiryService.getOffer(this.offerId).subscribe(
        (offer) => this.handleGetOffer(offer),
        (error) => this.handleServerError(error),
        () => (this.inProgress = false)
      );
    }
  }

  private handleGetOffer(envelope: PolicyInquiryOfferEnvelopeDto) {
    this.offer = envelope.offer;
    this.serverErrors = undefined;
    this.showErrors = false;

    this.saveButton.disabled = !this.isCalcEnabled();

    observableForkJoin(
      this.inquiryService.getById<PolicyInquiryVersionDto>(this.offer.policyInquiryVersion.id),
      this.inquiryService.getCalculate(this.offerId)
    ).subscribe(
      (res) => {
        this.setInquiry(res[0]);
        const offerCalculationEnv = res[1];
        this.handleGetCalculation(offerCalculationEnv);
        this.initSqYears(Array.isArray(offerCalculationEnv.calculation.tableYearSumKuke));
      },
      (error) => this.handleServerError(error),
      () => (this.inProgress = false)
    );
  }

  private setInquiry(inquiry: PolicyInquiryVersionDto) {
    this.inquiry = inquiry;
    // this.countries = inquiry.countries.map(c => c.country);
  }

  private initSqYears(existsTableYearSumKuke: boolean) {
    const masterPCId = this.inquiry.basePolicyContractVersion?.policyContract?.masterPolicyContract?.id;
    if (masterPCId && !existsTableYearSumKuke) {
      this.inquiryService.getCoefficientSQParams(masterPCId).subscribe((years) => {
        if (years && years.length > 0) {
          this.tableYearKukeRefresh(years);
        }
      });
    }
  }

  private handleGetCalculation(offerCalculationEnv: OfferCalculationEnvelopeDto) {
    this.loaded = true;
    this.patchValue(offerCalculationEnv.calculation);

    this.problems = offerCalculationEnv.problems;
    this.serverErrors = undefined;
    this.showErrors = false;
  }

  private patchValue(offerCalculation: OfferCalculationDto) {
    this.form.patchValue(offerCalculation);
    this.patchClauseRates(offerCalculation);
    this.patchTableYearKuke(offerCalculation);

    this.form.get('creationDate').setValue(new Date(this.form.get('creationDate').value));
  }

  private patchClauseRates(offerCalculation: OfferCalculationDto) {
    const clausesRates = offerCalculation.clausesRates;
    if (clausesRates && clausesRates.length) {
      const clausesRatesCtr = <UntypedFormArray>this.form.get('clausesRates');
      clausesRates.forEach((x) => {
        const foundClauseCtr = clausesRatesCtr.controls.find((c) => c.get('typeCode').value === this.clauseCode(x));
        if (foundClauseCtr) {
          foundClauseCtr.get('rate').setValue(x.rate);
        } else {
          clausesRatesCtr.push(this.clausesRatesControl(x));
        }
      });
    }
  }

  private patchTableYearKuke(offerCalculation: OfferCalculationDto) {
    this.setTableYearKukeForm(offerCalculation.tableYearKuke, offerCalculation.tableYearSumKuke);
  }

  private clausesRatesControl(cl: ClausesRate) {
    return this.formBuilder.group({
      name: [cl.name],
      typeCode: [this.clauseCode(cl)],
      rate: [cl.rate, Validators.compose([Validators.min(0), Validators.max(100)])],
    });
  }

  private clauseCode(cl: ClausesRate) {
    return StringUtils.shortCode(cl.typeCode);
  }

  onSave(): void {
    if (this.inProgress) {
      console.warn('Parallel save clicks');
      return;
    }

    this.showErrors = true;
    if (!this.form.valid) {
      this.showFormError();
      console.log(this.form);
      StringUtils.logFormInvalidFieldsRecursive(this.form);
      return;
    }
    this.inProgress = true;
    this.serverErrors = null;

    this.inquiryService.saveCalculation(this.offerId, this.prepareEnvelope()).subscribe(
      (_) => this.goToOffer(),
      (error) => this.handleServerError(error),
      () => (this.inProgress = false)
    );
  }

  private getOfferCalculation(): OfferCalculationDto {
    const offerCalculation: OfferCalculationDto = this.form.getRawValue();
    return offerCalculation;
  }

  private tableYearKukeRefresh(years: SQForYearsDto[]) {
    const sqYears: {years: SQYear[]; sums: SQYear[]} = {
      years: years.map((y) => y.year),
      sums: years.map((y) => y.sum),
    };

    if (!sqYears || !sqYears.years || !sqYears.years.length) {
      return;
    }
    const toYearKuke = (sy) =>
      <YearKuke>{
        claimsRatio: sy.sq,
        ibnrclaim: sy.ibnr,
        recoveries: sy.regres,
        openClaims: sy.rbnp,
        premium: sy.netamount,
        indemnifications: sy.tobepaidapprovedforpaymentamount,
      };
    const tableYearKuke = sqYears.years.map(toYearKuke);

    const tableYearSumKuke = sqYears.sums.map(toYearKuke);
    this.setTableYearKukeForm(tableYearKuke, tableYearSumKuke);
  }

  private setTableYearKukeForm(tableYearKuke: YearKuke[], tableYearSumKuke: YearKuke[]) {
    const setControl = (name: string, yearKuke?: YearKuke[]) => {
      const buildYearForm = (tyk: YearKuke[]) => {
        const tearKukeToForm = (ty) =>
          <any>{
            claimsRatio: [{value: ty.claimsRatio, disabled: true}],
            ibnrclaim: [{value: ty.ibnrclaim, disabled: true}],
            recoveries: [{value: ty.recoveries, disabled: true}],
            openClaims: [{value: ty.openClaims, disabled: true}],
            premium: [{value: ty.premium, disabled: true}],
            indemnifications: [{value: ty.indemnifications, disabled: true}],
          };

        return this.formBuilder.array(tyk.map((ty) => this.formBuilder.group(tearKukeToForm(ty))));
      };

      if (yearKuke) {
        this.form.setControl(name, buildYearForm(yearKuke));
      }
    };

    setControl('tableYearKuke', tableYearKuke);
    setControl('tableYearSumKuke', tableYearSumKuke);
  }

  private prepareEnvelope(): OfferCalculationEnvelopeDto {
    const envelope = <OfferCalculationEnvelopeDto>{};

    envelope.calculation = this.getOfferCalculation();
    return envelope;
  }

  onCancel(route?: ActivatedRoute) {
    this.goToOffer();
  }

  private goToOffer() {
    this.routerService.toKukeInquiryOfferDetails(this.offerId);
  }

  onCalculate() {
    this.showErrors = true;
    if (!this.form.valid) {
      this.showFormError();
      console.log(this.form);
      StringUtils.logFormInvalidFieldsRecursive(this.form);
      return;
    }
    this.inProgress = true;
    this.serverErrors = null;

    this.inquiryService.calculate(this.offerId, this.prepareEnvelope()).subscribe({
      next: (calcResult) => {
        this.handleGetCalculation(calcResult);
        this.growlService.notice('inquiry.calculator.succeeded');
      },
      error: (error) => this.handleServerError(error),
      complete: () => (this.inProgress = false),
    });
  }

  isXpremiumTableFull(): boolean {
    return !this.isSP();
  }

  isBalanceVisible(): boolean {
    return this.isSP();
  }

  private requiredIfBalancedVisible(control: AbstractControl): ValidationErrors | null {
    if (this.isBalanceVisible()) {
      return Validators.required(control);
    } else {
      return null;
    }
  }

  private isSP(): boolean {
    const inq = this.inquiry;
    return inq && inq.productType && inq.productType.id && inq.productType.id === InquiryProductType.KUKE_KOM_SP;
  }

  onBrokerListSearchFinished(brokers: SearchResult<BrokerContractRelationVersionSimpleDto>) {
    if (!brokers || !brokers.result || !brokers.result.length) {
      return;
    }

    const percentages = brokers.result.filter((s) => s.commissionFee && s.commissionFee.percentage);
    const sumPercentage = (sum: number, val: BrokerContractRelationVersionSimpleDto) =>
      sum + val.commissionFee.percentage;

    const filterByType = (typeId: number) => (b: BrokerContractRelationVersionSimpleDto) => {
      const tId = b.brokerRelation.brokerContract.brokerType;
      return tId && tId.id === typeId;
    };
    const toPercent = (val: number) => {
      if (!val) {
        return null;
      }
      return val / 100;
    };
    const intermediaryCommission = toPercent(percentages.reduce(sumPercentage, 0));
    const brokerIntermediaryCommission = toPercent(
      percentages.filter(filterByType(BrokerType.BROKER)).reduce(sumPercentage, 0)
    );
    const agentIntermediaryCommission = toPercent(
      percentages.filter(filterByType(BrokerType.AGENT)).reduce(sumPercentage, 0)
    );

    this.form.get('intermediaryCommission').setValue(intermediaryCommission);
    this.form.get('brokerIntermediaryCommission').setValue(brokerIntermediaryCommission);
    this.form.get('agentIntermediaryCommission').setValue(agentIntermediaryCommission);
  }

  dataFetchedFromExternal() {
    return true;
  }

  handleServerError(error: BackendError | OfferCalculationEnvelopeDto, showGrowl = true) {
    this.inProgress = false;

    if (error.hasOwnProperty('problems')) {
      const oc = <OfferCalculationEnvelopeDto>error;
      this.handleGetCalculation(oc);
      super.handleServerError(this.getBackendError(oc), showGrowl);
    } else {
      super.handleServerError(<BackendError>error, showGrowl);
    }
  }

  private getBackendError(oc: OfferCalculationEnvelopeDto): BackendError {
    return oc.problems.map(
      (p) => <ErrorReason>{message: this.translateService.instant('inquiry.calculator.errors.' + p)}
    );
  }

  minPremiumDisabled() {
    return this.isNotE50Problem() && (!(this.isAnnex() === true) || !this.isMinPremiumSet());
  }

  private isAnnex(): boolean {
    return this.inquiry?.policyInquiry?.inquiryType === 'POLICY_ANNEX';
  }

  private isMinPremiumSet(): boolean {
    const isNumber = (v) => typeof v === 'number' && isFinite(v);
    return isNumber(this.form.get('minPremium')?.value);
  }

  expectedXPremiumTableDisabled() {
    return this.isNotE50Problem();
  }

  private isNotE50Problem() {
    return !this.isProblem('RULE_E50_NOT_MET');
  }

  private isProblem(p: CalcProblem): boolean {
    const problems = this.problems;
    if (!problems || problems.length === 0) {
      return false;
    }
    return problems.indexOf(p) > -1;
  }

  onNewInquiry() {
    const o = this.offer;
    const companyId =
      o &&
      o.policyInquiryVersion &&
      o.policyInquiryVersion.client &&
      o.policyInquiryVersion.client.company &&
      o.policyInquiryVersion.client.company.id;
    this.routerService.toInquiryDetailsNew(companyId, this.offer.type.id);
  }

  isCalcEnabled() {
    const of = this.offer;
    if (!of || !of.status || !of.status.id) {
      return false;
    }
    return OFFER_CALCULABLE_STATUSES.includes(of.status.id);
  }
}
