import {DetailsView} from '../../bonding_shared/components/details-view/details-view';
import {Component, OnInit, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import {
  AppConfigService,
  CountryCategoryLimitVersionBaseDto,
  CountryCategoryLimitVersionCriteriaDto,
  CountryCategoryLimitVersionDto,
  DictionaryBaseDto,
  GrowlService,
  LoggedUserService,
  Page,
  RouterService,
  SearchCriteria,
  StringUtils,
} from '../../bonding_shared';
import {CountryCategoryLimitsService} from '../../bonding_shared/services/country-category-limits.service';
import {ActivatedRoute, Params} from '@angular/router';
import {CountryCategoryLimitStatus} from '../../bonding_shared/model/dictionary-ids';

@Component({
  selector: 'country-category-limits-details',
  templateUrl: 'country-category-limits-details.component.pug',
})
export class CountryCategoryLimitsDetailsComponent extends DetailsView implements OnInit {
  @ViewChild('ngForm', {static: true}) ngForm: NgForm;
  ratingVersion: CountryCategoryLimitVersionDto;
  ratingVersions: CountryCategoryLimitVersionBaseDto[];
  public versionId: number;
  private readonly newVersionButtonLabelKey = 'common.change';

  constructor(
    private route: ActivatedRoute,
    public appService: AppConfigService,
    public router: RouterService,
    protected growlService: GrowlService,
    private countryCategoryLimitsService: CountryCategoryLimitsService,
    private loggedUserService: LoggedUserService
  ) {
    super(growlService);
    this.ratingVersion = <CountryCategoryLimitVersionDto>{};
    this.deleteButton = undefined;
    this.newVersionButton.name = this.newVersionButtonLabelKey;
  }

  public ngOnInit(): void {
    this.form = this.ngForm.form;
    this.route.params.subscribe((params) => this.initializeView(params));
  }

  public initializeView(params: Params): void {
    this.versionId = +params['versionId'];
    if (this.versionId) {
      this.setCountryCategoryLimitVersion(this.versionId);
    } else {
      this.setCountryCategoryLimitVersionInitial();
    }
  }

  onSelectVersion(r: CountryCategoryLimitVersionDto) {
    this.setCountryCategoryLimitVersion(r.id);
    this.handleButtons();
  }

  public editable(): boolean {
    return this.ratingVersion.last && !this.ratingVersion.id;
  }

  onSave(): void {
    this.serverErrors = undefined;
    if (!this.ngForm.form.valid) {
      this.showFormError();
      StringUtils.logFormInvalidFields(this.ngForm.form);
      return;
    }
    this.inProgress = true;
    this.save(CountryCategoryLimitStatus.INACTIVE);
  }

  private save(statusId: number): void {
    const originalId = this.ratingVersion.id;
    this.ratingVersion.status = <DictionaryBaseDto>{id: statusId};
    this.countryCategoryLimitsService.save<CountryCategoryLimitVersionDto>(this.ratingVersion).subscribe({
      next: (v) => {
        this.ratingVersion = v;
        this.afterObjectSaved(originalId, () => this.router.toCountryCategoryLimitDetails(v.id));
      },
      error: (error) => {
        this.handleServerError(error);
      },
    });
  }

  private refreshVersions() {
    const criteria = <SearchCriteria<CountryCategoryLimitVersionCriteriaDto>>{};
    criteria.criteria = <CountryCategoryLimitVersionCriteriaDto>{};
    criteria.page = <Page>{start: 0, count: 300};
    this.countryCategoryLimitsService
      .searchByCriteria<CountryCategoryLimitVersionCriteriaDto, CountryCategoryLimitVersionBaseDto>(criteria)
      .subscribe({
        next: (search) => {
          this.ratingVersions = search.result;
        },
        error: (error) => this.handleServerError(error),
      });
  }

  private setCountryCategoryLimitVersionInitial() {
    this.countryCategoryLimitsService.getInitialVersion().subscribe({
      next: (v) => {
        this.setRatingVersion(v);
      },
      error: (error) => this.handleServerError(error),
    });
  }

  private setRatingVersion(v: CountryCategoryLimitVersionDto): void {
    this.ratingVersion = v;
    this.handleButtons();
  }

  private setCountryCategoryLimitVersion(versionId: number) {
    this.countryCategoryLimitsService.getById<CountryCategoryLimitVersionDto>(versionId).subscribe({
      next: (v) => {
        this.setRatingVersion(v);
        this.refreshVersions();
      },
      error: (error) => this.handleServerError(error),
    });
  }

  public onCreateNewVersion(): void {
    this.countryCategoryLimitsService
      .newVersion<CountryCategoryLimitVersionDto>(this.ratingVersion.countryCategoryLimit.id)
      .subscribe((cclv) => {
        this.setRatingVersion(cclv);
      });
    this.handleButtons();
  }

  public onCancel(): void {
    super.onCancel();
    this.setCountryCategoryLimitVersion(this.ratingVersions[0].id);
    this.handleButtons();
  }

  protected handleButtons(): void {
    this.hideButtons(false);
    this.saveButton.hidden = this.ratingVersion.id > 0;
    this.cancelButton.hidden = this.ratingVersion.id > 0;
    this.newVersionButton.hidden = !this.ratingVersion.id;
  }

  public disabledForButtonActive(): boolean {
    return !(this.ratingVersion.id && !this.isSelectedCurrentUser() && this.ratingVersion.last);
  }

  public onActive() {
    this.serverErrors = undefined;
    if (!this.ngForm.form.valid) {
      this.showFormError();
      StringUtils.logFormInvalidFields(this.ngForm.form);
      return;
    }
    this.inProgress = true;
    this.save(CountryCategoryLimitStatus.ACTIVE);
  }

  isSelectedCurrentUser(): boolean {
    return (
      this.ratingVersion.createdBy && this.loggedUserService.getLoggedUserData().id === this.ratingVersion.createdBy.id
    );
  }
}
