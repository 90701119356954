import {Directive} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, ValidationErrors, Validator} from '@angular/forms';
import {CustomValidators} from '../../../../bonding_shared/validators';

@Directive({
  selector: '[passwordConfirm]',
  providers: [{provide: NG_VALIDATORS, useExisting: PasswordConfirmDirective, multi: true}],
})
export class PasswordConfirmDirective implements Validator {
  validate(control: AbstractControl): ValidationErrors {
    return CustomValidators.passwordConfirmValidation(control);
  }
}
