import {Component} from '@angular/core';
import {RouterService} from '../../services';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'bo-route',
  template: '',
})
export class BusinessObjectRoutingComponent {
  constructor(private router: RouterService, private route: ActivatedRoute) {
    this.route.queryParams.subscribe((params) => {
      router.goToBusinessObject(+params['relatedTo'], +params['relatedToId']);
    });
  }
}
