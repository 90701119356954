import {Component, OnInit, ViewChild} from '@angular/core';
import {DetailsView} from '../../bonding_shared/components/details-view/details-view';
import {
  GrowlService,
  LoggedUserService,
  RouterService,
  StringUtils,
  UserDto,
  UserHolidaySettingsDto,
  UserNamePipe,
  UserRange,
  UserSimpleDto,
} from '../../bonding_shared';
import {NgForm} from '@angular/forms';
import {ActivatedRoute, Params} from '@angular/router';
import {UserHolidaySettingsService} from '../../bonding_shared/services/user-holiday-settings-service';

@Component({
  selector: 'user-holiday-settings-details',
  templateUrl: 'user-holiday-settings-details.component.pug',
})
export class UserHolidaySettingsDetailsComponent extends DetailsView implements OnInit {
  @ViewChild('ngForm', {static: true}) public ngForm: NgForm;
  public holidaySettings: UserHolidaySettingsDto;
  public holidaysSettings: UserHolidaySettingsDto[];
  readonly employeeUserRange: UserRange = 'employees';
  disableChangeEmployee = !this.loggedUserService.isHRRole();
  availableYears = this.userHolidaySettingsService.prepareAvailableYears();
  year: AvailableYear;

  constructor(
    protected growlService: GrowlService,
    private loggedUserService: LoggedUserService,
    private route: ActivatedRoute,
    private router: RouterService,
    private userHolidaySettingsService: UserHolidaySettingsService
  ) {
    super(growlService);
    this.saveButton.hidden = false;
    this.cancelButton.hidden = true;
  }

  ngOnInit() {
    this.route.params.subscribe((params) => this.initializeView(params));
  }

  initializeView(params: Params) {
    const holidaySettingId = +params['id'];
    if (holidaySettingId === 0) {
      this.holidaySettings = <UserHolidaySettingsDto>{};
      this.holidaySettings.user = this.getLoggedUser();
      this.year = <AvailableYear>{};
      this.getUserHolidaySettings(this.holidaySettings.user.id);
    } else {
      this.userHolidaySettingsService.getById<UserHolidaySettingsDto>(holidaySettingId).subscribe({
        next: (h) => {
          this.holidaySettings = h;
          this.year = <AvailableYear>{id: this.holidaySettings.cyear, name: this.holidaySettings.cyear.toString()};
          this.getUserHolidaySettings(this.holidaySettings.user.id);
        },
        error: (error) => {
          this.handleServerError(error);
        },
      });
    }
  }

  onUserChange(item: UserSimpleDto) {
    this.holidaySettings.user = item;
    this.getUserHolidaySettings(item.id);
  }

  userFullName = (u: UserDto): string => {
    return new UserNamePipe().transform(u);
  };

  private getLoggedUser(): UserDto {
    return this.loggedUserService.getLoggedUserData();
  }

  private getUserHolidaySettings(userId: number) {
    this.holidaysSettings = null;
    this.userHolidaySettingsService
      .getUserHolidaySettings(userId)
      .subscribe((res) => (this.holidaysSettings = res.result));
  }

  public onSave(): void {
    this.serverErrors = undefined;
    if (!this.ngForm.form.valid) {
      this.showFormError();
      StringUtils.logFormInvalidFields(this.ngForm.form);
      return;
    }
    if (this.typeExists(this.holidaySettings.cyear, this.holidaySettings.type.id, this.holidaySettings.id)) {
      this.growlService.error('Your settings overlaps other settings');
      return;
    }
    this.inProgress = true;
    this.save();
  }

  private save(): void {
    const originalId = this.holidaySettings.id;
    this.userHolidaySettingsService.save<UserHolidaySettingsDto>(this.holidaySettings).subscribe({
      next: (s) => {
        this.holidaySettings = s;
        if (originalId > 0) {
          this.getUserHolidaySettings(s.user.id);
        }
        this.afterObjectSaved(originalId, () => this.router.toHolidaySettingsDetails(s.id));
      },
      error: (error) => {
        this.handleServerError(error);
      },
    });
  }

  onYearChange(value: AvailableYear) {
    this.holidaySettings.cyear = value.id;
  }

  private typeExists(year: number, type: number, holidayId: number): boolean {
    return this.holidaysSettings
      .filter((h) => h.id !== holidayId)
      .some((settings) => {
        return settings.type.id === type && settings.cyear === year;
      });
  }
}

export class AvailableYear {
  id: number;
  name: string;
}
