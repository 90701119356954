import {Component, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {
  AppConfigService,
  CollectionAccountedCostDto,
  CollectionAccountedCostsService,
  CollectionCostInsurerShareRule,
  CollectionEstimatedCostDto,
  CollectionExternalProviderRelationsService,
  CollectionPaymentDto,
  CollectionPaymentsService,
  CollectionVersionService,
  FormDialogComponent,
  GrowlService,
  LoggedUserService,
  RouterService,
  TemplateService,
} from '../../bonding_shared';
import {CollectionCostsAbstractComponent} from './collection-costs-abstract.component';
import * as _ from 'lodash';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'collection-accounted-costs',
  templateUrl: 'collection-costs.component.pug',
})
export class CollectionAccountedCostsComponent extends CollectionCostsAbstractComponent {
  commissionPaymentsSelected: CollectionPaymentDto[];

  @ViewChild(FormDialogComponent, {static: true}) commissionPaymentsDialog: FormDialogComponent;

  constructor(
    route: ActivatedRoute,
    versionService: CollectionVersionService,
    parentService: CollectionAccountedCostsService,
    router: RouterService,
    translateService: TranslateService,
    growlService: GrowlService,
    loggedUserService: LoggedUserService,
    collectionExternalProviderRelationsService: CollectionExternalProviderRelationsService,
    templateService: TemplateService,
    private paymentsService: CollectionPaymentsService,
    appConfig: AppConfigService
  ) {
    super(
      route,
      versionService,
      parentService,
      router,
      translateService,
      growlService,
      loggedUserService,
      collectionExternalProviderRelationsService,
      templateService,
      appConfig
    );
    this.titleLabelKey = 'collectionCosts.accountedCostsTitle';
  }

  estimatedCostsEditable(): boolean {
    return false;
  }

  newAccountedCost(e: CollectionEstimatedCostDto) {
    this.addAccountedCost(e, this.defaultInsurerShare(e));
  }

  addAccountedCost(e: CollectionEstimatedCostDto, insurerShare: number) {
    this.current.accountedCosts.push(<CollectionAccountedCostDto>{
      insurerShare: insurerShare,
      estimatedCost: e,
      currency: e.currency,
    });
  }

  deleteAccountedCost(a: CollectionAccountedCostDto) {
    _.remove(this.current.accountedCosts, (c) => c === a);
  }

  defaultInsurerShare(ec: CollectionEstimatedCostDto): number {
    const rule = this.findInsurerShareRule(ec);
    return rule && rule.defaultInsurerShare;
  }

  isInsurerShareEditable(ac: CollectionAccountedCostDto): boolean {
    const rule = this.findInsurerShareRule(ac.estimatedCost);
    return !rule || rule.editable;
  }

  private findInsurerShareRule(c: CollectionEstimatedCostDto): CollectionCostInsurerShareRule {
    return (
      this.parent.type &&
      this.current.insurerShareRules.find(
        (r) => r.collectionTypeId === this.parent.type.id && r.collectionCostCalcTypeId === c.calcType.id
      )
    );
  }

  public get pickUser(): boolean {
    return !!(this.current.warnings && this.current.warnings.length);
  }
}
