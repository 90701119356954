import {BatchExecutionDetailsComponent} from './batch-execution-details.component';
import {BatchExecutionsComponent} from './batch-executions.component';
import {BatchRunComponent} from './batch-run.component';

export const BatchRoutes = [
  {
    path: 'batch-execution/:id',
    component: BatchExecutionDetailsComponent,
  },
  {
    path: 'batch-executions',
    component: BatchExecutionsComponent,
  },
  {
    path: 'batch-run/:name',
    component: BatchRunComponent,
  },
];
