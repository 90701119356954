import {Component, Input} from '@angular/core';
import {ControlContainer, NgForm} from '@angular/forms';

@Component({
  selector: 'vip-criteria',
  template: `
    <div>
      <a-table [sortable]="false"> <column labelKey="policyAssignmentRule.vipTitle"> </column> </a-table>
      <select class="bon-select" [(ngModel)]="vip" [ngModelOptions]="{standalone: true}">
        <option [value]="null">
          <span> {{ 'policyAssignmentRule.vipNull' | translate }}</span>
        </option>
        <option [value]="true">
          <span> {{ 'policyAssignmentRule.vipTrueValue' | translate }}</span>
        </option>
        <option [value]="false">
          <span> {{ 'policyAssignmentRule.vipFalseValue' | translate }}</span>
        </option>
      </select>
    </div>
  `,
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}],
})
export class VipCriteriaComponent {
  @Input() vip: boolean;
}
